import type { IdType } from '@repo-breteuil/common-definitions';
import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface AddNegativePropertyFeedbackArgs
{
  id: IdType,
  propertyId: IdType,
  propertyPrice: number,
}

export default function AddNegativePropertyFeedback(args: AddNegativePropertyFeedbackArgs)
{
  return mutation<IdType>({
    operationName: 'Contact_AddNegativePropertyFeedback',
    variables: {
      id: 'Int!',
      propertyId: 'Int!',
      propertyPrice: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: {
          addNegativePropertyFeedback: [{
            args: {
              id: true,
              propertyId: true,
              propertyPrice: true,
            },
          }, { id: true }],
        },
      }),
    },
  }, args);
}
