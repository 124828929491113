import { mutation, errorWrapper } from "@core/api/graphql/gen-v2";

export interface CreateArgs
{
  name: string,
  file: File,
  defaultDisplayDurationMs?: number | null | undefined,
}

async function CreateShowcaseMedia(args: CreateArgs): Promise<void>
{
  await mutation({
    operationName: 'Showcase_MediasList_CreateShowcaseMedia',
    variables: {
      name: 'String!',
      file: 'Upload!',
      defaultDisplayDurationMs: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseMedia: {
          create: [{
            args: {
              name: true,
              file: true,
              defaultDisplayDurationMs: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, args);
}

export default CreateShowcaseMedia;
