import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs } from '@repo-lib/graphql-query-builder';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface CreateAreaStreetArgs
{
  userId: IdType,
  name: string,
}

const CreateAreaStreetArgsVariables = {
  userId: 'Int!',
  name: 'String!',
} as const;

const CreateAreaStreetArgsFields: ReadonlyFieldArgs = {
  userId: true,
  name: true,
};

export default function CreateAreaStreet(args: CreateAreaStreetArgs)
{
  return mutation<IdType>({
    operationName: 'Prospecting_CreateAreaStreet',
    variables: CreateAreaStreetArgsVariables,
    fieldsSelection: {
      authenticated: errorWrapper({
        prospectingAreaStreet: {
          create: [{
            args: CreateAreaStreetArgsFields,
          }, {
            id: true,
          }],
        },
      }),
    },
  }, args);
}
