import { Day } from '@repo-lib/utils-core';
import { mapRecord } from '@repo-lib/utils-core';

export function getUserActiveDaysInPeriod(
  args: {
    user: {
      arrivedAt: number,
      leavedAt: number | null,
    },
    startDate: number,
    endDate: number,
  },
)
{
  const { user, startDate, endDate } = args;
  const { arrivedAt, leavedAt } = user;
  const effectiveStartDate = Math.max(startDate, arrivedAt);
  const effectiveEndDate = leavedAt ? Math.min(endDate, leavedAt) : endDate;
  const startDay = Math.floor(effectiveStartDate / Day);
  const endDay = Math.floor(effectiveEndDate / Day);
  return Math.max(endDay - startDay, 0);
}

export type AggregateMultipleMethod<ItemKeyType>
  = { method: 'sum', field?: ItemKeyType | undefined }
  | { method: 'avg', field?: ItemKeyType | undefined }
  | { method: 'weightedAvg', weightField: ItemKeyType, field?: ItemKeyType | undefined }
  // TODO implement other methods (min, max, etc)?
;

export function aggregateMultiple<
  ItemType extends Record<string, any>,
  OptionsKeyType extends string,
>(
  items: Array<ItemType>,
  opts: Record<OptionsKeyType, AggregateMultipleMethod<keyof ItemType>>,
)
{
  const sums: Partial<Record<OptionsKeyType, number>> = {};
  const counts: Partial<Record<OptionsKeyType, number>> = {};

  for (const item of items)
  {
    for (const optKey of Object.keys(opts) as Array<OptionsKeyType>)
    {
      const opt = opts[optKey];
      const field = opt.field ?? optKey;
      const itemValue = item[field as string]; // Safe cast
      if (itemValue === undefined)
        throw new Error(`Invalid argKey: ${optKey}`);
      if (itemValue === null) // NULL values are simply ignored
        continue;
      switch (opt.method)
      {
        case 'sum':
          sums[optKey] = sums[optKey] === undefined ? itemValue : sums[optKey] + itemValue;
          break;
        case 'avg':
          sums[optKey] = sums[optKey] === undefined ? itemValue : sums[optKey] + itemValue;
          counts[optKey] = counts[optKey] === undefined ? 1 : counts[optKey] + 1;
          break;
        case 'weightedAvg':
          const { weightField } = opt;
          const weightValue = item[weightField];
          if (weightValue === undefined || weightValue === null)
            throw new Error(`Invalid weightField: ${String(weightField)}`);
          const value = itemValue * weightValue;
          sums[optKey] = sums[optKey] === undefined ? value : sums[optKey] + value;
          counts[optKey] = counts[optKey] === undefined ? weightValue : counts[optKey] + weightValue;
          break;
      }
    }
  }
  return mapRecord(opts, (opt, key) => {
    const method = opt.method;
    switch (method)
    {
      case 'sum':
        return sums[key] ?? 0;
      case 'avg':
      case 'weightedAvg':
        return (sums[key] ?? 0) / (counts[key] || 1);
    }
  });
}
