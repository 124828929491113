import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs, ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { MyBreteuilUserContractTypeEnum } from '@core/api/types';

import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface ManagedUser
{
  id: IdType,
  fullname: string,
  contractType: MyBreteuilUserContractTypeEnum | null,
  isAgencyPotCommun: boolean,
  contactSearchAgency: {
    id: IdType,
    name: string,
    code: string,
  } | null,
  goalsComment: string,
  goalsWeekComment: string,
  goalsQuarterComment: string,
  goalsYearComment: string,
}

export const ManagedUserFields: ReadonlyNodeFields = {
  id: true,
  fullname: true,
  contractType: true,
  isAgencyPotCommun: true,
  contactSearchAgency: {
    id: true,
    name: true,
    code: true,
  },
  goalsComment: true,
  goalsWeekComment: true,
  goalsQuarterComment: true,
  goalsYearComment: true,
};

export const ManagedUsersFieldArgs: ReadonlyFieldArgs = {
  managedOnly: { literal: 'true' },
  includePotCommun: { literal: 'true' },
  includeInactiveUsers: { literal: 'false' },
  includeNonAgents: { literal: 'true' },
};

export default function GetManagedUsers()
{
  return query<Array<ManagedUser>>({
    operationName: 'Profile_GetUsers_Managed',
    fieldsSelection: {
      authenticated: errorWrapper({
        users: [{
          args: ManagedUsersFieldArgs,
        }, paginationResultAsList(ManagedUserFields) ],
      }),
    },
  });
}
