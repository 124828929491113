import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs } from '@repo-lib/graphql-query-builder';
import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface TransferUserContactsArgs
{
  sourceUserId: IdType,
  targetUserId: IdType,
  contactIds: Array<IdType>,
}

const TransferUserContactsFieldArgs: ReadonlyFieldArgs = {
  sourceUserId: true,
  targetUserId: true,
  contactIds: true,
};

const TransferUserContactsVars = {
  sourceUserId: 'Int!',
  targetUserId: 'Int!',
  contactIds: '[Int!]',
};

export default function TransferUserContacts(args: TransferUserContactsArgs)
{
  return mutation<number>({
    operationName: 'Common_ReferentTransfer_TransferUserContacts',
    variables: TransferUserContactsVars,
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: {
          transferUserContacts: [{
            args: TransferUserContactsFieldArgs,
          }, true],
        },
      }),
    },
  }, args);
}
