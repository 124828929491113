export interface PaginationOpts
{
  page: number,
  rowsPerPage: number,
}

export function applyTablePagination<ItemType>(items: Array<ItemType>, opts: PaginationOpts)
{
  const { page, rowsPerPage } = opts;
  const start = rowsPerPage * page;
  const end = start + rowsPerPage;
  return items.slice(start, end);
}
