import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface DeleteValuationArgs
{
  id: IdType,
}

export default function DeleteValuation(args: DeleteValuationArgs)
{
  return mutation<boolean>({
    operationName: 'Property_ValuationsAndTransactions_DeleteValuation',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          myBreteuilValuation: {
            delete: errorWrapper([{
              args: {
                id: true,
              },
            }, true]),
          },
        },
      }),
    },
  }, args);
}
