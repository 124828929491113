import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

import { query, errorWrapper } from '@core/api/graphql/gen-v2';

// - UsersGoals

export interface UserGoalDetail
{
  agency: {
    id: IdType,
    name: string,
  },
  value: number,
}

const UserGoalDetailFields = {
  agency: {
    id: true,
    name: true,
  },
  value: true,
} as const satisfies ReadonlyNodeFields;

export interface UserGoalRanked
{
  value: number,
  details: Array<UserGoalDetail>,
  goal: number | null,
  rank: number | null,
}

const UserGoalRankedFields = {
  value: true,
  details: UserGoalDetailFields,
  goal: true,
  rank: true,
} as const satisfies ReadonlyNodeFields;

export interface UserGoals
{
  user: {
    id: IdType,
    fullname: string,
    arrivedAt: number,
    leavedAt: number | null,
    active: boolean,
  },
  acceptedOffersHT: UserGoalRanked,
  successfulReminders: UserGoalRanked,
  successfulPhoneReminders: UserGoalRanked,
  successfulWhatsAppReminders: UserGoalRanked,
  madeOffers: UserGoalRanked,
  mandates: UserGoalRanked,
  viewings: UserGoalRanked,
  valuations: UserGoalRanked,
  prospectedStreets: UserGoalRanked,
}

const UserGoalsFields = {
  user: {
    id: true,
    fullname: true,
    arrivedAt: true,
    leavedAt: true,
    active: true,
  },
  acceptedOffersHT: UserGoalRankedFields,
  successfulReminders: UserGoalRankedFields,
  successfulPhoneReminders: UserGoalRankedFields,
  successfulWhatsAppReminders: UserGoalRankedFields,
  madeOffers: UserGoalRankedFields,
  mandates: UserGoalRankedFields,
  viewings: UserGoalRankedFields,
  valuations: UserGoalRankedFields,
  prospectedStreets: UserGoalRankedFields,
} as const satisfies ReadonlyNodeFields;

// - Scoreboard

export interface UserGoalScoreboardInfo
{
  user: {
    id: IdType,
    fullname: string,
  },
  value: number,
}

const UserGoalScoreboardInfoFields = {
  user: {
    id: true,
    fullname: true,
  },
  value: true,
} as const satisfies ReadonlyNodeFields;

export interface UsersGoalsScoreboard
{
  acceptedOffersHT: Array<UserGoalScoreboardInfo>,
  mandates: Array<UserGoalScoreboardInfo>,
}

const UsersGoalsScoreboardFields = {
  acceptedOffersHT: UserGoalScoreboardInfoFields,
  mandates: UserGoalScoreboardInfoFields,
} as const satisfies ReadonlyNodeFields;

export interface UserGoalAgencyScoreboardInfo
{
  agency: {
    id: IdType,
    shortName: string,
  },
  value: number,
}

const UserGoalAgencyScoreboardInfoFields = {
  agency: {
    id: true,
    shortName: true,
  },
  value: true,
} as const satisfies ReadonlyNodeFields;

export interface UsersGoalsAgencyScoreboard
{
  acceptedOffersHT: Array<UserGoalAgencyScoreboardInfo>,
  mandates: Array<UserGoalAgencyScoreboardInfo>,
}

const UsersGoalsAgencyScoreboardFields = {
  acceptedOffersHT: UserGoalAgencyScoreboardInfoFields,
  mandates: UserGoalAgencyScoreboardInfoFields,
} as const satisfies ReadonlyNodeFields;

export interface UsersGoals
{
  users: Array<UserGoals>,
  rankedUsersCount: number,
  rankedAgencies: Array<{
    id: IdType,
    name: string,
  }>,
  scoreboard: UsersGoalsScoreboard,
  agenciesScoreboard: UsersGoalsAgencyScoreboard,
}

export const UsersGoalsFields = {
  users: UserGoalsFields,
  rankedUsersCount: true,
  rankedAgencies: {
    id: true,
    name: true,
  },
  scoreboard: UsersGoalsScoreboardFields,
  agenciesScoreboard: UsersGoalsAgencyScoreboardFields,
} as const satisfies ReadonlyNodeFields;

// - Args

export interface GetUsersGoalsArgs
{
  startDate: number,
  endDate: number,
}

export default function GetUsersGoals(args: GetUsersGoalsArgs)
{
  return query<UsersGoals>({
    operationName: 'Profile_GetUsersGoals',
    variables: {
      startDate: 'Date!',
      endDate: 'Date!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        usersGoals: [{
          args: {
            startDate: true,
            endDate: true,
          },
        }, UsersGoalsFields],
      }),
    },
  }, args);
}
