import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface MyBreteuilViewing
{
  id: IdType,
}

export interface UpdateViewingVariables
{
  id: IdType,
  date?: number/*timestamp*/ | undefined,
  endDate?: number/*timestamp*/ | undefined,
}

export default function UpdateViewing(variables: UpdateViewingVariables)
{
  return mutation<MyBreteuilViewing>({
    operationName: 'Property_Viewings_UpdateViewing',
    variables: {
      id: 'Int!',
      date: 'Date',
      endDate: 'Date',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilViewing: {
          update: [{
            args: {
              id: true,
              date: true,
              endDate: true,
            },
            preventUnwrap: true,
          }, {
            id: true,
          }],
        },
      }),
    },
  }, variables);
}
