import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs } from '@repo-lib/graphql-query-builder';
import type{ UserDayOffType } from '@core/api/types';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';
import { UserDayOffRequestFields, type UserDayOffRequest } from './GetUserDayOffRequests';

export interface CreateUserDayOffRequestArgs
{
  userId: IdType,
  startDate: Date,
  endDate: Date | null,
  type: UserDayOffType,
  comment: string | null,
  files: Array<File> | null,
}

const CreateUserDayOffRequestVariables = {
  userId: 'Int!',
  startDate: 'Date!',
  endDate: 'Date',
  type: 'UserDayOffType!',
  comment: 'String',
  files: '[Upload!]',
};

const CreateUserDayOffRequestArgsFields: ReadonlyFieldArgs = {
  userId: true,
  startDate: true,
  endDate: true,
  type: true,
  comment: true,
  files: true,
};

export default function CreateUserDayOffRequest(args: CreateUserDayOffRequestArgs)
{
  return mutation<UserDayOffRequest>({
    operationName: 'Profile_CreateUserDayOffRequest',
    variables: CreateUserDayOffRequestVariables,
    fieldsSelection: {
      authenticated: errorWrapper({
        userDayOffRequest: {
          create: [{
            args: CreateUserDayOffRequestArgsFields,
          }, UserDayOffRequestFields ],
        },
      }),
    },
  }, args);
}
