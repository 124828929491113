import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { Language, SearchAreaLevel } from '@core/api/types';

import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export interface GeoArea
{
  id: IdType,
  parentId: IdType | null,
  name: string,
  displayName: string,
  shortName: string,
  alphaNumNameOrderKey: string,
  searchAreaLevel: SearchAreaLevel | null,
  searchAreaDisplayOrder: number | null,
  searchAreaDefaultExpanded: boolean,
  agencyAreaDisplayOrder: number | null,
  agencyAreaDefaultExpanded: boolean,
}

const GeoAreaFields: ReadonlyNodeFields = {
  id: true,
  parentId: true,
  name: true,
  displayName: [{ args: { language: true } }, true],
  shortName: [{ args: { language: true } }, true],
  alphaNumNameOrderKey: true,
  searchAreaLevel: true,
  searchAreaDisplayOrder: true,
  searchAreaDefaultExpanded: true,
  agencyAreaDisplayOrder: true,
  agencyAreaDefaultExpanded: true,
};

export default function GetGeoAreas(args: { language: Language })
{
  return query<Array<GeoArea>>({
    operationName: 'Global_GetGeoAreas',
    variables: {
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        geoAreas: GeoAreaFields,
      }),
    },
  }, args);
}
