import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs } from '@repo-lib/graphql-query-builder';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface UpdateUserDayOffRequestArgs
{
  id: IdType,
  accepted?: boolean | null | undefined,
}

const UpdateUserDayOffRequestVariables = {
  id: 'Int!',
  accepted: 'Boolean',
};

const UpdateUserDayOffRequestArgsFields: ReadonlyFieldArgs = {
  id: true,
  accepted: true,
};

export default function UpdateUserDayOffRequest(args: UpdateUserDayOffRequestArgs)
{
  return mutation<IdType>({
    operationName: 'Common_UserDayOffRequestDialog_UpdateUserDayOffRequest',
    variables: UpdateUserDayOffRequestVariables,
    fieldsSelection: {
      authenticated: errorWrapper({
        userDayOffRequest: {
          update: [{
            args: UpdateUserDayOffRequestArgsFields,
          }, { id: true } ],
        },
      }),
    },
  }, args);
}
