import type { IdType } from '@repo-breteuil/common-definitions';
import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { Locale } from '@repo-breteuil/common-texts';

export interface CriteriaAIDecriptionField {
  key: string,
  value: number,
}

export interface AnalyzedPropertyDescription {
  criteria: Array<CriteriaAIDecriptionField>,
  fields: Array<CriteriaAIDecriptionField>,
}

export interface Args
{
  propertyId: IdType,
  description: string,
  language: Locale,
}

export default function Valuation_GetAnalyzedPropertyDescription(args: Args)
{
  return query<AnalyzedPropertyDescription>(
    {
      operationName: 'Valuation_GetAnalyzedPropertyDescription',
      variables: {
        propertyId: 'Int!',
        language: 'Language!',
        description: 'String!',
      },
      fieldsSelection: {
        authenticated: errorWrapper({
          ai: {
            analyzedPropertyDescription: [
              {
                args: {
                  propertyId: true,
                  language: true,
                  description: true,
                },
              },
              {
                criteria: {
                  key: true,
                  value: true,
                },
                fields: {
                  key: true,
                  value: true,
                },
              },
            ],
          },
        }),
      },
    },
    args,
  );
}
