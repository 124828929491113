import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationBaseArgs, PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { Filters__Int, OrderBy, UserDayOffType } from '@core/api/types';

import {
  query,
  errorWrapper,
  ExtendedPaginationArgs,
  ExtendedPaginationVariables,
  paginationResult,
} from '@core/api/graphql/gen-v2';

export interface UserDayOffRequest
{
  id: IdType,
  accepted: boolean | null, // null=pending
  startDate: number,
  endDate: number | null,
  workingDaysCount: number,
  type: UserDayOffType,
  comment: string | null,
  documentsCount: number,
  createdAt: number,
  user: {
    id: IdType,
    fullname: string,
  },
}

const UserDayOffRequestField: ReadonlyNodeFields = {
  id: true,
  accepted: true,
  startDate: true,
  endDate: true,
  workingDaysCount: true,
  type: true,
  comment: true,
  documentsCount: [{ aliasedField: 'documents' }, { count: true }],
  createdAt: true,
  user: {
    id: true,
    fullname: true,
  },
};

export interface Filters__UserDayOffRequest
{
  userId?: Filters__Int | undefined,
}

export interface GetUserDayOffRequestsArgs extends PaginationBaseArgs
{
  filter?: Filters__UserDayOffRequest | undefined,
  orderBy?: OrderBy | undefined,
}

export default function GetUserDayOffRequests(args: GetUserDayOffRequestsArgs)
{
  return query<PaginationExtendedResponse<UserDayOffRequest>>({
    operationName: 'Holidays_GetUserDayOffRequests',
    variables: ExtendedPaginationVariables('UserDayOffRequest'),
    fieldsSelection: {
      authenticated: errorWrapper({
        userDayOffRequests: [{
          args: ExtendedPaginationArgs,
        }, paginationResult(UserDayOffRequestField)],
      }),
    },
  }, args);
}
