import type { SetConfigVariableArgs, ConfigVariables } from './api';

import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleCriticalError, handleNonCriticalError } from '@repo-breteuil/front-error';
import {
  GetConfigVariablesList,
  GetConfigVariables,
  SetConfigVariable,
} from './api';

export interface VariablesGroup
{
  variables: ConfigVariables,
  groups: Record<string/*prefixName*/, VariablesGroup>,
}

class ConfigVariablesStore
{
  private static SupportedVariablesTypes = ['Bool', 'Int', 'Float'] as const;

  private static buildHierarchy(variables: ConfigVariables)
  {
    const hierarchy: VariablesGroup = {
      variables: {},
      groups: {},
    };
    for (const [variableName, variable] of Object.entries(variables))
    {
      if (!ConfigVariablesStore.SupportedVariablesTypes.includes(variable.type))
        handleNonCriticalError(new Error(`Unsupported config variable type: ${variable.type}`));
      const parts = variableName.split('__');
      let selectedGroup = hierarchy;
      for (let i = 0; i < parts.length; ++i)
      {
        if (i === (parts.length -1))
          break;
        const groupPrefix = parts[i];
        const group = selectedGroup.groups[groupPrefix];
        if (group === undefined)
        {
          selectedGroup.groups[groupPrefix] = {
            variables: {},
            groups: {},
          };
        }
        selectedGroup = selectedGroup.groups[groupPrefix];
      }
      selectedGroup.variables[variableName] = variable;
    }
    return hierarchy;
  }

  public variablesMetadatas = new Fetchable(GetConfigVariablesList, { catchUnhandled: handleCriticalError });

  public variablesHierarchy = new Fetchable(async () => {
    if (this.variablesMetadatas.result === undefined)
      throw new Error('Config variables metadatas not loaded yet');
    const variables = await GetConfigVariables(this.variablesMetadatas.result);
    return ConfigVariablesStore.buildHierarchy(variables);
  }, { catchUnhandled: handleCriticalError });

  public async setConfigVariable(args: SetConfigVariableArgs)
  {
    await SetConfigVariable(args);
    await this.refresh();
  }

  public async refresh()
  {
    await this.variablesMetadatas.ensureSuccess();
    await this.variablesHierarchy.ensureSuccessReload();
  }
}

export default new ConfigVariablesStore();
