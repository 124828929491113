import type { IdType } from '@repo-breteuil/common-definitions';

import { observable, action, computed } from 'mobx';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { reverseMap } from '@repo-lib/utils-core';
import { OpeningControl } from '@repo-lib/utils-mobx-store';
import {
  handleNonCriticalError,
} from '@repo-breteuil/front-error';
import { formatGlobalErrorMessage } from '@core/store/GlobalMessage';
import localesStore from '@core/store/Locales';
import ReminderDialogStore from '@my-breteuil/store/ui/common/reminder-dialog';
import MessageDrawerStore from '@my-breteuil/store/ui/common/message-drawer';
import { ViewingCreateDialogStore } from '@my-breteuil/store/ui/common/viewing-dialogs';
import PropertyConfidentialLinkDialogStore from '@my-breteuil/store/ui/common/property-confidential-link-dialog';
import {
  GetContact,
} from './api';
import contactInfoStore from './info';
import contactLogsStore from './logs';

export enum ContactTab
{
  Info = 'Info',
  Logs = 'Logs',
}

export const RouteParamToContactTab = new Map<string, ContactTab>([
  ['info', ContactTab.Info],
  ['logs', ContactTab.Logs],
]);

export const ContactTabToRouteParam = reverseMap(RouteParamToContactTab);

class ContactStore
{
  @observable private _currentTab = ContactTab.Info;

  get currentTab()
  {
    return this._currentTab;
  }

  @action setCurrentTab(tab)
  {
    this._currentTab = tab;
  }

  private _contact = new Fetchable(GetContact, { catchUnhandled: handleNonCriticalError });

  public get contact()
  {
    const contact = this._contact.lastResult;
    if (!contact)
      throw new Error(`ContactStore.contact was accessed before ContactStore.init was called`);
    return contact;
  }

  @computed get isContactMerged()
  {
    return this.contact.mergedIntoContactId !== null;
  }

  public reminderDialogStore = new ReminderDialogStore({
    onReminderCreateSuccess: () => this.refresh().catch((error) => formatGlobalErrorMessage(error)),
    onSentPropertiesTableRefresh: () => this.refresh().catch((error) => formatGlobalErrorMessage(error)),
  });

  public messageDrawerStore = new MessageDrawerStore({
    onMessageSentSuccess: () => contactInfoStore.refresh().catch((error) => formatGlobalErrorMessage(error)),
  });

  public viewingCreateStore = new ViewingCreateDialogStore();

  public propertyConfidentialLinkDialogStore = new PropertyConfidentialLinkDialogStore();

  public contactTransferDialog = new OpeningControl();

  public refresh()
  {
    return this._contact.ensureSuccessReload({
      id: this.contact.id,
      language: localesStore.currentLocale,
    });
  }

  public async init(contactId: IdType, tab?: ContactTab)
  {
    const contact = await this._contact.ensureSuccessReload({
      id: contactId,
      language: localesStore.currentLocale,
    });
    if (!contact)
      return null;
    this.setCurrentTab(tab ?? ContactTab.Info);
    if (this._currentTab === ContactTab.Info)
      await Promise.all([
        contactInfoStore.refreshResources(),
        contactInfoStore.refresh({ disableContactRefresh: true }),
      ]);
    else if (this._currentTab === ContactTab.Logs)
      await contactLogsStore.refresh();
    return contact;
  }
}

export default new ContactStore();
