import type { PaginationBaseArgs } from '@repo-lib/graphql-query-pagination';
import type { Filters__String, Filters_HasMany } from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResultAsList,
  PaginationArgs,
  PaginationVariables,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilContact
{
  id: number,
  fullname: string,
  emails: Array<string>,
  phones: Array<string>,
}

interface Filters__MyBreteuilContact
{
  phonesEmailsNamesString?: Filters__String | undefined,
  emails?: Filters_HasMany<{ email: Filters__String }> | undefined,
  phones?: Filters_HasMany<{ telephone: Filters__String }> | undefined,
}

export interface GetContactsVariables extends PaginationBaseArgs
{
  filter?: Filters__MyBreteuilContact | undefined,
}

export default function GetContacts(variables: GetContactsVariables)
{
  return query<Array<MyBreteuilContact>>({
    operationName: 'Common_MessageDrawer_GetContacts',
    variables: {
      ...PaginationVariables,
      filter: 'Filters__Authenticated_Contacts_MainPagination',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContacts: [{ args: {
          ...PaginationArgs,
          filter: true,
        }}, paginationResultAsList({
          id: true,
          fullname: true,
          emails: true,
          phones: true,
        })],
      }),
    },
  }, variables);
}
