import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { Filters__Int } from '@core/api/types';

import { UserDayOffType } from '@core/api/types';
import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface UserDayOffRequest
{
  id: IdType,
  accepted: boolean | null, // null=pending
  startDate: number,
  endDate: number | null,
  workingDaysCount: number,
  type: UserDayOffType,
  user: {
    id: IdType,
    fullname: string,
  },
}

const UserDayOffRequestFields: ReadonlyNodeFields = {
  id: true,
  accepted: true,
  startDate: true,
  endDate: true,
  workingDaysCount: true,
  type: true,
  user: {
    id: true,
    fullname: true,
  },
};

export interface User
{
  id: IdType,
  fullname: string,
  isAgencyPotCommun: boolean,
  contactSearchAgency: {
    id: IdType,
    shortName: string,
    agencyTreeGeoAreaId: IdType | null,
  } | null,
  manager: boolean,
  agent: boolean,
  availablePaidLeaveDaysOff: number,
  takenPaidLeaveDaysOff: number,
  dayOffRequests: Array<UserDayOffRequest>,
}

const UserFields: ReadonlyNodeFields = {
  id: true,
  fullname: true,
  isAgencyPotCommun: true,
  contactSearchAgency: {
    id: true,
    shortName: true,
    agencyTreeGeoAreaId: true,
  },
  manager: true,
  agent: true,
  availablePaidLeaveDaysOff: [{
    aliasedField: 'availableDaysOff',
    args: {
      type: { literal: UserDayOffType.PaidLeave },
    },
  }, true],
  takenPaidLeaveDaysOff: [{
    aliasedField: 'takenDaysOff',
    args: {
      type: { literal: UserDayOffType.PaidLeave },
    },
  }, true],
  dayOffRequests: paginationResultAsList(UserDayOffRequestFields),
};

export interface Filters__MyBreteuilUser
{
  id?: Filters__Int | undefined,
}

export interface GetManagedUsersArgs
{
  filter?: Filters__MyBreteuilUser | undefined,
}

export default function GetManagedUsers(args: GetManagedUsersArgs)
{
  return query<Array<User>>({
    operationName: 'Holidays_GetUsersDaysOff',
    variables: {
      filter: 'Filters__MyBreteuilUser',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        users: [{
          args: {
            managedOnly: { literal: 'true' },
            includePotCommun: { literal: 'true' },
            includeInactiveUsers: { literal: 'false' },
            includeNonAgents: { literal: 'true' },
            filter: true,
          },
        }, paginationResultAsList(UserFields)],
      }),
    },
  }, args);
}
