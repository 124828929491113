import type { IdType } from '@repo-breteuil/common-definitions';
import type { AdvancedTableColumnOrder } from '@lib/components/data-table/advanced-table';
import type { OrderField } from '@core/api/types';

import { observable, computed, runInAction } from 'mobx';
import { UncachedPagination, makePaginationResultsAutoObservable } from '@repo-lib/graphql-query-pagination';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import { OrderType, UserPinOrigin } from '@core/api/types';
import { formatGlobalErrorMessage } from '@core/store/GlobalMessage';
import localesStore from '@core/store/Locales';
import PaginationSelection from '@my-breteuil/store/utils/PaginationSelection';
import sessionStore from '@my-breteuil/store/ui/common/Session';
import ReminderDialogStore from '@my-breteuil/store/ui/common/reminder-dialog';
import MessageDrawerStore from '@my-breteuil/store/ui/common/message-drawer';
import ReferentTransferStore from '@my-breteuil/store/ui/common/referent-transfer';
import {
  GetOwnerContacts,
  type OwnerContact,
  ContactsSimpleFilter,
  convertContactsSimpleFilterToAPIFilter,
  GetPinnedOwnerContacts,
  GetUsers,
  SetContactPinned,
  type SetContactPinnedArgs,
  UpdateContact,
} from './api';

class OwnersStore {
  private static DefaultContactsPageSize = 50;
  public readonly PageSizeOptions = [50, 100, 200, 500];

  private _order: OrderField | null = {
    fieldName: 'lastInterestDate',
    ordering: OrderType.DESC,
  };
  get order()
  {
    return this._order;
  }

  private _filter: ContactsSimpleFilter = {};
  get filter()
  {
    return this._filter;
  }

  private _getDefaultFilter(): ContactsSimpleFilter
  {
    return {
      responsibleUsers: sessionStore.mybUser ? [ sessionStore.mybUser.id ] : [],
    };
  }

  public pinnedContacts = new Fetchable(() => GetPinnedOwnerContacts({
    language: localesStore.currentLocale,
    pinnedOrigin: UserPinOrigin.MybOwners,
  }), { catchUnhandled: handleNonCriticalError });

  public contacts = new UncachedPagination({
    fetch: (baseArgs) => GetOwnerContacts({
      ...baseArgs,
      pinnedOrigin: UserPinOrigin.MybOwners,
      orderBy: this._order ? [this._order] : undefined,
      ...convertContactsSimpleFilterToAPIFilter(this._filter),
      language: localesStore.currentLocale,
    }).then(makePaginationResultsAutoObservable),
    pageSize: OwnersStore.DefaultContactsPageSize,
  });

  @computed get contactsAndPinned()
  {
    const pinnedContacts = this.pinnedContacts.lastResult;
    const contacts = this.contacts.data?.items;
    return [
      ...(pinnedContacts ?? []),
      ...(contacts ?? []),
    ];
  }

  public setOrderAndReload(order: AdvancedTableColumnOrder<string> | null)
  {
    this._order = (order !== null ? {
      fieldName: order.column,
      ordering: order.desc ? OrderType.DESC : OrderType.ASC,
    } : null);
    return this.contacts.first();
  }

  public setFilter(newFilter: ContactsSimpleFilter)
  {
    this._filter = newFilter;
  }

  public setFilterAndReload(newFilter: ContactsSimpleFilter)
  {
    this.setFilter(newFilter);
    return this.contacts.first();
  }

  public resetFilter()
  {
    this._filter = {};
  }

  public users = new Fetchable(GetUsers, { catchUnhandled: handleNonCriticalError });

  @computed get userCommonPot()
  {
    return this.users.result?.filter((user) => user.isAgencyPotCommun).map(user => user.id);
  }

  public selectedContacts = observable.map<IdType, OwnerContact>();

  @computed get selectedContactsList()
  {
    return Array.from(this.selectedContacts.values());
  }

  public messageDrawerStore = new MessageDrawerStore({
    onMessageSentSuccess: () => {
      this.selectedContacts.clear();
      this.refreshContactsAndPinned().catch((error) => formatGlobalErrorMessage(error));
    },
  });

  public openMessageDrawer()
  {
    this.messageDrawerStore.open({
      defaultContacts: () => this.selectedContactsList,
    });
  }

  public reminderDialogStore = new ReminderDialogStore({
    onReminderCreateSuccess: () => this.refresh().catch((error) => formatGlobalErrorMessage(error)),
    onSentPropertiesTableRefresh: () => this.refresh().catch((error) => formatGlobalErrorMessage(error)),
    navigationControls: {
      hasPrevContact: () => this._contactsSelection.hasPrevItem,
      hasNextContact: () => this._contactsSelection.hasNextItem,
      onClickPrev: () => this._prevContact().catch((error) => formatGlobalErrorMessage(error)),
      onClickNext: () => this._nextContact().catch((error) => formatGlobalErrorMessage(error)),
    },
  });

  private _contactsSelection = new PaginationSelection(this.contacts, this.pinnedContacts);

  private async _openReminderDialogWithCurrentContact()
  {
    await this.reminderDialogStore.open(this._contactsSelection.item.id);
  }

  public async openReminderDialog(contactId: number)
  {
    this._contactsSelection.selectItem((contact) => contact.id === contactId);
    await this._openReminderDialogWithCurrentContact();
  }

  private async _prevContact()
  {
    await this._contactsSelection.prevItem();
    await this._openReminderDialogWithCurrentContact();
  }

  private async _nextContact()
  {
    await this._contactsSelection.nextItem();
    await this._openReminderDialogWithCurrentContact();
  }

  public referentTransferStore = new ReferentTransferStore();

  public updateLocalContact(contactId: IdType, effect: (contact: OwnerContact) => void)
  {
    const contact = this.contacts.data?.items.find((contact) => (contact.id === contactId)) || this.pinnedContacts.lastResult?.find((c) => c.id === contactId);
    if (contact)
      runInAction(() => effect(contact));
  }

  public async setContactVIP(
    args: {
      id: IdType,
      vip: boolean,
    },
  )
  {
    await UpdateContact(args);
    this.updateLocalContact(args.id, (contact) => {
      contact.vip = args.vip;
    });
  };

  private _firstRefresh = true;

  async refresh()
  {
    if (this._firstRefresh)
    {
      this.setFilter(this._getDefaultFilter());
      this._firstRefresh = false;
    }
    return Promise.all([
      this.users.ensureSuccess(),
      this.refreshContactsAndPinned(),
    ]);
  }

  public async refreshContactsAndPinned()
  {
    await Promise.all([
      this.contacts.reload(),
      this.pinnedContacts.ensureSuccessReload(),
    ]);
  }

  public async setContactPinned(args: SetContactPinnedArgs)
  {
    await SetContactPinned(args);
    await this.refreshContactsAndPinned();
  }
}

export default new OwnersStore();
