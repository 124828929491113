import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { OrderField, OrderType } from '@core/api/types';
import { assert, handleNonCriticalError } from '@repo-breteuil/front-error';
import locales from '@core/store/Locales';
import { SimpleLogsFilters, convertSimpleLogsFilters } from '@my-breteuil/store/ui/fragments/entity-logs';
import {
  GetPropertyLogs,
  GetLogsUsers,
} from './api';
import propertyStore from './index';

class PropertyLogsStore
{
  get propertyMainInfo()
  {
    const property = propertyStore.propertyMainInfo.lastResult;
    assert(property);
    return property;
  }

  public readonly LogsPageSizeOptions = [ 100, 250, 500 ];
  private static LogsDefaultPageSize = 100;

  private _logsFilters: SimpleLogsFilters = {};

  get logsFilters()
  {
    return this._logsFilters;
  }

  public async setLogsFiltersAndReload(newFilters: SimpleLogsFilters)
  {
    this._logsFilters = newFilters;
    await this.logs.first();
  }

  private _logsOrderField: OrderField | null = {
    fieldName: 'createdAt',
    ordering: OrderType.DESC,
  };

  get logsOrderField()
  {
    return this._logsOrderField;
  }

  public async setLogsOrderAndReload(newOrder: OrderField | null)
  {
    this._logsOrderField = newOrder;
    await this.logs.first();
  }

  public logs = new UncachedPagination({
    fetch: (baseArgs) => GetPropertyLogs({
      ...baseArgs,
      propertyId: propertyStore.propertyMainInfo.lastResult!.id,
      ...convertSimpleLogsFilters(this._logsFilters),
      orderBy: this._logsOrderField ? [this._logsOrderField] : [],
      language: locales.currentLocale,
    }),
    pageSize: PropertyLogsStore.LogsDefaultPageSize,
  });

  public users = new Fetchable(GetLogsUsers, { catchUnhandled: handleNonCriticalError });

  public async refresh()
  {
    await Promise.all([
      this.users.ensureSuccess(),
      this.logs.first(),
    ]);
  }
}

export default new PropertyLogsStore;
