import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type {
  PropertyStatus,
  OperationType,
  PropertyType,
  MyBreteuilPropertyVisibility,
  BreteuilPropertyPriceChangeStatus,
  PropertyRequiredField,
  PropertyFavoriteStatus,
  Currency,
  Language,
} from '@core/api/types/enums';
import {
  MyBreteuilPropertyDocumentType,
  PictureFormat,
  UserVisibilityScope,
} from '@core/api/types';
import {
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilUser
{
  id: number,
  firstname: string,
  lastname: string,
  fullname: string,
  agent: boolean,
  isAgencyPotCommun: boolean,
  contactSearchAgency: {
    id: IdType,
    code: string,
    name: string,
  } | null,
}

export interface MyBreteuilCriteria
{
  id: number,
  webName: string,
}

export interface MyBreteuilPropertyPhoto
{
  id: IdType,
  fileURL: string,
  pictureFormat: string,
}

export interface PropertyRequirements
{
  requiredFields: Array<PropertyRequiredField> | null,
  requiredPhotos: number | null,
  requiredStatus: Array<PropertyStatus> | null,
  requiredVisibility: Array<MyBreteuilPropertyVisibility> | null,
}

export const PropertyRequirementsFields: ReadonlyNodeFields = {
  requiredFields: true,
  requiredPhotos: true,
  requiredStatus: true,
  requiredVisibility: true,
} as const;

export interface MyBreteuilProperty
{
  id: number,
  slug: string,
  pinned: boolean,
  currentUserReferent: boolean | null,
  users: Array<MyBreteuilUser>,
  title: string,
  address: string,
  addrStreetAddress: string | null,
  publication: MyBreteuilPropertyVisibility,
  confidential: boolean,
  operationType: OperationType,
  currency: Currency,
  lastRelevantPriceOrStatusChange: number/*timestamp*/,
  lastPriceUpdateStatus: BreteuilPropertyPriceChangeStatus,
  priceStatusUpdates: Array<{
    date: number,
    price: number,
    status: PropertyStatus,
    updateType: BreteuilPropertyPriceChangeStatus,
  }>,
  ref: string,
  warrantNumber: string | null,
  verifiedWarrant: boolean,
  status: PropertyStatus,
  price: number,
  pricePerSurface: number,
  addressPricePerSurface: number | null,
  addressPricePerSurfaceTransactionsCount: number | null,
  addressPricePerSurfaceMin: number | null,
  addressPricePerSurfaceMax: number | null,
  addressPricePerSurfaceAvg: number | null,
  addressPricePerSurfaceMedian: number | null,
  addressPricePerSurfaceFilters: {
    radius: number | null,
    sameStreet: {
      sameSide: boolean,
    } | null,
    surfaceMin: number | null,
    surfaceMax: number | null,
  },
  valuationEstimatedPrice: number | null,
  valuationEstimatedPricePerSurface: number | null,
  valuationTotalVariation: number | null,
  fees: number | null,
  feesPercentage: number | null,
  district: {
    id: IdType,
    displayName: string,
    parent: {
      id: IdType,
      displayName: string,
    } | null,
  } | null,
  surface: number,
  balconySurface: number | null,
  bedrooms: number,
  floor: number,
  criterias: Array<MyBreteuilCriteria>,
  propertyType: PropertyType,
  viewingsCount: number,
  photos: Array<MyBreteuilPropertyPhoto>,
  hasVideo: boolean,
  favoriteStatus: PropertyFavoriteStatus | null,
  marketConsistent: boolean,
  managerMarketConsistent: boolean,
  priceDecreaseRequest: {
    price: number,
    fulfilled: boolean,
  } | null,
  warrantDate: number | null/*timestamp*/,
  matchesCountAbsolute: number,
  matchesRapprochements: {
    matchesCount: number,
    rapprochedMatchesCount: number,
  },
  matchesRapprochementsPerUser: Array<{
    user: {
      id: IdType,
      fullname: string,
      isAgencyPotCommun: boolean,
    },
    matchesCount: number,
    rapprochedMatchesCount_Total: number,
    rapprochedMatchesCount_Viewing: number,
    rapprochedMatchesCount_WhatsApp: number,
    rapprochedMatchesCount_NegativeFeedback: number,
  }>,
  createdAt: number/*timestamp*/,
  communicationEvents: { count: number },
  clicksCount: { count: number },
  documents__mandatesCount: number,
  documents__CNIsCount: number,
  documents__valuationsCount: number,
  photograph: {
    id: IdType,
    name: string,
  },
  publicationPlatformsUserPublishedLastUpdateDate: number | null/*timestamp*/,
  missingPublicationRequirementsPublicationPlatforms: PropertyRequirements | null,
  exclusive: boolean,
  lastMarketingReportSendDate: number/*timestamp*/ | null,
  lastAgentValuationDate: number/*timestamp*/ | null,
}

export const MyBreteuilPropertyFields: ReadonlyNodeFields = {
  id: true,
  pinned: true,
  slug: [{ args: { language: true } }, true],
  currentUserReferent: true,
  users: {
    id: true,
    firstname: true,
    lastname: true,
    fullname: true,
    agent: true,
    isAgencyPotCommun: true,
    contactSearchAgency: {
      id: true,
      code: true,
      name: true,
    },
  },
  title: true,
  address: true,
  addrStreetAddress: true,
  publication: true,
  confidential: true,
  operationType: true,
  currency: true,
  lastRelevantPriceOrStatusChange: true,
  lastPriceUpdateStatus: true,
  priceStatusUpdates: {
    date: true,
    price: true,
    status: true,
    updateType: true,
  },
  ref: true,
  warrantNumber: true,
  verifiedWarrant: true,
  status: true,
  price: true,
  pricePerSurface: true,
  addressPricePerSurface: true,
  addressPricePerSurfaceTransactionsCount: true,
  addressPricePerSurfaceMin: true,
  addressPricePerSurfaceMax: true,
  addressPricePerSurfaceAvg: true,
  addressPricePerSurfaceMedian: true,
  addressPricePerSurfaceFilters: {
    radius: true,
    sameStreet: [{
      preventUnwrap: true,
    }, {
      sameSide: true,
    }],
    surfaceMin: true,
    surfaceMax: true,
  },
  valuationEstimatedPrice: true,
  valuationEstimatedPricePerSurface: true,
  valuationTotalVariation: true,
  fees: true,
  feesPercentage: true,
  district: {
    id: true,
    displayName: [{ args: { language: true }}, true],
    parent: {
      id: true,
      displayName: [{ args: { language: true }}, true],
    },
  },
  surface: true,
  balconySurface: true,
  bedrooms: true,
  floor: true,
  criterias: [{ aliasedField: 'criteria' }, paginationResultAsList({
    id: true,
    webName: [{ args: { language: true }}, true],
  })],
  propertyType: true,
  viewingsCount: true,
  photos: paginationResultAsList({
    id: true,
    fileURL: [{ args: { format: { literal: PictureFormat._360p } } }, true],
    pictureFormat: true,
  }),
  hasVideo: true,
  favoriteStatus: true,
  marketConsistent: true,
  managerMarketConsistent: true,
  priceDecreaseRequest: {
    price: true,
    fulfilled: true,
  },
  warrantDate: true,
  matchesCountAbsolute: true,
  matchesRapprochements: [{
    args: {
      usersSubset: 'matchesRapprochementsUsersSubset',
      includeAgencyUsers: { literal: 'false' },
    },
  }, {
    matchesCount: true,
    rapprochedMatchesCount: true,
  }],
  matchesRapprochementsPerUser: [{
    args: {
      usersSubset: { literal: UserVisibilityScope.SelfAgency },
      includeAgencyUsers: { literal: 'true' },
    },
  }, {
    user: {
      id: true,
      fullname: true,
      isAgencyPotCommun: true,
    },
    matchesCount: true,
    rapprochedMatchesCount_Total: true,
    rapprochedMatchesCount_Viewing: true,
    rapprochedMatchesCount_WhatsApp: true,
    rapprochedMatchesCount_NegativeFeedback: true,
  }],
  createdAt: true,
  communicationEvents: [{
    args: {
      filter: { literal: '{ event: { type: { equals: CommunicationSend } } }' },
    },
    aliasedField: 'historyEvents',
    preventUnwrap: true,
  }, {
    count: true,
  }],
  clicksCount: [{
    args: {
      filter: { literal: '{ event: { communicationEventType: { equals: Click } } }' },
    },
    aliasedField: 'historyEvents',
    preventUnwrap: true,
  }, {
    count: true,
  }],
  documents__mandatesCount: [{
    args: {
      filter: { literal: `{ documentType: { equals: ${MyBreteuilPropertyDocumentType.Mandate} } }` },
    },
    aliasedField: 'documents',
  }, {
    count: true,
  }],
  documents__CNIsCount: [{
    args: {
      filter: { literal: `{ documentType: { equals: ${MyBreteuilPropertyDocumentType.CNI} } }` },
    },
    aliasedField: 'documents',
  }, {
    count: true,
  }],
  documents__valuationsCount: [{
    args: {
      filter: { literal: `{ documentType: { equals: ${MyBreteuilPropertyDocumentType.Valuation} } }` },
    },
    aliasedField: 'documents',
  }, {
    count: true,
  }],
  photograph: {
    id: true,
    name: true,
  },
  publicationPlatformsUserPublishedLastUpdateDate: true,
  missingPublicationRequirementsPublicationPlatforms: PropertyRequirementsFields,
  exclusive: true,
  lastMarketingReportSendDate: true,
  lastAgentValuationDate: true,
};

export interface MyBreteuilPropertyVars
{
  matchesRapprochementsUsersSubset: UserVisibilityScope,
  language: Language,
}

export const MyBreteuilPropertyVarsFields = {
  matchesRapprochementsUsersSubset: 'UserVisibilityScope!',
  language: 'Language!',
};
