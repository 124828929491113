import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface Agency
{
  id: IdType,
  name: string,
}

const AgencyFields: ReadonlyNodeFields = {
  id: true,
  name: true,
};

export default function GetManagedAgencies()
{
  return query<Array<Agency>>({
    operationName: 'Users_GetAgencies',
    fieldsSelection: {
      authenticated: errorWrapper({
        managedMyBreteuilAgencies: paginationResultAsList(AgencyFields),
      }),
    },
  });
}
