import type { IdType } from '@repo-breteuil/common-definitions';
import type { MyBreteuilSearch } from './GetContact';
import type { UpdateSearchArgs } from './UpdateSearch';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export type CreateSearchArgs = { contactId: IdType } & Omit<UpdateSearchArgs, "id" | "archived">;

export default function CreateSearch(args: CreateSearchArgs)
{
  return mutation<MyBreteuilSearch>({
    operationName: 'Contact_CreateSearch',
    variables: {
      contactId: "Int!",
      budgetMin: "Int",
      budgetMax: "Int!",
      surfaceMin: "Int",
      surfaceMax: "Int",
      bedroomsMin: "Int",
      bedroomsMax: "Int",
      operationType: "MyBreteuilOperationType!",
      myb_offre: "Boolean",
      criteriaIds: "[Int!]",
      geoAreasIds: "[Int!]!",
      usersIds: "[Int!]!",
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilSearch: {
          create: [{
            args: {
              contactId: true,
              budgetMin: true,
              budgetMax: true,
              surfaceMin: true,
              surfaceMax: true,
              bedroomsMin: true,
              bedroomsMax: true,
              operationType: true,
              myb_offre: true,
              criteriaIds: true,
              geoAreasIds: true,
              usersIds: true,
            },
          }, { id: true }],
        },
      }),
    },
  }, args);
}
