import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';


import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { UserGoalMetric, UserGoalPeriod } from '@core/api/types';

export interface UserGoals {
  dailySuccessfulReminders: UserGoal,
}

export interface UserGoal
{
  value: number,
  goal: number | null,
}

export const UserGoalFields: ReadonlyNodeFields = {
  value: true,
  goal: true,
};

export const UserGoalArgs: ReadonlyNodeFields = {
  userId: true,
};

export interface GetDailyReminderGoalArgs {
  userId: IdType,
}

export default function GetDailyReminderGoal(args: GetDailyReminderGoalArgs)
{
  return query<UserGoals>({
    operationName: 'Common_AppTopBar_GetDailyReminderGoal',
    variables: {
      userId: "Int!",
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        dailySuccessfulReminders: [{
          aliasedField: "userGoal",
          args: {
            ...UserGoalArgs,
            period: { literal: UserGoalPeriod.Day },
            metric: { literal: UserGoalMetric.SuccessfulPhoneReminders },
          },
        }, UserGoalFields],
      }),
    },
  }, args);
}
