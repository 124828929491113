import type { IdType } from '@repo-breteuil/common-definitions';
import type { OrderField } from '@core/api/types';
import type { FiltersArgs, UpdateCommissionPaymentInfoArgs } from './api';

import { action, computed, observable, runInAction } from 'mobx';
import { startOfMonth, endOfMonth } from 'date-fns';
import { HierarchyTreeNode } from '@repo-lib/utils-hierarchy-tree';
import { UncachedPagination, makePaginationResultsAutoObservable } from '@repo-lib/graphql-query-pagination';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { indexArrayItems, sum } from '@repo-lib/utils-core';
import { assert, handleCriticalError, handleNonCriticalError } from '@repo-breteuil/front-error';
import {
  MyBreteuilTransactionYearlySummaryType,
  OperationType,
  OrderType,
} from '@core/api/types';
import localeStore from '@core/store/Locales';
import { FranceId as FranceGeoAreaId } from '@my-breteuil/_hardcoded/geo-areas';
// TODO Importing component related stuff is discouraged
import {
  genHistoryNodes,
  makeAreaIdentifier,
  makeUserHierarchyTree,
  NodeType,
  parseHistoryNodeIdentifier,
  parseNodeTypeIdentifier,
  parseUserHistoryNodesIdentifiers,
  type UserHistoryHierarchyNode,
} from '@my-breteuil/components/common/user-pickers/hierarchy/utils';
import geoAreasStore from '@my-breteuil/store/ui/common/geo-areas';
import {
  GetLastCommissions,
  GetAgenciesSummary,
  GetCommissionsStats,
  SettleInvoice,
  UpdateCommissionPaymentInfo,
  GetManagedUsersHistorical,
} from './api';
import { OpeningControl } from '@repo-lib/utils-mobx-store';

// TODO move to lib?
function genFilledArray<T>(
  args: {
    length: number,
    fill: T | ((idx: number) => T),
  },
)
{
  const { length, fill } = args;
  const res: Array<T> = [];
  for (let i = 0; i < length; ++i)
    res.push(fill instanceof Function ? fill(i) : fill);
  return res;
}

function monthsToQuarters(months: Array<number>)
{
  const quarters = genFilledArray({ length: 4, fill: 0 });
  for (let i = 0; i < months.length; ++i)
  {
    const quarterIdx = Math.floor(i / 3);
    quarters[quarterIdx] += months[i];
  }
  return quarters;
}

export interface AgencySummaryHierarchyNode extends UserHistoryHierarchyNode
{
  total: number,
  months: Array<number>,
  quarters: Array<number>,
  childNodes: Array<AgencySummaryHierarchyNode>,
}

export enum AgenciesSummaryPeriodicity
{
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
}

class OffersStore
{
  private static DefaultPageSize = 500;
  private static PageSizeOptions = [100, 250, OffersStore.DefaultPageSize, 1000];
  private static DefaultCommissonOrder = {
    "fieldName": "transaction_authenticDeedDate",
    "ordering": OrderType.DESC,
  };

  public openingControl = new OpeningControl();

  private _commissionId: IdType;

  public async setCommissionId(commissionId: IdType)
  {
    this._commissionId = commissionId;
  }

  get commissionId()
  {
    return this._commissionId;
  }

  @computed public get selectedUserCpRemuneration()
  {
    const commission = this.lastCommissions.data?.items.find((commission) => (commission.id === this._commissionId));
    if (commission)
      return commission.userCpRemuneration;
    return null;
  }

  private static FirstCommissionYear = 2019;

  get firstCreatedCommissionYear()
  {
    return OffersStore.FirstCommissionYear;
  }

  get currentYear()
  {
    return new Date().getFullYear();
  }

  public get pageSizeOptions()
  {
    return OffersStore.PageSizeOptions;
  }

  private get _defaultMinimalFilters(): FiltersArgs
  {
    return {
      type: MyBreteuilTransactionYearlySummaryType.Offers,
      yearRange: {
        from: 0,
        to: 0,
      },
    };
  }

  private get _defaultFilters(): FiltersArgs
  {
    const franceNode = this.usersHierarchyTree.ensureNode(makeAreaIdentifier(FranceGeoAreaId));
    const franceUsersIdentifiers = Array.from(franceNode.leafNodesIdentifiers).filter((identifier) => (
      parseNodeTypeIdentifier(identifier) === NodeType.User
    ));
    const agencyUsers = parseUserHistoryNodesIdentifiers(franceUsersIdentifiers);
    const now = new Date();
    return {
      ...this._defaultMinimalFilters,
      operationTypes: [
        OperationType.ResidencyTransaction,
        OperationType.Rental,
        OperationType.SeasonalRental,
      ],
      yearRange: {
        from: this.currentYear - 3,
        to: this.currentYear + 1,
      },
      dateRange: {
        from: startOfMonth(now).getTime(),
        to: endOfMonth(now).getTime(),
      },
      agencyUsers,
    };
  }

  private _filters: FiltersArgs = this._defaultMinimalFilters;

  get filters()
  {
    return this._filters;
  }

  public async setFiltersArgs(args: FiltersArgs)
  {
    this._filters = args;
    await Promise.all([
      this.lastCommissions.first(),
      this.agenciesSummary.ensureSuccessReload(),
      this.commissionsStats.ensureSuccessReload(),
    ]);
  }

  private _lastCommissionsOrder: OrderField | null = OffersStore.DefaultCommissonOrder;

  public setLastCommissionsOrderAndReload(order: OrderField | null)
  {
    this._lastCommissionsOrder = order;
    return this.lastCommissions.first();
  }

  public lastCommissions = new UncachedPagination({
    fetch: (params) => GetLastCommissions({
      ...params,
      language: localeStore.currentLocale,
      orderBy: this._lastCommissionsOrder ? [this._lastCommissionsOrder] : undefined,
      ...this._filters,
    }).then(makePaginationResultsAutoObservable),
    pageSize: OffersStore.DefaultPageSize,
  });

  @observable private _agenciesSummaryPeriodicity = AgenciesSummaryPeriodicity.Monthly;

  get agenciesSummaryPeriodicity()
  {
    return this._agenciesSummaryPeriodicity;
  }

  @action public setAgenciesSummaryPeriodicity(newPeriodicity: AgenciesSummaryPeriodicity)
  {
    this._agenciesSummaryPeriodicity = newPeriodicity;
  }

  public agenciesSummary = new Fetchable(async () => {
    const agenciesSummary = await GetAgenciesSummary(this._filters);
    return agenciesSummary.map(val => {
      const { year, data } = val;
      const usersNodes = data.map(({ user, agency }) => ({
        user,
        agency,
        activeInAgency: true,
      }));
      const nodes = genHistoryNodes(geoAreasStore.geoAreas, usersNodes);
      const tree = makeUserHierarchyTree(nodes);

      const usersSummariesMap = indexArrayItems(data, item => `${item.agency.id}:${item.user.id}`);
      function processNode(hierarchyNode: HierarchyTreeNode<string, UserHistoryHierarchyNode>): AgencySummaryHierarchyNode
      {
        const { node, directChildren } = hierarchyNode;
        const parsedIdentifier = parseHistoryNodeIdentifier(node.identifier);
        if (parsedIdentifier.type === NodeType.User)
        {
          const { agencyId, userId } = parsedIdentifier;
          const userSummary = usersSummariesMap.get(`${agencyId}:${userId}`);
          assert(userSummary, 'Unable to retrieve user summary');
          const { months } = userSummary;
          const quarters = monthsToQuarters(months);
          const total = sum(months);
          return {
            ...node,
            months,
            quarters,
            total,
            childNodes: [],
          };
        }
        const childNodes = directChildren.map(processNode);
        const months = childNodes.map(({ months }) => months).reduce((acc, months) => {
          return acc.map((val, idx) => val + months[idx]);
        }, genFilledArray({ length: 12, fill: 0 }));
        const quarters = monthsToQuarters(months);
        const total = sum(months);
        return {
          ...node,
          months,
          quarters,
          total,
          childNodes,
        };
      }

      return { year, summaryTree: tree.rootNodes.map(processNode) };
    });
  }, { catchUnhandled: handleCriticalError });

  public commissionsStats = new Fetchable(() => GetCommissionsStats({
    ...this._filters,
  }), { catchUnhandled: handleCriticalError });

  public managedUsersHistorical = new Fetchable(GetManagedUsersHistorical, { catchUnhandled: handleNonCriticalError });

  @computed get usersHierarchyNodes()
  {
    return genHistoryNodes(geoAreasStore.geoAreas, this.managedUsersHistorical?.lastResult ?? []);
  }

  @computed get usersHierarchyTree()
  {
    return makeUserHierarchyTree(this.usersHierarchyNodes);
  }

  public settleInvoice = SettleInvoice;

  public async updateCommissionPaymentInfo(
    args: Omit<UpdateCommissionPaymentInfoArgs, 'language'>,
  )
  {
    const res = await UpdateCommissionPaymentInfo({
      ...args,
      language: localeStore.currentLocale,
    });
    if (this.lastCommissions.data && res)
    {
      const edge = this.lastCommissions.data.edges.find(({node}) => (node.id === res.id));
      if (edge)
        runInAction(() => { edge.node = res; });
    }
  };

  public async refresh()
  {
    await this.managedUsersHistorical.ensureSuccess();
    this._filters = this._defaultFilters;
    await Promise.all([
      this.lastCommissions.first(),
      this.agenciesSummary.ensureSuccessReload(),
      this.commissionsStats.ensureSuccessReload(),
    ]);
  }
}

export default new OffersStore();
