import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface PropertySetPhotoItem_OneOf
{
  create?: File | undefined,
  existing?: IdType | undefined,
}

export interface SetPropertyPhotosVariables
{
  propertyId: IdType,
  photos: Array<PropertySetPhotoItem_OneOf>,
}

export default function SetPropertyPhotos(variables: SetPropertyPhotosVariables)
{
  return mutation<{ id: IdType }>({
    operationName: 'Property_Medias_SetPropertyPhotos',
    variables: {
      propertyId: 'Int!',
      photos: '[PropertySetPhotoItem_OneOf!]!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilPropertyPhoto: {
          setPropertyPhotos: [{
            args: {
              propertyId: true,
              photos: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, variables);
}
