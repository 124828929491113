import type { MyBreteuilAgency, MyBreteuilAgencyUpdateVariables } from './api';

import { observable } from 'mobx';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleCriticalError } from '@repo-breteuil/front-error';
import { GetMyBreteuilAgencies, UpdateMyBreteuilAgency } from './api';

class AgenciesStore
{
  @observable private _selectedAgency: MyBreteuilAgency | null = null;

  public get selectedAgency()
  {
    return this._selectedAgency;
  }

  public set selectedAgency(agency)
  {
    this._selectedAgency = agency;
  }

  public agencies = new Fetchable(GetMyBreteuilAgencies, { catchUnhandled: handleCriticalError });

  public async updateAgencyAndRefresh(args: MyBreteuilAgencyUpdateVariables)
  {
    await UpdateMyBreteuilAgency(args);
    this.selectedAgency = null;
    await this.refresh();
  }

  public async refresh()
  {
    await this.agencies.ensureSuccessReload();
  }
}

export default new AgenciesStore();
