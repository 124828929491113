import type { IdType } from '@repo-breteuil/common-definitions';
import type { FiltersArgs } from './filters';

import { pickKeysFromObject } from '@repo-lib/utils-core';
import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { FiltersVariables, FiltersFieldArgs } from './filters';

export interface TransactionsYearlySummaryAnalyticsTab
{
  agency: {
    id: IdType,
    shortName: string,
    agencyTreeGeoAreaId: IdType | null,
  },
  user: {
    id: IdType,
    fullname: string,
  },
  months: Array<number>,
}

export interface TransactionsYearlySummaryAnalytics
{
  year: number,
  data: Array<TransactionsYearlySummaryAnalyticsTab>,
}

export interface GetAgenciesSummaryVariables extends Omit<FiltersArgs, 'dateRange'> {}

const FiltersArgsPickedKeys: Array<keyof FiltersArgs> = [
  'type', 'operationTypes', 'agencyUsers', 'cancelledOnly', 'yearRange',
];

export default function GetAgenciesSummary(variables: GetAgenciesSummaryVariables)
{
  return query<Array<TransactionsYearlySummaryAnalytics>>({
    operationName: 'Offers_GetAgenciesSummary',
    variables: pickKeysFromObject(FiltersVariables, FiltersArgsPickedKeys),
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          reporting: {
            agenciesSummary: [{
              args: pickKeysFromObject(FiltersFieldArgs, FiltersArgsPickedKeys),
            }, {
              year: true,
              data: {
                agency: {
                  id: true,
                  shortName: true,
                  agencyTreeGeoAreaId: true,
                },
                user: {
                  id: true,
                  fullname: true,
                },
                months: true,
              },
            }],
          },
        },
      }),
    },
  }, variables);
}
