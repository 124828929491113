import type { IdType } from '@repo-breteuil/common-definitions';
import {
  query,
  errorWrapper,
  paginationResultAsList,
  ExtendedPaginationVariables,
  ExtendedPaginationArgs,
} from '@core/api/graphql/gen-v2';
import {
  OrderBy,
  Filters__Boolean,
} from '@core/api/types';

export interface FolderFile
{
  id: IdType,
  name: string,
  position: number,
  folderId: IdType,
  fileUploadId: IdType,
  fileURL: string,
  updatedAt: Date,
  user: {
    id: IdType,
    fullname: string,
  } | null,
}

export interface Folder
{
  id: IdType,
  name: string,
  position: number,
  linkedMybAgencyIds: Array<IdType>,
  linkedMybCompanyIds: Array<IdType>,
  personal: boolean,
  editableByManagers: boolean,
  visibleByAgents: boolean,
  externalUrl: string,
  folderFiles: Array<FolderFile>,
}

export const getFoldersFields = (
  args: {
    files: 'self' | 'managed',
  },
) => paginationResultAsList({
  id: true,
  name: true,
  position: true,
  linkedMybAgencyIds: true,
  linkedMybCompanyIds: true,
  personal: true,
  editableByManagers: true,
  visibleByAgents: true,
  externalUrl: true,
  folderFiles: [{
    aliasedField: (args.files === 'managed') ? 'managedFiles' : 'files',
  }, paginationResultAsList({
    id: true,
    name: true,
    position: true,
    folderId: true,
    fileUploadId: true,
    fileURL: true,
    updatedAt: true,
    user: {
      id: true,
      fullname: true,
    },
  })],
});

export function GetFolders(
  args: {
    filter?: {
      personal: Filters__Boolean,
      visibleByAgents: Filters__Boolean,
    },
    orderBy?: OrderBy,
  },
)
{
  return query<Array<Folder>>({
    operationName: 'Folders_Folders',
    variables: {
      ...ExtendedPaginationVariables('Folder'),
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        folders: [{
          args: ExtendedPaginationArgs,
        }, getFoldersFields({ files: 'managed' }) ],
      }),
    },
  }, args);
}
