import type { IdType } from '@repo-breteuil/common-definitions';
import type {
  OperationType,
} from '@core/api/types/enums';
import type { MyBreteuilSearch } from './GetContact';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export type ArchiveSearchArgs = Pick<UpdateSearchArgs, "id" | "archived">

export type UpdateSearchArgs = {
  id: IdType,
  archived?: boolean,
  budgetMin?: number | null,
  budgetMax?: number,
  surfaceMin?: number | null,
  surfaceMax?: number | null,
  bedroomsMin?: number | null,
  bedroomsMax?: number | null,
  operationType?: OperationType,
  myb_offre?: boolean,
  criteriaIds?: Array<IdType>,
  geoAreasIds?: Array<IdType>,
  usersIds?: Array<IdType>,
}

export default function UpdateSearch(args: UpdateSearchArgs | ArchiveSearchArgs)
{
  return mutation<MyBreteuilSearch>({
    operationName: 'Contact_UpdateSearch',
    variables: {
      id: "Int!",
      archived: "Boolean",
      budgetMin: "Int",
      budgetMax: "Int",
      surfaceMin: "Int",
      surfaceMax: "Int",
      bedroomsMin: "Int",
      bedroomsMax: "Int",
      operationType: "MyBreteuilOperationType",
      myb_offre: "Boolean",
      criteriaIds: "[Int!]",
      geoAreasIds: "[Int!]",
      usersIds: "[Int!]",
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilSearch: {
          update: [{
            args: {
              id: true,
              archived: true,
              budgetMin: true,
              budgetMax: true,
              surfaceMin: true,
              surfaceMax: true,
              bedroomsMin: true,
              bedroomsMax: true,
              operationType: true,
              myb_offre: true,
              criteriaIds: true,
              geoAreasIds: true,
              usersIds: true,
            },
          }, { id: true }],
        },
      }),
    },
  }, args);
}
