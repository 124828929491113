import type { IdType } from '@repo-breteuil/common-definitions';

import { observable, computed, action } from 'mobx';
import { OrderType } from '@repo-lib/graphql-query-pagination';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError, ensureFetchableResource } from '@repo-breteuil/front-error';
import {
  GetAgencies,
  GetManagedCompanies,
  GetManagedUsers,
  GetFolders,
  FolderFile,
} from './api';

class FoldersMain
{
  public agencies = new Fetchable(GetAgencies, { catchUnhandled: handleNonCriticalError });
  public managedCompanies = new Fetchable(GetManagedCompanies, { catchUnhandled: handleNonCriticalError });
  public managedUsers = new Fetchable(GetManagedUsers, { catchUnhandled: handleNonCriticalError });

  private _folders = new Fetchable(() => {
    return GetFolders({
      orderBy: [{ fieldName: 'name', ordering: OrderType.ASC }],
    });
  }, { catchUnhandled: handleNonCriticalError });

  @observable selectedFile: FolderFile | null = null;

  @observable files: FileList | null = null;

  @observable selectedFolderId: IdType | null = null;

  @action setSelectedFolderId(id: IdType | null)
  {
    this.selectedFolderId = id;
  }

  @action setSelectedFile(file: FolderFile)
  {
    this.selectedFile = file;
  }

  @computed get folders()
  {
    return this._folders.result || [];
  }

  @computed get selectedFolder()
  {
    if (this.selectedFolderId === null)
      return null;
    return this.folders.find(folder => (folder.id === this.selectedFolderId)) || null;
  }

  @computed get isSingleFileUpload() {
    return this.files?.length === 1;
  }

  @computed get isMultiFileUpload() {
    return (this.files?.length ?? 0) > 1;
  }

  @computed get singleFileName() {
    return this.isSingleFileUpload ? (this.files?.[0])?.name : undefined;
  }

  @action.bound handleFileChange(event)
  {
    const {
      target: {
        validity,
        files,
      },
    } = event;

    if (validity.valid)
      this.files = files;
  }

  get selectableAgencies()
  {
    return ensureFetchableResource(this.agencies);
  }

  get selectableCompanies()
  {
    return ensureFetchableResource(this.managedCompanies);
  }

  get selectableUsers()
  {
    return ensureFetchableResource(this.managedUsers);
  }

  refreshFolders()
  {
    return this._folders.ensureSuccessReload();
  }

  async refresh()
  {
    await Promise.all([
      this.refreshFolders(),
      this.agencies.ensureSuccessReload(),
      this.managedCompanies.ensureSuccessReload(),
      this.managedUsers.ensureSuccessReload(),
    ]);
  }
}

export default new FoldersMain();
