import type { PartialNullish } from '@repo-lib/utils-core';
import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs } from '@repo-lib/graphql-query-builder';
import type {
  PropertyStatus,
  OperationType,
  BreteuilPropertySurfaceUnit,
  PropertyType,
  MyBreteuilPropertyRentalExpensesTypeEnum,
  BreteuilPropertyLawFulRental,
  MyBreteuilHeating,
  MyBreteuilWaterHeating,
  BreteuilPropertyUsage,
  MyBreteuilPropertyDisponibilityEnum,
  Orientation,
  MyBreteuilPropertyVisibility,
  MyBreteuilPropertySurroundingDistance,
  BreteuilWebsiteViewType,
  BreteuilWebsiteRenovationType,
  DPERating,
  PropertyFavoriteStatus,
 } from '@core/api/types';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export type PublicationPlatforms = PartialNullish<{
  BienIci?: boolean | null,
  SeLoger?: boolean | null,
  BellesDemeures?: boolean | null,
  MaisonsEtAppartements?: boolean | null,
  ResidencesImmobilier?: boolean | null,
  PLF?: boolean | null,
  FigaroImmo?: boolean | null,
}>;

export interface CreatePropertyVariables
{
  title: string,
  price: number,
  priceMax: number | null,
  priceOnRequest: boolean,
  status: PropertyStatus,
  displayedStatus?: PropertyStatus | null | undefined,
  availabilityDate?: number | null/**timestamp */ | undefined,
  agencyId: number,
  warrantNumber?: string | null | undefined,
  warrantDate?: number | null/**timestamp */ | undefined,
  warrantEndDate?: number | null/**timestamp */ | undefined,
  exclusive?: boolean | undefined,
  displayedExclusive?: boolean | null | undefined,
  verifiedWarrant?: boolean | null | undefined,
  address: string,
  userIds: Array<IdType>,
  origine?: string | null | undefined,
  contactIds: Array<IdType>,
  operationType: OperationType,
  myb_reference?: string | null | undefined,
  registrationNumber?: string | null | undefined,
  favoriteStatus?: PropertyFavoriteStatus | null | undefined,
  myb_entree?: boolean | null | undefined,
  companyManagementBroughtDeal?: boolean | null | undefined,
  marketConsistent?: boolean | undefined,
  descriptionFR?: string | null | undefined,
  descriptionEN?: string | null | undefined,
  descriptionPT?: string | null | undefined,
  descriptionES?: string | null | undefined,
  myb_description_smsFR?: string | null | undefined,
  myb_description_smsEN?: string | null | undefined,
  myb_description_smsPT?: string | null | undefined,
  myb_description_smsES?: string | null | undefined,
  quoteDescriptionFR?: string | null | undefined,
  quoteDescriptionEN?: string | null | undefined,
  quoteDescriptionPT?: string | null | undefined,
  quoteDescriptionES?: string | null | undefined,
  practicalInformations?: string | null | undefined,
  collectiveOwnership?: boolean | null | undefined,
  lots?: number | null | undefined,
  collectiveOwnershipCharges?: number | null | undefined,
  myb_taxe_fonciere?: number | null | undefined,
  fees?: number | null | undefined,
  feesPercentage?: number | null | undefined,
  feesPaidByBuyer?: Boolean | undefined,
  rentalExpenses?: number | null | undefined,
  rentalExpensesType?: MyBreteuilPropertyRentalExpensesTypeEnum | null | undefined,
  rentalDeposit?: number | null | undefined,
  myb_locatif_legal?: BreteuilPropertyLawFulRental | null | undefined,
  guestsCount: number | null,
  keyInfosIds?: Array<IdType> | null | undefined,
  surroundings?: Array<{
    surroundingId: IdType,
    distance: MyBreteuilPropertySurroundingDistance,
  }> | null | undefined,
  rentalOwnerIntermediary?: number | null | undefined,
  rentalAdministrationFees?: number | null | undefined,
  rentalOwnerAdministrationFees?: number | null | undefined,
  rentalInventory?: number | null | undefined,
  rentalOwnerInventory?: number | null | undefined,
  surface: number,
  surfaceUnit?: BreteuilPropertySurfaceUnit | null | undefined,
  bedrooms?: number | null | undefined,
  floor: number,
  floorsCount?: number | null | undefined,
  propertyType: PropertyType,
  constructionYear?: number | null | undefined,
  criteriaIds: Array<IdType>,
  lotSize?: number | null | undefined,
  rooms?: number | null | undefined,
  bathrooms?: number | null | undefined,
  toilets?: number | null | undefined,
  balconyCount?: number | null | undefined,
  ceilingHeight?: number | null | undefined,
  heating?: MyBreteuilHeating | null | undefined,
  waterHeatType?: MyBreteuilWaterHeating | null | undefined,
  usage?: BreteuilPropertyUsage | null | undefined,
  disponibility?: MyBreteuilPropertyDisponibilityEnum | null | undefined,
  orientation?: Orientation | null | undefined,
  sectorization?: string | null | undefined,
  transports?: string | null | undefined,
  buildingType?: string | null | undefined,
  dpe?: number | null | undefined,
  dpeCarbonFootprint?: number | null | undefined,
  dpeKwhRatingOverride?: DPERating | null | undefined,
  dpeCo2RatingOverride?: DPERating | null | undefined,
  dpeCreationDate?: number | null/** timestamp */ | undefined,
  dpeSimulationReferenceDate?: number | null/** timestamp*/ | undefined,
  dpeAnnualCost?: number | null | undefined,
  inflatedRent?: number | null | undefined,
  rentControl?: boolean | undefined,
  taxIdentificationNumber?: string | undefined,
  videoURL?: string | null | undefined,
  myb_photographer_id?: IdType | null | undefined,
  publication?: MyBreteuilPropertyVisibility | null | undefined,
  forceSendable?: boolean | null | undefined,
  publicationPlatformsAgencyId?: IdType | null | undefined,
  publicationPlatforms?: PublicationPlatforms | null | undefined,
  view?: BreteuilWebsiteViewType | null | undefined,
  renovation?: BreteuilWebsiteRenovationType | null | undefined,
  balconySurface?: number | null | undefined,
  addressPricePerSurfaceDifferenceFromSubDistrict?: number | null | undefined,
}

export const CreatePropertyArgs: ReadonlyFieldArgs = {
  title: true,
  price: true,
  priceMax: true,
  priceOnRequest: true,
  status: true,
  displayedStatus: true,
  availabilityDate: true,
  agencyId: true,
  warrantNumber: true,
  warrantDate: true,
  warrantEndDate: true,
  exclusive: true,
  displayedExclusive: true,
  verifiedWarrant: true,
  address: true,
  userIds: true,
  origine: true,
  contactIds: true,
  operationType: true,
  myb_reference: true,
  registrationNumber: true,
  favoriteStatus: true,
  myb_entree: true,
  companyManagementBroughtDeal: true,
  marketConsistent: true,
  descriptionFR: true,
  descriptionEN: true,
  descriptionPT: true,
  descriptionES: true,
  myb_description_smsFR: true,
  myb_description_smsEN: true,
  myb_description_smsPT: true,
  myb_description_smsES: true,
  quoteDescriptionFR: true,
  quoteDescriptionEN: true,
  quoteDescriptionPT: true,
  quoteDescriptionES: true,
  practicalInformations: true,
  collectiveOwnership: true,
  lots: true,
  collectiveOwnershipCharges: true,
  myb_taxe_fonciere: true,
  fees: true,
  feesPercentage: true,
  feesPaidByBuyer: true,
  rentalExpenses: true,
  rentalExpensesType: true,
  rentalDeposit: true,
  myb_locatif_legal: true,
  guestsCount: true,
  keyInfosIds: true,
  surroundings: true,
  rentalOwnerIntermediary: true,
  rentalAdministrationFees: true,
  rentalOwnerAdministrationFees: true,
  rentalInventory: true,
  rentalOwnerInventory: true,
  surface: true,
  surfaceUnit: true,
  bedrooms: true,
  floor: true,
  floorsCount: true,
  propertyType: true,
  constructionYear: true,
  criteriaIds: true,
  lotSize: true,
  rooms: true,
  bathrooms: true,
  toilets: true,
  balconyCount: true,
  ceilingHeight: true,
  heating: true,
  waterHeatType: true,
  usage: true,
  disponibility: true,
  orientation: true,
  sectorization: true,
  transports: true,
  buildingType: true,
  dpe: true,
  dpeCarbonFootprint: true,
  dpeKwhRatingOverride: true,
  dpeCo2RatingOverride: true,
  dpeCreationDate: true,
  dpeSimulationReferenceDate: true,
  dpeAnnualCost: true,
  inflatedRent: true,
  rentControl: true,
  taxIdentificationNumber: true,
  videoURL: true,
  myb_photographer_id: true,
  publication: true,
  forceSendable: true,
  publicationPlatformsAgencyId: true,
  publicationPlatforms: true,
  view: true,
  renovation: true,
  balconySurface: true,
  addressPricePerSurfaceDifferenceFromSubDistrict: true,
};

export default function CreateProperty(variables: CreatePropertyVariables)
{
  return mutation<{ id: IdType, slug: string }>({
    operationName: 'Property_Info_CreateProperty',
    variables: {
      title: 'String!',
      price: 'Int!',
      priceMax: 'Int',
      priceOnRequest: 'Boolean!',
      status: 'MyBreteuilPropertyStatus!',
      displayedStatus: 'MyBreteuilPropertyStatus',
      availabilityDate: 'Date',
      agencyId: 'Int!',
      warrantNumber: 'String',
      warrantDate: 'Date',
      warrantEndDate: 'Date',
      exclusive: 'Boolean',
      displayedExclusive: 'Boolean',
      verifiedWarrant: 'Boolean',
      address: 'String!',
      userIds: '[Int!]!',
      origine: 'String',
      contactIds: '[Int!]!',
      operationType: 'MyBreteuilOperationType!',
      myb_reference: 'String',
      registrationNumber: 'String',
      favoriteStatus: 'PropertyFavoriteStatus',
      myb_entree: 'Boolean',
      companyManagementBroughtDeal: 'Boolean',
      marketConsistent: 'Boolean',
      descriptionFR: 'String',
      descriptionEN: 'String',
      descriptionPT: 'String',
      descriptionES: 'String',
      myb_description_smsFR: 'String',
      myb_description_smsEN: 'String',
      myb_description_smsPT: 'String',
      myb_description_smsES: 'String',
      quoteDescriptionFR: 'String',
      quoteDescriptionEN: 'String',
      quoteDescriptionPT: 'String',
      quoteDescriptionES: 'String',
      practicalInformations: 'String',
      collectiveOwnership: 'Boolean',
      lots: 'Int',
      collectiveOwnershipCharges: 'Float',
      myb_taxe_fonciere: 'Float',
      fees: 'Float',
      feesPercentage: 'Float',
      feesPaidByBuyer: 'Boolean',
      rentalExpenses: 'Float',
      rentalExpensesType: 'MyBreteuilPropertyRentalExpensesTypeEnum',
      rentalDeposit: 'Float',
      myb_locatif_legal: 'BreteuilPropertyLawFulRental',
      guestsCount: 'Int',
      keyInfosIds: '[Int!]',
      surroundings: '[MyBreteuilPropertySurroundingWithDistance!]',
      rentalOwnerIntermediary: 'Float',
      rentalAdministrationFees: 'Float',
      rentalOwnerAdministrationFees: 'Float',
      rentalInventory: 'Float',
      rentalOwnerInventory: 'Float',
      surface: 'Float!',
      surfaceUnit: 'BreteuilPropertySurfaceUnit',
      bedrooms: 'Int',
      floor: 'Int!',
      floorsCount: 'Int',
      propertyType: 'MyBreteuilPropertyType!',
      constructionYear: 'Int',
      criteriaIds: '[Int!]!',
      lotSize: 'Float',
      rooms: 'Int',
      bathrooms: 'Int',
      toilets: 'Int',
      balconyCount: 'Int',
      ceilingHeight: 'Float',
      heating: 'MyBreteuilHeating',
      waterHeatType: 'MyBreteuilWaterHeating',
      usage: 'BreteuilPropertyUsage',
      disponibility: 'MyBreteuilPropertyDisponibilityEnum',
      orientation: 'Orientation',
      sectorization: 'String',
      transports: 'String',
      buildingType: 'String',
      dpe: 'Float',
      dpeCarbonFootprint: 'Int',
      dpeKwhRatingOverride: 'DPERating',
      dpeCo2RatingOverride: 'DPERating',
      dpeCreationDate: 'Date',
      dpeSimulationReferenceDate: 'Date',
      dpeAnnualCost: 'Float',
      inflatedRent: 'Float',
      rentControl: 'Boolean',
      taxIdentificationNumber: 'String',
      videoURL: 'String',
      myb_photographer_id: 'Int',
      publication: 'MyBreteuilPropertyVisibility',
      forceSendable: 'Boolean',
      publicationPlatformsAgencyId: 'Int',
      publicationPlatforms: 'PublicationPlatforms',
      view: 'BreteuilWebsiteViewType',
      renovation: 'BreteuilWebsiteRenovationType',
      balconySurface: 'Float',
      addressPricePerSurfaceDifferenceFromSubDistrict: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: {
          create: errorWrapper([{
            args: CreatePropertyArgs,
          }, {
            id: true,
            slug: true,
          }]),
        },
      }),
    },
  },variables);
}
