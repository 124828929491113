import type { IdType } from '@repo-breteuil/common-definitions';
import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface ManagedUser
{
  id: IdType,
  fullname: string,
  manager: boolean,
}

export function GetManagedUsers()
{
  return query<Array<ManagedUser>>({
    operationName: 'Folders_ManagedMyBreteuilUsers',
    fieldsSelection: {
      authenticated: errorWrapper({
        users: [{
          args: {
            managedOnly: { literal: true },
            includePotCommun: { literal: true },
            includeNonAgents: { literal: true },
          },
        }, paginationResultAsList({
          id: true,
          fullname: true,
          manager: true,
        }) ],
      }),
    },
  });
}
