import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs, ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export interface MyBreteuilUserHistorical
{
  user: {
    id: IdType,
    fullname: string,
  },
  agency: {
    id: IdType,
    shortName: string,
    agencyTreeGeoAreaId: IdType | null,
  },
  activeInAgency: boolean,
}

export const MyBreteuilUserHistoricalFields: ReadonlyNodeFields = {
  user: {
    id: true,
    fullname: true,
  },
  agency: {
    id: true,
    shortName: true,
    agencyTreeGeoAreaId: true,
  },
  activeInAgency: true,
};

export const ManagedUsersHistoricalFieldArgs: ReadonlyFieldArgs = {
  managedOnly: { literal: 'true' },
  includePotCommun: { literal: 'false' },
  includeInactiveUsers: { literal: 'true' },
  includePreviousAgencyAffectations: { literal: 'true' },
  includeInactiveAgencies: { literal: 'false' },
  includeChildAgencies: { literal: 'true' },
  includeNonAgents: { literal: 'true' },
};

export default function GetManagedUsersHistorical()
{
  return query<Array<MyBreteuilUserHistorical>>({
    operationName: 'Profile_GetUsers_ManagedHistorical',
    fieldsSelection: {
      authenticated: errorWrapper({
        usersPerAgency: [{
          args: ManagedUsersHistoricalFieldArgs,
        }, MyBreteuilUserHistoricalFields],
      }),
    },
  });
}
