import type { IdType, OperationType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { Language } from '@repo-breteuil/common-texts';
import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export interface Criteria
{
  id: IdType,
  webName: string,
}

const CriteriaFields = {
  id: true,
  webName: [{ args: { language: true } }, true],
} satisfies ReadonlyNodeFields;

export interface GetCriteriaArgs
{
  language: Language,
  operationType?: OperationType | null | undefined,
  geoAreaId?: IdType | null | undefined,
}

export default function GetCriteria(args: GetCriteriaArgs)
{
  return query<Array<Array<Criteria>>>({
    operationName: 'Buyers_GetCriteria',
    variables: {
      language: 'Language!',
      operationType: 'MyBreteuilOperationType',
      geoAreaId: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        searchCriteria: [{
          args: {
            operationType: true,
            geoAreaId: true,
          },
        }, CriteriaFields],
      }),
    },
  }, args);
}
