import type { IdType } from '@repo-breteuil/common-definitions';
import {
  query,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface ManagedCompany
{
  id: IdType,
  name: string,
}

export function GetManagedCompanies()
{
  return query<Array<ManagedCompany>>({
    operationName: 'Folders_ManagedMyBreteuilCompanies',
    fieldsSelection: {
      authenticated: errorWrapper({
        managedMyBreteuilCompanies: {
          id: true,
          name: true,
        },
      }),
    },
  });
}
