import type { IdType } from '@repo-breteuil/common-definitions';
import type { OrderField } from '@core/api/types';
import type { UserGoals, UserGoalDetail } from './api';

import { action, computed, observable } from 'mobx';
import { startOfMonth, startOfQuarter } from '@repo-lib/utils-date-tz';
import { makeArrayUniq, setsIntersect, sum } from '@repo-lib/utils-core';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { assert, handleCriticalError } from '@repo-breteuil/front-error';
import { OrderType } from '@core/api/types';
import { AggregationMethod } from '@my-breteuil/components/pages/profile/common';
import { applyTableOrderBy } from '@my-breteuil/store/ui/common/array-processing-utils';
import { GetUsersGoals } from './api';
import profileStore from './index';
import { getUserActiveDaysInPeriod, aggregateMultiple } from './common';

function makeAgencyUserIdentifier(agencyUser: { agencyId: IdType, userId: IdType })
{
  const { agencyId, userId } = agencyUser;
  return `${agencyId}-${userId}`;
}

function indexAgencyUsers(agencyUsers: Array<{ agencyId: IdType, userId: IdType }>)
{
  return new Set(agencyUsers.map(makeAgencyUserIdentifier));
}

// TODO improve perfs
function sumUpMetricDetails(details: Array<UserGoalDetail>, selectedAgenciesIds: Set<IdType>)
{
  return sum(details.filter(({ agency }) => selectedAgenciesIds.size === 0 || selectedAgenciesIds.has(agency.id)).map(({ value }) => value));
}

function flattenUserGoals(userGoals: UserGoals, selectedAgenciesIds: Set<IdType>, startDate: number, endDate: number)
{
  const activeDays = getUserActiveDaysInPeriod({ user: userGoals.user, startDate, endDate });
  const agenciesIds = makeArrayUniq([
    ...userGoals.acceptedOffersHT.details.map(({ agency }) => agency.id),
    ...userGoals.successfulReminders.details.map(({ agency }) => agency.id),
    ...userGoals.successfulPhoneReminders.details.map(({ agency }) => agency.id),
    ...userGoals.successfulWhatsAppReminders.details.map(({ agency }) => agency.id),
    ...userGoals.madeOffers.details.map(({ agency }) => agency.id),
    ...userGoals.mandates.details.map(({ agency }) => agency.id),
    ...userGoals.viewings.details.map(({ agency }) => agency.id),
    ...userGoals.valuations.details.map(({ agency }) => agency.id),
    ...userGoals.prospectedStreets.details.map(({ agency }) => agency.id),
  ]);
  const indexedAgencyUsers = new Set(agenciesIds.map((agencyId) => (
    makeAgencyUserIdentifier({ agencyId, userId: userGoals.user.id })
  )));
  return {
    userId: userGoals.user.id,
    agenciesIds,
    indexedAgencyUsers,
    fullname: userGoals.user.fullname,
    active: userGoals.user.active,
    activeDays,
    acceptedOffersHT__agencyValue: sumUpMetricDetails(userGoals.acceptedOffersHT.details, selectedAgenciesIds),
    acceptedOffersHT__value: userGoals.acceptedOffersHT.value,
    acceptedOffersHT__details: userGoals.acceptedOffersHT.details,
    acceptedOffersHT__goal: userGoals.acceptedOffersHT.goal,
    acceptedOffersHT__rank: userGoals.acceptedOffersHT.rank,
    successfulReminders__agencyValue: sumUpMetricDetails(userGoals.successfulReminders.details, selectedAgenciesIds),
    successfulReminders__value: userGoals.successfulReminders.value,
    successfulReminders__details: userGoals.successfulReminders.details,
    successfulReminders__goal: userGoals.successfulReminders.goal,
    successfulReminders__rank: userGoals.successfulReminders.rank,
    successfulPhoneReminders__agencyValue: sumUpMetricDetails(userGoals.successfulPhoneReminders.details, selectedAgenciesIds),
    successfulPhoneReminders__value: userGoals.successfulPhoneReminders.value,
    successfulPhoneReminders__details: userGoals.successfulPhoneReminders.details,
    successfulPhoneReminders__goal: userGoals.successfulPhoneReminders.goal,
    successfulPhoneReminders__rank: userGoals.successfulPhoneReminders.rank,
    successfulWhatsAppReminders__agencyValue: sumUpMetricDetails(userGoals.successfulWhatsAppReminders.details, selectedAgenciesIds),
    successfulWhatsAppReminders__value: userGoals.successfulWhatsAppReminders.value,
    successfulWhatsAppReminders__details: userGoals.successfulWhatsAppReminders.details,
    successfulWhatsAppReminders__goal: userGoals.successfulWhatsAppReminders.goal,
    successfulWhatsAppReminders__rank: userGoals.successfulWhatsAppReminders.rank,
    madeOffers__agencyValue: sumUpMetricDetails(userGoals.madeOffers.details, selectedAgenciesIds),
    madeOffers__value: userGoals.madeOffers.value,
    madeOffers__details: userGoals.madeOffers.details,
    madeOffers__goal: userGoals.madeOffers.goal,
    madeOffers__rank: userGoals.madeOffers.rank,
    mandates__agencyValue: sumUpMetricDetails(userGoals.mandates.details, selectedAgenciesIds),
    mandates__value: userGoals.mandates.value,
    mandates__details: userGoals.mandates.details,
    mandates__goal: userGoals.mandates.goal,
    mandates__rank: userGoals.mandates.rank,
    viewings__agencyValue: sumUpMetricDetails(userGoals.viewings.details, selectedAgenciesIds),
    viewings__value: userGoals.viewings.value,
    viewings__details: userGoals.viewings.details,
    viewings__goal: userGoals.viewings.goal,
    viewings__rank: userGoals.viewings.rank,
    valuations__agencyValue: sumUpMetricDetails(userGoals.valuations.details, selectedAgenciesIds),
    valuations__value: userGoals.valuations.value,
    valuations__details: userGoals.valuations.details,
    valuations__goal: userGoals.valuations.goal,
    valuations__rank: userGoals.valuations.rank,
    prospectedStreets__agencyValue: sumUpMetricDetails(userGoals.prospectedStreets.details, selectedAgenciesIds),
    prospectedStreets__value: userGoals.prospectedStreets.value,
    prospectedStreets__details: userGoals.prospectedStreets.details,
    prospectedStreets__goal: userGoals.prospectedStreets.goal,
    prospectedStreets__rank: userGoals.prospectedStreets.rank,
  } as const;
}

export type UsersGoalsTotals = ReturnType<typeof aggregateUsersGoalsTotals>;

function aggregateUsersGoalsTotals(
  usersGoals: Array<ReturnType<typeof flattenUserGoals>>,
  useAgencyValue: boolean,
  method: AggregationMethod,
)
{
  const aggregateBaseOpts = (
    method === AggregationMethod.Avg
      ? { method: 'weightedAvg', weightField: 'activeDays' } as const
      : { method: 'sum' } as const
  );
  const sufix = useAgencyValue ? 'agencyValue' : 'value';

  return aggregateMultiple(usersGoals, {
    acceptedOffersHT__value: { field: `acceptedOffersHT__${sufix}`, ...aggregateBaseOpts },
    acceptedOffersHT__goal: aggregateBaseOpts,
    successfulReminders__value: { field: `successfulReminders__${sufix}`, ...aggregateBaseOpts },
    successfulReminders__goal: aggregateBaseOpts,
    successfulPhoneReminders__value: { field: `successfulPhoneReminders__${sufix}`, ...aggregateBaseOpts },
    successfulPhoneReminders__goal: aggregateBaseOpts,
    successfulWhatsAppReminders__value: { field: `successfulWhatsAppReminders__${sufix}`, ...aggregateBaseOpts },
    successfulWhatsAppReminders__goal: aggregateBaseOpts,
    madeOffers__value: { field: `madeOffers__${sufix}`, ...aggregateBaseOpts },
    madeOffers__goal: aggregateBaseOpts,
    mandates__value: { field: `mandates__${sufix}`, ...aggregateBaseOpts },
    mandates__goal: aggregateBaseOpts,
    viewings__value: { field: `viewings__${sufix}`, ...aggregateBaseOpts },
    viewings__goal: aggregateBaseOpts,
    valuations__value: { field: `valuations__${sufix}`, ...aggregateBaseOpts },
    valuations__goal: aggregateBaseOpts,
    prospectedStreets__value: { field: `prospectedStreets__${sufix}`, ...aggregateBaseOpts },
    prospectedStreets__goal: aggregateBaseOpts,
  });
}

export const MinMonthsOffset = -15;
export const MaxMonthsOffset = 0;

class UsersGoalsStore
{
  @observable private _startMonthOffset = startOfQuarter().getMonth() - new Date().getMonth(); // Sets the offset to the start of the quarter
  @observable private _endMonthOffset = 0;

  get startMonthOffset()
  {
    return this._startMonthOffset;
  }

  get endMonthOffset()
  {
    return this._endMonthOffset;
  }

  @action private setStartMonthOffset(offset: number)
  {
    this._startMonthOffset = offset;
  }

  @action private setEndMonthOffset(offset: number)
  {
    this._endMonthOffset = offset;
  }

  @computed public get startDate()
  {
    return startOfMonth({ shift: this._startMonthOffset, timezone: 'UTC' }).getTime();
  }

  @computed public get endDate()
  {
    return startOfMonth({ shift: this._endMonthOffset + 1, timezone: 'UTC' }).getTime();
  }

  public setMonthsOffsetsAndReload(startMonthOffset: number, endMonthOffset: number)
  {
    this.setStartMonthOffset(startMonthOffset);
    this.setEndMonthOffset(endMonthOffset);
    return this.usersGoalsFetchable.ensureSuccessReload();
  }

  public usersGoalsFetchable = new Fetchable(() => (
    GetUsersGoals({
      startDate: this.startDate,
      endDate: this.endDate,
    })
  ), { catchUnhandled: handleCriticalError });

  @computed get usersGoals()
  {
    assert(this.usersGoalsFetchable.lastResult);
    return this.usersGoalsFetchable.lastResult;
  }

  @computed public get usersGoalsScoreboard()
  {
    return this.usersGoals.scoreboard;
  }

  @computed public get rankedUsersCount()
  {
    return this.usersGoals.rankedUsersCount;
  }

  @computed public get rankedAgencies()
  {
    return this.usersGoals.rankedAgencies;
  }

  @computed public get agenciesScoreboard()
  {
    return this.usersGoals.agenciesScoreboard;
  }

  @computed private get _flattenedUsersGoals()
  {
    return this.usersGoals.users.map(userGoals => (
      flattenUserGoals(userGoals, profileStore.selectedAgenciesIds, this.startDate, this.endDate)
    ));
  }

  @computed private get _filteredUsers()
  {
    if (profileStore.selectedUsersIds.size <= 0)
      return this._flattenedUsersGoals;
    const indexedSelectedAgencyUsers = indexAgencyUsers(profileStore.selectedAgencyUsersIds);
    return this._flattenedUsersGoals.filter(({ indexedAgencyUsers }) => (
      setsIntersect(indexedAgencyUsers, indexedSelectedAgencyUsers)
    ));
  }

  @computed public get processedUsersGoals()
  {
    const orderBy = this.order ? [ this.order ] : [];
    return applyTableOrderBy(this._filteredUsers, orderBy);
  }

  @computed public get processedUsersGoalsTotals()
  {
    return aggregateUsersGoalsTotals(this.processedUsersGoals, true, profileStore.totalsAggregationMethod);
  }

  @computed public get pinnedNodesTotals()
  {
    return profileStore.pinnedNodesInfo.map(({ node, usersWithAgencies }) => {
      const pinnedAgencyUsers = indexAgencyUsers(usersWithAgencies);
      const filteredUsersGoals = this._flattenedUsersGoals.filter(({ indexedAgencyUsers }) => (
        setsIntersect(indexedAgencyUsers, pinnedAgencyUsers)
      ));
      const totals = aggregateUsersGoalsTotals(filteredUsersGoals, false, profileStore.totalsAggregationMethod);
      return { node, totals } as const;
    });
  }

  @observable private _order: OrderField | null = {
    fieldName: 'acceptedOffersHT__value',
    ordering: OrderType.DESC,
  };

  get order()
  {
    return this._order;
  }

  @action setOrder(order: OrderField | null)
  {
    this._order = order;
  }

  public async refresh()
  {
    await this.usersGoalsFetchable.ensureSuccessReload();
  }
}

export default new UsersGoalsStore();
