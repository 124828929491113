import type { IdType } from '@repo-breteuil/common-definitions';

import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilUser
{
  id: IdType,
  fullname: string,
}

export default function GetManagedUsers()
{
  return query<Array<MyBreteuilUser>>({
    operationName: 'Logs_GetUsers',
    fieldsSelection: {
      authenticated: errorWrapper({
        users: [{
          args: {
            managedOnly: { literal: 'true' },
            includePotCommun: { literal: 'true' },
            includeNonAgents: { literal: 'true' },
          },
        }, paginationResultAsList({
          id: true,
          fullname: true,
        })],
      }),
    },
  });
}
