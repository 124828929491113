import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import {
  type MyBreteuilPropertyVars,
  MyBreteuilPropertyVarsFields,
  type MyBreteuilProperty,
  MyBreteuilPropertyFields,
} from './shared';

export default function GetUserPinnedProperties(args: MyBreteuilPropertyVars)
{
  return query<Array<MyBreteuilProperty>>({
    operationName: 'Properties_GetUserPinnedProperties',
    variables: MyBreteuilPropertyVarsFields,
    fieldsSelection: {
      authenticated: errorWrapper({
        pinnedProperties: MyBreteuilPropertyFields,
      }),
    },
  }, args);
}
