import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs } from '@repo-lib/graphql-query-builder';
import type { User } from './shared';

import { errorWrapper, mutation } from '@core/api/graphql/gen-v2';
import { UserFields } from './shared';

export interface SetUserSalaryInfoArgs
{
  id: IdType,
  year: number,
  month: number,
  advanceOwed?: number | null | undefined,
  advanceKept?: number | null | undefined,
  advanceDropped?: number | null | undefined,
  remunerationPercentage?: number | null | undefined,
  comment?: string | null | undefined,
}

const SetUserSalaryInfoArgsFields: ReadonlyFieldArgs = {
  id: true,
  year: true,
  month: true,
  advanceOwed: true,
  advanceKept: true,
  advanceDropped: true,
  remunerationPercentage: true,
  comment: true,
};

const SetUserSalaryInfoArgsVariables: Readonly<Record<string, string>> = {
  id: 'Int!',
  year: 'Int!',
  month: 'Int!',
  advanceOwed: 'Float',
  advanceKept: 'Float',
  advanceDropped: 'Float',
  remunerationPercentage: 'Float',
  comment: 'String',
};

export default function SetUserSalaryInfo(args: SetUserSalaryInfoArgs)
{
  return mutation<User>({
    operationName: 'Users_SetUserSalaryInfo',
    variables: SetUserSalaryInfoArgsVariables,
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilUser: {
          setUserSalaryInfo: [{
            args: SetUserSalaryInfoArgsFields,
          }, UserFields],
        },
      }),
    },
  }, args);
}
