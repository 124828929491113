import type { ReadonlyGraphQLQueryDefinition } from '@repo-lib/graphql-query-builder';

import queryStore from '@core/api/graphql/client';

export async function query<ResultType>(
  definition: Omit<ReadonlyGraphQLQueryDefinition, 'operationType'>,
  variables?: Readonly<Record<string, any>>,
): Promise<ResultType>
{
  return queryStore.query(definition, variables);
}

export async function mutation<ResultType>(
  definition: Omit<ReadonlyGraphQLQueryDefinition, 'operationType'>,
  variables?: Readonly<Record<string, any>>,
): Promise<ResultType>
{
  return queryStore.mutation(definition, variables);
}
