import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { Language } from '@core/api/types';
import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface Property
{
  id: IdType,
  slug: string,
  addrStreetAddress: string | null,
  contacts: Array<{
    id: IdType,
    fullname: string,
    emails: Array<string>,
    phones: Array<string>,
  }>,
}

const PropertyFields: ReadonlyNodeFields = {
  id: true,
  slug: [{ args: { language: true } }, true],
  addrStreetAddress: true,
  contacts: paginationResultAsList({
    id: true,
    fullname: true,
    emails: true,
    phones: true,
  }),
};

export default function GetProperty(
  args: {
    slug: string,
    language: Language,
  })
{
  return query<Property>({
    operationName: 'Common_ViewingDialog_GetProperty',
    variables: {
      slug: 'String!',
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: [{
          args: {
            slug: true,
          },
        }, PropertyFields],
      }),
    },
  }, args);
}
