import type { IdType } from '@repo-breteuil/common-definitions';
import type { PropertyFavoriteStatus } from '@core/api/types';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface UpdatePropertyVariables
{
  id: IdType,
  verifiedWarrant?: boolean | null | undefined,
  favoriteStatus?: PropertyFavoriteStatus | null | undefined,
  marketConsistent?: boolean | null | undefined,
  managerMarketConsistent?: boolean | null | undefined,
  priceDecreaseRequest?: number | null | undefined,
  exclusive?: boolean | null | undefined,
}

// TODO Import Property fields instead

export default function UpdateProperty(variables: UpdatePropertyVariables)
{
  return mutation<{ id: IdType }>({
    operationName: 'Properties_UpdateProperty',
    variables: {
      id: 'Int!',
      verifiedWarrant: 'Boolean',
      favoriteStatus: 'PropertyFavoriteStatus',
      marketConsistent: 'Boolean',
      managerMarketConsistent: 'Boolean',
      priceDecreaseRequest: 'Int',
      exclusive: 'Boolean',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: {
          update: errorWrapper([{
            args: {
              id: true,
              verifiedWarrant: true,
              favoriteStatus: true,
              marketConsistent: true,
              managerMarketConsistent: true,
              priceDecreaseRequest: true,
              exclusive: true,
            },
          }, {
            id: true,
            marketConsistent: true,
            verifiedWarrant: true,
            favoriteStatus: true,
            exclusive: true,
          }]),
        },
      }),
    },
  }, variables);
}
