import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type {
  Language,
  PropertyStatus,
  OperationType,
  BreteuilPropertyLawFulRental,
  BreteuilPropertySurfaceUnit,
  PropertyType,
  MyBreteuilHeating,
  MyBreteuilWaterHeating,
  BreteuilPropertyUsage,
  Orientation,
  MyBreteuilPropertyDisponibilityEnum,
  MyBreteuilPropertyRentalExpensesTypeEnum,
  MyBreteuilPropertyVisibility,
  FileUploadType,
  Currency,
  TranslationStatus,
  MyBreteuilPropertySurroundingDistance,
  BreteuilWebsiteViewType,
  BreteuilWebsiteRenovationType,
  DPERating,
  PropertyRequiredField,
  PropertyFavoriteStatus,
} from '@core/api/types';

import { PictureFormat, PictureRatio } from '@core/api/types';
import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilAgency
{
  id: IdType,
  name: string,
  useFROnlyAddressAutocomplete: boolean,
  childrenAgencies: Array<{
    id: IdType,
    name: string,
  }>,
}

export interface MyBreteuilUser
{
  id: IdType,
  fullname: string,
  contactSearchAgency: {
    id: IdType,
    shortName: string,
    agencyTreeGeoAreaId: IdType | null,
  } | null,
}

export interface MyBreteuilContact
{
  id: IdType,
  fullname: string,
  emails: Array<string>,
  phones: Array<string>,
}

export interface MyBreteuilCriteria
{
  id: IdType,
  webName: string,
}

export interface PublicationPlatformPublished
{
  published: boolean,
  lastUpdateDate: number | null/*timestamp*/,
}

const PublicationPlatformPublishedFields: ReadonlyNodeFields = {
  published: true,
  lastUpdateDate: true,
};

export interface PublicationPlatformStatus
{
  userPublished: PublicationPlatformPublished,
  serverPublished: PublicationPlatformPublished,
  platformPublished: PublicationPlatformPublished,
}

const PublicationPlatformStatusFields: ReadonlyNodeFields = {
  userPublished: PublicationPlatformPublishedFields,
  serverPublished: PublicationPlatformPublishedFields,
  platformPublished: PublicationPlatformPublishedFields,
};

export interface MyBreteuilPropertyDocument
{
  id: IdType,
  fileURL: string,
  fileType: FileUploadType,
}

export interface MyBreteuilPropertySurrounding
{
  id: IdType,
  distance: MyBreteuilPropertySurroundingDistance,
  surrounding: {
    id: IdType,
    content: string,
  },
}

export interface MyBreteuilPropertyPhoto
{
  id: IdType,
  fileURL: string,
  pictureFormat: string,
}

export interface PropertyRequirements
{
  requiredFields: Array<PropertyRequiredField> | null,
  requiredPhotos: number | null,
  requiredStatus: Array<PropertyStatus> | null,
  requiredVisibility: Array<MyBreteuilPropertyVisibility> | null,
}

const PropertyRequirementsFields: ReadonlyNodeFields = {
  requiredFields: true,
  requiredPhotos: true,
  requiredStatus: true,
  requiredVisibility: true,
} as const;

export interface PropertyValuationVariations
{
  floor: number | null,
  lastFloor: number | null,
  elevator: number | null,
  orientation: number | null,
  view: number | null,
  luminosity: number | null,
  noisy: number | null,
  popularAdress: number | null,
  roomsSurfaceRatio: number | null,
  buildingType: number | null,
  renovation: number | null,
}

const PropertyValuationVariationsFields: ReadonlyNodeFields = {
  floor: true,
  lastFloor: true,
  elevator: true,
  orientation: true,
  view: true,
  luminosity: true,
  noisy: true,
  popularAdress: true,
  roomsSurfaceRatio: true,
  buildingType: true,
  renovation: true,
};

export interface MyBreteuilProperty
{
  id: IdType,
  slug: string,
  currency: Currency,
  geoAreaId: IdType,
  geoAreaResolvedFromAddress: boolean | null,
  plan: MyBreteuilPropertyDocument | null,
  photos: Array<MyBreteuilPropertyPhoto>,
  currentUserReferent: boolean,
  // Main info
  title: string,
  price: number,
  priceMax: number,
  priceOnRequest: boolean,
  status: PropertyStatus,
  availabilityDate: number | null/*timestamp*/,
  agency: MyBreteuilAgency,
  // Mandat
  warrantNumber: string,
  warrantDate: number | null/*timestamp*/,
  warrantEndDate: number | null/*timestamp*/,
  exclusive: boolean,
  verifiedWarrant: boolean,
  // Localisation
  resolvedAddress: string | null,
  address: string,
  // Negociateurs
  users: Array<MyBreteuilUser>,
  origine: string | null,
  // Owners
  contacts: Array<MyBreteuilContact>,
  // Misc
  operationType: OperationType | null,
  mybReference: string | null,
  registrationNumber: string | null,
  favoriteStatus: PropertyFavoriteStatus | null,
  mybEntree: boolean,
  companyManagementBroughtDeal: boolean,
  marketConsistent: boolean,
  // Descriptions
  descriptionFR: string | null,
  descriptionEN: string | null,
  descriptionPT: string | null,
  descriptionES: string | null,
  descriptionTranslationStatusFR: TranslationStatus | null,
  descriptionTranslationStatusEN: TranslationStatus | null,
  descriptionTranslationStatusPT: TranslationStatus | null,
  descriptionTranslationStatusES: TranslationStatus | null,
  quoteDescriptionFR: string | null,
  quoteDescriptionEN: string | null,
  quoteDescriptionPT: string | null,
  quoteDescriptionES: string | null,
  // Descriptions SMS
  mybDescriptionSmsFR: string | null,
  mybDescriptionSmsEN: string | null,
  mybDescriptionSmsPT: string | null,
  mybDescriptionSmsES: string | null,
  // Infos pratiques
  practicalInformations: string | null,
  // Charges
  collectiveOwnership: boolean,
  lots: number | null,
  collectiveOwnershipCharges: number | null,
  mybTaxeFonciere: number | null,
  fees: number | null,
  feesPercentage: number | null,
  feesPaidByBuyer: boolean,
  // LOCATIF
  rentalExpenses: number | null,
  rentalExpensesType: MyBreteuilPropertyRentalExpensesTypeEnum | null,
  rentalDeposit: number | null,
  mybLocatifLegal: BreteuilPropertyLawFulRental | null,
  // LOCATIF SAISONNIER
  guestsCount: number | null,
  keyInfos: Array<{
    id: IdType,
    content: string,
  }>,
  surroundings: Array<MyBreteuilPropertySurrounding>,
  // - bottom part
  rentalOwnerIntermediary: number | null,
  rentalAdministrationFees: number | null,
  rentalOwnerAdministrationFees: number | null,
  rentalInventory: number | null,
  rentalOwnerInventory: number | null,
  // CARACTÉRISTIQUES MATCHANTES
  surface: number,
  surfaceUnit: BreteuilPropertySurfaceUnit | null,
  bedrooms: number,
  floor: number,
  floorsCount: number,
  propertyType: PropertyType,
  constructionYear: number,
  criteria: Array<MyBreteuilCriteria>,
  // CARACTÉRISTIQUES INFORMATIVES
  lotSize: number,
  rooms: number,
  bathrooms: number,
  toilets: number,
  balconyCount: number,
  ceilingHeight: number,
  heating: MyBreteuilHeating,
  waterHeatType: MyBreteuilWaterHeating,
  usage: BreteuilPropertyUsage,
  disponibility: MyBreteuilPropertyDisponibilityEnum,
  orientation: Orientation,
  sectorization: string,
  transports: string,
  buildingType: string,
  dpe: number,
  dpeCarbonFootprint: number,
  dpeKwhRatingOverride: DPERating | null,
  dpeCo2RatingOverride: DPERating | null,
  dpeCreationDate: number | null/*timestamp*/,
  dpeSimulationReferenceDate: number | null/*timestamp*/,
  dpeAnnualCost: number,
  inflatedRent: number,
  rentControl: boolean,
  taxIdentificationNumber: string | null,
  // Estimation
  view: BreteuilWebsiteViewType | null,
  renovation: BreteuilWebsiteRenovationType | null,
  balconySurface: number | null,
  valuationDetails: {
    estimatedPrice: number,
    transactionPrice: number,
    detailsVariation: {
      totalVariation: number,
      variations: PropertyValuationVariations,
    },
    transactionsInfo: {
      datasetFilters: {
        radius: number | null,
        sameStreet: {
          sameSide: boolean,
        } | null,
        surfaceMin: number | null,
        surfaceMax: number | null,
      },
    } | null,
  } | null,
  // Publication
  publication: MyBreteuilPropertyVisibility,
  displayedStatus: PropertyStatus,
  forceSendable: boolean | null,
  publicationPlatformsAgencyId: IdType | null,
  publicationPlatformsStatus: {
    BienIci: PublicationPlatformStatus,
    SeLoger: PublicationPlatformStatus,
    BellesDemeures: PublicationPlatformStatus,
    MaisonsEtAppartements: PublicationPlatformStatus,
    ResidencesImmobilier: PublicationPlatformStatus,
    PLF: PublicationPlatformStatus,
    FigaroImmo: PublicationPlatformStatus,
  },
  missingPublicationRequirementsWebsite: PropertyRequirements | null,
  missingPublicationRequirementsShowcase: PropertyRequirements | null,
  missingPublicationRequirementsPublicationPlatforms: PropertyRequirements | null,
  missingPublicationRequirementsPropertiesInfo: PropertyRequirements | null,
}

export interface GetPropertyvariables
{
  slug: string,
  language: Language,
}

export default function GetProperty(variables: GetPropertyvariables)
{
  return query<MyBreteuilProperty>({
    operationName: 'Property_Info_GetProperty',
    variables: {
      slug: 'String!',
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: [{
          args: {
            slug: true,
          },
        }, {
          id: true,
          slug: true,
          currency: true,
          geoAreaId: true,
          geoAreaResolvedFromAddress: true,
          plan: {
            id: true,
            fileURL: true,
            fileType: true,
          },
          photos: paginationResultAsList({
            id: true,
            fileURL: [{ args: { format: { literal: PictureFormat._2160p }, ratio: { literal: PictureRatio._3x2 } } }, true],
            pictureFormat: true,
          }),
          currentUserReferent: true,
          // Main info
          title: true,
          price: true,
          priceMax: true,
          priceOnRequest: true,
          status: true,
          availabilityDate: true,
          agency: {
            id: true,
            name: true,
            useFROnlyAddressAutocomplete: true,
            childrenAgencies: {
              id: true,
              name: true,
            },
          },
          // Mandat
          warrantNumber: true,
          warrantDate: true,
          warrantEndDate: true,
          exclusive: true,
          verifiedWarrant: true,
          // Localisation
          resolvedAddress: true,
          address: true,
          // Negociateurs
          users: {
            id: true,
            fullname: true,
            contactSearchAgency: {
              id: true,
              shortName: true,
              agencyTreeGeoAreaId: true,
            },
          },
          origine: true,
          // Owners
          contacts: paginationResultAsList({
            id: true,
            fullname: true,
            emails: true,
            phones: true,
          }),
          // Misc
          operationType: true,
          mybReference: true,
          registrationNumber: true,
          favoriteStatus: true,
          mybEntree: true,
          companyManagementBroughtDeal: true,
          marketConsistent: true,
          // Descriptions
          descriptionFR: true,
          descriptionEN: true,
          descriptionPT: true,
          descriptionES: true,
          descriptionTranslationStatusFR: true,
          descriptionTranslationStatusEN: true,
          descriptionTranslationStatusPT: true,
          descriptionTranslationStatusES: true,
          quoteDescriptionFR: true,
          quoteDescriptionEN: true,
          quoteDescriptionPT: true,
          quoteDescriptionES: true,
          // Descriptions SMS
          mybDescriptionSmsFR: true,
          mybDescriptionSmsEN: true,
          mybDescriptionSmsPT: true,
          mybDescriptionSmsES: true,
          // Infos pratiques
          practicalInformations: true,
          // Charges
          collectiveOwnership: true,
          lots: true,
          collectiveOwnershipCharges: true,
          mybTaxeFonciere: true,
          fees: true,
          feesPercentage: true,
          feesPaidByBuyer: true,
          // LOCATIF
          rentalExpenses: true,
          rentalExpensesType: true,
          rentalDeposit: true,
          mybLocatifLegal: true,
          // LOCATIF SAISONNIER
          guestsCount: true,
          keyInfos: paginationResultAsList({
            id: true,
            content: [{ args: { language: true } }, true],
          }),
          surroundings: paginationResultAsList({
            id: true,
            distance: true,
            surrounding: {
              id: true,
              content: [{ args: { language: true } }, true],
            },
          }),
          // - bottom part
          rentalOwnerIntermediary: true,
          rentalAdministrationFees: true,
          rentalOwnerAdministrationFees: true,
          rentalInventory: true,
          rentalOwnerInventory: true,
          // CARACTÉRISTIQUES MATCHANTES
          surface: true,
          surfaceUnit: true,
          bedrooms: true,
          floor: true,
          floorsCount: true,
          propertyType: true,
          constructionYear: true,
          criteria: paginationResultAsList({
            id: true,
            webName: [{ args: { language: true } }, true],
          }),
          // CARACTÉRISTIQUES INFORMATIVES
          lotSize: true,
          rooms: true,
          bathrooms: true,
          toilets: true,
          balconyCount: true,
          ceilingHeight: true,
          heating: true,
          waterHeatType: true,
          usage: true,
          disponibility: true,
          orientation: true,
          sectorization: true,
          transports: true,
          buildingType: true,
          dpe: true,
          dpeCarbonFootprint: true,
          dpeKwhRatingOverride: true,
          dpeCo2RatingOverride: true,
          dpeCreationDate: true,
          dpeSimulationReferenceDate: true,
          dpeAnnualCost: true,
          inflatedRent: true,
          rentControl: true,
          taxIdentificationNumber: true,
          //Estimation
          view: true,
          renovation: true,
          balconySurface: true,
          valuationDetails: {
            estimatedPrice: true,
            transactionPrice: true,
            detailsVariation: {
              totalVariation: true,
              variations: PropertyValuationVariationsFields,
            },
            transactionsInfo: [{ preventUnwrap: true }, {
              datasetFilters: {
                radius: true,
                sameStreet: [{
                  preventUnwrap: true,
                }, {
                  sameSide: true,
                }],
                surfaceMin: true,
                surfaceMax: true,
              },
            }],
          },
          // Publication
          publication: true,
          displayedStatus: true,
          forceSendable: true,
          publicationPlatformsAgencyId: true,
          publicationPlatformsStatus: {
            BienIci: PublicationPlatformStatusFields,
            SeLoger: PublicationPlatformStatusFields,
            BellesDemeures: PublicationPlatformStatusFields,
            MaisonsEtAppartements: PublicationPlatformStatusFields,
            ResidencesImmobilier: PublicationPlatformStatusFields,
            PLF: PublicationPlatformStatusFields,
            FigaroImmo: PublicationPlatformStatusFields,
          },
          missingPublicationRequirementsWebsite: PropertyRequirementsFields,
          missingPublicationRequirementsShowcase: PropertyRequirementsFields,
          missingPublicationRequirementsPublicationPlatforms: PropertyRequirementsFields,
          missingPublicationRequirementsPropertiesInfo: PropertyRequirementsFields,
        }],
      }),
    },
  }, variables);
}
