import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { PropertyConfidentialLinkStatus } from '@core/api/types';
import { errorWrapper, paginationResultAsList, query } from '@core/api/graphql/gen-v2';

export interface Property_ConfidentialLink
{
  id: IdType,
  status: PropertyConfidentialLinkStatus,
  propertyUrl: string,
  openedCount: number,
  user: {
    id: IdType,
    fullname: string,
  },
  contact: {
    id: IdType,
    fullname: string,
  },
}

const Property_ConfidentialLinkFields: ReadonlyNodeFields = {
  id: true,
  status: true,
  propertyUrl: true,
  openedCount: true,
  user: {
    id: true,
    fullname: true,
  },
  contact: {
    id: true,
    fullname: true,
  },
};

export interface Property
{
  id: IdType,
  confidentialPreviewLink: string,
  addrStreetAddress: string | null,
  users: Array<{
    id: IdType,
    fullname: string,
    email: string,
    phoneMobile: string,
  }>,
  currentUserReferent: boolean,
  confidentialLinks: Array<Property_ConfidentialLink>,
}

const PropertyFields: ReadonlyNodeFields = {
  id: true,
  confidentialPreviewLink: true,
  addrStreetAddress: true,
  users: {
    id: true,
    fullname: true,
    email: true,
    phoneMobile: true,
  },
  currentUserReferent: true,
  confidentialLinks: paginationResultAsList(Property_ConfidentialLinkFields),
};

export default function GetProperty(id: IdType)
{
  return query<Property>({
    operationName: 'Common_PropertyConfidentialLinkDialog_GetProperty',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: [{
          args: {
            id: true,
          },
        }, PropertyFields],
      }),
    },
  }, { id });
}
