import type { IdType, OperationType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { Currency, Language, MyBreteuilPropertyVisibility, MyBreteuilSearchLevel, NotificationChannel, PropertyStatus } from '@core/api/types';

import { errorWrapper, paginationResultAsList, query } from '@core/api/graphql/gen-v2';

export interface User
{
  id: IdType,
  fullname: string,
  firstname: string,
  lastname: string,
  agent: boolean,
  isAgencyPotCommun: boolean,
  left: boolean,
  contactSearchAgency: {
    id: IdType,
    code: string,
    name: string,
  } | null,
}

const UserFields: ReadonlyNodeFields = {
  id: true,
  fullname: true,
  firstname: true,
  lastname: true,
  agent: true,
  isAgencyPotCommun: true,
  left: true,
  contactSearchAgency: {
    id: true,
    code: true,
    name: true,
  },
};

export interface Reminder
{
  id: IdType,
  com: NotificationChannel,
  comments: string | null,
  recallDate: number | null/*timestamp*/,
  response: boolean,
  createdAt: number/*timestamp*/,
  user: User | null,
}

const ReminderFields: ReadonlyNodeFields = {
  id: true,
  com: true,
  comments: true,
  recallDate: true/*timestamp*/,
  response: true,
  createdAt: true/*timestamp*/,
  user: UserFields,
};

export interface Viewing
{
  id: IdType,
  date: number/*timestamp*/,
  user: {
    id: IdType,
    login: string,
  } | null,
  property: {
    id: IdType,
    slug: string,
    publication: MyBreteuilPropertyVisibility,
    address: string,
  } | null,
}

const ViewingFields: ReadonlyNodeFields = {
  id: true,
  date: true,
  user: {
    id: true,
    login: true,
  },
  property: {
    id: true,
    slug: true,
    publication: true,
    address: true,
  },
};

export interface Property
{
  id: IdType,
  slug: string,
  title: string,
  publication: MyBreteuilPropertyVisibility,
  address: string,
  operationType: OperationType,
  status: PropertyStatus,
  administrativeAreas: {
    city: {
      id: IdType,
      displayName: string,
    } | null,
    district: {
      id: IdType,
      displayName: string,
    } | null,
  },
  price: number,
  currency: Currency,
  users: Array<User>,
  lastValuationViewingDate: number | null,
}

const PropertyFields: ReadonlyNodeFields = {
  id: true,
  slug: [{ args: { language: true } }, true],
  title: true,
  publication: true,
  address: true,
  operationType: true,
  status: true,
  administrativeAreas: {
    city: {
      id: true,
      displayName: [{ args: { language: true } }, true],
    },
    district: {
      id: true,
      displayName: [{ args: { language: true } }, true],
    },
  },
  price: true,
  currency: true,
  users: UserFields,
  lastValuationViewingDate: true,
};

export interface ContactPerson
{
  id: IdType,
  fullname: string | null,
  emails: Array<string>,
  phones: Array<string>,
}

const ContactPersonFields: ReadonlyNodeFields = {
  id: true,
  fullname: true,
  emails: true,
  phones: true,
};

export interface Contact
{
  id: IdType,
  firstname: string | null,
  lastname: string | null,
  fullname: string,
  phones: Array<string>,
  emails: Array<string>,
  persons: Array<ContactPerson>,
  reminders: Array<Reminder>,
  eval: MyBreteuilSearchLevel | null,
  evalUpdatedAt: number | null,
  ownerComment: string | null,
  ownerCommentUpdatedAt: number | null,
  tenant: boolean | null,
  tenantUpdatedAt: number | null,
  viewings: Array<Viewing>,
  properties: Array<Property>,
  hidePersonalInfo: boolean,
  lastSearchUpdateEntityLog: {
    author: {
      auto: boolean,
      __typename: 'HistoryEventAuthor_Auto',
    } | {
      user: {
        id: IdType,
        fullname: string,
      },
      originUser: {
        id: IdType,
        fullname: string,
      },
      __typename: 'HistoryEventAuthor_Agent',
    } | {
      id: IdType,
      fullname: string,
      __typename: 'MyBreteuilContact',
    } | null,
    createdAt: number,
  } | null,
  lastValuationViewingStatus: {
    date: number,
    accepted: boolean,
  } | null,
}

const ContactFields: ReadonlyNodeFields = {
  id: true,
  firstname: true,
  lastname: true,
  fullname: true,
  phones: true,
  emails: true,
  persons: ContactPersonFields,
  reminders: paginationResultAsList(ReminderFields),
  eval: true,
  evalUpdatedAt: true,
  ownerComment: true,
  ownerCommentUpdatedAt: true,
  tenant: true,
  tenantUpdatedAt: true,
  viewings: paginationResultAsList(ViewingFields),
  properties: PropertyFields,
  hidePersonalInfo: true,
  lastSearchUpdateEntityLog: {
    author: [{
      polymorphicFields: {
        HistoryEventAuthor_Auto: { auto: true },
        HistoryEventAuthor_Agent: {
          user: {
            id: true,
            fullname: true,
          },
          originUser: {
            id: true,
            fullname: true,
          },
        },
        MyBreteuilContact: {
          id: true,
          fullname: true,
        },
      },
    }, {}],
    createdAt: true,
  },
  lastValuationViewingStatus: {
    date: true,
    accepted: true,
  },
};

export interface GetContactArgs
{
  id: IdType,
  language: Language,
}

export default function GetContact(args: GetContactArgs)
{
  return query<Contact>({
    operationName: 'Common_ReminderDialog_GetContact',
    variables: {
      id: 'Int!',
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: [{
          args: {
            id: true,
          },
        }, ContactFields],
      }),
    },
  }, args);
}
