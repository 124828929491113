import { isNonNullish } from '@repo-lib/utils-core';
import { strContains } from '@lib/utils/string';

export interface FilterFields
{
  in?: Array<any> | undefined,
  contains?: string | undefined,
  lte?: number | undefined,
  gte?: number | undefined,
  eq?: any | undefined,
  not?: any | undefined,
  fn?: ((val: any) => boolean) | undefined,
}

function handleFilter(filter: FilterFields, value: any)
{
  let res = true;
  if (filter.in)
    res = filter.in.includes(value);
  if (filter.contains)
    res = isNonNullish(value) && strContains(value, filter.contains);
  if (filter.lte)
    res = isNonNullish(value) && (value <= filter.lte);
  if (filter.gte)
    res = isNonNullish(value) && (value >= filter.gte);
  if (filter.eq)
    res = (value === filter.eq);
  if (filter.not !== undefined)
    res = (value !== filter.not);
  if (filter.fn)
    res = filter.fn(value);
  return res;
}

export function applyTableFilters<ItemType>(
  items: Array<ItemType>,
  filters: Record<string/*column*/, FilterFields>,
  valueAccessor?: ((item: ItemType, column: string) => any) | undefined,
)
{
  return items.filter(item => {
    for (const key in filters)
    {
      const filter = filters[key];
      const itemValue = valueAccessor ? valueAccessor(item, key) : item[key];
      if (!handleFilter(filter, itemValue))
        return false;
    }
    return true;
  });
}
