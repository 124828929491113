import { Fetchable } from '@repo-lib/utils-mobx-store';
import {
  handleNonCriticalError,
} from '@repo-breteuil/front-error';
import {
  GetContacts,
} from './api';

class ContactCreateStore
{
  public contacts = new Fetchable(GetContacts, { catchUnhandled: handleNonCriticalError });

  public reset()
  {
    this.contacts.reset();
  }
}

export default new ContactCreateStore();
