export * from '@repo-lib/routing-routes';
export type {
  RouteHandler_Sync,
  RouteHandler_Async,
  RouteHandlerEffectFn,
  RouteHandlerBase,
  RouteHandlerReturnType,
  RouteHandler,
  RouteHandlersMap,
  MatchedRouteHandler,
  RoutingInfoOpts,
} from './RoutingInfo';
export type {
  PageRedirect,
  RouteBindingRedirect,
  StaticRouteBindingArgs,
  StaticRouteBinding,
  DynamicRouteBindingArgs,
  DynamicRouteBinding,
} from './PageRouteHandlers';
export type {
  PageRouterOpts,
} from './PageRouter';
export {
  default as RoutingInfo,
  matchRouteHandlersMap,
  ensureLocationHandler,
} from './RoutingInfo';
export { default as PageRouteHandlers, PageRouteHandlersScope } from './PageRouteHandlers';
export {
  default as PageRouter,
  isRouteBindingRedirect,
  isMatchedRouteHandler,
  makePageRedirectWithDefaults,
} from './PageRouter';
export * from './utils';
