import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { PaginationBaseArgs, PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import type {
  MyBreteuilPropertyVisibility,
  Filters__Boolean,
  OrderBy,
  Language,
  Currency,
} from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationVariables,
  ExtendedPaginationArgs,
} from '@core/api/graphql/gen-v2';

export interface PropertyValuationPDF
{
  id: IdType,
  validationRequested: boolean,
  validationManagerId: IdType | null,
  validationZoneManagerId: IdType | null,
  draft: boolean,
  pending: boolean,
  accepted: boolean,
  createdAt: number,
  updatedAt: number,
  currentUserManager: boolean,
  currentUserZoneManager: boolean,
  data: {
    displayedPrice_FAI: number | null,
    valuationPrice_FAI: number | null,
  } | null,
  property: {
    id: IdType,
    slug: string,
    ref: string,
    publication: MyBreteuilPropertyVisibility,
    currency: Currency | null,
    addrStreetAddress: string | null,
    geoArea: {
      id: IdType,
      displayName: string,
    } | null,
    territoryAgency: {
      id: IdType,
      shortName: string,
    } | null,
  },
  user: {
    id: IdType,
    fullname: string,
    contactSearchAgency: {
      id: IdType,
      shortName: string,
    } | null,
  },
  validationManager: {
    id: IdType,
    fullname: string,
  } | null,
  validationZoneManager: {
    id: IdType,
    fullname: string,
  } | null,
  managers: Array<{
    id: IdType,
    fullname: string,
  }>,
  zoneManagers: Array<{
    id: IdType,
    fullname: string,
  }>,
  similarPropertyValuationPDFs: Array<{
    id: IdType,
    data: {
      displayedPrice_FAI: number | null,
      valuationPrice_FAI: number | null,
    } | null,
    user: {
      id: IdType,
      fullname: string,
    },
    property: {
      slug: string,
      publication: MyBreteuilPropertyVisibility,
      currency: Currency,
    },
    updatedAt: number,
  }>,
}

const PropertyValuationPDFFields = {
  id: true,
  validationRequested: true,
  validationManagerId: true,
  validationZoneManagerId: true,
  draft: true,
  pending: true,
  accepted: true,
  createdAt: true,
  updatedAt: true,
  currentUserManager: true,
  currentUserZoneManager: true,
  data: {
    displayedPrice_FAI: true,
    valuationPrice_FAI: true,
  },
  property: {
    id: true,
    slug: true,
    ref: true,
    publication: true,
    currency: true,
    addrStreetAddress: true,
    geoArea: {
      id: true,
      displayName: [{ args: { language: true } }, true],
    },
    territoryAgency: {
      id: true,
      shortName: true,
    },
  },
  user: {
    id: true,
    fullname: true,
    contactSearchAgency: {
      id: true,
      shortName: true,
    },
  },
  validationManager: {
    id: true,
    fullname: true,
  },
  validationZoneManager: {
    id: true,
    fullname: true,
  },
  managers: {
    id: true,
    fullname: true,
  },
  zoneManagers: {
    id: true,
    fullname: true,
  },
  similarPropertyValuationPDFs: {
    id: true,
    data: {
      displayedPrice_FAI: true,
      valuationPrice_FAI: true,
    },
    user: {
      id: true,
      fullname: true,
    },
    property: {
      slug: true,
      publication: true,
      currency: true,
    },
    updatedAt: true,
  },
} as const satisfies ReadonlyNodeFields;

export interface Filters__MyBreteuilPropertyValuationPDF
{
  draft?: Filters__Boolean | undefined,
  pending?: Filters__Boolean | undefined,
  accepted?: Filters__Boolean | undefined,
}

export interface GetValuationsPDFsArgs extends PaginationBaseArgs
{
  filter?: Filters__MyBreteuilPropertyValuationPDF | undefined,
  order?: OrderBy | undefined,
  language: Language,
}

export default function GetValuationsPDFs(args: GetValuationsPDFsArgs)
{
  return query<PaginationExtendedResponse<PropertyValuationPDF>>({
    operationName: 'ValuationsPDFs_GetValuationPDFs',
    variables: {
      ...ExtendedPaginationVariables('MyBreteuilPropertyValuationPDF'),
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        propertyValuationPDFs: [{
          args: ExtendedPaginationArgs,
        }, paginationResult(PropertyValuationPDFFields)],
      }),
    },
  }, args);
}
