import type { IdType, Language, OperationType } from '@repo-breteuil/common-definitions';
import { query } from '@core/api/graphql/gen-v2';

export interface SessionUser
{
  id: IdType,
  email: string,
  phoneMobile: string | null,
  phone: string | null,
  firstname: string,
  lastname: string,
  fullname: string,
  photoURL: string | null,
  admin: boolean,
  manager: boolean,
  superManager: boolean,
  zoneLeader: boolean,
  agent: boolean,
  accountant: boolean,
  propertyManager: boolean,
  managingConfidentialProperties: boolean,
  defaultOperationType: OperationType,
  passwordLogInDisabled: boolean,
  contactSearchAgency: {
    id: IdType,
    name: string,
    shortName: string,
    phone: string,
    email: string,
    fullAddress: string,
    agencyTreeGeoAreaId: IdType | null,
  } | null,
}

export interface SessionOriginUser
{
  id: IdType,
  fullname: string,
}

export interface SessionResult
{
  token: string,
  expire: number/*Timestamp*/,
  language: Language | null,
  mybUser: SessionUser,
  originMybUser: SessionOriginUser,
}

export const SessionFields = {
  token: true,
  expire: true,
  language: true,
  mybUser: {
    id: true,
    email: true,
    phoneMobile: true,
    phone: true,
    firstname: true,
    lastname: true,
    fullname: true,
    photoURL: true,
    admin: true,
    manager: true,
    superManager: true,
    zoneLeader: true,
    agent: true,
    accountant: true,
    propertyManager: true,
    managingConfidentialProperties: true,
    defaultOperationType: true,
    passwordLogInDisabled: true,
    contactSearchAgency: {
      id: true,
      name: true,
      shortName: true,
      phone: true,
      email: true,
      fullAddress: true,
      agencyTreeGeoAreaId: true,
    },
  },
  originMybUser: {
    id: true,
    fullname: true,
  },
} as const;

export function GetSession()
{
  return query<SessionResult | null>({
    operationName: 'Global_Session',
    fieldsSelection: {
      session: SessionFields,
    },
  });
}
