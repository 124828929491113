import { action, computed, observable } from 'mobx';
import type { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import type { Fetchable } from '@repo-lib/utils-mobx-store';
import { assert, ensurePaginationData } from '@repo-breteuil/front-error';

/**
 * A simple utility class to navigate through pagination items.
 * Handle pages navigation.
 */
export default class PaginationSelection<ItemType>
{
  constructor(
    private _pagination: UncachedPagination<ItemType>,
    private _pinned?: Fetchable<any, Array<ItemType>>,
  ) {}

  @observable private _itemIdx = -1;

  @computed get pinnedItems()
  {
    return this._pinned?.lastResult ?? [];
  }

  @computed get allItems()
  {
    const items = ensurePaginationData(this._pagination).items;
    return [ ...this.pinnedItems, ...items ];
  }

  @computed get item()
  {
    assert(this._itemIdx >= 0 && this._itemIdx < this.allItems.length);
    return this.allItems[this._itemIdx];
  }

  @computed get hasPrevItem()
  {
    if (this._pagination.loading || this._pinned?.pending)
      return false;
    const data = ensurePaginationData(this._pagination);
    return this._itemIdx > 0 || data.hasPreviousPage;
  }

  @computed get hasNextItem()
  {
    if (this._pagination.loading || this._pinned?.pending)
      return false;
    const data = ensurePaginationData(this._pagination);
    return this._itemIdx < (this.allItems.length - 1) || data.hasNextPage;
  }

  @action public async prevItem()
  {
    if (this._itemIdx > 0)
    {
      this._itemIdx -= 1;
    }
    else
    {
      await this._pagination.prev();
      const data = ensurePaginationData(this._pagination);
      this._itemIdx = (this.pinnedItems.length ?? 0) + data.items.length - 1;
    }
  }

  @action async nextItem()
  {
    if (this._itemIdx < this.allItems.length - 1)
    {
      this._itemIdx += 1;
    }
    else
    {
      await this._pagination.next();
      this._itemIdx = (this.pinnedItems.length ?? 0);
    }
  }

  @action selectItem(comparatorFn: (item: ItemType) => boolean)
  {
    const idx = this.allItems.findIndex(comparatorFn);
    assert(idx !== -1);
    this._itemIdx = idx;
  }
}
