import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs } from '@repo-lib/graphql-query-builder';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface TransferUserPropertiesVariables
{
  sourceUserId: IdType,
  targetUserId: IdType,
  propertyIds?: Array<IdType> | null,
}

export const TransferUserPropertiesArgs: ReadonlyFieldArgs = {
  sourceUserId: true,
  targetUserId: true,
  propertyIds: true,
};


export default function TransferUserProperties(variables: TransferUserPropertiesVariables)
{
  return mutation<number>({
    operationName: 'Common_PropertyTransfer_TransferUserProperties',
    variables: {
      sourceUserId: "Int!",
      targetUserId: "Int!",
      propertyIds: "[Int!]",
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: {
          transferUserProperties: [{
            args: TransferUserPropertiesArgs,
          }, true],
        },
      }),
    },
  },variables);
}
