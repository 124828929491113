import Locales from '@repo-lib/ptr-mobx-locales';
import { Locale, isValidLocale, getBrowserLanguage } from '@repo-breteuil/common-texts';

export function ensureValidLocale(locale: string | null | undefined): Locale | null
{
  if (locale === null || locale === undefined)
    return null;
  if (isValidLocale(locale))
    return locale;
  return null;
}

class LocaleStorage
{
  constructor(private name: string)
  {
  }

  clear()
  {
    localStorage.removeItem(this.name);
  }

  store(locale: Locale)
  {
    localStorage.setItem(this.name, locale);
  }

  get(): Locale | null
  {
    const stored = localStorage.getItem(this.name);
    return ensureValidLocale(stored);
  }
};

const defaultLanguage = ensureValidLocale(getBrowserLanguage()) || Locale.French;

const store = new Locales<Locale>(defaultLanguage, new LocaleStorage('locale'));

export function setLocaleSafe(lang: string)
{
  const locale = ensureValidLocale(lang) ?? defaultLanguage;
  store.setLocale(locale);
}

export default store;
