import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { UserGoalPeriod } from '@core/api/types';
import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export interface UserObjectivesDailyReportStats
{
  viewingsTriggeredOffersSum: number | null,
  remindersTriggeredValuationsSum: number | null,
  prospectedStreetsMetContributorsSum: number | null,

  acceptedOffersHT: number | null,
  successfulReminders: number | null,
  successfulPhoneReminders: number | null,
  successfulWhatsAppReminders: number | null,
  madeOffers: number | null,
  mandates: number | null,
  viewings: number | null,
  valuations: number | null,
  prospectedStreets: number | null,

  createdViewingsCount: number | null,
  createdPropertyValuationViewingsCount: number | null,
  authenticDeedsCount: number | null,
}

export const UserObjectivesDailyReportStatsFields: ReadonlyNodeFields = {
  viewingsTriggeredOffersSum: true,
  remindersTriggeredValuationsSum: true,
  prospectedStreetsMetContributorsSum: true,

  acceptedOffersHT: true,
  successfulReminders: true,
  successfulPhoneReminders: true,
  successfulWhatsAppReminders: true,
  madeOffers: true,
  mandates: true,
  viewings: true,
  valuations: true,
  prospectedStreets: true,

  createdViewingsCount: true,
  createdPropertyValuationViewingsCount: true,
  authenticDeedsCount: true,
};

export interface GetUserDailyReportStatsArgs
{
  userId: IdType,
  period: UserGoalPeriod,
  previousPeriod?: number,
}

export default function GetUserDailyReportStats(args: GetUserDailyReportStatsArgs)
{
  return query<UserObjectivesDailyReportStats>({
    operationName: 'Profile_GetUserDailyReportStats',
    variables: {
      userId: 'Int!',
      period: 'UserGoalPeriod!',
      previousPeriod: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        userDailyReportStats: [{
          args: {
            userId: true,
            period: true,
            previousPeriod: true,
          },
        }, UserObjectivesDailyReportStatsFields],
      }),
    },
  }, args);
}
