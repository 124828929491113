import { computed } from 'mobx';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { indexArrayItems } from '@repo-lib/utils-core';
import {
  HierarchyTree,
  type HierarchyTreeOpts,
  type HierarchyNodeBase,
} from '@repo-lib/utils-hierarchy-tree';
import type { IdType } from '@repo-breteuil/common-definitions';
import { ensureFetchableResource, handleCriticalError } from '@repo-breteuil/front-error';
import localesStore from '@core/store/Locales';
import { GetGeoAreas, type GeoArea } from './api';

export type GeoAreaHierarchyNode = GeoArea & HierarchyNodeBase<IdType>;

class GeoAreasStore {
  private _geoAreas = new Fetchable(GetGeoAreas, { catchUnhandled: handleCriticalError });

  @computed public get geoAreas()
  {
    const areas = ensureFetchableResource(this._geoAreas);
    const orderedAreas = areas.slice().sort((lhs, rhs) => ((lhs.searchAreaDisplayOrder || 0) - (rhs.searchAreaDisplayOrder || 0)));
    return orderedAreas;
  }

  @computed public get geoAreasPerId()
  {
    return indexArrayItems(this.geoAreas, area => area.id);
  }

  @computed public get geoAreasNodes()
  {
    return this.geoAreas.map(area => ({
      ...area,
      identifier: area.id,
      parentIdentifier: area.parentId,
    }));
  }

  public makeGeoAreasTree(opts: HierarchyTreeOpts<IdType, GeoAreaHierarchyNode>)
  {
    return new HierarchyTree<IdType, GeoAreaHierarchyNode>(this.geoAreasNodes, opts);
  }

  @computed public get geoAreasSearchLevelTree()
  {
    return this.makeGeoAreasTree({
      filterNodes: area => area.searchAreaDisplayOrder !== null,
    });
  }

  public async init()
  {
    return this._geoAreas.ensureSuccess({ language: localesStore.currentLocale });
  }
}

export default new GeoAreasStore();
