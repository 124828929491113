import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { NotificationChannel, PropertyStatus, ProspectingActionType, Currency } from '@core/api/types';
import type { DailyReport } from './SubmitObjectivesDailyReport';

import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { DailyReportFields } from './SubmitObjectivesDailyReport';

export interface Contact {
  id: IdType,
  lastname: string,
}

export const ContactFields: ReadonlyNodeFields = {
  id: true,
  lastname: true,
};

export interface Commission {
  id: IdType,
  agencyFeesSubtractingContributorsFeesHT: number,
  transactionPropertyAddress: string,
  transactionPropertyPrice: number,
  createdAt: number/*timestamp*/,
}

export const CommissionFields: ReadonlyNodeFields = {
  id: true,
  agencyFeesSubtractingContributorsFeesHT: true,
  transactionPropertyAddress: true,
  transactionPropertyPrice: true,
  createdAt: true,
};

export interface Reminder {
  id: IdType,
  date: number/**timestamp*/,
  com: NotificationChannel,
  contact: Contact | null,
}

export const ReminderFields: ReadonlyNodeFields = {
  id: true,
  date: true,
  com: true,
  contact: ContactFields,
};

export interface Property {
  id: IdType,
  addrStreetAddress: string | null,
  addrCity: string | null,
  status: PropertyStatus,
  price: number,
  currency: Currency,
  warrantDate: number/*timestamp*/,
  exclusive: boolean,
}

export const PropertyFields: ReadonlyNodeFields = {
  id: true,
  addrStreetAddress: true,
  addrCity: true,
  status: true,
  price: true,
  currency: true,
  warrantDate: true,
  exclusive: true,
};

export interface MadeOffer {
  id: IdType,
  madeOfferDate: number | null,
  property: Property,
}

export const MadeOfferFields: ReadonlyNodeFields = {
  id: true,
  madeOfferDate: true,
  property: PropertyFields,
};

export interface Valuation {
  id: IdType,
  date: number/*timestamp*/,
  property: Property,
}

export const ValuationFields: ReadonlyNodeFields = {
  id: true,
  date: true,
  property: PropertyFields,
};

export interface ProspectingAction {
  id: IdType,
  date: number,
  action: ProspectingActionType,
  informationCount: number,
  street: {
    id: IdType,
    name: string,
  },
}

export const ProspectingActionFields: ReadonlyNodeFields = {
  id: true,
  date: true,
  action: true,
  informationCount: true,
  street: {
    id: true,
    name: true,
  },
};

export interface Viewing {
  id: IdType,
  date: number/**timestamp*/,
  property: Property,
  contact: Contact,
}

export const ViewingFields: ReadonlyNodeFields = {
  id: true,
  date: true,
  property: PropertyFields,
  contact: ContactFields,
};

export interface UserDayTaskRecap {
  acceptedOffersHT: Array<Commission>,
  successfulPhoneReminders: Array<Reminder>,
  successfulWhatsAppReminders: Array<Reminder>,
  madeOffers: Array<MadeOffer>,
  mandates: Array<Property>,
  valuations: Array<Valuation>,
  prospectedStreets: Array<ProspectingAction>,
  viewings: Array<Viewing>,
  dailyReport: DailyReport | null,
}

export const UserDayTaskRecapFields: ReadonlyNodeFields = {
  acceptedOffersHT: CommissionFields,
  successfulPhoneReminders: ReminderFields,
  successfulWhatsAppReminders: ReminderFields,
  madeOffers: MadeOfferFields,
  mandates: PropertyFields,
  valuations: ValuationFields,
  prospectedStreets: ProspectingActionFields,
  viewings: ViewingFields,
  dailyReport: DailyReportFields,
};

export interface GetUserDayTaskRecapArgs {
  userId: IdType,
}

export default function GetUserDayTaskRecap(args: GetUserDayTaskRecapArgs)
{
  return query<UserDayTaskRecap>({
    operationName: 'Profile_GetUserDayTaskRecap',
    variables: {
      userId: "Int!",
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        userDayTasksRecap: [{
          args: { userId: true },
        }, UserDayTaskRecapFields],
      }),
    },
  }, args);
}
