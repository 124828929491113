import type { GetMarketDataAreasArgs, AdjustValuationPricePerSurfaceVariables } from './api';

import { computed } from 'mobx';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { indexMultipleArrayItems, indexArrayItems, max, min, intRange } from '@repo-lib/utils-core';
import { assert, handleCriticalError } from '@repo-breteuil/front-error';
import localesStore from '@core/store/Locales';
import { GetMarketDataAreas, AdjustValuationPricePerSurface } from './api';

class PriceAdjustmentStore
{
  public marketDataAreas = new Fetchable(async (args: GetMarketDataAreasArgs) => {
    const areas = await GetMarketDataAreas(args);
    areas.sort((lhs, rhs) => lhs.alphaNumNameOrderKey.localeCompare(rhs.alphaNumNameOrderKey));
    return areas.map(area => {
      const { yearlyTransactionsPricePerSurface, ...areaRest } = area;
      const yearlyPricePerSurfacePerYear = indexArrayItems(yearlyTransactionsPricePerSurface, data => data.year, data => data.pricePerSurface);
      return { ...areaRest, yearlyPricePerSurfacePerYear };
    });
  }, { catchUnhandled: handleCriticalError });

  @computed get marketDataAreasByParentId()
  {
    const areas = this.marketDataAreas.lastResult;
    assert(areas);
    const areasIds = new Set(areas.map(area => area.id));
    return indexMultipleArrayItems(areas, ({ parentId }) => (
      parentId && areasIds.has(parentId) ? parentId : null
    ));
  }

  @computed get years()
  {
    const marketDataAreas = this.marketDataAreas.lastResult;
    assert(marketDataAreas);
    const years = marketDataAreas.flatMap(area => Array.from(area.yearlyPricePerSurfacePerYear.keys()));
    assert(years.length > 0);
    const minYear = min(years);
    const maxYear = max(years);
    return intRange(minYear, maxYear - minYear + 1);
  }

  public async adjustValuationPricePerSurface(variables: AdjustValuationPricePerSurfaceVariables)
  {
    await AdjustValuationPricePerSurface(variables);
    await this.marketDataAreas.ensureSuccessReload({ language: localesStore.currentLocale });
  }

  public async refresh()
  {
    await this.marketDataAreas.ensureSuccessReload({ language: localesStore.currentLocale });
  }
}

export default new PriceAdjustmentStore();
