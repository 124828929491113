import { AgencyFields, type MyBreteuilAgency } from './shared';

import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export default function GetAgencies()
{
  return query<Array<MyBreteuilAgency>>({
    operationName: 'Valuation_GetAgencies',
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilAgencies: [{
          args: {
            canHaveTransactions: { literal: 'true' },
          },
        }, paginationResultAsList(AgencyFields)],
      }),
    },
  });
}
