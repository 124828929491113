import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface DeleteAreaStreetArgs
{
  id: IdType,
}

export default function DeleteAreaStreet(args: DeleteAreaStreetArgs)
{
  return mutation<boolean>({
    operationName: 'Prospecting_DeleteAreaStreet',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        prospectingAreaStreet: {
          delete: errorWrapper([{ args: { id: true } }, true]),
        },
      }),
    },
  }, args);
}
