import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { Locale } from '@repo-breteuil/common-texts';

export interface Args
{
  description: string,
  language: Locale,
}

export default function Valuation_GetFixedPropertyDescription(args: Args)
{
  return query<string>(
    {
      operationName: 'Valuation_GetFixedPropertyDescription',
      variables: {
        description: 'String!',
        language: 'Language!',
      },
      fieldsSelection: {
        authenticated: errorWrapper({
          ai: {
            fixedPropertyDescription: [
              {
                args: {
                  description: true,
                  language: true,
                },
              },
              true,
            ],
          },
        }),
      },
    },
    args,
  );
}
