import type { IdType } from '@repo-breteuil/common-definitions';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';
import type { ShowcaseScreenResolution } from '@core/api/types';

export interface UpdateArgs
{
  name?: string | undefined,
  showcaseStreamId?: IdType | null | undefined,
  streamItemOffset?: IdType | null | undefined,
  expectedResolution?: ShowcaseScreenResolution | null | undefined,
  nightTimeFilterStartMinute?: number | null | undefined,
  nightTimeFilterEndMinute?: number | null | undefined,
}

async function UpdateShowcaseScreen(id: IdType, args: UpdateArgs): Promise<void>
{
  await mutation({
    operationName: 'Showcase_ScreensList_UpdateShowcaseScreen',
    variables: {
      id: 'Int!',
      name: 'String',
      showcaseStreamId: 'Int',
      streamItemOffset: 'Int',
      expectedResolution: 'ShowcaseScreenResolution',
      nightTimeFilterStartMinute: 'Int',
      nightTimeFilterEndMinute: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseScreen: {
          update: errorWrapper([{
            args: {
              id: true,
              name: true,
              showcaseStreamId: true,
              streamItemOffset: true,
              expectedResolution: true,
              nightTimeFilterStartMinute: true,
              nightTimeFilterEndMinute: true,
            },
          }, {
            id: true,
          }]),
        },
      }),
    },
  }, {
    ...args,
    id,
  });
}

export default UpdateShowcaseScreen;
