import type { IdType } from '@repo-breteuil/common-definitions';

import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface MyBreteuilPhotographer
{
  id: IdType,
  name: string,
}

export default function GetPhotographers()
{
  return query<Array<MyBreteuilPhotographer>>({
    operationName: 'Property_Medias_GetPhotographers',
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilPhotographers: [{
          args: {
            orderBy: { literal: '[{ fieldName: name, ordering: ASC }]' },
          },
        }, paginationResultAsList({
          id: true,
          name: true,
        })],
      }),
    },
  });
}
