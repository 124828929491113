import type { IdType } from '@repo-breteuil/common-definitions';
import type { UpdateContactArgs } from './UpdateContact';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export type CreateContactArgs = Omit<UpdateContactArgs, "id">

export default function CreateContact(args: CreateContactArgs)
{
  return mutation<{ id: IdType }>({
    operationName: 'Contact_CreateContact',
    variables: {
      persons: "[MyBreteuilPersonArgs!]!",
      origins: "[MyBreteuilContactOriginType!]",
      address: "String",
      address1: "String",
      postalCode: "String",
      city: "String",
      countryCode: "String",
      language: "Language",
      optIn: "Boolean",
      newsletterOptIn: "Boolean",
      category: "MyBreteuilContactCategory",
      alertsFrequency: "MyBreteuilAlertsFrequency",
      vip: 'Boolean',
      eval: 'MyBreteuilSearchLevel',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: {
          create: errorWrapper([{
            args: {
              persons: true,
              origins: true,
              address: true,
              address1: true,
              postalCode: true,
              city: true,
              countryCode: true,
              language: true,
              optIn: true,
              newsletterOptIn: true,
              category: true,
              alertsFrequency: true,
              vip: true,
              eval: true,
            },
            preventUnwrap: true,
          }, { id: true }]),
        },
      }),
    },
  }, args);
}
