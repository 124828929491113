import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface UpdateValuationPDFArgs
{
  id: IdType,
  validationManagerId?: IdType | null | undefined,
  validationZoneManagerId?: IdType | null | undefined,
}

export default function UpdateValuationPDF(args: UpdateValuationPDFArgs)
{
  return mutation<IdType>({
    operationName: 'ValuationsPDFs_UpdateValuationPDF',
    variables: {
      id: 'Int!',
      validationManagerId: 'Int',
      validationZoneManagerId: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilPropertyValuationPDF: {
          update: [{
            args: {
              id: true,
              validationManagerId: true,
              validationZoneManagerId: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, args);
}
