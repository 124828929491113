import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { UserDayOffType } from '@core/api/types';

import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface UserDayOffRequest
{
  id: IdType,
  accepted: boolean | null,
  pending: boolean,
  startDate: number,
  endDate: number | null,
  type: UserDayOffType,
  comment: string | null,
  user: {
    id: IdType,
    fullname: string,
  },
  documents: Array<{
    id: IdType,
    originalFilename: string,
    fileURL: string,
  }>,
}

export const UserDayOffRequestFields: ReadonlyNodeFields = {
  id: true,
  accepted: true,
  pending: true,
  startDate: true,
  endDate: true,
  type: true,
  comment: true,
  user: {
    id: true,
    fullname: true,
  },
  documents: paginationResultAsList({
    id: true,
    originalFilename: true,
    fileURL: true,
  }),
};

export default function GetUserDayOffRequest(args: { id: IdType })
{
  return query<UserDayOffRequest>({
    operationName: 'Common_UserDayOffRequestDialog_GetUserDayOffRequest',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        userDayOffRequest: [{
          args: {
            id: true,
          },
        }, UserDayOffRequestFields],
      }),
    },
  }, args);
}
