import type { IdType } from '@repo-breteuil/common-definitions';
import type {
  Language,
  MyBreteuilContactCategory,
  AlertsFrequency,
  MyBreteuilSearchLevel,
  MyBreteuilContactOriginType,
} from '@core/api/types';
import type {
  Contact,
} from './GetContact';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface UpdateContactArgs
{
  id: IdType,
  persons: Array<{
    firstname: string,
    lastname: string,
    emails: Array<string>,
    phones: Array<string>,
  }>,
  origins: Array<MyBreteuilContactOriginType>,
  language: Language | null,
  optIn: boolean,
  newsletterOptIn: boolean,
  category: MyBreteuilContactCategory | null,
  alertsFrequency: AlertsFrequency | null,
  vip: boolean | null,
  eval: MyBreteuilSearchLevel | null,
  ownerComment?: string | null,
  tenant?: boolean | null,
  address?: string | null | undefined,
  address1?: string | null | undefined,
  postalCode?: string | null | undefined,
  city?: string | null | undefined,
  countryCode?: string | null | undefined,
}

export default function UpdateContact(args: UpdateContactArgs)
{
  return mutation<Contact>({
    operationName: 'Contact_UpdateContact',
    variables: {
      id: "Int!",
      persons: "[MyBreteuilPersonArgs!]!",
      origins: "[MyBreteuilContactOriginType!]",
      address: "String",
      address1: "String",
      postalCode: "String",
      city: "String",
      countryCode: "String",
      language: "Language",
      optIn: "Boolean",
      newsletterOptIn: "Boolean",
      category: "MyBreteuilContactCategory",
      alertsFrequency: "MyBreteuilAlertsFrequency",
      vip: 'Boolean',
      eval: 'MyBreteuilSearchLevel',
      ownerComment: 'String',
      tenant: 'Boolean',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: {
          update: errorWrapper([{
            args: {
              id: true,
              persons: true,
              origins: true,
              address: true,
              address1: true,
              postalCode: true,
              city: true,
              countryCode: true,
              language: true,
              optIn: true,
              newsletterOptIn: true,
              category: true,
              alertsFrequency: true,
              vip: true,
              eval: true,
              ownerComment: true,
              tenant: true,
            },
          }, { id: true }]),
        },
      }),
    },
  }, args);
}
