import * as Routes from './routes';

export { Link, MuiLink } from '@core/store/routing';
export { Routes };
export { RootPages } from './pages';
export { default as store } from './RoutingStore';

import routingLogic from './RoutingLogic';

export default routingLogic;
