import { query, errorWrapper } from '@core/api/graphql/gen-v2';
// TODO Bad to import stuff from other requests
import { DailyReportFields, type DailyReport } from './SubmitObjectivesDailyReport';

export default function GetUserDailyReport(userId: number)
{
  return query<DailyReport>({
    operationName: 'Profile_GetUserDailyReport',
    variables: {
      userId: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        userDailyReport: [ { args: { userId: true } }, DailyReportFields ],
      }),
    },
  }, { userId });
}
