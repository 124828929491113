import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export default function DeleteUserDayOffRequest(id: IdType)
{
  return mutation<boolean>({
    operationName: 'Common_UserDayOffRequestDialog_DeleteUserDayOffRequest',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        userDayOffRequest: {
          delete: errorWrapper([{
            args: {
              id: true,
            },
          }, true ]),
        },
      }),
    },
  }, { id });
}
