import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';
import { MyBreteuilPropertyValuationPDFFields, type MyBreteuilPropertyValuationPDF } from './shared';

export interface ForceValuationPDFValidationArgs
{
  id: IdType,
}

export default function ForceValuationPDFValidation(args: ForceValuationPDFValidationArgs)
{
  return mutation<MyBreteuilPropertyValuationPDF>({
    operationName: 'Valuation_ForceValuationPDFValidation',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilPropertyValuationPDF: {
          forceValidation: [{
            args: {
              id: true,
            },
          }, MyBreteuilPropertyValuationPDFFields],
        },
      }),
    },
  }, args);
}
