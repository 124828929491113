import type { ConfigVariableMetadata } from './GetConfigVariablesList';

import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export interface ConfigVariable__Int
{
  value: number | null,
  type: 'Int',
}

export interface ConfigVariable__Float
{
  value: number | null,
  type: 'Float',
}

export interface ConfigVariable__Boolean
{
  value: boolean | null,
  type: 'Bool',
}

export type ConfigVariable = ConfigVariable__Int | ConfigVariable__Float | ConfigVariable__Boolean;

export type ConfigVariables = Record<string/*variableName*/, ConfigVariable>;

const ConfigVariableArgs = {
  type: true,
  value: true,
} as const;

function GetConfigVariables(variablesMetadatas: Array<ConfigVariableMetadata>)
{
  const variablesSelection = variablesMetadatas.reduce<Record<string, typeof ConfigVariableArgs>>((acc, variableMetadata) => ({
    ...acc,
    [variableMetadata.name]: ConfigVariableArgs,
  }), {});
  return query<ConfigVariables>({
    operationName: 'ConfigVariables_GetConfigVariables',
    fieldsSelection: {
      authenticated: errorWrapper({
        configVariables: {
          getConfigVariables: variablesSelection,
        },
      }),
    },
  });
}

export default GetConfigVariables;
