import type { IdType } from '@repo-breteuil/common-definitions';
import type { MyBreteuilContact } from './api';

import { action, computed, observable } from 'mobx';
import { OpeningControl } from '@repo-lib/utils-mobx-store';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { assert, handleNonCriticalError } from '@repo-breteuil/front-error';
import { CommunicationChannelEnum } from '@core/api/types';
import { formatGlobalErrorMessage } from '@core/store/GlobalMessage';
import {
  GetContacts,
  SendManualPropertiesInfo, type SendManualPropertiesInfoVariables,
  GenManualPropertiesInfoWhatsAppUrls,
} from './api';

export interface Property
{
  id: IdType,
  addrStreetAddress: string | null,
}

class MessageDrawerStore
{
  constructor(private _args: { onMessageSentSuccess: () => void }) {}

  public openingControl = new OpeningControl();

  public onMessageSentSuccess()
  {
    this.openingControl.close();
    this._args.onMessageSentSuccess();
  }

  public contacts = new Fetchable(GetContacts, { catchUnhandled: handleNonCriticalError });

  @observable private _properties: (() => Array<Property>) | null = null;
  @observable private _defaultContacts: (() => Array<MyBreteuilContact>) | null = null;
  @observable private _defaultCommunicationChannel = CommunicationChannelEnum.Email;

  private static DefaultCommunicationChannelOptions = [ CommunicationChannelEnum.Email, CommunicationChannelEnum.WhatsApp ];
  @observable private _communicationChannelOptions = MessageDrawerStore.DefaultCommunicationChannelOptions;

  @computed get properties()
  {
    if (!this._properties)
      return null;
    return this._properties();
  }

  @computed get defaultContacts()
  {
    if (!this._defaultContacts)
      return null;
    return this._defaultContacts();
  }

  get defaultCommunicationChannel()
  {
    return this._defaultCommunicationChannel;
  }

  get communicationChannelOptions()
  {
    return this._communicationChannelOptions;
  }

  @action public open(
    args: {
      properties?: (() => Array<Property>) | undefined, // Can be observable
      defaultContacts?: (() => Array<MyBreteuilContact>) | undefined, // Can be obsevable
      /** Must be included in options when provided */
      defaultCommunicationChannel?: CommunicationChannelEnum | undefined,
      /** @default All channels */
      communicationChannelOptions?: Array<CommunicationChannelEnum> | undefined,
      autoGenWhatsAppUrls?: boolean | undefined,
    },
  )
  {
    const {
      properties,
      defaultContacts,
      defaultCommunicationChannel,
      communicationChannelOptions,
      autoGenWhatsAppUrls,
    } = args;
    this._properties = properties ?? null;
    this._defaultContacts = defaultContacts ?? null;
    this._defaultCommunicationChannel = defaultCommunicationChannel ?? CommunicationChannelEnum.Email;
    this._communicationChannelOptions = communicationChannelOptions ?? MessageDrawerStore.DefaultCommunicationChannelOptions;
    this.propertiesInfoWhatsAppUrls.reset();

    if (autoGenWhatsAppUrls)
      this.autoGenWhatsAppUrls().catch((error) => formatGlobalErrorMessage(error));

    this.openingControl.open();
  }

  public async autoGenWhatsAppUrls()
  {
    assert(this.defaultContacts?.length === 1 && this.properties && this.properties.length > 0);
    const contactId = this.defaultContacts[0].id;
    const propertyIds = this.properties.map(property => property.id);
    return this.propertiesInfoWhatsAppUrls.ensureSuccessReload({ contactId, propertyIds });
  }

  public async sendPropertiesInfo(args: SendManualPropertiesInfoVariables)
  {
    return SendManualPropertiesInfo(args);
  }

  public propertiesInfoWhatsAppUrls = new Fetchable(GenManualPropertiesInfoWhatsAppUrls, {  catchUnhandled: handleNonCriticalError });
}

export default MessageDrawerStore;
