import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

import { errorWrapper, query } from '@core/api/graphql/gen-v2';

export interface UserAnalytics
{
  user: {
    id: IdType,
    fullname: string,
    arrivedAt: number,
    contactSearchAgency: {
      id: IdType,
    } | null,
  },
  buyersCount: number,
  buyersWithViewingsCount: number,
  buyersWithViewingsCount_4plus: number,
  buyersAverageDaysWithoutInteraction: number | null,
  buyersWithManualAlertCount: number,
  buyersWithManualAlertAndMatchesNotSentCount: number,
  buyersToCallBackCount: number,
  buyersMatchesCount: number,
  buyersRapprochedMatchesCount: number,
  leadsCount: number,
  valuationsCount: number,
  valuationsWithoutContact3mCount: number,
  viewingAuthorizationsCount: number,
  mandatesCount: number,
  exclusiveMandatesCount: number,
  mandatesWithoutMarketingReportCount: number,
  mandatesAveragePrice: number | null,
}

export const UserAnalyticsFields = {
  user: {
    id: true,
    fullname: true,
    arrivedAt: true,
    contactSearchAgency: [{
      preventUnwrap: true,
    }, {
      id: true,
    }],
  },
  buyersCount: true,
  buyersWithViewingsCount: true,
  buyersWithViewingsCount_4plus: true,
  buyersAverageDaysWithoutInteraction: true,
  buyersWithManualAlertCount: true,
  buyersWithManualAlertAndMatchesNotSentCount: true,
  buyersToCallBackCount: true,
  buyersMatchesCount: true,
  buyersRapprochedMatchesCount: true,
  leadsCount: true,
  valuationsCount: true,
  valuationsWithoutContact3mCount: true,
  viewingAuthorizationsCount: true,
  mandatesCount: true,
  exclusiveMandatesCount: true,
  mandatesWithoutMarketingReportCount: true,
  mandatesAveragePrice: true,
} as const satisfies ReadonlyNodeFields;

export default function GetUsersAnalytics()
{
  return query<Array<UserAnalytics>>({
    operationName: 'Profile_GetUsersAnalytics',
    fieldsSelection: {
      authenticated: errorWrapper({
        usersAnalytics: UserAnalyticsFields,
      }),
    },
  });
}
