import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export interface MyBreteuilAgency
{
  id: IdType,
  name: string,
}

export type AgencyAverageWarrantPrice = {
  total: number,
  warrantsCount: number,
  warrantsCount_Exclusive: true,
  warrantsCount_1MLess: true,
  warrantsCount_1MTo3M: true,
  warrantsCount_3MPlus: true,
}

const AgencyAverageWarrantPriceFields: ReadonlyNodeFields = {
  total: true,
  warrantsCount: true,
  warrantsCount_Exclusive: true,
  warrantsCount_1MLess: true,
  warrantsCount_1MTo3M: true,
  warrantsCount_3MPlus: true,
};

export interface BeeTeamReportingAgenciesStats
{
  agency: MyBreteuilAgency,
  dutyFreeCurrentYearAgencyCaev: number,
  agencyAverageWarrantPrice: number,
  lastYearAverageAgencyPercentage: number,
  agencyAverageWarrantPriceThroughUser: AgencyAverageWarrantPrice,
  agencyAverageMarketedWarrantPrice: AgencyAverageWarrantPrice,
  currentMonthAcceptedOffersValue: number,
}

export const BeeTeamReportingAgenciesStatsFields: ReadonlyNodeFields = {
  agency: {
    id: true,
    name: true,
  },
  dutyFreeCurrentYearAgencyCaev: true,
  agencyAverageWarrantPrice: true,
  lastYearAverageAgencyPercentage: true,
  agencyAverageWarrantPriceThroughUser: AgencyAverageWarrantPriceFields,
  agencyAverageMarketedWarrantPrice: AgencyAverageWarrantPriceFields,
  currentMonthAcceptedOffersValue: true,
};

export default function GetAgenciesStats()
{
  return query<Array<BeeTeamReportingAgenciesStats>>({
    operationName : 'Profile_GetAgenciesStats',
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          reporting: {
            agenciesStats: BeeTeamReportingAgenciesStatsFields,
          },
        },
      }),
    },
  });
}
