import type { ManagedUser, SubmitObjectivesDailyReportArgs } from './api';

import {
  startOfDay, endOfDay,
  startOfWeek, endOfWeek,
  startOfMonth, endOfMonth,
  startOfQuarter, endOfQuarter,
  startOfYear, endOfYear,
} from 'date-fns';
import { action, computed, observable } from 'mobx';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { Day, timestampToDay } from '@repo-lib/utils-core';
import { OpeningControl } from '@repo-lib/utils-mobx-store';
import session from '@my-breteuil/store/ui/common/Session';
import { assert, ensureFetchableResource, handleCriticalError } from '@repo-breteuil/front-error';
import { UserGoalPeriod } from '@core/api/types';
import {
  genHistoryNodes,
  makeUserHierarchyTree,
  parseUserHistoryNodesIdentifiers,
} from '@my-breteuil/components/common/user-pickers/hierarchy/utils';
import { AggregationMethod } from '@my-breteuil/components/pages/profile/common';
import geoAreasStore from '@my-breteuil/store/ui/common/geo-areas';
import { sortMultiple } from '@my-breteuil/store/ui/common/array-processing-utils';
import {
  InitQuery,
  GetAgenciesStats,
  GetManagedUsers,
  GetUserGoals,
  SubmitObjectivesDailyReport,
  GetUserTasksRecap, type UserTasksRecap,
  GetManagedUsersHistorical,
  GetUserDailyReport,
  GetUserDailyReportStats,
} from './api';
import dayOffRequestsStore from './dayOffRequests';

// Utility to sort array by date and hour
function sortByDateGrouped<T>(items: Array<T>, dateAccessor: (item: T) => number | null)
{
  return sortMultiple(items, [
    {
      valueAccessor: (item) => {
        const date = dateAccessor(item);
        if (!date)
          return null;
        return timestampToDay(date);
      },
      desc: true,
    },
    {
      valueAccessor: (item) => {
        const date = dateAccessor(item);
        if (!date)
          return null;
        return date % Day;
      },
    },
  ]);
}

export function sortUserTasksRecap(tasksRecap: UserTasksRecap)
{
  return {
    acceptedOffersHT: sortByDateGrouped(tasksRecap.acceptedOffersHT, item => item.createdAt),
    madeOffers: sortByDateGrouped(tasksRecap.madeOffers, item => item.madeOfferDate),
    mandates: sortByDateGrouped(tasksRecap.mandates, item => item.warrantDate),
    prospectedStreets: sortByDateGrouped(tasksRecap.prospectedStreets, item => item.date),
    successfulPhoneReminders: sortByDateGrouped(tasksRecap.successfulPhoneReminders, item => item.date),
    successfulWhatsAppReminders: sortByDateGrouped(tasksRecap.successfulWhatsAppReminders, item => item.date),
    valuations: sortByDateGrouped(tasksRecap.valuations, item => item.date),
    viewings: sortByDateGrouped(tasksRecap.viewings, item => item.date),
  };
}

//TODO: handle timezones properly
function getPeriodRange(period: UserGoalPeriod): [ Date, Date ]
{
  const now = new Date();
  if (period === UserGoalPeriod.Day)
    return [ startOfDay(now), endOfDay(now) ];
  if (period === UserGoalPeriod.Week)
    return [ startOfWeek(now), endOfWeek(now) ];
  if (period === UserGoalPeriod.Month)
    return [ startOfMonth(now), endOfMonth(now) ];
  if (period === UserGoalPeriod.Quarter)
    return [ startOfQuarter(now), endOfQuarter(now) ];
  if (period === UserGoalPeriod.Year)
    return [ startOfYear(now), endOfYear(now) ];
  throw new Error(`Unexpected time period: ${period}`);
}

class ProfileStore {
  public initialData = new Fetchable(InitQuery, { catchUnhandled: handleCriticalError });

  // Agencies
  public agenciesStats = new Fetchable(GetAgenciesStats, { catchUnhandled: handleCriticalError });

  public managedUsers = new Fetchable(GetManagedUsers, { catchUnhandled: handleCriticalError });

  @computed get managedUserFiltered()
  {
    const { mybUser: currentUser } = session;
    assert(currentUser);
    const users = this.managedUsers.result || [];
    let filteredUsers = users.filter((user) => !user.isAgencyPotCommun && user.id !== currentUser.id);
    filteredUsers.unshift(currentUser as any); // TODO better type
    if (this.selectedUsers.size > 0)
    {
      filteredUsers = filteredUsers.filter(user => this.selectedUsersIds.has(user.id));
      if (filteredUsers.length === 0)
        filteredUsers.unshift(currentUser as any); // TODO better type
    }
    const { _currentUser } = this;
    if (_currentUser && !filteredUsers.find(user => user.id === _currentUser.id))
      filteredUsers.unshift(_currentUser);
    return filteredUsers;
  }

  public managedUsersHistorical = new Fetchable(GetManagedUsersHistorical, { catchUnhandled: handleCriticalError });

  @computed get managedUsersHistoricalNodes()
  {
    const users = ensureFetchableResource(this.managedUsersHistorical);
    return genHistoryNodes(geoAreasStore.geoAreas, users);
  }

  @computed get managedUsersHistoricalNodesTree()
  {
    return makeUserHierarchyTree(this.managedUsersHistoricalNodes);
  }

  public selectedUsers = observable.set<string/*agencyId:userId*/>();
  public pinnedNodes = observable.set<string/*id:type*/>();

  @computed get selectedAgencyUsersIds()
  {
    return parseUserHistoryNodesIdentifiers(this.selectedUsers);
  }

  @computed get selectedUsersIds()
  {
    return new Set(this.selectedAgencyUsersIds.map(({ userId }) => userId));
  }

  @computed get selectedAgenciesIds()
  {
    return new Set(this.selectedAgencyUsersIds.map(({ agencyId }) => agencyId));
  }

  @computed get pinnedNodesInfo()
  {
    return Array.from(this.pinnedNodes).map(identifier => {
      const node = this.managedUsersHistoricalNodesTree.ensureNode(identifier);
      const usersWithAgencies = parseUserHistoryNodesIdentifiers(node.leafNodesIdentifiers);
      return { node, usersWithAgencies } as const;
    });
  }

  @computed get userTasksRecapAcceptedOffersHTSum()
  {
    return this.userTasksRecap.result?.acceptedOffersHT.reduce((acc, val) => (
      acc + val.agencyFeesSubtractingContributorsFeesHT
    ), 0) ?? 0;
  }

  @action public setCurrentUserIndex(index: number)
  {
    const maxIndex = this.managedUserFiltered.length - 1;
    if (index < 0)
      index = maxIndex;
    else if (index > maxIndex)
      index = 0;
    this.setCurrentUser(this.managedUserFiltered[index]);
  }

  @computed public get currentUserIndex()
  {
    return this.managedUserFiltered.findIndex(user => user.id === this.currentUser.id);
  }

  @observable private _currentUser: ManagedUser | null = null;

  @action public setCurrentUser(user: ManagedUser | null)
  {
    this._currentUser = user;
    this.refresh();
  }

  @computed public get currentUser()
  {
    if (this._currentUser)
      return this._currentUser;
    assert(this.managedUserFiltered.length > 0);
    return this.managedUserFiltered[0];
  }

  @observable _previousPeriod: number = 0;

  @action public setPreviousPeriodAndRefresh(previousPeriod: number)
  {
    if (previousPeriod >= 0) {
      this._previousPeriod = previousPeriod;
      this.userGoals.reload();
      this.userGoalsTasksRecap.reload();
    }
  }

  public get previousPeriod()
  {
    return this._previousPeriod;
  }

  public dailyReportDialogControl = new OpeningControl();

  public dailyReport = new Fetchable(async () => GetUserDailyReport(this.currentUser.id), { catchUnhandled: handleCriticalError });

  @observable private _dailyReportStatsPreviousPeriod = 0;

  get dailyReportStatsPreviousPeriod()
  {
    return this._dailyReportStatsPreviousPeriod;
  }

  @action setDailyReportStatsPreviousPeriod(period: number)
  {
    this._dailyReportStatsPreviousPeriod = period;
  }

  public async setDailyReportStatsPreviousPeriodAndRefresh(period: number)
  {
    this.setDailyReportStatsPreviousPeriod(period);
    await this.dailyReportStats.ensureSuccessReload();
  }

  public dailyReportStats = new Fetchable(async () => GetUserDailyReportStats({
    userId: this.currentUser.id,
    period: UserGoalPeriod._7Day,
    previousPeriod: this._dailyReportStatsPreviousPeriod,
  }), { catchUnhandled: handleCriticalError });

  public dailyReportStatsDay = new Fetchable(async () => GetUserDailyReportStats({
    userId: this.currentUser.id,
    period: UserGoalPeriod.Day,
  }), { catchUnhandled: handleCriticalError });

  public dailyReportStats3m = new Fetchable(async () => GetUserDailyReportStats({
    userId: this.currentUser.id,
    period: UserGoalPeriod._63Day,
  }), { catchUnhandled: handleCriticalError });

  @observable private _userGoalsPeriodFilter = UserGoalPeriod.Month;

  get userGoalsPeriodFilter()
  {
    return this._userGoalsPeriodFilter;
  }

  @action setUserGoalsPeriodFilter(period: UserGoalPeriod)
  {
    this._userGoalsPeriodFilter = period;
  }

  public async setUserGoalsPeriodFilterAndRefresh(period: UserGoalPeriod)
  {
    this.setUserGoalsPeriodFilter(period);
    await Promise.all([
      this.userGoals.ensureSuccessReload(),
      this.userGoalsTasksRecap.ensureSuccessReload(),
    ]);
  }

  public userGoals = new Fetchable(async () => GetUserGoals({
    userId: this.currentUser.id,
    period: this._userGoalsPeriodFilter,
    previousPeriod: this._previousPeriod,
  }), { catchUnhandled: handleCriticalError });

  public userGoalsTasksRecap = new Fetchable(async () => {
    const tasksRecap = await GetUserTasksRecap({
      userId: this.currentUser.id,
      period: this._userGoalsPeriodFilter,
      previousPeriod: this._previousPeriod,
    });
    return sortUserTasksRecap(tasksRecap);
  }, { catchUnhandled: handleCriticalError });

  @observable private _periodFilter: UserGoalPeriod = UserGoalPeriod.Day;

  public get periodFilter()
  {
    return this._periodFilter;
  }

  @action public setPeriodFilter(period: UserGoalPeriod)
  {
    this._periodFilter = period;
    this.userTasksRecap.reload();
  }

  public userTasksRecap = new Fetchable(async () => {
    const tasksRecap = await GetUserTasksRecap({
      userId: this.currentUser.id,
      period: this._periodFilter,
    });
    return sortUserTasksRecap(tasksRecap);
  }, { catchUnhandled: handleCriticalError });

  /*
   * From 0 to 1, the point where the current time is, on the range of the periodFilter.
   * If the current time is outside of this range, return null.
   */
  @computed public get periodProgression(): number | null
  {
    const [ from, to ] = getPeriodRange(this._userGoalsPeriodFilter);
    const now = new Date();
    if (now < from || now >= to)
      return null;
    const rangeDuration = to.valueOf() - from.valueOf();
    const elapsed = now.valueOf() - from.valueOf();
    return (elapsed / rangeDuration);
  }

  @observable totalsAggregationMethod: AggregationMethod = AggregationMethod.Avg;

  @action.bound setTotalsAggregationMethod(method: AggregationMethod)
  {
    this.totalsAggregationMethod = method;
  }

  public async submitObjectivesDailyReport(args: SubmitObjectivesDailyReportArgs)
  {
    const res = await SubmitObjectivesDailyReport(args);
    this.dailyReport.setResult({
      comment: res.comment,
      viewingsTriggeredOffers: res.viewingsTriggeredOffers,
      remindersTriggeredValuations: res.remindersTriggeredValuations,
      remindersTriggeredViewings: res.remindersTriggeredViewings,
      viewingsTriggeredNotarySignatures: res.viewingsTriggeredNotarySignatures,
      prospectedStreetsMetContributors: res.prospectedStreetsMetContributors,
      sentDate: res.sentDate,
    });
  };

  /*
   * TODO: there is a big issue with this store's "refresh" in case of partial failure
   * The userId/currentUser is set before loading is finished (it should be set after)
   * If only some of the fetchables fail, the failed one will reference the previous user, while the other ones will reference the new user
   * We need to set values consistently, even in case of errors
   */
  public async refresh()
  {
    if (!this._currentUser)
      this._currentUser = this.currentUser;
    await Promise.all([
      this.userTasksRecap.ensureSuccessReload(),
      this.dailyReport.ensureSuccessReload(),
      this.dailyReportStats.ensureSuccessReload(),
      this.dailyReportStatsDay.ensureSuccessReload(),
      this.dailyReportStats3m.ensureSuccessReload(),
      this.userGoals.ensureSuccessReload(),
      this.userGoalsTasksRecap.ensureSuccessReload(),
      dayOffRequestsStore.refresh(),
    ]);
  }

}

export default new ProfileStore();
