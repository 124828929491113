import type { OrderField } from '@core/api/types';
import type { UserAnalytics } from './api';

import { action, observable, computed } from 'mobx';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { assert, handleCriticalError } from '@repo-breteuil/front-error';
import { OrderType } from '@core/api/types';
import { AggregationMethod } from '@my-breteuil/components/pages/profile/common';
import { applyTableOrderBy } from '@my-breteuil/store/ui/common/array-processing-utils';
import { GetUsersAnalytics } from './api';
import profileStore from './index';
import { aggregateMultiple } from './common';

function flattenUserAnalytics(userAnalytics: UserAnalytics)
{
  const { user, ...rest } = userAnalytics;
  return {
    userId: userAnalytics.user.id,
    fullname: userAnalytics.user.fullname,
    arrivedAt: userAnalytics.user.arrivedAt,
    agencyId: userAnalytics.user.contactSearchAgency?.id ?? null,
    ...rest,
  };
}

export type UsersAnalyticsTotals = ReturnType<typeof aggregateUsersAnalyticsTotals>;

function aggregateUsersAnalyticsTotals(usersAnalytics: Array<ReturnType<typeof flattenUserAnalytics>>, method: AggregationMethod)
{
  const aggregationMethod = { method: method === AggregationMethod.Avg ? 'avg' : 'sum' } as const;
  return aggregateMultiple(usersAnalytics, {
    buyersCount: aggregationMethod,
    buyersWithViewingsCount: aggregationMethod,
    buyersWithViewingsCount_4plus: aggregationMethod,
    buyersAverageDaysWithoutInteraction: aggregationMethod,
    buyersWithManualAlertCount: aggregationMethod,
    buyersWithManualAlertAndMatchesNotSentCount: aggregationMethod,
    buyersToCallBackCount: aggregationMethod,
    buyersMatchesCount: aggregationMethod,
    buyersRapprochedMatchesCount: aggregationMethod,
    leadsCount: aggregationMethod,
    valuationsCount: aggregationMethod,
    valuationsWithoutContact3mCount: aggregationMethod,
    viewingAuthorizationsCount: aggregationMethod,
    mandatesCount: aggregationMethod,
    exclusiveMandatesCount: aggregationMethod,
    mandatesWithoutMarketingReportCount: aggregationMethod,
    mandatesAveragePrice: (method === AggregationMethod.Avg ? { method: 'weightedAvg', weightField: 'mandatesCount' } : { method: 'sum' }),
  });
}

class UsersAnalyticsStore
{
  public usersAnalytics = new Fetchable(GetUsersAnalytics, { catchUnhandled: handleCriticalError });

  @computed private get _flattenedUsersAnalytics()
  {
    assert(this.usersAnalytics.lastResult);
    return this.usersAnalytics.lastResult.map(flattenUserAnalytics);
  }

  @observable private _order: OrderField | null = {
    fieldName: 'buyersCount',
    ordering: OrderType.DESC,
  };

  get order()
  {
    return this._order;
  }

  @action setOrder(order: OrderField | null)
  {
    this._order = order;
  }

  @computed private get _filteredUsers()
  {
    if (profileStore.selectedUsersIds.size <= 0)
      return this._flattenedUsersAnalytics;
    return this._flattenedUsersAnalytics.filter(({ userId, agencyId }) => (
      profileStore.selectedAgencyUsersIds.some((selected) => selected.userId === userId && selected.agencyId === agencyId)
    ));
  }

  @computed public get processedUsersAnalytics()
  {
    const orderBy = this.order ? [ this.order ] : [];
    return applyTableOrderBy(this._filteredUsers, orderBy);
  }

  @computed public get usersAnalyticsTotals()
  {
    return aggregateUsersAnalyticsTotals(this.processedUsersAnalytics, profileStore.totalsAggregationMethod);
  }

  @computed public get pinnedNodesTotals()
  {
    return profileStore.pinnedNodesInfo.map(({ node, usersWithAgencies }) => {
      const pinnedUsersIds = new Set(usersWithAgencies.map(({ userId }) => userId));
      const filteredUsersGoals = this._flattenedUsersAnalytics.filter(({ userId }) => pinnedUsersIds.has(userId));
      const totals = aggregateUsersAnalyticsTotals(filteredUsersGoals, profileStore.totalsAggregationMethod);
      return { node: node.node, totals } as const;
    });
  }

  public async refresh()
  {
    await this.usersAnalytics.ensureSuccessReload();
  }
}

export default new UsersAnalyticsStore();
