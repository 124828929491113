import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs } from '@repo-lib/graphql-query-builder';
import { ViewingOrigin, ViewingType } from '@core/api/types';
import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface UpdateViewingArgs
{
  id: IdType,
  type?: ViewingType | null,
  origin?: ViewingOrigin | null,
  date?: number | null,
  endDate?: number | null,
  contactId?: IdType | null,
  userId?: IdType | null,
  comments?: string | null,
  positiveFeedback?: string | null,
  negativeFeedback?: string | null,
}

const UpdateViewingArgsVars = {
  id: 'Int!',
  type: 'ViewingType',
  origin: 'ViewingOrigin',
  date: 'Date',
  endDate: 'Date',
  contactId: 'Int',
  userId: 'Int',
  comments: 'String',
  positiveFeedback: 'String',
  negativeFeedback: 'String',
};

const UpdateViewingArgsFields: ReadonlyFieldArgs = {
  id: true,
  type: true,
  origin: true,
  date: true,
  endDate: true,
  contactId: true,
  userId: true,
  comments: true,
  positiveFeedback: true,
  negativeFeedback: true,
};

export default function UpdateViewing(args: UpdateViewingArgs)
{
  return mutation<IdType>({
    operationName: 'Common_ViewingDialog_UpdateViewing',
    variables: UpdateViewingArgsVars,
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilViewing: {
          update: [{
            args: UpdateViewingArgsFields,
          }, {
            id: true,
          }],
        },
      }),
    },
  }, args);
}
