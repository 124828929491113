import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields, ReadonlyNodeFull } from '@repo-lib/graphql-query-builder';
import type {
  InvalidPropertyContext,
  MyBreteuilPropertyVisibility,
  PropertiesInfoContactIssue,
  PropertyRequiredField,
  PropertyStatus,
} from '@core/api/types';

export interface MyBreteuilProperty
{
  id: IdType,
  address: string,
}

const PropertyFields: ReadonlyNodeFields = {
  id: true,
  address: true,
};

export interface MyBreteuilContact
{
  id: IdType,
  fullname: string,
}

const ContactFields: ReadonlyNodeFields = {
  id: true,
  fullname: true,
};

export interface InvalidPhones
{
  code: 'InvalidPhones',
  phones: Array<string>,
}

const InvalidPhonesFields: ReadonlyNodeFields = {
  code: true,
  phones: true,
};

export interface InvalidEmails
{
  code: 'InvalidEmails',
  emails: Array<string>,
}

const InvalidEmailsFields: ReadonlyNodeFields = {
  code: true,
  emails: true,
};

export interface PropertiesInfoInvalidContact
{
  code: 'PropertiesInfoInvalidContact',
  contact: MyBreteuilContact,
  issues: Array<PropertiesInfoContactIssue>,
}

const PropertiesInfoInvalidContactFields: ReadonlyNodeFields = {
  code: true,
  contact: ContactFields,
  issues: true,
};

export interface PropertyRequirements
{
  requiredFields: Array<PropertyRequiredField> | null,
  requiredPhotos: number | null,
  requiredStatus: Array<PropertyStatus> | null,
  requiredVisibility: Array<MyBreteuilPropertyVisibility> | null,
}

const InvalidPropertyFieldsFields: ReadonlyNodeFields = {
  requiredFields: true,
  requiredPhotos: true,
  requiredStatus: true,
  requiredVisibility: true,
};

export interface InvalidProperty
{
  code: 'InvalidProperty',
  property: MyBreteuilProperty,
  context: InvalidPropertyContext,
  requirements: PropertyRequirements,
}

const InvalidPropertyFields: ReadonlyNodeFields = {
  code: true,
  property: PropertyFields,
  context: true,
  requirements: InvalidPropertyFieldsFields,
};

export interface PropertyAlreadyReceivedByContact
{
  code: 'PropertyAlreadyReceivedByContact',
  lastSendDate: number,
  property: MyBreteuilProperty,
  contact: MyBreteuilContact,
}

const PropertyAlreadyReceivedByContactFields: ReadonlyNodeFields = {
  code: true,
  property: PropertyFields,
  contact: ContactFields,
  lastSendDate: true,
};

export interface PropertyAlreadyBeingSentToContact
{
  code: 'PropertyAlreadyBeingSentToContact',
  property: MyBreteuilProperty,
  contact: MyBreteuilContact,
}

const PropertyAlreadyBeingSentToContactFields: ReadonlyNodeFields = {
  code: true,
  property: PropertyFields,
  contact: ContactFields,
};

export type ManualPropertiesInfoError
  = InvalidPhones
  | InvalidEmails
  | PropertiesInfoInvalidContact
  | InvalidProperty
  | PropertyAlreadyReceivedByContact
  | PropertyAlreadyBeingSentToContact
;

export const ManualPropertiesInfoErrorFields: ReadonlyNodeFull = [{
  polymorphicFields: {
    InvalidPhones: InvalidPhonesFields,
    InvalidEmails: InvalidEmailsFields,
    PropertiesInfoInvalidContact: PropertiesInfoInvalidContactFields,
    InvalidProperty: InvalidPropertyFields,
    PropertyAlreadyReceivedByContact: PropertyAlreadyReceivedByContactFields,
    PropertyAlreadyBeingSentToContact: PropertyAlreadyBeingSentToContactFields,
  }},
  {},
];
