import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationExtendedResponse, PaginationBaseArgs } from '@repo-lib/graphql-query-pagination';
import type {
  OrderBy,
  Language,
} from '@core/api/types';
import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationVariables,
  ExtendedPaginationArgs,
} from '@core/api/graphql/gen-v2';
import {
  EntityLogFields,
  type EntityLog,
  type EntityLogHistoryEventFilters,
} from '@my-breteuil/store/ui/fragments/entity-logs';

export interface GetPropertyLogsVariables extends PaginationBaseArgs
{
  language: Language,
  propertyId: IdType,
  orderBy?: OrderBy | undefined,
  filter?: EntityLogHistoryEventFilters | undefined,
}

export default function GetPropertyLogs(variables: GetPropertyLogsVariables)
{
  return query<PaginationExtendedResponse<EntityLog>>({
    operationName: 'Property_Logs_GetPropertyLogs',
    variables: {
      language: 'Language!',
      propertyId: 'Int!',
      ...ExtendedPaginationVariables('HistoryEvent'),
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        propertyLogs: [{
          args: {
            propertyId: true,
            ...ExtendedPaginationArgs,
          },
        }, paginationResult(EntityLogFields)],
      }),
    },
  }, variables);
}
