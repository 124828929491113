import type { IdType } from '@repo-breteuil/common-definitions';
import type {
  MyBreteuilMatch,
  CreateContactArgs,
  UpdateContactArgs,
  CreateContactCommentArgs,
  CreateSearchArgs,
  UpdateSearchArgs,
  ArchiveSearchArgs,
  Filters__MyBreteuilMatch,
  SentPropertiesFilterArgs,
} from './api';

import { action, computed, observable } from 'mobx';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import {
  handleNonCriticalError,
} from '@repo-breteuil/front-error';
import {
  Language,
  PropertyStatus,
  OrderField,
} from '@core/api/types';
import localesStore from '@core/store/Locales';

import {
  CreateContact,
  UpdateContact,
  CreateContactComment,
  CreateSearch,
  UpdateSearch,
  SetMatchBanned,
  GetSearchCriteria,
  GetUsersAssignableToSearches,
  GetContactSentProperties,
  GetContactMatches,
  AddNegativePropertyFeedback, type AddNegativePropertyFeedbackArgs,
  RemoveNegativePropertyFeedback, type RemoveNegativePropertyFeedbackArgs,
} from './api';
import contactStore from './contact';

class ContactInfoStore
{
  private static DefaultPageSize = 50;

  get defaultPageSize()
  {
    return ContactInfoStore.DefaultPageSize;
  }

  public searchCriteria = new Fetchable(GetSearchCriteria, { catchUnhandled: handleNonCriticalError });

  private _agentsAssignableToSearches = new Fetchable(GetUsersAssignableToSearches, { catchUnhandled: handleNonCriticalError });

  @computed get agentsAssignableToSearches()
  {
    const agents = this._agentsAssignableToSearches;
    if (!agents.result)
      return [];
    return agents.result.slice().sort((a, b) => a.contactSearchAgency.name.localeCompare(b.contactSearchAgency.name));
  }

  public selectedMatches = observable.map<IdType, MyBreteuilMatch>();

  @computed get selectedMatchesProperties()
  {
    return Array.from(this.selectedMatches.values()).map(match => ({
      id: match.propertyId,
      addrStreetAddress: match.propertyAddrStreetAddress,
    }));
  }

  public createContact(args: Omit<CreateContactArgs, 'language'>)
  {
    return CreateContact({
      language: Language.French,
      ...args,
    });
  };

  public async updateContactAndRefresh(args: Omit<UpdateContactArgs, 'id'>)
  {
    const updatedContact = await UpdateContact({
      id: contactStore.contact.id,
      ...args,
    });
    await this.refresh();
    return updatedContact;
  };

  public async createContactComment(args: Omit<CreateContactCommentArgs, 'contactId'>)
  {
    await CreateContactComment({
      contactId: this.contact.id,
      ...args,
    });
    await contactStore.refresh();
  }

  public async createSearch(args: CreateSearchArgs)
  {
    await CreateSearch(args);
    await this.refresh();
  };

  public async updateSearch(args: UpdateSearchArgs | ArchiveSearchArgs)
  {
    await UpdateSearch(args);
    await this.refresh();
  };

  public setMatchBanned = SetMatchBanned;

  public async addNegativePropertyFeedback(args: AddNegativePropertyFeedbackArgs)
  {
    await AddNegativePropertyFeedback(args);
  }

  public async removeNegativePropertyFeedback(args: RemoveNegativePropertyFeedbackArgs)
  {
    await RemoveNegativePropertyFeedback(args);
  }

  @observable private _sentPropertiesArgs: SentPropertiesFilterArgs = {
    propertyStatus: [PropertyStatus.Mandate, PropertyStatus.ViewingAuthorization, PropertyStatus.Offer],
  };

  @action public setSentPropertiesArgs(sentPropertiesArgs: SentPropertiesFilterArgs)
  {
    this._sentPropertiesArgs = sentPropertiesArgs;
  }

  public get sentPropertiesArgs()
  {
    return this._sentPropertiesArgs;
  }

  public sentProperties = new UncachedPagination({
    fetch: (baseArgs) => GetContactSentProperties({
      ...baseArgs,
      id: contactStore.contact.id,
      language: localesStore.currentLocale,
      ...this._sentPropertiesArgs,
    }),
    pageSize: ContactInfoStore.DefaultPageSize,
  });

  private _matchesFilters: Filters__MyBreteuilMatch = {};

  public setMatchesFiltersAndReload(newFilters: Filters__MyBreteuilMatch)
  {
    if (JSON.stringify(newFilters) === JSON.stringify(this._matchesFilters))
      return Promise.resolve(null);
    this._matchesFilters = JSON.parse(JSON.stringify(newFilters));
    return this.matches.first();
  }

  private _matchesOrderField: OrderField | null = null;

  public setMatchesOrderFieldAndReload(newOrder)
  {
    this._matchesOrderField = newOrder;
    return this.matches.first();
  }

  public matches = new UncachedPagination({
    fetch: (baseArgs) => GetContactMatches({
      ...baseArgs,
      id: contactStore.contact.id,
      language: localesStore.currentLocale,
      filter: {
        sendableCondition_NotAlreadySent: { equals: true },
        search_archived: { equals: false },
        ...this._matchesFilters,
      },
      orderBy: this._matchesOrderField ? [ this._matchesOrderField ] : [],
    }),
    pageSize: ContactInfoStore.DefaultPageSize,
  });

  public get isContactMerged()
  {
    return contactStore.isContactMerged;
  }

  public get contact()
  {
    return contactStore.contact;
  }

  public refreshResources()
  {
    return Promise.all([
      this.searchCriteria.ensureSuccessReload({
        language: localesStore.currentLocale,
        property: false,
      }),
      this._agentsAssignableToSearches.ensureSuccessReload(),
    ]);
  }

  public async refresh(args: { disableContactRefresh?: boolean } = {})
  {
    const { disableContactRefresh } = args;
    if (disableContactRefresh !== true)
      await contactStore.refresh();
    this.selectedMatches.clear();
    return Promise.all([
      this.sentProperties.first(),
      this.matches.first(),
    ]);
  }
}

export default new ContactInfoStore();
