import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationConnectionWithCount } from '@repo-lib/graphql-query-pagination';

import { query, errorWrapper, paginationResult } from '@core/api/graphql/gen-v2';

export interface ShowcaseStreamPropertiesComposition
{
  totalCount: number,
  avgPrice: number,
  exclusiveCount: number,
  geoArea_Paris_Count: number,
  geoArea_UK_Count: number,
  geoArea_FRLittoral_Count: number,
  operationType_ResidencyTransaction_Count: number,
  operationType_Rental_Count: number,
  operationType_SeasonalRental_Count: number,
}

export interface ShowcaseStream
{
  id: IdType,
  name: string,
  propertiesComposition: ShowcaseStreamPropertiesComposition,
}

function GetShowcaseStreams(): Promise<PaginationConnectionWithCount<ShowcaseStream>>
{
  return query({
    operationName: 'Showcase_StreamsList_GetShowcaseStreams',
    fieldsSelection: {
      authenticated: errorWrapper({
        showcase: {
          streams: paginationResult({
            id: true,
            name: true,
            propertiesComposition: {
              totalCount: true,
              avgPrice: true,
              exclusiveCount: true,
              geoArea_Paris_Count: true,
              geoArea_UK_Count: true,
              geoArea_FRLittoral_Count: true,
              operationType_ResidencyTransaction_Count: true,
              operationType_Rental_Count: true,
              operationType_SeasonalRental_Count: true,
            },
          }),
        },
      }),
    },
  });
}

export default GetShowcaseStreams;
