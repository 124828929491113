import type { DPERating, IdType } from '@repo-breteuil/common-definitions';
import type {
  Language,
  Currency,
  PropertyStatus,
  MyBreteuilPropertyVisibility,
  OperationType,
  PropertyType,
  Orientation,
  MyBreteuilHeating,
  FileUploadType,
  BreteuilPropertySurfaceUnit,
} from '@core/api/types';

import {
  PictureFormat,
  ContactOwnershipFilter,
  MyBreteuilMatchType,
  MyBreteuilPropertyDocumentType,
  UserVisibilityScope,
} from '@core/api/types';
import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilCity
{
  id: IdType,
  displayName: string,
}

export interface MyBreteuilAgency
{
  id: IdType,
  name: string,
  address: string,
  postalCode: string,
  city: MyBreteuilCity,
  phone: string,
  email: string,
  fullAddress: string,
}

export interface GeoArea
{
  id: IdType,
  displayName: string,
}

export interface MyBreteuilCriteria
{
  id: IdType,
  webName: string,
}

interface MyBreteuilPropertyDocument
{
  id: IdType,
  fileURL: string,
  fileType: FileUploadType,
}

export interface PropertyMainInfo
{
  id: IdType,
  slug: string,
  currentUserReferent: boolean,
  currentUserAssigned: boolean,
  title: string,
  publication: MyBreteuilPropertyVisibility,
  address: string,
  addrStreetAddress: string,
  postalCode: string | null,
  price: number,
  pricePerSurface: number,
  currency: Currency,
  status: PropertyStatus,
  availabilityDate: number | null/*timestamp*/,
  ref: string,
  refId: IdType,
  warrantDate: number | null/*timestamp*/,
  mainPictureURL: string,
  picturesURL: Array<string>,
  agency: MyBreteuilAgency,
  descriptionFR: string | null,
  descriptionEN: string | null,
  descriptionPT: string | null,
  descriptionES: string | null,
  district: GeoArea,
  criteria: Array<MyBreteuilCriteria>,
  buildingType: string,
  operationType: OperationType | null,
  collectiveOwnershipCharges: number | null,
  mybTaxeFonciere: number | null,
  surface: number,
  surfaceUnit: BreteuilPropertySurfaceUnit | null,
  bedrooms: number,
  floor: number,
  floorsCount: number,
  propertyType: PropertyType,
  constructionYear: number,
  orientation: Orientation,
  ceilingHeight: number,
  dpe: number,
  dpeCarbonFootprint: number,
  dpeRating: DPERating | null,
  heating: MyBreteuilHeating,
  plan: MyBreteuilPropertyDocument | null,
  rooms: number,
  bathrooms: number,
  toilets: number,
  valuationPDFAvailable: boolean,
  matchesRapprochements: {
    matchesCount: number,
    rapprochedMatchesCount: number,
  },
  matchesRapprochementsPerUser: Array<{
    user: {
      id: IdType,
      fullname: string,
      isAgencyPotCommun: boolean,
    },
    matchesCount: number,
    rapprochedMatchesCount_Total: number,
    rapprochedMatchesCount_Viewing: number,
    rapprochedMatchesCount_WhatsApp: number,
    rapprochedMatchesCount_NegativeFeedback: number,
  }>,
  matchesCount__All: number,
  matchesCountAbsolute__All: number,
  matchesCountExtended__All: number,
  matchesCount__SelfAgency: number,
  matchesCountAbsolute__SelfAgency: number,
  matchesCountExtended__SelfAgency: number,
  viewingsCount__All: number,
  viewingsCount__SelfAgency: number,
  clicksCount__All: number,
  clicksCount__SelfAgency: number,
  valuationsCount: number,
  transactionsCount: number,
  documentsValuationCount: number,
  documentsCNICount: number,
  documentsMandateCount: number,
  documentsTransactionDocsCount: number,
  managedUsersValuationPDFs: Array<{
    id: IdType,
    user: {
      id: IdType,
      fullname: string,
    },
    draft: boolean,
    pending: boolean,
    updatedAt: number,
  }>,
  contacts: Array<{
    id: IdType,
    fullname: string,
    emails: Array<string>,
    phones: Array<string>,
  }>,
  confidential: boolean,
  duplicatedBoughtProperties: Array<{
    id: IdType,
    slug: string,
    ref: string,
  }>,
}

export interface GetPropertyMainInfoVariables
{
  slug: string,
  language: Language,
}

export default function GetPropertyMainInfo(variables: GetPropertyMainInfoVariables)
{
  return query<PropertyMainInfo>({
    operationName: 'Property_GetPropertyMainInfo',
    variables: {
      slug: 'String!',
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: [{
          args: {
            slug: true,
          },
        }, {
          id: true,
          slug: true,
          currentUserReferent: true,
          currentUserAssigned: true,
          title: true,
          publication: true,
          address: true,
          addrStreetAddress: true,
          postalCode: true,
          price: true,
          pricePerSurface: true,
          currency: true,
          status: true,
          availabilityDate: true,
          ref: true,
          refId: true,
          warrantDate: true,
          mainPictureURL: [{
            args: {
              format: { literal: PictureFormat._1080p },
            },
          }, true],
          picturesURL: [{
            args: {
              format: { literal: PictureFormat._720p },
            },
          }, true],
          agency: {
            id: true,
            name: true,
            address: true,
            postalCode: true,
            city: {
              id: true,
              displayName: [{ args: { language: true } }, true],
            },
            fullAddress: true,
            phone: true,
            email: true,
          },
          descriptionFR: true,
          descriptionEN: true,
          descriptionPT: true,
          descriptionES: true,
          district: {
            id: true,
            displayName: [{ args: { language: true } }, true],
          },
          criteria: paginationResultAsList({
            id: true,
            webName: [{ args: { language: true } }, true],
          }),
          buildingType: true,
          operationType: true,
          collectiveOwnershipCharges: true,
          mybTaxeFonciere: true,
          surface: true,
          surfaceUnit: true,
          bedrooms: true,
          floor: true,
          floorsCount: true,
          propertyType: true,
          constructionYear: true,
          orientation: true,
          ceilingHeight: true,
          dpe: true,
          dpeCarbonFootprint: true,
          dpeRating: true,
          heating: true,
          plan: {
            id: true,
            fileURL: true,
            fileType: true,
          },
          rooms: true,
          bathrooms: true,
          toilets: true,
          valuationPDFAvailable: true,
          matchesRapprochements: [{
            args: {
              usersSubset: { literal: UserVisibilityScope.All },
              includeAgencyUsers: { literal: 'false' },
            },
          }, {
            matchesCount: true,
            rapprochedMatchesCount: true,
          }],
          matchesRapprochementsPerUser: [{
            args: {
              usersSubset: { literal: UserVisibilityScope.All },
              includeAgencyUsers: { literal: 'false' },
            },
          }, {
            user: {
              id: true,
              fullname: true,
              isAgencyPotCommun: true,
            },
            matchesCount: true,
            rapprochedMatchesCount_Total: true,
            rapprochedMatchesCount_Viewing: true,
            rapprochedMatchesCount_WhatsApp: true,
            rapprochedMatchesCount_NegativeFeedback: true,
          }],
          matchesCount__All: [{
            aliasedField: 'matchesCount',
            args: {
              contactsFilter: { literal: ContactOwnershipFilter.All },
            },
          }, true],
          matchesCountAbsolute__All: [{
            aliasedField: 'matchesCount',
            args: {
              contactsFilter: { literal: ContactOwnershipFilter.All },
              matchType: { literal: MyBreteuilMatchType.Absolute },
            },
          }, true],
          matchesCountExtended__All: [{
            aliasedField: 'matchesCount',
            args: {
              contactsFilter: { literal: ContactOwnershipFilter.All },
              matchType: { literal: MyBreteuilMatchType.Extended },
            },
          }, true],
          matchesCount__SelfAgency: [{
            aliasedField: 'matchesCount',
            args: {
              contactsFilter: { literal: ContactOwnershipFilter.SelfAgency },
            },
          }, true],
          matchesCountAbsolute__SelfAgency: [{
            aliasedField: 'matchesCount',
            args: {
              contactsFilter: { literal: ContactOwnershipFilter.SelfAgency },
              matchType: { literal: MyBreteuilMatchType.Absolute },
            },
          }, true],
          matchesCountExtended__SelfAgency: [{
            aliasedField: 'matchesCount',
            args: {
              contactsFilter: { literal: ContactOwnershipFilter.SelfAgency },
              matchType: { literal: MyBreteuilMatchType.Extended },
            },
          }, true],
          viewingsCount__All: [{
            aliasedField: 'viewings',
            args: {
              contactsFilter: { literal: ContactOwnershipFilter.All },
            },
          }, {
            count: true,
          }],
          viewingsCount__SelfAgency: [{
            aliasedField: 'viewings',
            args: {
              contactsFilter: { literal: ContactOwnershipFilter.SelfAgency },
            },
          }, {
            count: true,
          }],
          clicksCount__All: [{
            aliasedField: 'clicksCount',
            args: {
              contactsFilter: { literal: ContactOwnershipFilter.All },
            },
          }, true],
          clicksCount__SelfAgency: [{
            aliasedField: 'clicksCount',
            args: {
              contactsFilter: { literal: ContactOwnershipFilter.SelfAgency },
            },
          }, true],
          valuationsCount: [{
            aliasedField: 'valuations',
          }, { count: true }],
          transactionsCount: [{
            aliasedField: 'transactions',
          }, { count: true }],
          documentsValuationCount: [{
            aliasedField: 'documents',
            args: { filter: { literal: `{ documentType: { equals: ${MyBreteuilPropertyDocumentType.Valuation} } }` } },
          }, { count: true }],
          documentsCNICount: [{
            aliasedField: 'documents',
            args: { filter: { literal: `{ documentType: { equals: ${MyBreteuilPropertyDocumentType.CNI} } }` } },
          }, { count: true }],
          documentsMandateCount: [{
            aliasedField: 'documents',
            args: { filter: { literal: `{ documentType: { equals: ${MyBreteuilPropertyDocumentType.Mandate} } }` } },
          }, { count: true }],
          documentsTransactionDocsCount: [{
            aliasedField: 'documents',
            args: { filter: { literal: `{ documentType: { equals: ${MyBreteuilPropertyDocumentType.TransactionDocs} } }` } },
          }, { count: true }],
          managedUsersValuationPDFs: paginationResultAsList({
            id: true,
            user: {
              id: true,
              fullname: true,
            },
            draft: true,
            pending: true,
            updatedAt: true,
          }),
          contacts: paginationResultAsList({
            id: true,
            fullname: true,
            emails: true,
            phones: true,
          }),
          confidential: true,
          duplicatedBoughtProperties: [{
            args: { bought: { literal: 'true' } },
            aliasedField: 'duplicatedProperties',
          }, {
            id: true,
            slug: [{ args: { language: true } }, true],
            ref: true,
          }],
        }],
      }),
    },
  }, variables);
}
