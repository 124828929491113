import type { AdvancedTableColumnOrder } from '@lib/components/data-table/advanced-table';
import type { OrderField } from '@core/api/types';
import type {
  Filters__SimplePropertyValuation,
  Filters__ValuationAgencyMeeting,
} from './api';

import { Fetchable } from '@repo-lib/utils-mobx-store';
import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import { OrderType } from '@core/api/types';
import {
  GetValuations,
  GetAgencyMeetings,
  GetSimplePropertyValuationsDistinctPhonesCount,
  GetValuationAgencyMeetingsDistinctPhonesCount,
} from './api';

class ValuationsStore
{
  private static readonly DefaultPageSize = 500;
  public readonly pageSizeOptions = [250, 500, 1000];

  private _valuationsOrder: OrderField | null = null;
  private _valuationsFilter: Filters__SimplePropertyValuation = {};

  public valuations = new UncachedPagination({
    fetch: (baseArgs) => GetValuations({
      ...baseArgs,
      orderBy: this._valuationsOrder ? [this._valuationsOrder] : undefined,
      filter: this._valuationsFilter,
    }),
    pageSize: ValuationsStore.DefaultPageSize,
  });

  public setValuationsOrderByAndReload(order: AdvancedTableColumnOrder<string> | null)
  {
    this._valuationsOrder = (order !== null ? {
      fieldName: order.column,
      ordering: order.desc ? OrderType.DESC : OrderType.ASC,
    } : null);
    return this.valuations.first();
  }

  public setValuationsFilterAndReload(filter: Filters__SimplePropertyValuation)
  {
    if (JSON.stringify(filter) === JSON.stringify(this._valuationsFilter))
      return Promise.resolve(null);
    this._valuationsFilter = JSON.parse(JSON.stringify(filter));
    return Promise.all([
      this.valuations.first(),
      this.simplePropertyValuationUserCount.ensureSuccessReload({
        ...this._valuationsFilter,
        geoAreasIds: this._valuationsFilter.geoAreaId?.in,
        createdAt: {
          min: this._valuationsFilter.createdAt?.gte,
          max: this._valuationsFilter.createdAt?.lte,
        },
      }),
    ]);
  }

  private _meetingsOrder: OrderField | null = null;
  private _meetingsFilter: Filters__ValuationAgencyMeeting = {};

  public agencyMeetings = new UncachedPagination({
    fetch: (baseArgs) => GetAgencyMeetings({
      ...baseArgs,
      orderBy: this._meetingsOrder ? [this._meetingsOrder] : undefined,
      filter: this._meetingsFilter,
    }),
    pageSize: ValuationsStore.DefaultPageSize,
  });

  public setMeetingsOrderByAndReload(order: AdvancedTableColumnOrder<string> | null)
  {
    this._meetingsOrder = (order !== null ? {
      fieldName: order.column,
      ordering: order.desc ? OrderType.DESC : OrderType.ASC,
    } : null);
    return this.agencyMeetings.first();
  }

  public setMeetingsFilterAndReload(filter: Filters__ValuationAgencyMeeting)
  {
    if (JSON.stringify(filter) === JSON.stringify(this._meetingsFilter))
      return Promise.resolve(null);
    this._meetingsFilter = JSON.parse(JSON.stringify(filter));
    return Promise.all([
      this.agencyMeetings.first(),
      this.agencyMeetingUserCount.ensureSuccessReload({
        ...this._meetingsFilter,
        createdAt: {
          min: this._meetingsFilter.createdAt?.gte,
          max: this._meetingsFilter.createdAt?.lte,
        },
        confirmedMeetingDate: {
          min: this._meetingsFilter.confirmedMeetingDate?.gte,
          max: this._meetingsFilter.confirmedMeetingDate?.lte,
        },
      }),
    ]);
  }

  public simplePropertyValuationUserCount = new Fetchable(
    GetSimplePropertyValuationsDistinctPhonesCount,
    { catchUnhandled: handleNonCriticalError },
  );

  public agencyMeetingUserCount = new Fetchable(
    GetValuationAgencyMeetingsDistinctPhonesCount,
    { catchUnhandled: handleNonCriticalError },
  );


  public refresh()
  {
    return Promise.all([
      this.valuations.first(),
      this.agencyMeetings.first(),
      this.simplePropertyValuationUserCount.ensureSuccessReload({}),
      this.agencyMeetingUserCount.ensureSuccessReload({}),
    ]);
  }
}

export default new ValuationsStore();
