import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface CreateArgs
{
  name: string,
  defaultPropertyDisplayDurationMs?: number | null | undefined,
  defaultMediaDisplayDurationMs?: number | null | undefined,
}

async function CreateShowcaseStream(args: CreateArgs): Promise<void>
{
  await mutation({
    operationName: 'Showcase_StreamsList_CreateShowcaseStream',
    variables: {
      name: 'String!',
      defaultPropertyDisplayDurationMs: 'Int',
      defaultMediaDisplayDurationMs: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseStream: {
          create: [{
            args: {
              name: true,
              defaultPropertyDisplayDurationMs: true,
              defaultMediaDisplayDurationMs: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, args);
}

export default CreateShowcaseStream;
