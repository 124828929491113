import type { IdType } from '@repo-breteuil/common-definitions';
import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export default function OverwriteLastPropertyValuation(id: IdType)
{
  return mutation<IdType>({
    operationName: 'Valuation_OverwriteLastPropertyValuation',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilPropertyValuationPDF: {
          overwriteLastPropertyValuation: [{
            args: { id: true },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, { id });
}
