import type { IdType } from '@repo-breteuil/common-definitions';

import {
  query,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilContact
{
  id: IdType,
  fullname: string,
  emails: Array<string>,
  phones: Array<string>,
}

export interface GetContactVariables
{
  id: IdType,
}

export default function GetContact(variables: GetContactVariables)
{
  return query<MyBreteuilContact>({
    operationName: 'Property_Info_GetContact',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: [{
          args: {
            id: true,
          },
        }, {
          id: true,
          fullname: true,
          emails: true,
          phones: true,
        }],
      }),
    },
  }, variables);
}
