import type { ShowcaseScreenStatus } from './api/GetScreensStatus';

import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import GetScreensStatus from './api/GetScreensStatus';

class ScreensStatusStore
{
  public screens = new UncachedPagination<ShowcaseScreenStatus>({
    fetch: async (baseArgs) => (
      GetScreensStatus()
    ),
    pageSize: 1000, //Not paginated at the moment
  });

  public async refresh()
  {
    await this.screens.first();
  }
}

export default new ScreensStatusStore();
