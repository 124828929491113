import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { PaginationExtendedResponse, PaginationBaseArgs } from '@repo-lib/graphql-query-pagination';
import type {
  MyBreteuilUserContractTypeEnum,
  MyBreteuilCommissionType,
  OperationType,
  PropertyStatus,
  MyBreteuilPropertyVisibility,
  Language,
  OrderBy,
  Currency,
  MyBreteuilInvoiceType,
} from '@core/api/types';
import type { FiltersArgs } from './filters';

import { pickKeysFromObject } from '@repo-lib/utils-core';
import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationArgs,
  ExtendedPaginationVariables,
} from '@core/api/graphql/gen-v2';
import { FiltersFieldArgs, FiltersVariables } from './filters';

export interface MyBreteuilUser
{
  id: IdType,
  firstname: string,
  lastname: string,
  fullname: string,
  isAgencyPotCommun: boolean,
  contactSearchAgency: {
    id: IdType,
    code: string,
    name: string,
  } | null,
  cpRemuneration: number,
}

export interface MyBreteuilProperty
{
  id: IdType,
  slug: string,
  address: string,
  status: PropertyStatus,
  companyManagementBroughtDeal: boolean,
  publication: MyBreteuilPropertyVisibility,
  currency: Currency,
}

export interface MyBreteuilInvoice
{
  id: IdType,
  transactionId: IdType,
  type: MyBreteuilInvoiceType,
  invoiceNumber: number | null,
  statement: string | null,
  invoiceSettled: boolean,

  transactionMandate: string | null,
  transactionProperty: string | null,
  transactionIdentifier: string | null,
  transactionAuthenticDeedDate: number /** timestamp */ | null,

  recipientTitle: string | null,
  recipientCity: string | null,
  recipientFirstname: string | null,
  recipientLastname: string | null,
  recipientFullName: string | null,
  recipientAddress: string | null,
  recipientPostalCode: string | null,
  recipientCountryCode: string | null,

  company: string,
  companyBIC: string,
  companyCPI: string,
  companyVAT: string,
  companyName: string,
  companySIREN: string,
  companyWarranty: string,
  companyRCS: string,
  companyIBAN: string,
  companyAccountName: string,
  companySAS: string,

  agencyName: string,
  agencyPhone: string,
  agencyEmail: string,
  agencyAddress: string,
  agencyCode: string,

  amountTTC: number | null,
  amountHT: number | null,
  amountVAT: number | null,

  agency: {
    id: IdType,
    identifier: string,
  },
}

const TransactionInvoicesFields: ReadonlyNodeFields = {
  id: true,
  transactionId: true,
  type: true,
  invoiceNumber: true,
  statement: true,
  invoiceSettled: true,

  transactionMandate: true,
  transactionProperty: true,
  transactionIdentifier: true,
  transactionAuthenticDeedDate: true,

  recipientTitle: true,
  recipientCity: true,
  recipientFirstname: true,
  recipientLastname: true,
  recipientFullName: true,
  recipientAddress: true,
  recipientPostalCode: true,
  recipientCountryCode: true,

  company: true,
  companyBIC: true,
  companyCPI: true,
  companyVAT: true,
  companyName: true,
  companySIREN: true,
  companyWarranty: true,
  companyRCS: true,
  companyIBAN: true,
  companyAccountName: true,
  companySAS: true,

  agencyName: true,
  agencyPhone: true,
  agencyEmail: true,
  agencyAddress: true,
  agencyCode: true,

  amountTTC: true,
  amountHT: true,
  amountVAT: true,

  agency: {
    id: true,
    identifier: true,
  },
} as const;

const MyBreteuilPropertyFields: ReadonlyNodeFields = {
  id: true,
  slug: [{ args: { language: true } }, true],
  address: true,
  status: true,
  companyManagementBroughtDeal: true,
  publication: true,
};

const MyBreteuilUserFields: ReadonlyNodeFields = {
  id: true,
  firstname: true,
  lastname: true,
  fullname: true,
  isAgencyPotCommun: true,
  contactSearchAgency: {
    id: true,
    code: true,
    name: true,
  },
  cpRemuneration: true,
};

export interface MyBreteuilTransaction
{
  id: IdType,
  amount: number | null,
  totalAgencyFeesTTC: number | null,
  operationType: OperationType | null,
  acceptedOfferDate: number | null/*timestamp*/,
  compromiseDate: number | null/*timestamp*/,
  loanDate: number | null/*timestamp*/,
  mybDossierComplet: number | null/*timestamp*/,
  authenticDeedDate: number | null/*timestamp*/,
  virtualAuthenticDeedDate: number | null/*timestamp*/,
  validateInvoice: boolean,
  invoiceSettled: boolean,
  property: MyBreteuilProperty,
  transactionInvoiceNumber: string | null,
  createdAt: number/*timestamp*/,
  user: MyBreteuilUser,
  buyerGivenName: string | null,
  buyerFamilyName: string | null,
  sellerFamilyName: string | null,
  cancelledOffer: boolean,
}

const MyBreteuilTransactionFields: ReadonlyNodeFields = {
  id: true,
  amount: true,
  totalAgencyFeesTTC: true,
  operationType: true,
  acceptedOfferDate: true,
  compromiseDate: true,
  loanDate: true,
  mybDossierComplet: true,
  authenticDeedDate: true,
  virtualAuthenticDeedDate: true,
  validateInvoice: true,
  invoiceSettled: true,
  property: MyBreteuilPropertyFields,
  transactionInvoiceNumber: true,
  createdAt: true,
  user: MyBreteuilUserFields,
  buyerGivenName: true,
  buyerFamilyName: true,
  sellerFamilyName: true,
  cancelledOffer: true,
};

export interface MyBreteuilCommission
{
  id: IdType,
  agency: {
    id: IdType,
    code: string,
    name: string,
  } | null,
  user: MyBreteuilUser | null,
  type: MyBreteuilCommissionType | null,
  repartition: number | null,
  validatePayment: boolean,
  agencyFeesTTC: number | null,
  agencyFeesSubtractingContributorsFeesHT: number | null,
  dueCommission: number | null,
  transaction: MyBreteuilTransaction | null,
  userContractType: MyBreteuilUserContractTypeEnum,
  userCpRemuneration: number,
  transactionAmount: number | null,
  transactionPropertyPricePerSurface: number | null,
  transactionAgencyFeesTTC: number | null,
  transactionContributorsFeesTTC: number | null,
  transactionPropertyAddress: string | null,
  transactionPropertyStatus: PropertyStatus,
  transactionPropertyPrice: number | null,
  transactionPropertySurface: number | null,
  transactionAcceptedOfferDate: number | null/*timestamp*/,
  transactionLoanDate: number | null/*timestamp*/,
  transactionCompromiseDate: number | null/*timestamp*/,
  transactionAuthenticDeedDate: number | null/*timestamp*/,
  transactionVirtualAuthenticDeedDate: number | null/*timestamp*/,
  transactionValidateInvoice: boolean | null,
  transactionInvoiceSettled: boolean | null,
  invoice: MyBreteuilInvoice,
}

export const MyBreteuilCommissionFields: ReadonlyNodeFields = {
  id: true,
  agency: {
    id: true,
    code: true,
    name: true,
  },
  user: MyBreteuilUserFields,
  type: true,
  repartition: true,
  validatePayment: true,
  agencyFeesTTC: true,
  agencyFeesSubtractingContributorsFeesHT: true,
  dueCommission: true,
  transaction: MyBreteuilTransactionFields,
  userContractType: true,
  userCpRemuneration: true,
  transactionAmount: true,
  transactionPropertyPricePerSurface: true,
  transactionAgencyFeesTTC: true,
  transactionContributorsFeesTTC: true,
  transactionPropertyAddress: true,
  transactionPropertyStatus: [{ aliasedField: 'effectivePropertyStatus' }, true ],
  transactionPropertyPrice: true,
  transactionPropertySurface: true,
  transactionAcceptedOfferDate: true,
  transactionLoanDate: true,
  transactionCompromiseDate: true,
  transactionAuthenticDeedDate: true,
  transactionVirtualAuthenticDeedDate: true,
  transactionValidateInvoice: true,
  transactionInvoiceSettled: true,
  invoice: TransactionInvoicesFields,
};

export interface GetLastCommissionsVariables extends FiltersArgs, PaginationBaseArgs
{
  language: Language,
  orderBy?: OrderBy | undefined,
}

const FiltersArgsPickedKeys: Array<keyof FiltersArgs> = [
  'agencyUsers', 'cancelledOnly', 'dateRange', 'operationTypes', 'propertyStatuses', 'contractTypes', 'type',
];

export default function GetLastCommissions(variables: GetLastCommissionsVariables)
{
  return query<PaginationExtendedResponse<MyBreteuilCommission>>({
    operationName: 'Offers_GetLastCommissions',
    variables: {
      ...ExtendedPaginationVariables('MyBreteuilCommission'),
      ...pickKeysFromObject(FiltersVariables, FiltersArgsPickedKeys),
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          reporting: {
            lastCommissions: [{
              args: {
                ...ExtendedPaginationArgs,
                ...pickKeysFromObject(FiltersFieldArgs, FiltersArgsPickedKeys),
              },
            }, paginationResult(MyBreteuilCommissionFields)],
          },
        },
      }),
    },
  }, variables);
}
