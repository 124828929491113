import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface RepublishPropertyVariables
{
  propertyId: IdType,
}

export default function RepublishProperty(variables: RepublishPropertyVariables)
{
  return mutation<true>({
    operationName: 'Property_Info_RepublishProperty',
    variables: {
      propertyId: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          publicationPlatforms: {
            republishProperty: [{
              args: {
                propertyId: true,
              },
            }, true],
          },
        },
      }),
    },
  }, variables);
}
