import { action, autorun } from 'mobx';
import {
  ParsedLocation,
  MatchedRouteHandler,
  ensureLocationHandler,
  PageRouter,
} from '@lib/routing';

import { handleCriticalError, handleNonCriticalError } from '@repo-breteuil/front-error';
import router from '@core/store/routing/router';
import { RootPages } from '@my-breteuil/store/routing/pages';
import * as Routes from '@my-breteuil/store/routing/routes';
import setHandlers from '@my-breteuil/store/routing/handlers';
import routingStore from '@my-breteuil/store/routing/RoutingStore';

import session from '@my-breteuil/store/ui/common/Session';
import { formatGlobalErrorMessage } from '@core/store/GlobalMessage';

class RoutingLogic extends PageRouter<RootPages>
{
  private LogInRedirect: MatchedRouteHandler;
  private ProfileRedirect: MatchedRouteHandler;
  private AgentsBlueprintAccountingRedirect: MatchedRouteHandler;
  private PropertiesConfidentialLinksRedirect: MatchedRouteHandler;

  constructor()
  {
    super(router, routingStore, {
      onRouteNotFound: () => ({ page: RootPages.NotFound, route: null }),
      onRouteChangeError: (error) => {
        handleCriticalError(error);
        return null;
      },
      onRouteChangeDroppedError: handleNonCriticalError,
    });
    setHandlers(this.handlers);
    this.LogInRedirect = ensureLocationHandler(this.handlers.handlers, Routes.logIn.generateStaticPath());
    this.ProfileRedirect = ensureLocationHandler(this.handlers.handlers, Routes.profile.generateStaticPath());
    this.AgentsBlueprintAccountingRedirect = ensureLocationHandler(this.handlers.handlers, Routes.usersAccounting.generateStaticPath());
    this.PropertiesConfidentialLinksRedirect = ensureLocationHandler(this.handlers.handlers, Routes.propertiesConfidentialLinks.generateStaticPath());
  }

  init()
  {
    this.updateFromCurrentLocation();
    autorun(() => {
      if (this.loadError)
        formatGlobalErrorMessage(this.loadError as any);
    });
  }

  public getDefaultLoggedOutRedirectInfo(location?: ParsedLocation | null)
  {
    return this.LogInRedirect;
  }

  public getDefaultRedirectInfo(location?: ParsedLocation | null)
  {
    //Keep this in sync with handlers.root
    //TODO: find a way to deduplicate things
    if (!session.loggedIn)
      return this.getDefaultLoggedOutRedirectInfo(location);
    if (session.accountant && !session.admin)
      return this.AgentsBlueprintAccountingRedirect;
    if (session.propertyManager && !session.admin)
      return this.PropertiesConfidentialLinksRedirect;
    return this.ProfileRedirect;
  }

  @action public changeToDefaultLoggedOutRoute(
    opts: {
      replace?: boolean,
      force?: boolean,
      invalidateCurrentLocation?: boolean,
    } = {},
  )
  {
    this.changeRoute(this.getDefaultLoggedOutRedirectInfo(), opts);
  }

  @action public changeToDefaultRoute(
    opts: {
      replace?: boolean,
      force?: boolean,
      invalidateCurrentLocation?: boolean,
    } = {},
  )
  {
    this.changeRoute(this.getDefaultRedirectInfo(), opts);
  }
};

export default new RoutingLogic();
