import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface DenyValidationArgs
{
  id: IdType,
  comment?: string | null | undefined,
}

export default function DenyValidation(args: DenyValidationArgs)
{
  return mutation<IdType>({
    operationName: 'ValuationsPDFs_DenyValidation',
    variables: {
      id: 'Int!',
      comment: 'String',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilPropertyValuationPDF: {
          denyValidation: [{
            args: {
              id: true,
              comment: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, args);
}
