import type { IdType } from '@repo-breteuil/common-definitions';
import type { Language } from '@core/api/types';
import type { MyBreteuilCommission } from './GetLastCommissions';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';
import { MyBreteuilCommissionFields } from './GetLastCommissions';

export interface UpdateCommissionPaymentInfoArgs {
  commissionId: IdType,
  language: Language,
  validatePayment?: boolean | undefined,
  remunerationPercentage?: number | undefined,
}

export default function UpdateCommissionPaymentInfo(args: UpdateCommissionPaymentInfoArgs)
{
  return mutation<MyBreteuilCommission>({
    operationName: 'Offers_UpdateCommissionPaymentInfo',
    variables: {
      commissionId: 'Int!',
      validatePayment: 'Boolean',
      remunerationPercentage: 'Int',
      language: 'Language',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          myBreteuilTransaction: {
            updateCommissionPaymentInfo: [{
              args: {
                commissionId: true,
                validatePayment: true,
                remunerationPercentage: true,
              },
            }, MyBreteuilCommissionFields],
          },
        },
      }),
    },
  }, args);
}
