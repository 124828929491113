import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';
import { MyBreteuilPropertyValuationPDFFields, type MyBreteuilPropertyValuationPDF } from './shared';

export interface RequestValuationPDFReviewArgs
{
  id: IdType,
}

export default function RequestValuationPDFReview(args: RequestValuationPDFReviewArgs)
{
  return mutation<MyBreteuilPropertyValuationPDF>({
    operationName: 'Valuation_RequestValuationPDFReview',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilPropertyValuationPDF: {
          requestValidation: [{
            args: {
              id: true,
            },
          }, MyBreteuilPropertyValuationPDFFields],
        },
      }),
    },
  }, args);
}
