import type { IdType } from '@repo-breteuil/common-definitions';

import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilAgency
{
  id: IdType,
  name: string,
  usersIds: Array<IdType>,
}

export default function GetManagedAgencies()
{
  return query<Array<MyBreteuilAgency>>({
    operationName: 'Logs_GetAgencies',
    fieldsSelection: {
      authenticated: errorWrapper({
        managedMyBreteuilAgencies: paginationResultAsList({
          id: true,
          name: true,
          usersIds: [{
            aliasedField: 'users',
          }, paginationResultAsList({
            id: true,
          })],
        }),
      }),
    },
  });
}
