import type { IdType } from '@repo-breteuil/common-definitions';
import type { GetManagedUsers_UserDayOffRequest } from './api';

import { action, computed, observable } from 'mobx';
import { addWeeks } from 'date-fns';
import { sum, mergeArrayMaps, transformMapValues, indexArrayItems, assert } from '@repo-lib/utils-core';
import { HierarchyTreeNode } from '@repo-lib/utils-hierarchy-tree';
import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { startOfWeek, getWeekOfYear } from '@repo-lib/utils-date-tz';
import { getWorkingDaysInRange } from '@repo-lib/utils-date-holidays';
import { incrementIntMapValue, insertToArrayMap } from '@repo-lib/utils-core';
import { OrderType } from '@core/api/types';
import { handleCriticalError } from '@repo-breteuil/front-error';
import { DefaultHolidaysCountry } from '@core/constants/timezones';
import geoAreasStore from '@my-breteuil/store/ui/common/geo-areas';
import UserDayOffRequestDialogStore from '@my-breteuil/store/ui/common/user-day-off-request-dialog';
// TODO Importing component related stuff is discouraged
import {
  genHistoryNodes,
  parseHistoryNodeIdentifier,
  makeUserHierarchyTree,
  NodeType,
  type UserHistoryHierarchyNode,
} from '@my-breteuil/components/common/user-pickers/hierarchy/utils';
import { GetUsers, GetManagedUsersDaysOff, GetUserDayOffRequests } from './api';

// TODO move to lib?
function sumNumberMaps<KeyT extends string>(...args: Array<Map<KeyT, number>>)
{
  const res = new Map<string, number>();
  for (const map of args)
  {
    for (const [key, value] of map)
    {
      const oldValue = res.get(key);
      const newValue = oldValue !== undefined ? oldValue + value : value;
      res.set(key, newValue);
    }
  }
  return res;
}

export interface UserDaysOffHierarchyNode extends UserHistoryHierarchyNode
{
  availablePaidLeaveDaysOff: number,
  takenPaidLeaveDaysOff: number,
  dayOffRequestsPerYearlyWeek: Map<string/*year-week*/, Array<GetManagedUsers_UserDayOffRequest>>,
  dayOffRequestsCountPerYearlyWeek: Map<string/*year-week*/, number>,
  dayOffRequestsManagersCountPerYearlyWeek: Map<string/*year-week*/, number>,
  dayOffRequestsAgentsCountPerYearlyWeek: Map<string/*year-week*/, number>,
  dayOffRequestsOthersCountPerYearlyWeek: Map<string/*year-week*/, number>,
  childNodes: Array<UserDaysOffHierarchyNode>,
}

export enum UsersDayOffRequestsDataType
{
  WorkingDays = 'WorkingDays',
  Managers = 'Managers',
  Agents = 'Agents',
  Others = 'Others',
}

export const DisplayedWeeksCount = 20;
const DefaultStartWeekOffset = -4;

export interface FiltersArgs
{
  selectedUsers?: Array<IdType> | undefined,
}

class HolidaysStore
{
  public users = new Fetchable(GetUsers, { catchUnhandled: handleCriticalError });

  @computed get usersNodes()
  {
    const users = this.users.lastResult ?? [];
    const filteredUsers = users.filter((user) => user.contactSearchAgency !== null);
    const nodes = filteredUsers.map((user) => {
      const { contactSearchAgency } = user;
      return {
        user,
        agency: contactSearchAgency!, // Safe assertion
        activeInAgency: true,
      };
    });
    return genHistoryNodes(geoAreasStore.geoAreas, nodes);
  }

  public usersDaysOff = new Fetchable(async () => {
    const users = await GetManagedUsersDaysOff({
      filter: {
        id: this._filters.selectedUsers ? { in: this._filters.selectedUsers } : undefined,
      },
    });
    const filteredUsers = users.filter((user) => (
      !user.isAgencyPotCommun && user.contactSearchAgency != null
    ));
    return filteredUsers.map((user) => {
      const { dayOffRequests, ...rest } = user;
      const dayOffRequestsPerYearlyWeek = new Map<string/*year-week*/, Array<GetManagedUsers_UserDayOffRequest>>();
      const dayOffRequestsCountPerYearlyWeek = new Map<string/*year-week*/, number>();

      for (const request of dayOffRequests)
      {
        const { startDate, endDate } = request;
        const days = getWorkingDaysInRange({
          from: new Date(startDate),
          to: new Date(endDate ?? startDate),
          includeTo: true,
          includeSaturdays: true,
          holidaysCountry: DefaultHolidaysCountry,
        });
        const keys = new Set();
        for (const day of days)
        {
          const key = `${day.getFullYear()}-${getWeekOfYear(day)}`;
          keys.add(key);
          incrementIntMapValue(dayOffRequestsCountPerYearlyWeek, key);
        }
        for (const key of keys)
          insertToArrayMap(dayOffRequestsPerYearlyWeek, key, request);
      }
      return {
        ...rest,
        dayOffRequestsPerYearlyWeek,
        dayOffRequestsCountPerYearlyWeek,
      };
    });
  }, { catchUnhandled: handleCriticalError });

  @computed get usersDaysOffTree()
  {
    const usersDaysOff = this.usersDaysOff.lastResult ?? [];

    const usersNodes = usersDaysOff.map((userDaysOff) => ({
      user: userDaysOff,
      agency: userDaysOff.contactSearchAgency!,
      activeInAgency: true,
    }));
    const nodes = genHistoryNodes(geoAreasStore.geoAreas, usersNodes);
    const tree = makeUserHierarchyTree(nodes);

    const indexedUsersDaysOff = indexArrayItems(usersDaysOff, u => u.id);

    function processNode(hierarchyNode: HierarchyTreeNode<string, UserHistoryHierarchyNode>): UserDaysOffHierarchyNode
    {
      const { node, directChildren } = hierarchyNode;
      const parsedIdentifier = parseHistoryNodeIdentifier(node.identifier);
      if (parsedIdentifier.type === NodeType.User)
      {
        const { userId } = parsedIdentifier;
        const daysOffInfo = indexedUsersDaysOff.get(userId);
        assert(daysOffInfo, 'Unable to retrieve daysOffInfo');
        const {
          availablePaidLeaveDaysOff,
          takenPaidLeaveDaysOff,
          dayOffRequestsPerYearlyWeek,
          dayOffRequestsCountPerYearlyWeek,
          manager,
          agent,
        } = daysOffInfo;

        const dayOffRequestsManagersCountPerYearlyWeek = transformMapValues(
          dayOffRequestsPerYearlyWeek,
          () => manager ? 1 : 0,
        ) as Map<string/*year-week*/, number>;
        const dayOffRequestsAgentsCountPerYearlyWeek = transformMapValues(
          dayOffRequestsPerYearlyWeek,
          () => agent && !manager ? 1 : 0,
        ) as Map<string/*year-week*/, number>;
        const dayOffRequestsOthersCountPerYearlyWeek = transformMapValues(
          dayOffRequestsPerYearlyWeek,
          () => !manager && !agent ? 1 : 0,
        ) as Map<string/*year-week*/, number>;
        return {
          ...node,
          availablePaidLeaveDaysOff,
          takenPaidLeaveDaysOff,
          dayOffRequestsPerYearlyWeek,
          dayOffRequestsCountPerYearlyWeek,
          dayOffRequestsManagersCountPerYearlyWeek,
          dayOffRequestsAgentsCountPerYearlyWeek,
          dayOffRequestsOthersCountPerYearlyWeek,
          childNodes: [],
        };
      }

      const childNodes = directChildren.map(processNode);
      const availablePaidLeaveDaysOff = sum(childNodes.map(({ availablePaidLeaveDaysOff }) => availablePaidLeaveDaysOff));
      const takenPaidLeaveDaysOff = sum(childNodes.map(({ takenPaidLeaveDaysOff }) => takenPaidLeaveDaysOff));
      const dayOffRequestsPerYearlyWeek = mergeArrayMaps(...childNodes.map(({ dayOffRequestsPerYearlyWeek }) => dayOffRequestsPerYearlyWeek));
      const dayOffRequestsCountPerYearlyWeek = sumNumberMaps(...childNodes.map(({ dayOffRequestsCountPerYearlyWeek }) => dayOffRequestsCountPerYearlyWeek));
      const dayOffRequestsManagersCountPerYearlyWeek = sumNumberMaps(...childNodes.map(({ dayOffRequestsManagersCountPerYearlyWeek }) => dayOffRequestsManagersCountPerYearlyWeek));
      const dayOffRequestsAgentsCountPerYearlyWeek = sumNumberMaps(...childNodes.map(({ dayOffRequestsAgentsCountPerYearlyWeek }) => dayOffRequestsAgentsCountPerYearlyWeek));
      const dayOffRequestsOthersCountPerYearlyWeek = sumNumberMaps(...childNodes.map(({ dayOffRequestsOthersCountPerYearlyWeek }) => dayOffRequestsOthersCountPerYearlyWeek));
      return {
        ...node,
        availablePaidLeaveDaysOff,
        takenPaidLeaveDaysOff,
        dayOffRequestsPerYearlyWeek,
        dayOffRequestsCountPerYearlyWeek,
        dayOffRequestsManagersCountPerYearlyWeek,
        dayOffRequestsAgentsCountPerYearlyWeek,
        dayOffRequestsOthersCountPerYearlyWeek,
        childNodes,
      };
    }

    return tree.rootNodes.map(processNode);
  }

  @observable private _startWeekOffset = DefaultStartWeekOffset;

  @action increaseStartWeekOffset()
  {
    this._startWeekOffset += 4;
  }

  @action decreaseStartWeekOffset()
  {
    this._startWeekOffset -= 4;
  }

  @computed get weeks()
  {
    const startDate = startOfWeek();
    const weeks: Array<Date> = [];
    for (let i = 0; i < DisplayedWeeksCount; i++)
      weeks.push(addWeeks(startDate, i + this._startWeekOffset));
    return weeks;
  }

  @observable private _usersDayOffRequestsDataType = UsersDayOffRequestsDataType.WorkingDays;

  get usersDayOffRequestsDataType()
  {
    return this._usersDayOffRequestsDataType;
  }

  @action setUsersDayOffRequestsDataType(dataType: UsersDayOffRequestsDataType)
  {
    this._usersDayOffRequestsDataType = dataType;
  }

  private _filters: FiltersArgs = {};

  public async setFiltersArgsAndRefresh(filters: FiltersArgs)
  {
    this._filters = filters;
    await Promise.all([
      this.usersDaysOff.ensureSuccessReload(),
      this.userDayOffRequests.first(),
    ]);
  }

  private static DefaultDayOffRequestsPageSize = 200;
  public readonly DayOffRequestsPageSizeOptions = [ 50, 100, 200, 500 ];

  public userDayOffRequests = new UncachedPagination({
    fetch: (baseArgs) => (
      GetUserDayOffRequests({
        ...baseArgs,
        orderBy: [ { fieldName: 'createdAt', ordering: OrderType.DESC } ],
        filter: {
          userId: this._filters.selectedUsers ? { in: this._filters.selectedUsers } : undefined,
        },
      })
    ),
    pageSize: HolidaysStore.DefaultDayOffRequestsPageSize,
  });

  public userDayOffDialogStore = new UserDayOffRequestDialogStore();

  public async refresh()
  {
    await Promise.all([
      this.users.ensureSuccessReload(),
      this.usersDaysOff.ensureSuccessReload(),
      this.userDayOffRequests.first(),
    ]);
  }
}

export default new HolidaysStore();
