import type { UpdatePasswordArgs } from './api/UpdatePassword';
import { computed } from 'mobx';

import PublicFolderStore from './PublicFolders';
import {
  UpdatePassword,
} from './api';

class PersonalSpaceMain
{
  private _publicFolders = new PublicFolderStore();

  @computed get publicFolders()
  {
    return this._publicFolders;
  }

  public updatePassword(args: UpdatePasswordArgs)
  {
    return UpdatePassword(args);
  };

  async refreshFolders()
  {
    await this.publicFolders.refreshFolders();
  }
}

export default new PersonalSpaceMain();
