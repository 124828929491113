import { action, observable } from 'mobx';
import { Day, Hour, Minute, Second, timestampToDay } from '@repo-lib/utils-core';
import { formatGlobalErrorMessage } from '@core/store/GlobalMessage';
import session from '@my-breteuil/store/ui/common/Session';
import { GetUserDailyReport } from './api';

const ShowBannerDayMs = 18 * Hour;
const HideBannerDayMs = 24 * Hour + 1;

const LastDateReportStorageKey = 'daily-report-banner_lastReportDate';
const StoragePollingIntervalMs = 10 * Second;

function getDateOffsetFromDayMs(args: { date: Date, targetDayMs: number })
{
  const { date, targetDayMs } = args;
  const adjustedDate = date.valueOf() + date.getTimezoneOffset() * Minute * -1;
  const dateDayMs = adjustedDate % Day;
  return Math.max(targetDayMs - dateDayMs, 0);
}

function isTodaysReport(args: { reportDate: number, date: number })
{
  const { reportDate, date } = args;
  const reportDateDay = timestampToDay(reportDate);
  const dateDay = timestampToDay(date);
  return reportDateDay === dateDay;
}

class DailyReportBannerStore
{
  private _running = false;
  private _pollingInterval: NodeJS.Timeout | null = null;

  @observable private _showBanner = false;

  get showBanner()
  {
    return this._showBanner;
  }

  @action setShowBanner(value: boolean)
  {
    this._showBanner = value;
  }

  setReportDate(date: number)
  {
    window.localStorage.setItem(LastDateReportStorageKey, date.toString());
    if (this._showBanner)
      this.setShowBanner(false);
    if (this._pollingInterval)
      clearInterval(this._pollingInterval);
  }

  runHideBanner()
  {
    const now = new Date();
    const timeoutUntilHideBanner = getDateOffsetFromDayMs({
      date: now,
      targetDayMs: HideBannerDayMs,
    });

    setTimeout(() => {
      this.setShowBanner(false);
      if (this._pollingInterval)
        clearInterval(this._pollingInterval);
      this.runDisplayBanner();
    }, timeoutUntilHideBanner);
  }

  runDisplayBannerPolling()
  {
    this._pollingInterval = setInterval(() => {
      const dateRaw = window.localStorage.getItem(LastDateReportStorageKey);
      if (!dateRaw)
        return;
      const date = parseInt(dateRaw);
      const now = new Date();
      if (!isTodaysReport({ reportDate: date, date: now.valueOf() }))
        return;
      this.setShowBanner(false);
      if (this._pollingInterval)
        clearInterval(this._pollingInterval);
    }, StoragePollingIntervalMs);
  }

  runDisplayBanner()
  {
    const now = new Date();
    const timeoutUntilShowBanner = getDateOffsetFromDayMs({
      date: now,
      targetDayMs: ShowBannerDayMs,
    });

    setTimeout(() => {
      const fetchReport = async () => {
        const user = session.mybUser;
        if (!user)
          return;
        const report = await GetUserDailyReport(user.id);
        const now = new Date();
        if (report && isTodaysReport({ reportDate: report.date, date: now.valueOf() }))
          return;
        this.setShowBanner(true);
        this.runDisplayBannerPolling();
        this.runHideBanner();
      };
      fetchReport().catch((error) => formatGlobalErrorMessage(error));
    }, timeoutUntilShowBanner);
  }

  init()
  {
    if (!session || !session.agent || session.admin)
      return;
    if (this._running)
      return;
    this._running = true;
    this.runDisplayBanner();
  }
}

export default new DailyReportBannerStore();
