import type {
  PasswordResetExecuteArgs,
} from './api';

import Session from '@my-breteuil/store/ui/common/Session';
import {
  PasswordResetExecute,
} from './api';

export class ResetPasswordStore
{
  private _emailToken: string = "";

  public setEmailToken(emailToken: string)
  {
    this._emailToken = emailToken;
  }

  public get emailToken()
  {
    return this._emailToken;
  }

  public async passwordResetExecute(args: PasswordResetExecuteArgs)
  {
    const session = await PasswordResetExecute(args);
    return Session.update(session);
  };
}

export default new ResetPasswordStore();
