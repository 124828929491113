import type { IdType } from '@repo-breteuil/common-definitions';
import { errorWrapper, mutation } from '@core/api/graphql/gen-v2';
import type { PropertyConfidentialLinkStatus } from '@core/api/types';

export interface UpdateConfidentialLinkStatusArgs
{
  id: IdType,
  status: PropertyConfidentialLinkStatus,
}

export default function UpdateConfidentialLinkStatus(args: UpdateConfidentialLinkStatusArgs)
{
  return mutation<IdType>({
    operationName: 'PropertiesConfidentialLinks_UpdateConfidentialLinkStatus',
    variables: {
      id: 'Int!',
      status: 'PropertyConfidentialLinkStatus!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        propertyConfidentialLink: {
          updateStatus: [{
            args: {
              id: true,
              status: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, args);
}
