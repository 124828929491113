import { action, observable } from 'mobx';
import { Fetchable, OpeningControl } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import type { ViewingOrigin, ViewingType } from '@core/api/types';
import localesStore from '@core/store/Locales';
import {
  GetProperty,
  CreateViewing,
  type CreateViewingVariables,
  type MyBreteuilContact,
  GetAssignableUsers,
  SearchContacts,
  type SearchContactsVariables,
} from './api';

class ViewingCreateDialogStore
{
  @observable private _defaultDate: number | null = null;
  @observable private _defaultEndDate: number | null = null;
  @observable private _defaultContact: MyBreteuilContact | null = null;
  @observable private _defaultViewingType: ViewingType | null = null;
  @observable private _origin: ViewingOrigin | null = null;
  private _onCreateSuccess: (() => void) | null = null;

  get defaultDate()
  {
    return this._defaultDate;
  }

  @action private setDefaultDate(date: number)
  {
    this._defaultDate = date;
  }

  get defaultEndDate()
  {
    return this._defaultEndDate;
  }

  @action private setDefaultEndDate(date: number)
  {
    this._defaultEndDate = date;
  }

  get defaultContact()
  {
    return this._defaultContact;
  }

  @action private setDefaultContact(contact: MyBreteuilContact | null)
  {
    this._defaultContact = contact;
  }

  get defaultViewingType()
  {
    return this._defaultViewingType;
  }

  @action private setDefaultViewingType(type: ViewingType | null)
  {
    this._defaultViewingType = type;
  }

  get origin()
  {
    return this._origin;
  }

  get onCreateSuccess()
  {
    return this._onCreateSuccess;
  }

  @action private setOrigin(origin: ViewingOrigin)
  {
    this._origin = origin;
  }

  public property = new Fetchable(GetProperty, { catchUnhandled: handleNonCriticalError });

  public visibleUsers = new Fetchable(GetAssignableUsers, { catchUnhandled: handleNonCriticalError });

  private _openingControl = new OpeningControl();

  public get opened()
  {
    return this._openingControl.isOpen;
  }

  public async open(
    args: {
      propertySlug: string,
      defaultDate: number/*timestamp*/,
      defaultEndDate: number/*timestamp*/,
      defaultContact?: MyBreteuilContact | undefined,
      defaultViewingType?: ViewingType | undefined,
      origin: ViewingOrigin,
      onCreateSuccess: () => void,
    },
  )
  {
    await Promise.all([
      this.property.ensureSuccessReload({
        slug: args.propertySlug,
        language: localesStore.currentLocale,
      }),
      this.visibleUsers.ensureSuccessReload(),
    ]);
    this.setDefaultDate(args.defaultDate);
    this.setDefaultEndDate(args.defaultEndDate);
    this.setDefaultContact(args.defaultContact ?? null);
    this.setDefaultViewingType(args.defaultViewingType ?? null);
    this.setOrigin(args.origin);
    this._onCreateSuccess = args.onCreateSuccess;
    this._openingControl.open();
  }

  public close()
  {
    this._openingControl.close();
  }

  public async createViewing(args: CreateViewingVariables)
  {
    await CreateViewing(args);
  }

  public async searchContacts(args: SearchContactsVariables)
  {
    return SearchContacts(args);
  }
}

export default ViewingCreateDialogStore;
