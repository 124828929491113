import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface SetMatchBannedVariables
{
  id: IdType,
  banned: boolean,
}

export default function SetMatchBanned(variables: SetMatchBannedVariables)
{
  return mutation<{ id: IdType }>({
    operationName: 'Contact_SetMatchBanned',
    variables: {
      id: 'Int!',
      banned: 'Boolean!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilMatch: {
          setBanned: [{
            args: {
              id: true,
              banned: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, variables);
}
