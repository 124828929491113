import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationConnectionWithCount } from '@repo-lib/graphql-query-pagination';

import { omitUndefinedValues } from '@repo-lib/utils-core';
import { formatFieldsToPaginationFilter } from '@repo-lib/graphql-query-pagination';
import {
  query,
  errorWrapper,
  paginationResult,
} from '@core/api/graphql/gen-v2';
import type { ShowcaseScreenResolution } from '@core/api/types';

export interface ShowcaseScreen
{
  id: IdType,
  name: string,
  identifier: string,
  displayURL: string,
  stream: {
    id: IdType,
    name: string,
  } | null,
  streamItemOffset: number | null,
  expectedResolution: ShowcaseScreenResolution | null,
  nightTimeFilterStartMinute: number | null,
  nightTimeFilterEndMinute: number | null,
}

export interface ScreensFilters
{
  name?: string | undefined,
}

function GetShowcaseScreens(
  variables: { filter?: ScreensFilters | undefined } = {},
): Promise<PaginationConnectionWithCount<ShowcaseScreen>>
{
  return query({
    operationName: 'Showcase_ScreensList_GetShowcaseScreens',
    variables: {
      filter: 'Filters__ShowcaseScreen',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcase: {
          screens: [{
            args: {
              filter: true,
            },
          }, paginationResult({
            id: true,
            name: true,
            identifier: true,
            displayURL: true,
            stream: {
              id: true,
              name: true,
            },
            streamItemOffset: true,
            expectedResolution: true,
            nightTimeFilterStartMinute: true,
            nightTimeFilterEndMinute: true,
          })],
        },
      }),
    },
  }, {
    filter: variables.filter && formatFieldsToPaginationFilter(omitUndefinedValues(variables.filter)),
  });
}

export default GetShowcaseScreens;
