import type { IdType } from '@repo-breteuil/common-definitions';

import { Currency, PictureFormat } from '@core/api/types';
import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface Property
{
  id: IdType,
  ref: string,
  refId: IdType,
  picturesURL: Array<string>,
  price: number,
  pricePerSurface: number,
  soldDate: number/** timestamp */,
  currency: Currency,
}

const PropertyFields = {
  id: true,
  ref: true,
  refId: true,
  picturesURL: [ { args: { format: { literal: PictureFormat._720p } } }, true ],
  price: true,
  pricePerSurface: true,
  soldDate: true,
  currency: true,
} as const;

export default function GetHighlightedProperties(refIds: Array<number>)
{
  return query<Array<Property>>({
    operationName: 'Valuation_GetHighlightedProperties',
    variables: {
      filter: 'Filters__MyBreteuilProperty',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperties: [{
          args: {
            filter: true,
          },
        },
        paginationResultAsList(PropertyFields)],
      }),
    },
  }, {
    filter: { refId: { in: refIds } },
  });
}
