import type { ThemeMode } from '@core/store/Theme';
import type { MyBreteuilTransaction } from './types';

import { Language } from '@core/api/types';
import locales from '@core/store/Locales';
import FR from './fr';
import EN from './en';

export interface Definition
{
  appBar: {
    title: string,
    buttons: {
      managerMenu: string,
      adminMenu: string,
      profile: string,
      logOut: string,
      logIn: string,
      help: string,
      folders: string,
      personalSpace: string,
      analytics: string,
      showcaseMenu: string,
      showcaseMediasList: string,
      showcaseStreamsList: string,
      showcaseScreensList: string,
      showcaseScreensStatus: string,
      marketDataTransactions: string,
      marketDataHeatMap: string,
      marketDataPriceAdjustment: string,
      valuationsPDFs: string,
      agencies: string,
      users: string,
      configVariables: string,
      properties: string,
      valuations: string,
      buyers: string,
      owners: string,
      logs: string,
      entityLogs: string,
      offers: string,
      substituteUser: string,
      property: string,
      prospecting: string,
      propertiesConfidentialLinks: string,
      holidays: string,
      usersAPIRequests: string,
      theme: (mode: ThemeMode) => string,
    },
  },

  drawer: {
    buttons: {
      logIn: string,
      folders: string,
      personalSpace: string,
    },
  },

  tablePagination: {
    formatCurrentRange(currentRange: [number, number], count: number): string,
  },

  reloadPageAfterError: string,
  loading: string,
  error: string,
  noDataToDisplay: string,

  version: string,

  formatUserLogin(
    user: {
      firstname: string | null,
      lastname: string | null,
    }
  ): string,

  formatTransactionStatus(transaction: MyBreteuilTransaction): JSX.Element,
}

export default locales.createResources<Definition>(new Map([
  [ Language.French, FR ],
  [ Language.English, EN ],
]));
