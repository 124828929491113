import * as React from 'react';
import { observer } from 'mobx-react-lite';
import {
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
} from '@mui/material';
import texts from './texts';

export enum AggregationMethod
{
  Sum,
  Avg,
}

export interface AggregationMethodPickerProps
{
  value: AggregationMethod,
  onChange: (newValue: AggregationMethod) => void,
  avgTooltip?: string,
}

const AggregationMethodPicker = observer((props: AggregationMethodPickerProps) => {
  const { value, onChange, avgTooltip } = props;
  const T = texts.current;

  return (
    <ToggleButtonGroup
      value={value}
      onChange={(event, newValue) => {
        if (newValue !== null)
          onChange(newValue);
      }}
      exclusive
      size="small"
    >
      <ToggleButton value={AggregationMethod.Sum}>{T.sum}</ToggleButton>
      <ToggleButton value={AggregationMethod.Avg}>
        <Tooltip title={avgTooltip} disableInteractive>
          <span>{T.avg + (avgTooltip ? '*' : '')}</span>
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
});

export default AggregationMethodPicker;
