import type { AdvancedTableColumnOrder } from '@lib/components/data-table/advanced-table';
import type { OrderField, Filters__Date, Filters__Int } from '@core/api/types';
import type { MyBreteuilUser, MyBreteuilAgency } from './api';

import { observable, computed } from 'mobx';
import {
  subDays,
  subWeeks,
  subMonths,
  subYears,
  startOfYesterday,
  endOfYesterday,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import { OrderType } from '@core/api/types';
import {
  GetLogsAnalytics,
  GetValuations,
  GetWarrants,
  Filters__MyBreteuilLogSimple,
  GetTransactions,
  GetViewings,
  GetReminders,
  GetFeedbacks,
  Filters__MyBreteuilFeedback,
  GetManagedUsers,
  GetManagedAgencies,
} from './api';

export enum LogsType
{
  Valuations = 'Valuations',
  Warrants = 'Warrants',
  Transactions = 'Transactions',
  Viewings = 'Viewings',
  Reminders = 'Reminders',
  Feedbacks = 'Feedbacks',
}
export const RouteParamToLogsType = new Map<string, LogsType>([
  ['valuations', LogsType.Valuations],
  ['warrants', LogsType.Warrants],
  ['transactions', LogsType.Transactions],
  ['viewings', LogsType.Viewings],
  ['reminders', LogsType.Reminders],
  ['feedbacks', LogsType.Feedbacks],
]);
export const LogsTypeToRouteParam = new Map<LogsType, string>(Array.from(RouteParamToLogsType.entries()).map(([key, value]) => [value, key]));

export enum StartDateType
{
  Yesterday = 'Yesterday',
  D = 'D',
  W = 'W',
  M = 'M',
  M3 = 'M3',
  M6 = 'M6',
  Y = 'Y',
  DTD = 'DTD',
  WTD = 'WTD',
  MTD = 'MTD',
  QTD = 'QTD',
  YTD = 'YTD',
}
const StartDateTypeToDateFilterGetter = new Map<StartDateType, () => Filters__Date>([
  [StartDateType.Yesterday, () => ({ gte: startOfYesterday().valueOf(), lte: endOfYesterday().valueOf() })],
  [StartDateType.D, () => ({ gte: subDays(Date.now(), 1).valueOf(), lte: Date.now() })],
  [StartDateType.W, () => ({ gte: subWeeks(Date.now(), 1).valueOf(), lte: Date.now() })],
  [StartDateType.M, () => ({ gte: subMonths(Date.now(), 1).valueOf(), lte: Date.now() })],
  [StartDateType.M3, () => ({ gte: subMonths(Date.now(), 3).valueOf(), lte: Date.now() })],
  [StartDateType.M6, () => ({ gte: subMonths(Date.now(), 6).valueOf(), lte: Date.now() })],
  [StartDateType.Y, () => ({ gte: subYears(Date.now(), 1).valueOf(), lte: Date.now() })],
  [StartDateType.DTD, () => ({ gte: startOfDay(Date.now()).valueOf(), lte: Date.now() })],
  [StartDateType.WTD, () => ({ gte: startOfWeek(Date.now()).valueOf(), lte: Date.now() })],
  [StartDateType.MTD, () => ({ gte: startOfMonth(Date.now()).valueOf(), lte: Date.now() })],
  [StartDateType.QTD, () => ({ gte: startOfQuarter(Date.now()).valueOf(), lte: Date.now() })],
  [StartDateType.YTD, () => ({ gte: startOfYear(Date.now()).valueOf(), lte: Date.now() })],
]);

export class LogsStore
{
  @observable private _logsType: LogsType = LogsType.Valuations;

  public set logsType(type: LogsType)
  {
    this._logsType = type;
  }

  public get logsType()
  {
    return this._logsType;
  }

  public agents = new Fetchable(GetManagedUsers, { catchUnhandled: handleNonCriticalError });
  public agencies = new Fetchable(GetManagedAgencies, { catchUnhandled: handleNonCriticalError });

  @observable public selectedUsers: Array<MyBreteuilUser> = [];
  @observable public selectedAgencies: Array<MyBreteuilAgency> = [];

  @computed get userIdFilter(): Filters__Int | undefined
  {
    const agencyUsersIds = this.selectedAgencies.map(agency => agency.usersIds).flat();
    const usersIds = this.selectedUsers.map(u => u.id);
    const ids = [...usersIds, ...agencyUsersIds];
    if (ids.length <= 0)
      return undefined;
    return { in: ids };
  }

  @observable private _startDateType: StartDateType = StartDateType.YTD;

  public set startDateType(type: StartDateType)
  {
    this._startDateType = type;
  }

  public get startDateType()
  {
    return this._startDateType;
  }

  @computed get dateFilter()
  {
    const dateFilterGetter = StartDateTypeToDateFilterGetter.get(this._startDateType);
    if (dateFilterGetter === undefined)
      throw new Error(`Unsupported startDateType: ${this._startDateType}`);
    return dateFilterGetter();
  }

  public logsAnalytics = new Fetchable(() => GetLogsAnalytics({
    valuationsFilter: { date: this.dateFilter, userId: this.userIdFilter },
    warrantsFilter: { ...this._warrantsFilter, date: this.dateFilter, userId: this.userIdFilter },
    transactionsFilter: { authenticDeedDate: this.dateFilter, userId: this.userIdFilter },
    viewingsFilter: { date: this.dateFilter, userId: this.userIdFilter },
    remindersFilter: { date: this.dateFilter, userId: this.userIdFilter },
    feedbacksFilter: { ...this._feedbacksFilter, date: this.dateFilter, userId: this.userIdFilter },
  }), { catchUnhandled: handleNonCriticalError });

  public static PageSizeOptions = [50, 100, 250, 500];
  private static DefaultPageSize = 50;

  private _valuationsOrder: OrderField | null = null;
  public set valuationOrder(order: AdvancedTableColumnOrder<string> | null)
  {
    this._valuationsOrder = order && {
      fieldName: order.column,
      ordering: order.desc ? OrderType.DESC : OrderType.ASC,
    };
  }
  public valuations = new UncachedPagination({
    fetch: (baseArgs) => GetValuations({
      ...baseArgs,
      filter: { date: this.dateFilter, userId: this.userIdFilter },
      orderBy: this._valuationsOrder ? [this._valuationsOrder] : undefined,
    }),
    pageSize: LogsStore.DefaultPageSize,
  });

  private _warrantsFilter: Filters__MyBreteuilLogSimple = {};
  public set warrantsFilter(newFilter: Filters__MyBreteuilLogSimple)
  {
    const newFilterStringified = JSON.stringify(newFilter);
    if (newFilterStringified !== JSON.stringify(this._warrantsFilter))
      this._warrantsFilter = JSON.parse(newFilterStringified);
  }
  private _warrantsOrder: OrderField | null = null;
  public set warrantsOrder(order: AdvancedTableColumnOrder<string> | null)
  {
    this._warrantsOrder = order && {
      fieldName: order.column,
      ordering: order.desc ? OrderType.DESC : OrderType.ASC,
    };
  }
  public warrants = new UncachedPagination({
    fetch: (baseArgs) => GetWarrants({
      ...baseArgs,
      filter: {
        ...this._warrantsFilter,
        date: this.dateFilter,
        userId: this.userIdFilter,
      },
      orderBy: this._warrantsOrder ? [this._warrantsOrder] : undefined,
    }),
    pageSize: LogsStore.DefaultPageSize,
  });

  private _transactionsOrder: OrderField | null = null;
  public set transactionsOrder(order: AdvancedTableColumnOrder<string> | null)
  {
    this._transactionsOrder = order && {
      fieldName: order.column,
      ordering: order.desc ? OrderType.DESC : OrderType.ASC,
    };
  }
  public transactions = new UncachedPagination({
    fetch: (baseArgs) => GetTransactions({
      ...baseArgs,
      filter: { authenticDeedDate: this.dateFilter, userId: this.userIdFilter },
      orderBy: this._transactionsOrder ? [this._transactionsOrder] : undefined,
    }),
    pageSize: LogsStore.DefaultPageSize,
  });

  private _viewingsOrder: OrderField | null = null;
  public set viewingsOrder(order: AdvancedTableColumnOrder<string> | null)
  {
    this._viewingsOrder = order && {
      fieldName: order.column,
      ordering: order.desc ? OrderType.DESC : OrderType.ASC,
    };
  }
  public viewings = new UncachedPagination({
    fetch: (baseArgs) => GetViewings({
      ...baseArgs,
      filter: { date: this.dateFilter, userId: this.userIdFilter },
      orderBy: this._viewingsOrder ? [this._viewingsOrder] : undefined,
    }),
    pageSize: LogsStore.DefaultPageSize,
  });

  private _remindersOrder: OrderField | null = null;
  public set remindersOrder(order: AdvancedTableColumnOrder<string> | null)
  {
    this._remindersOrder = order && {
      fieldName: order.column,
      ordering: order.desc ? OrderType.DESC : OrderType.ASC,
    };
  }
  public reminders = new UncachedPagination({
    fetch: (baseArgs) => GetReminders({
      ...baseArgs,
      filter: { date: this.dateFilter, userId: this.userIdFilter },
      orderBy: this._remindersOrder ? [this._remindersOrder] : undefined,
    }),
    pageSize: LogsStore.DefaultPageSize,
  });

  private _feedbacksFilter: Filters__MyBreteuilFeedback = {};
  public set feedbacksFilter(newFilter: Filters__MyBreteuilFeedback)
  {
    const newFilterStringified = JSON.stringify(newFilter);
    if (newFilterStringified !== JSON.stringify(this._feedbacksFilter))
      this._feedbacksFilter = JSON.parse(newFilterStringified);
  }
  private _feedbacksOrder: OrderField | null = null;
  public set feedbacksOrder(order: AdvancedTableColumnOrder<string> | null)
  {
    this._feedbacksOrder = order && {
      fieldName: order.column,
      ordering: order.desc ? OrderType.DESC : OrderType.ASC,
    };
  }
  public feedbacks = new UncachedPagination({
    fetch: (baseArgs) => GetFeedbacks({
      ...baseArgs,
      filter: {
        ...this._feedbacksFilter,
        date: this.dateFilter,
        userId: this.userIdFilter,
      },
      orderBy: this._feedbacksOrder ? [this._feedbacksOrder] : undefined,
    }),
    pageSize: LogsStore.DefaultPageSize,
  });

  @computed public get currentPagination()
  {
    switch (this._logsType)
    {
      case LogsType.Valuations: return this.valuations;
      case LogsType.Warrants: return this.warrants;
      case LogsType.Transactions: return this.transactions;
      case LogsType.Viewings: return this.viewings;
      case LogsType.Reminders: return this.reminders;
      case LogsType.Feedbacks: return this.feedbacks;
      default: throw new Error(`No pagination associated with LogsType: ${this._logsType}`);
    }
  }

  public refresh()
  {
    return Promise.all([
      this.currentPagination.first(),
      this.logsAnalytics.ensureSuccessReload(),
      this.agencies.ensureSuccess(),
      this.agents.ensureSuccess(),
    ]);
  }
}

export default new LogsStore();
