import { computed } from 'mobx';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { timestampToDay } from '@repo-lib/utils-core';
import { adjustDateToTimezone } from '@repo-lib/utils-date-tz';
import { getWorkingDaysInRange } from '@repo-lib/utils-date-holidays';
import { assert, ensureFetchableResource, handleCriticalError } from '@repo-breteuil/front-error';
import { DefaultHolidaysCountry } from '@core/constants/timezones';
import UserDayOffRequestDialogStore from '@my-breteuil/store/ui/common/user-day-off-request-dialog';
import {
  GetUserDayOffRequests, type UserDayOffRequest_GetUserDayOffRequests,
  GetUserDayOffBalances,
  CreateUserDayOffRequest, type CreateUserDayOffRequestArgs,
} from './api';
import profileStore from './index';

export function getDateUTCDay(date: Date)
{
  const dateUTC = adjustDateToTimezone(date, {
    fromTimezone: 'local',
    toTimezone: 'UTC',
  });
  return timestampToDay(dateUTC.valueOf());
}

class DayOffRequestsStore
{
  // TODO merge both queries to have one Fetchable
  public dayOffRequests = new Fetchable(GetUserDayOffRequests, { catchUnhandled: handleCriticalError });
  public dayOffBalances = new Fetchable(GetUserDayOffBalances, { catchUnhandled: handleCriticalError });

  @computed get dayOffRequestsByDay()
  {
    assert(this.dayOffRequests.lastResult);
    const res = new Map<number, UserDayOffRequest_GetUserDayOffRequests>();
    for (const request of this.dayOffRequests.lastResult)
    {
      const { startDate, endDate } = request;
      const dates = getWorkingDaysInRange({
        from: new Date(startDate),
        to: new Date(endDate ?? startDate),
        includeTo: true,
        includeSaturdays: true,
        holidaysCountry: DefaultHolidaysCountry,
        // TODO timezone?
      });
      for (const date of dates)
        res.set(timestampToDay(date), request);
    }
    return res;
  }

  public userDayOffRequestDialogStore = new UserDayOffRequestDialogStore();

  public async createDayOffRequest(args: Omit<CreateUserDayOffRequestArgs, 'userId'>)
  {
    const createdRequest = await CreateUserDayOffRequest({ userId: profileStore.currentUser.id, ...args });
    const requests = ensureFetchableResource(this.dayOffRequests);
    this.dayOffRequests.setResult([ ...requests, createdRequest ]);
    await this.dayOffBalances.ensureSuccessReload({ id: profileStore.currentUser.id });
  }

  public async refresh()
  {
    await Promise.all([
      this.dayOffRequests.ensureSuccessReload({ id: profileStore.currentUser.id }),
      this.dayOffBalances.ensureSuccessReload({ id: profileStore.currentUser.id }),
    ]);
  }
}

export default new DayOffRequestsStore();
