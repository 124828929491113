import type { IdType } from '@repo-breteuil/common-definitions';
import type { Language } from '@core/api/types';
import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface Criteria
{
  id: IdType,
  webName: string,
}

export interface GetMatchesCriteriaArgs
{
  language: Language,
}

export default function GetMatchesCriteria(args: GetMatchesCriteriaArgs)
{
  return query<Array<Criteria>>({
    operationName: 'Property_Matches_GetMatchesCriteria',
    variables: {
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilCriteria: paginationResultAsList({
          id: true,
          webName: [{ args: { language: true } }, true],
        }),
      }),
    },
  }, args);
}
