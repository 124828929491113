import type { IdType } from '@repo-breteuil/common-definitions';
import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';
import { type PropertyConfidentialLink, PropertyConfidentialLinkFields } from './shared';

export interface CreatePropertyConfidentialLinkArgs
{
  propertyId: IdType,
  contactId: IdType,
  comment?: string | undefined,
}

export default function CreatePropertyConfidentialLink(args: CreatePropertyConfidentialLinkArgs) {
  return mutation<PropertyConfidentialLink>({
    operationName: 'Common_PropertyConfidentialLinkDialog_CreateConfidentialLink',
    variables: {
      propertyId: 'Int!',
      contactId: 'Int!',
      comment: 'String',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        propertyConfidentialLink: {
          create: [{
            args: {
              propertyId: true,
              contactId: true,
              comment: true,
            },
          }, PropertyConfidentialLinkFields],
        },
      }),
    },
  }, args);
}
