import type { IdType } from '@repo-breteuil/common-definitions';
import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { MyBreteuilPropertyValuationPDFFields, type MyBreteuilPropertyValuationPDF } from './shared';

export default function Valuation_GetPropertyUserValuationPDF(
  args: {
    slug: string,
    userId: IdType,
  },
)
{
  return query<MyBreteuilPropertyValuationPDF | null>({
    operationName: 'Valuation_GetPropertyUserValuationPDF',
    variables: {
      slug: 'String!',
      userId: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: [{
          args: {
            slug: true,
          },
        }, {
          userValuationPDF: [{
            args: {
              userId: true,
            },
          }, MyBreteuilPropertyValuationPDFFields],
        }],
      }),
    },
  }, args);
}
