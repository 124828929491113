import { errorWrapper, paginationResultAsList, query } from '@core/api/graphql/gen-v2';
import { type MyBreteuilUser, MyBreteuilUserFields, GetVisiblePropertyUsersFieldArgs } from './GetVisiblePropertyUsers';
import { type MyBreteuilPhotographer, MyBreteuilPhotographerFields } from './GetPhotographers';

export interface InitQueryResult
{
  visiblePropertyUsers: Array<MyBreteuilUser>,
  photographers: Array<MyBreteuilPhotographer>,
}

export default function InitQuery()
{
  return query<InitQueryResult>({
    operationName: 'Properties_InitQuery',
    fieldsSelection: {
      authenticated: errorWrapper({
        visiblePropertyUsers: [{
          args: GetVisiblePropertyUsersFieldArgs,
          aliasedField: 'users',
        }, paginationResultAsList(MyBreteuilUserFields) ],
        photographers: [{
          aliasedField: 'myBreteuilPhotographers',
        }, paginationResultAsList(MyBreteuilPhotographerFields)],
      }),
    },
  });
}
