import type { IdType } from '@repo-breteuil/common-definitions';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export type RemoveNegativePropertyFeedbackArgs = {
  id: IdType,
  propertyId: IdType,
  propertyPrice?: number | undefined,
}

export default function RemoveNegativePropertyFeedback(args: RemoveNegativePropertyFeedbackArgs)
{
  return mutation<{ id: IdType }>({
    operationName: 'Common_ReminderDialog_RemoveNegativePropertyFeedback',
    variables: {
      id: "Int!",
      propertyId: "Int!",
      propertyPrice: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: {
          removeNegativePropertyFeedback: [{
            args: {
              id: true,
              propertyId: true,
              propertyPrice: true,
            },
          }, { id: true }],
        },
      }),
    },
  }, args);
}
