import type { IdType } from '@repo-breteuil/common-definitions';

import type { ViewingOrigin, ViewingType } from '@core/api/types';
import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilProperty
{
  id: IdType,
  title: string,
  contacts: Array<{
    id: IdType,
    fullname: string,
    emails: Array<string>,
    phones: Array<string>,
  }>,
}

export interface MyBreteuilContact
{
  id: IdType,
  fullname: string,
  emails: Array<string>,
  phones: Array<string>,
}

export interface MyBreteuilUser
{
  id: IdType,
  fullname: string,
}

export interface MyBreteuilViewing
{
  id: IdType,
  origin: ViewingOrigin,
  type: ViewingType,
  date: number/*timestamp*/,
  endDate: number/*timestamp*/,
  mybConfirmation: boolean,
  comments: string,
  property: MyBreteuilProperty,
  contact: MyBreteuilContact | null,
  mybNom: string | null,
  telephone: string | null,
  user: MyBreteuilUser,
  positiveFeedback: string | null,
  negativeFeedback: string | null,
}

export interface GetViewingVariables
{
  id: IdType,
}

export default function GetViewing(variables: GetViewingVariables)
{
  return query<MyBreteuilViewing>({
    operationName: 'Common_ViewingDialog_GetViewing',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilViewing: [{
          args: {
            id: true,
          },
        }, {
          id: true,
          origin: true,
          type: true,
          date: true,
          endDate: true,
          mybConfirmation: true,
          comments: true,
          property: {
            id: true,
            title: true,
            contacts: paginationResultAsList({
              id: true,
              fullname: true,
              emails: true,
              phones: true,
            }),
          },
          contact: {
            id: true,
            fullname: true,
            emails: true,
            phones: true,
          },
          mybNom: true,
          telephone: true,
          user: {
            id: true,
            fullname: true,
          },
          positiveFeedback: true,
          negativeFeedback: true,
        }],
      }),
    },
  }, variables);
}
