import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { Language, SearchAreaLevel } from '@core/api/types';

import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export interface GeoArea
{
  id: IdType,
  parentId: IdType | null,
  displayName: string,
  alphaNumNameOrderKey: string,
  searchAreaLevel: SearchAreaLevel | null,
  searchAreaDisplayOrder: number | null,
  searchAreaDefaultExpanded: boolean,
  yearlyTransactionsPricePerSurface: Array<{
    year: number,
    pricePerSurface: number,
  }>,
  valuationPrice: number | null,
}

const GeoAreaFields = {
  id: true,
  parentId: true,
  displayName: [ { args: { language: true } }, true ],
  alphaNumNameOrderKey: true,
  searchAreaLevel: true,
  searchAreaDisplayOrder: true,
  searchAreaDefaultExpanded: true,
  yearlyTransactionsPricePerSurface: {
    year: true,
    pricePerSurface: true,
  },
  valuationPrice: { avg: true },
} as const satisfies ReadonlyNodeFields;

export interface GetMarketDataAreasArgs
{
  language: Language,
}

export default async function GetMarketDataAreas(args: GetMarketDataAreasArgs)
{
  return query<Array<GeoArea>>({
    operationName: 'MarketData_PriceAdjustment_GetMarketDataAreas',
    variables: {
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        marketDataAreas: GeoAreaFields,
      }),
    },
  }, args);
}
