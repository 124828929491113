import { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { PropertyConfidentialLinkStatus } from '@core/api/types';

export interface PropertyConfidentialLink
{
  id: IdType,
  contactId: IdType,
  propertyId: IdType,
  status: PropertyConfidentialLinkStatus,
  propertyUrl: string,
}

export const PropertyConfidentialLinkFields: ReadonlyNodeFields = {
  id: true,
  contactId: true,
  propertyId: true,
  status: true,
  propertyUrl: true,
};
