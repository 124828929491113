import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { CreatePropertyVariables } from './CreateProperty';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';
import { CreatePropertyArgs } from './CreateProperty';

// TODO move somewhere else
type PartialNullable<T> = {
  [P in keyof T]?: T[P] | null | undefined;
};

export interface UpdatePropertyCustomVariables
{
  geoAreaId?: IdType | null | undefined,
}

const UpdatePropertyCustomVariablesFields: ReadonlyNodeFields = {
  geoAreaId: true,
};

export interface UpdatePropertyVariables extends PartialNullable<CreatePropertyVariables>, UpdatePropertyCustomVariables
{
  id: IdType,
}

export default function UpdateProperty(variables: UpdatePropertyVariables)
{
  return mutation<{ id: IdType, slug: string }>({
    operationName: 'Property_Info_UpdateProperty',
    variables: {
      id: 'Int!',
      title: 'String',
      price: 'Int',
      priceMax: 'Int',
      priceOnRequest: 'Boolean',
      status: 'MyBreteuilPropertyStatus',
      displayedStatus: 'MyBreteuilPropertyStatus',
      availabilityDate: 'Date',
      agencyId: 'Int',
      warrantNumber: 'String',
      warrantDate: 'Date',
      warrantEndDate: 'Date',
      exclusive: 'Boolean',
      displayedExclusive: 'Boolean',
      verifiedWarrant: 'Boolean',
      address: 'String',
      geoAreaId: 'Int',
      userIds: '[Int!]',
      origine: 'String',
      contactIds: '[Int!]',
      operationType: 'MyBreteuilOperationType',
      myb_reference: 'String',
      registrationNumber: 'String',
      favoriteStatus: 'PropertyFavoriteStatus',
      myb_entree: 'Boolean',
      companyManagementBroughtDeal: 'Boolean',
      marketConsistent: 'Boolean',
      descriptionFR: 'String',
      descriptionEN: 'String',
      descriptionPT: 'String',
      descriptionES: 'String',
      myb_description_smsFR: 'String',
      myb_description_smsEN: 'String',
      myb_description_smsPT: 'String',
      myb_description_smsES: 'String',
      quoteDescriptionFR: 'String',
      quoteDescriptionEN: 'String',
      quoteDescriptionPT: 'String',
      quoteDescriptionES: 'String',
      practicalInformations: 'String',
      collectiveOwnership: 'Boolean',
      lots: 'Int',
      collectiveOwnershipCharges: 'Float',
      myb_taxe_fonciere: 'Float',
      fees: 'Float',
      feesPercentage: 'Float',
      feesPaidByBuyer: 'Boolean',
      rentalExpenses: 'Float',
      rentalExpensesType: 'MyBreteuilPropertyRentalExpensesTypeEnum',
      rentalDeposit: 'Float',
      myb_locatif_legal: 'BreteuilPropertyLawFulRental',
      guestsCount: 'Int',
      keyInfosIds: '[Int!]',
      surroundings: '[MyBreteuilPropertySurroundingWithDistance!]',
      rentalOwnerIntermediary: 'Float',
      rentalAdministrationFees: 'Float',
      rentalOwnerAdministrationFees: 'Float',
      rentalInventory: 'Float',
      rentalOwnerInventory: 'Float',
      surface: 'Float',
      surfaceUnit: 'BreteuilPropertySurfaceUnit',
      bedrooms: 'Int',
      floor: 'Int',
      floorsCount: 'Int',
      propertyType: 'MyBreteuilPropertyType',
      constructionYear: 'Int',
      criteriaIds: '[Int!]',
      lotSize: 'Float',
      rooms: 'Int',
      bathrooms: 'Int',
      toilets: 'Int',
      balconyCount: 'Int',
      ceilingHeight: 'Float',
      heating: 'MyBreteuilHeating',
      waterHeatType: 'MyBreteuilWaterHeating',
      usage: 'BreteuilPropertyUsage',
      disponibility: 'MyBreteuilPropertyDisponibilityEnum',
      orientation: 'Orientation',
      sectorization: 'String',
      transports: 'String',
      buildingType: 'String',
      dpe: 'Float',
      dpeCarbonFootprint: 'Int',
      dpeKwhRatingOverride: 'DPERating',
      dpeCo2RatingOverride: 'DPERating',
      dpeCreationDate: 'Date',
      dpeSimulationReferenceDate: 'Date',
      dpeAnnualCost: 'Float',
      inflatedRent: 'Float',
      rentControl: 'Boolean',
      taxIdentificationNumber: 'String',
      videoURL: 'String',
      myb_photographer_id: 'Int',
      publication: 'MyBreteuilPropertyVisibility',
      forceSendable: 'Boolean',
      publicationPlatformsAgencyId: 'Int',
      publicationPlatforms: 'PublicationPlatforms',
      view: 'BreteuilWebsiteViewType',
      renovation: 'BreteuilWebsiteRenovationType',
      balconySurface: 'Float',
      addressPricePerSurfaceDifferenceFromSubDistrict: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: {
          update: errorWrapper([{
            args: {
              id: true,
              ...CreatePropertyArgs,
              ...UpdatePropertyCustomVariablesFields,
            },
          }, {
            id: true,
            slug: true,
          }]),
        },
      }),
    },
  }, variables);
}
