import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface CreateContactCommentArgs
{
  contactId: IdType,
  message: string,
}

export default function CreateContactComment(args: CreateContactCommentArgs)
{
  return mutation<IdType>({
    operationName: 'Contact_CreateContactComment',
    variables: {
      contactId: 'Int!',
      message: 'String!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContactComment: {
          create: [{
            args: {
              contactId: true,
              message: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, args);
}
