import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';
import { ManualPropertiesInfoErrorFields, type ManualPropertiesInfoError } from './common';

export interface GenManualPropertiesInfoWhatsAppUrlsArgs
{
  contactId?: IdType | null | undefined,
  targetSMS?: string | null | undefined,
  propertyIds: Array<IdType>,
}

export default function GenManualPropertiesInfoWhatsAppUrls(args: GenManualPropertiesInfoWhatsAppUrlsArgs)
{
  return mutation<{
    errors: Array<ManualPropertiesInfoError> | null,
    trackedUrls: Array<string> | null,
  }>({
    operationName: 'Common_MessageDrawer_GenManualPropertiesInfoWhatsAppUrls',
    variables: {
      contactId: 'Int',
      targetSMS: 'String',
      propertyIds: '[Int!]!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          agents: {
            genManualPropertiesInfoWhatsAppUrls: [{
              args: {
                contactId: true,
                targetSMS: true,
                propertyIds: true,
              },
              preventUnwrap: true,
            }, {
              errors: ManualPropertiesInfoErrorFields,
              trackedUrls: true,
            }],
          },
        },
      }),
    },
  }, args);
}
