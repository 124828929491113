import type { IdType } from '@repo-breteuil/common-definitions';
import { isNonNull } from '@repo-lib/utils-core';
import { HierarchyTree } from '@repo-lib/utils-hierarchy-tree';
import { handleCriticalError } from '@repo-breteuil/front-error';
import {
  NodeType,
  type UserHierarchyNode,
  type UserWithAgency,
  type GeoArea,
} from './types';
import {
  makeAreaIdentifier,
  makeAgencyIdentifier,
  makeUserIdentifier,
  parseNodeTypeIdentifier,
} from './identifiers';

export function genAreasNodes(areas: ReadonlyArray<GeoArea>)
{
  const orderedAreas = areas.slice().sort((lhs, rhs) => ((lhs.agencyAreaDisplayOrder || 0) - (rhs.agencyAreaDisplayOrder || 0)));
  const nodes: Array<UserHierarchyNode> = [];
  for (const area of orderedAreas)
  {
    const { id, parentId, displayName, agencyAreaDefaultExpanded, agencyAreaDisplayOrder } = area;
    const parentIdentifier = parentId ? makeAreaIdentifier(parentId) : null;
    nodes.push({
      identifier: makeAreaIdentifier(id),
      parentIdentifier,
      name: displayName,
      defaultExpanded: agencyAreaDefaultExpanded,
      ignore: agencyAreaDisplayOrder === null,
    });
  }
  return nodes;
}

// Used in the hierarchy to contain users without agencies
export const DummyAgency = {
  id: 0,
  shortName: 'Autres', // TODO texts
  agencyTreeGeoAreaId: null,
};

function genUsersNodes(usersWithAgencies: ReadonlyArray<UserWithAgency>)
{
  const nodes: Array<UserHierarchyNode> = [];

  const addedAgenciesIds = new Set<IdType>();
  const addedUsersIds = new Set<IdType>();
  for (const userHistorical of usersWithAgencies)
  {
    const { user, agency } = userHistorical;
    if (addedUsersIds.has(user.id))
    {
      handleCriticalError(new Error(`Found duplicate userId: ${user.id} while building users nodes`));
      continue;
    }
    addedUsersIds.add(user.id);
    const effectiveAgency = agency && agency.agencyTreeGeoAreaId !== null ? agency : DummyAgency;
    const agencyIdentifier = makeAgencyIdentifier(effectiveAgency.id);
    nodes.push({
      identifier: makeUserIdentifier(user.id),
      parentIdentifier: agencyIdentifier,
      name: user.fullname,
      defaultExpanded: false,
      ignore: false,
    });
    if (addedAgenciesIds.has(effectiveAgency.id))
      continue;
    addedAgenciesIds.add(effectiveAgency.id);
    nodes.push({
      identifier: agencyIdentifier,
      parentIdentifier: effectiveAgency.agencyTreeGeoAreaId ? makeAreaIdentifier(effectiveAgency.agencyTreeGeoAreaId) : null,
      name: effectiveAgency.shortName,
      defaultExpanded: false,
      ignore: false,
    });
  }

  return nodes;
}

export function genNodes(
  areas: ReadonlyArray<GeoArea>,
  users: ReadonlyArray<UserWithAgency>,
): Array<UserHierarchyNode>
{
  return [
    ...genAreasNodes(areas),
    ...genUsersNodes(users),
  ];
}

// Mostly used with filterNodesBranches option to strip out branches without agencies.
export function getNodesAgenciesIdentifiers(nodes: Array<UserHierarchyNode>)
{
  return new Set(nodes.map((node) => (
    parseNodeTypeIdentifier(node.identifier) === NodeType.Agency ? node.identifier : null
  )).filter(isNonNull));
}

export function makeUserHierarchyTree(nodes: Array<UserHierarchyNode>)
{
  const agenciesNodesIdentifiers = getNodesAgenciesIdentifiers(nodes);
  return new HierarchyTree(nodes, {
    filterNodes: node => !node.ignore,
    filterNodesBranches: agenciesNodesIdentifiers,
  });
}
