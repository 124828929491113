import type { IdType } from '@repo-breteuil/common-definitions';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

async function DeleteShowcaseScreen(id: IdType): Promise<void>
{
  await mutation({
    operationName: 'Showcase_ScreensList_DeleteShowcaseScreen',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseScreen: {
          delete: errorWrapper([{
            args: {
              id: true,
            },
          }, true]),
        },
      }),
    },
  }, { id });
}

export default DeleteShowcaseScreen;
