import type { Definition } from '.';
import type { MyBreteuilTransaction } from './types';

import * as React from 'react';
import { useTheme } from '@mui/material';
import { TransactionStatusColored } from '@my-breteuil/components/common/elements';

export default {
  appBar: {
    title: "MyBreteuil Dashboard",
    buttons: {
      managerMenu: "Manageur",
      adminMenu: "Admin",
      profile: "Profil",
      logOut: "Déconnexion",
      logIn: "Connexion",
      help: "Aide",
      folders: "Gestion des folders",
      personalSpace: "Documents",
      analytics: "Analytics",
      showcaseMenu: "Vitrines",
      showcaseMediasList: "Medias",
      showcaseStreamsList: "Flux",
      showcaseScreensList: "Ecrans",
      showcaseScreensStatus: "Activité des écrans",
      marketDataTransactions: "Transactions",
      marketDataHeatMap: "Heat Map",
      marketDataPriceAdjustment: "Ajuster prix/m²",
      valuationsPDFs: "Estimations PDFs",
      agencies: "Agences",
      users: "Blueprint agent",
      configVariables: "Variables de configuration",
      properties: "Propriétés",
      valuations: "Estimations",
      buyers: "Acquéreurs",
      owners: "Propriétaires",
      logs: "Logs (ancienne version)",
      entityLogs: "Logs",
      offers: "Offres et Transactions",
      substituteUser: "Connexion à un autre agent",
      property: "Propriété",
      prospecting: "Terrain",
      propertiesConfidentialLinks: "Liens confidentiels",
      holidays: "Congés",
      usersAPIRequests: "Logs API",
      theme: (mode) => `Mode ${mode === 'light' ? 'sombre' : 'clair'}`,
    },
  },

  drawer: {
    buttons: {
      logIn: "Connexion",
      folders: "Folders",
      personalSpace: "Espace Personnel",
    },
  },

  tablePagination: {
    formatCurrentRange(currentRange: [number, number], count: number)
    {
      const [ from, to ] = currentRange;
      if (count === 0)
        return "0 éléments";
      return `${from + 1} - ${to + 1} sur ${count}`;
    },
  },

  reloadPageAfterError: "Essayez de recharger la page",
  loading: "Chargement...",
  error: "Erreur",
  noDataToDisplay: "Pas de données à afficher",

  version: "Version",

  formatUserLogin(
    user: {
      firstname: string | null,
      lastname: string | null,
    },
  )
  {
    const { firstname, lastname } = user;
    if (!firstname || !lastname)
      return '';
    return firstname.charAt(0).toLowerCase() + lastname.toLowerCase();
  },

  formatTransactionStatus: (transaction: MyBreteuilTransaction) =>
  {
    const theme = useTheme();
    if (transaction.cancelledOffer) {
      return <TransactionStatusColored status="Annulée" color={theme.colors.transactionStatus.cancelled}/>;
    }
    else if (transaction.invoiceSettled) {
      return <TransactionStatusColored status="Encaissée" color={theme.colors.transactionStatus.paimentValidated}/>;
    }
    else if (transaction.validateInvoice) {
      return <TransactionStatusColored status="Validée" color={theme.colors.transactionStatus.validated}/>;
    }
    else if (transaction.authenticDeedDate) {
      return <TransactionStatusColored status="Acte" color={theme.colors.transactionStatus.bill}/>;
    }
    else if (transaction.compromiseDate) {
      return <TransactionStatusColored status="Compromis" color={theme.colors.transactionStatus.agreement}/>;
    }
    else if (transaction.acceptedOfferDate) {
      return <TransactionStatusColored status="Acceptée" color={theme.colors.transactionStatus.accepted}/>;
    }
    else {
      return <TransactionStatusColored status="Offre faite" color={theme.colors.transactionStatus.offre}/>;
    }
  },
} satisfies Definition;
