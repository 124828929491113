import type { IdType } from '@repo-breteuil/common-definitions';
import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export default function GetUserDailyReport(userId: IdType)
{
  return query<{ id: IdType, date: number } | null>({
    operationName: 'Common_DailyReportBanner_GetUserDailyReport',
    variables: {
      userId: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        userDailyReport: [{
          args: { userId: true },
        }, {
          id: true,
          date: true,
        }],
      }),
    },
  }, { userId });
}
