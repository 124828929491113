import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs } from '@repo-lib/graphql-query-builder';
import type { User } from './shared';

import { errorWrapper, mutation } from '@core/api/graphql/gen-v2';
import { UserFields } from './shared';

export interface SetUserDayOffBalanceArgs
{
  id: IdType,
  year: number,
  paidLeaveDays?: number | null | undefined,
  toilDays?: number | null | undefined,
}

const SetUserDayOffBalanceArgsFields: ReadonlyFieldArgs = {
  id: true,
  year: true,
  paidLeaveDays: true,
  toilDays: true,
};

const SetUserDayOffBalanceVariables: Readonly<Record<string, string>> = {
  id: 'Int!',
  year: 'Int!',
  paidLeaveDays: 'Float',
  toilDays: 'Float',
};

export default function SetUserDayOffBalance(args: SetUserDayOffBalanceArgs)
{
  return mutation<User>({
    operationName: 'Users_SetUserDayOffBalance',
    variables: SetUserDayOffBalanceVariables,
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilUser: {
          setUserDayOffBalance: [{
            args: SetUserDayOffBalanceArgsFields,
          }, UserFields],
        },
      }),
    },
  }, args);
}
