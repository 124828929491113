import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import { errorWrapper, query } from '@core/api/graphql/gen-v2';

export interface Contact
{
  id: IdType,
  fullname: string,
  lastname?: string,
  emails: Array<string>,
  phones: Array<string>,
}

const ContactFields: ReadonlyNodeFields = {
  id: true,
  fullname: true,
  lastname: true,
  emails: true,
  phones: true,
};

export default function GetContact(id: IdType)
{
  return query<Contact>({
    operationName: 'Common_PropertyConfidentialLinkDialog_GetContact',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: [{
          args: {
            id: true,
          },
        }, ContactFields],
      }),
    },
  }, { id });
}
