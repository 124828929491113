import type { IdType } from '@repo-breteuil/common-definitions';
import type { UserGoalPeriod } from '@core/api/types';

import { pickKeysFromObject } from '@repo-lib/utils-core';
import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { UserDayTaskRecapFields, type UserDayTaskRecap } from './GetUserDayTaskRecap';

const PickedKeys = [
  'acceptedOffersHT',
  'successfulPhoneReminders',
  'successfulWhatsAppReminders',
  'madeOffers',
  'mandates',
  'valuations',
  'prospectedStreets',
  'viewings',
] as const;

type PickedKeysType = typeof PickedKeys[number];

export type UserTasksRecap = Pick<UserDayTaskRecap, PickedKeysType>;

export const UserTasksRecapFields = pickKeysFromObject(UserDayTaskRecapFields, [ ...PickedKeys ]);

export interface GetUserTasksRecapArgs
{
  userId: IdType,
  period: UserGoalPeriod,
  previousPeriod?: number | undefined,
}

export default async function GetUserTasksRecap(args: GetUserTasksRecapArgs)
{
  return query<UserTasksRecap>({
    operationName: 'Profile_GetUserTasksRecap',
    variables: {
      userId: 'Int!',
      period: 'UserGoalPeriod!',
      previousPeriod: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        userTasksRecap: [{
          args: {
            userId: true,
            period: true,
            previousPeriod: true,
          },
        }, UserTasksRecapFields],
      }),
    },
  }, args);
}
