import type { Filters__UserAPIRequest } from './api';

import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleCriticalError } from '@repo-breteuil/front-error';
import localesStore from '@core/store/Locales';
import {
  GetUsersAPIRequests,
  GetManagedUsers,
  GetManagedAgencies,
} from './api';

class UsersAPIRequestsStore
{
  private static UsersAPIRequestsDefaultPageSize = 500;
  public readonly UsersAPIRequestsPageSizeOptions = [ 100, 200, 500, 1000 ];

  public usersAPIRequestsFilters: Filters__UserAPIRequest = {};

  public async setUsersAPIRequestsFiltersAndRefresh(newFilters: Filters__UserAPIRequest)
  {
    this.usersAPIRequestsFilters = newFilters;
    await this.usersAPIRequests.first();
  }

  public usersAPIRequests = new UncachedPagination({
    fetch: (baseArgs) => GetUsersAPIRequests({
      ...baseArgs,
      filter: this.usersAPIRequestsFilters,
      language: localesStore.currentLocale,
    }),
    pageSize: UsersAPIRequestsStore.UsersAPIRequestsDefaultPageSize,
  });

  public managedUsers = new Fetchable(GetManagedUsers, { catchUnhandled: handleCriticalError });
  public managedAgencies = new Fetchable(GetManagedAgencies, { catchUnhandled: handleCriticalError });

  public async refresh()
  {
    await Promise.all([
      this.managedUsers.ensureSuccessReload(),
      this.managedAgencies.ensureSuccessReload(),
      this.usersAPIRequests.first(),
    ]);
  }
}

export default new UsersAPIRequestsStore();
