import type { IdType } from '@repo-breteuil/common-definitions';

import {
  query,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilUser
{
  id: IdType,
  fullname: string,
  contactSearchAgency: {
    id: IdType,
    shortName: string,
    agencyTreeGeoAreaId: IdType | null,
  } | null,
}

export default function GetUsersAssignable()
{
  return query<Array<MyBreteuilUser>>({
    operationName: 'Property_Info_GetUsersAssignable',
    fieldsSelection: {
      authenticated: errorWrapper({
        usersAssignable: {
          id: true,
          fullname: true,
          contactSearchAgency: {
            id: true,
            shortName: true,
            agencyTreeGeoAreaId: true,
          },
        },
      }),
    },
  });
}
