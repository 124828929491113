import type { IdType } from '@repo-breteuil/common-definitions';

import { makeRecord } from '@repo-lib/utils-core';
import { UserDayOffType } from '@core/api/types';
import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export const DayOffBalancesFields = makeRecord(
  Object.values(UserDayOffType).map(type => {
    const stringifiedType = type.toString();
    return [
      stringifiedType,
      [ { aliasedField: 'daysOffBalance', args: { type: { literal: stringifiedType } } }, true ] as const,
    ];
  }),
);

export default function GetUserDayOffBalances(args: { id: IdType })
{
  return query<Record<UserDayOffType, number | null>>({
    operationName: 'Profile_GetUserDayOffBalances',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilUser: [{
          args: {
            id: true,
          },
        }, DayOffBalancesFields ],
      }),
    },
  }, args);
}
