import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationBaseArgs, PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { Filters_HasMany, Filters__Date, Filters__Int, Filters__String, OrderBy } from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationArgs,
  ExtendedPaginationVariables,
} from '@core/api/graphql/gen-v2';

export interface ProspectingAreaStreetActionHistoryStats
{
  fromDate: number/*timestamp*/,
  actionsCount: number,
  informationCountSum: number,
}

const ProspectingAreaStreetActionHistoryStatsFields: ReadonlyNodeFields = {
  fromDate: true,
  actionsCount: true,
  informationCountSum: true,
};

export interface ProspectingAreaStreet
{
  id: IdType,
  name: string,
  userFullname: string,
  user: {
    id: IdType,
    contactSearchAgency: {
      id: IdType,
      name: string,
    } | null,
  } | null,
  actionsHistoryStats: Array<ProspectingAreaStreetActionHistoryStats>,
  createdAt: number/*timestamp*/,
}

const ProspectingAreaStreetFields: ReadonlyNodeFields = {
  id: true,
  name: true,
  userFullname: true,
  user: {
    id: true,
    contactSearchAgency: {
      id: true,
      name: true,
    },
  },
  actionsHistoryStats: ProspectingAreaStreetActionHistoryStatsFields,
  createdAt: true,
};

export interface Filters__ProspectingAreaStreet
{
  name?: Filters__String | undefined,
  userId?: Filters__Int | undefined,
  user?: {
    agencies?: Filters_HasMany<{
      agencyId?: Filters__Int | undefined,
    }> | undefined,
  } | undefined,
  createdAt?: Filters__Date | undefined,
}

export interface GetProspectingStreetsArgs extends PaginationBaseArgs
{
  filter?: Filters__ProspectingAreaStreet | undefined,
  orderBy?: OrderBy | undefined,
}

export default function GetProspectingStreets(args: GetProspectingStreetsArgs)
{
  return query<PaginationExtendedResponse<ProspectingAreaStreet>>({
    operationName: 'Prospecting_GetProspectingStreets',
    variables: ExtendedPaginationVariables('ProspectingAreaStreet'),
    fieldsSelection: {
      authenticated: errorWrapper({
        prospectingAreaStreets: [{
          args: ExtendedPaginationArgs,
        }, paginationResult(ProspectingAreaStreetFields)],
      }),
    },
  }, args);
}
