import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationBaseArgs, PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type {
  MyBreteuilPropertyVisibility,
  Language,
  Filters__Int,
  Filters__Date,
  Filters__String,
} from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationArgs,
  ExtendedPaginationVariables,
} from '@core/api/graphql/gen-v2';

export interface UserAPIRequest
{
  id: IdType,
  date: number,
  operation: string,
  requestId: string,
  originMybUser: {
    id: IdType,
    fullname: string,
  },
  mybUser: {
    id: IdType,
    fullname: string,
  },
  ipAddressAgencyId: IdType, // 0=remote access
  ipAddressAgency: {
    id: IdType,
    name: string,
  } | null,
  propertyInfo: {
    property: {
      id: IdType,
      title: string,
      slug: string,
      ref: string,
      publication: MyBreteuilPropertyVisibility,
      addrStreetAddress: string | null,
      administrativeAreas: {
        city: {
          id: IdType,
          displayName: string,
        } | null,
        district: {
          id: IdType,
          displayName: string,
        } | null,
      },
    },
    requestUserHasViewing: boolean,
  } | null,
}

const UserAPIRequestFields: ReadonlyNodeFields = {
  id: true,
  date: true,
  operation: true,
  requestId: true,
  originMybUser: {
    id: true,
    fullname: true,
  },
  mybUser: {
    id: true,
    fullname: true,
  },
  ipAddressAgencyId: true,
  ipAddressAgency: {
    id: true,
    name: true,
  },
  propertyInfo: {
    property: {
      id: true,
      title: true,
      slug: [{ args: { language: true } }, true],
      ref: true,
      publication: true,
      addrStreetAddress: true,
      administrativeAreas: {
        city: {
          id: true,
          displayName: [{ args: { language: true }  }, true],
        },
        district: {
          id: true,
          displayName: [{ args: { language: true } }, true],
        },
      },
    },
    requestUserHasViewing: true,
  },
};

export interface Filters__UserAPIRequest
{
  date?: Filters__Date | undefined,
  operation?: Filters__String | undefined,
  originMybUserId?: Filters__Int | undefined,
  ipAddressAgencyId?: Filters__Int | undefined,
}

export interface GetUsersAPIRequestsArgs extends PaginationBaseArgs
{
  filter?: Filters__UserAPIRequest | undefined,
  language: Language,
}

export default function GetUsersAPIRequests(args: GetUsersAPIRequestsArgs)
{
  return query<PaginationExtendedResponse<UserAPIRequest>>({
    operationName: 'UsersAPIRequests_GetUsersAPIRequests',
    variables: {
      ...ExtendedPaginationVariables('UserAPIRequest'),
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        usersAPIRequests: [{
          args: ExtendedPaginationArgs,
        }, paginationResult(UserAPIRequestFields)],
      }),
    },
  }, args);
}
