import type { IdType } from '@repo-breteuil/common-definitions';
import type { Language } from '@core/api/types';

import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export type DolceVillaSurrounding = {
  id: IdType,
  content: string,
};

export interface GetSurroundingsArgs
{
  language: Language,
}

export default function GetSurroundings(args: GetSurroundingsArgs)
{
  return query<Array<DolceVillaSurrounding>>({
    operationName: 'Property_Info_GetSurroundings',
    variables: {
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        dolceVillaSurroundings: paginationResultAsList({
          id: true,
          content: [{ args: { language: true } }, true],
        }),
      }),
    },
  }, args);
}
