import type { PaginationConnectionWithCount } from '@repo-lib/graphql-query-pagination';
import { ShowcaseStream, CreateArgs } from './api';

import { action, observable } from 'mobx';
import { GetShowcaseStreams, CreateShowcaseStream } from './api';

class StreamsStore
{
  @observable private _streams: PaginationConnectionWithCount<ShowcaseStream>;

  public get streams()
  {
    return this._streams;
  }

  @action public setStreams(streams: PaginationConnectionWithCount<ShowcaseStream>)
  {
    this._streams = streams;
  }

  public async createStream(args: CreateArgs)
  {
    await CreateShowcaseStream(args);
    await this.refreshStreams();
  }

  public async refreshStreams()
  {
    this.setStreams(await GetShowcaseStreams());
  }

  public async refresh()
  {
    await this.refreshStreams();
  }
}

export default new StreamsStore;
