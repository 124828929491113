import type { IdType } from '@repo-breteuil/common-definitions';

import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilAgency
{
  id: IdType,
  name: string,
}

export default function GetAgencies()
{
  return query<Array<MyBreteuilAgency>>({
    operationName: 'Property_Info_GetAgencies',
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilAgencies: [{
          args: {
            active: { literal: 'true' },
          },
        }, paginationResultAsList({
          id: true,
          name: true,
        })],
      }),
    },
  });
}
