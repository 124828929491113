import type { IdType } from '@repo-breteuil/common-definitions';
import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface RemoveNegativePropertyFeedbackArgs
{
  id: IdType,
  propertyId: IdType,
  propertyPrice?: number | undefined,
}

export default function RemoveNegativePropertyFeedback(args: RemoveNegativePropertyFeedbackArgs)
{
  return mutation<IdType>({
    operationName: 'Contact_RemoveNegativePropertyFeedback',
    variables: {
      id: 'Int!',
      propertyId: 'Int!',
      propertyPrice: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: {
          removeNegativePropertyFeedback: [{
            args: {
              id: true,
              propertyId: true,
              propertyPrice: true,
            },
          }, { id: true }],
        },
      }),
    },
  }, args);
}
