import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationBaseArgs, PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import type { OrderBy, Filters__Date, Filters__Int, OperationType } from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationArgs,
  ExtendedPaginationVariables,
} from '@core/api/graphql/gen-v2';

interface MyBreteuilUser
{
  id: IdType,
  firstname: string,
  lastname: string,
  fullname: string,
  agent: boolean,
  isAgencyPotCommun: boolean,
  contactSearchAgency: {
    id: IdType,
    code: string,
    name: string,
  } | null,
}

interface MyBreteuilProperty
{
  id: IdType,
  slug: string,
  ref: string,
  refId: number,
  address: string,
  operationType: OperationType,
}

export interface MyBreteuilTransaction
{
  id: IdType,
  authenticDeedDate: number/*timestamp*/,
  cancelledOffer: boolean,
  user: MyBreteuilUser | null,
  property: MyBreteuilProperty | null,
}

export interface Filters__MyBreteuilTransaction
{
  authenticDeedDate?: Filters__Date | undefined,
  userId?: Filters__Int | undefined,
}

export interface GetTransactionsVariables extends PaginationBaseArgs
{
  filter?: Filters__MyBreteuilTransaction | undefined,
  orderBy?: OrderBy | undefined,
}

export default function GetTransactions(variables: GetTransactionsVariables)
{
  return query<PaginationExtendedResponse<MyBreteuilTransaction>>({
    operationName: 'Logs_GetTransactions',
    variables: ExtendedPaginationVariables('MyBreteuilTransaction'),
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          logs: {
            transactions: [{
              args: ExtendedPaginationArgs,
            }, paginationResult({
              id: true,
              authenticDeedDate: true,
              cancelledOffer: true,
              user: {
                id: true,
                firstname: true,
                lastname: true,
                fullname: true,
                agent: true,
                isAgencyPotCommun: true,
                contactSearchAgency: {
                  id: true,
                  code: true,
                  name: true,
                },
              },
              property: {
                id: true,
                slug: true,
                ref: true,
                refId: true,
                address: true,
                operationType: true,
              },
            })],
          },
        },
      }),
    },
  }, variables);
}
