import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationConnectionWithCount } from '@repo-lib/graphql-query-pagination';
import type {
  ShowcaseScreen,
  ScreensFilters,
  AssignableStream,
  CreateArgs,
  UpdateArgs,
} from './api';

import {
  GetShowcaseScreens,
  GetShowcaseScreensAssignableStreams,
  CreateShowcaseScreen,
  UpdateShowcaseScreen,
  DeleteShowcaseScreen,
} from './api';

import { action, observable } from 'mobx';

class ScreensStore
{
  @observable private _screens: PaginationConnectionWithCount<ShowcaseScreen>;
  @observable private _assignableStreams: Array<AssignableStream>;
  @observable private _filters: ScreensFilters = {};

  public get screens()
  {
    return this._screens;
  }

  public get assignableStreams()
  {
    return this._assignableStreams;
  }

  public get filters()
  {
    return this._filters;
  }

  @action public setScreens(screens: PaginationConnectionWithCount<ShowcaseScreen>)
  {
    this._screens = screens;
  }

  @action public setAssignableStreams(streams: Array<AssignableStream>)
  {
    this._assignableStreams = streams;
  }

  @action public setFilters(filters: ScreensFilters)
  {
    this._filters = filters;
  }

  public async setFiltersAndRefreshScreens(filters: ScreensFilters)
  {
    this.setFilters(filters);
    await this.refreshScreens();
  }

  public async createScreen(args: CreateArgs)
  {
    await CreateShowcaseScreen(args);
    await this.refreshScreens();
  }

  public async updateScreen(id: IdType, args: UpdateArgs)
  {
    await UpdateShowcaseScreen(id, args);
    await this.refreshScreens();
  }

  public async deleteScreen(id: IdType)
  {
    await DeleteShowcaseScreen(id);
    await this.refreshScreens();
  }

  public async refreshScreens()
  {
    this.setScreens(await GetShowcaseScreens({ filter: this._filters }));
  }

  public async refreshAssignableStreams()
  {
    this.setAssignableStreams(await GetShowcaseScreensAssignableStreams());
  }

  public async refresh()
  {
    await Promise.all([
      this.refreshScreens(),
      this.refreshAssignableStreams(),
    ]);
  }
}

export default new ScreensStore;
