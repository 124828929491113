import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs } from '@repo-lib/graphql-query-builder';
import type {
  MyBreteuilTransactionYearlySummaryType,
  MyBreteuilUserContractTypeEnum,
  OperationType,
  PropertyStatus,
} from '@core/api/types';

export interface AgencyUserArgs
{
  agencyId: IdType,
  userId: IdType,
}

export interface MyBreteuilReportingLastCommissionsDateRange
{
  from?: number/*timestamp*/ | undefined,
  to?: number /*timestamp*/ | undefined,
}

export interface MyBreteuilReportingYearRangeArgs
{
  from: number/*year*/,
  to: number/*year*/,
}

export interface FiltersArgs
{
  type: MyBreteuilTransactionYearlySummaryType,
  dateRange?: MyBreteuilReportingLastCommissionsDateRange | undefined,
  operationTypes?: Array<OperationType> | undefined,
  propertyStatuses?: Array<PropertyStatus> | undefined,
  contractTypes?: Array<MyBreteuilUserContractTypeEnum> | undefined,
  agencyUsers?: Array<AgencyUserArgs> | undefined,
  cancelledOnly?: boolean | undefined,
  yearRange: MyBreteuilReportingYearRangeArgs,
}

export const FiltersVariables = {
  type: 'MyBreteuilTransactionYearlySummaryType!',
  dateRange: 'MyBreteuilReportingLastCommissionsDateRane',
  operationTypes: '[MyBreteuilOperationType!]',
  propertyStatuses: '[MyBreteuilPropertyStatus!]',
  contractTypes: '[MyBreteuilUserContractTypeEnum!]',
  agencyUsers: '[MyBreteuilReportingAgencyUserArgs!]',
  cancelledOnly: 'Boolean',
  yearRange: 'MyBreteuilReportingYearRangeArgs',
} as const;

export const FiltersFieldArgs = {
  type: true,
  dateRange: true,
  operationTypes: true,
  propertyStatuses: true,
  contractTypes: true,
  agencyUsers: true,
  cancelledOnly: true,
  yearRange: true,
} as const satisfies ReadonlyFieldArgs;
