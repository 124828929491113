import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { MyBreteuilUserContractTypeEnum } from '@core/api/types';

import { errorWrapper, query } from '@core/api/graphql/gen-v2';

export interface UserRemunerationAnalytics
{
  user: {
    id: IdType,
    fullname: string,
    contractType: MyBreteuilUserContractTypeEnum,
    arrivedAt: number,
    active: boolean,
    contactSearchAgency: {
      id: IdType,
    } | null,
  },
  CAP12M_HT: number,
  feesPercentageCAP12m_TTC: number,
  remunerationPercentage: number | null,
  advanceOwedJanuary1st: number | null,
  advanceOwed: number | null,
  advanceEstimateN5M: number | null,
  vipsCount: number,
  firstDailyRequestAvg6m: number | null,
  lastDailyRequestAvg6m: number | null,
}

export const UserRemunerationAnalyticsFields = {
  user: {
    id: true,
    fullname: true,
    contractType: true,
    arrivedAt: true,
    active: true,
    contactSearchAgency: [{
      preventUnwrap: true,
    }, {
      id: true,
    }],
  },
  CAP12M_HT: true,
  feesPercentageCAP12m_TTC: true,
  remunerationPercentage: true,
  advanceOwedJanuary1st: true,
  advanceOwed: true,
  advanceEstimateN5M: true,
  vipsCount: true,
  firstDailyRequestAvg6m: true,
  lastDailyRequestAvg6m: true,
} as const satisfies ReadonlyNodeFields;


export default function GetUsersRemunerationAnalytics()
{
  return query<Array<UserRemunerationAnalytics>>({
    operationName: 'Profile_GetUsersRemunerationAnalytics',
    fieldsSelection: {
      authenticated: errorWrapper({
        usersRemunerationAnalytics: UserRemunerationAnalyticsFields,
      }),
    },
  });
}
