import type { IdType } from '@repo-breteuil/common-definitions';
import type { MyBreteuilPropertyDocumentType } from '@core/api/types';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface PropertyDocumentCreateArgs
{
  upload: File,
  documentType: MyBreteuilPropertyDocumentType,
}

export interface PropertySetDocumentItem_OneOf
{
  create?: PropertyDocumentCreateArgs | undefined,
  existing?: IdType | undefined,
}

export interface SetPropertyDocumentsVariables
{
  propertyId: IdType,
  documents: Array<PropertySetDocumentItem_OneOf>,
}

export default function SetPropertyDocuments(variables: SetPropertyDocumentsVariables)
{
  return mutation<{ id: IdType }>({
    operationName: 'Property_Documents_SetPropertyDocuments',
    variables: {
      propertyId: 'Int!',
      documents: '[PropertySetDocumentItem_OneOf!]!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilPropertyDocument: {
          setPropertyDocuments: [{
            args: {
              propertyId: true,
              documents: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, variables);
}
