import type { ConfigVariable } from './GetConfigVariables';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

const VariableTypeToTypeName = new Map<string/*Variable type*/, string/*GraphQL type*/>([
  ['Bool', 'Boolean'],
  ['Int', 'Int'],
  ['Float', 'Float'],
]);

export interface SetConfigVariableArgs
{
  variableName: string,
  variable: ConfigVariable,
}

async function SetConfigVariable(args: SetConfigVariableArgs): Promise<void>
{
  const { variableName, variable } = args;
  const typename = VariableTypeToTypeName.get(variable.type);
  if (typename === undefined)
    throw new Error(`Unsupported variable type: ${variable.type}`);
  await mutation({
    operationName: 'ConfigVariables_SetConfigVariable',
    variables: {
      [ variableName ]: typename,
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        configVariables: {
          setConfigVariables: [{
            args: {
              [ variableName ]: true,
            },
          }, {
            [ variableName ]: {
              type: true,
              value: true,
            },
          }],
        },
      }),
    },
  }, {
    [ variableName ]: variable.value,
  });
}

export default SetConfigVariable;
