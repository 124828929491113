import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface SubmitObjectivesDailyReportArgs extends Omit<DailyReport, 'sentDate'>
{
  userId: number,
}

export interface DailyReport {
  comment: string | null,
  viewingsTriggeredOffers: number,
  remindersTriggeredValuations: number,
  remindersTriggeredViewings: number,
  viewingsTriggeredNotarySignatures: number,
  prospectedStreetsMetContributors: number,
  sentDate: number | null,
}

export const DailyReportFields: ReadonlyNodeFields = {
  comment: true,
  viewingsTriggeredOffers: true,
  remindersTriggeredValuations: true,
  remindersTriggeredViewings: true,
  viewingsTriggeredNotarySignatures: true,
  prospectedStreetsMetContributors: true,
  sentDate: true,
};

export default function SubmitObjectivesDailyReport(args: SubmitObjectivesDailyReportArgs)
{
  return mutation<DailyReport>({
    operationName: 'Profile_SubmitObjectivesDailyReport',
    variables: {
      userId: 'Int',
      comment: "String",
      viewingsTriggeredOffers: "Int!",
      remindersTriggeredValuations: "Int!",
      remindersTriggeredViewings: "Int!",
      viewingsTriggeredNotarySignatures: "Int!",
      remindersSpentTime: "Int!",
      prospectedStreetsMetContributors: "Int!",
      prospectedStreetsSpentTime: "Int!",
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilUser: {
          submitObjectivesDailyReport: [{
            args: {
              userId: true,
              comment: true,
              viewingsTriggeredOffers: true,
              remindersTriggeredValuations: true,
              remindersTriggeredViewings: true,
              viewingsTriggeredNotarySignatures: true,
              remindersSpentTime: true,
              prospectedStreetsMetContributors: true,
              prospectedStreetsSpentTime: true,
            },
          },
          DailyReportFields],
        },
      }),
    },
  }, args);
}
