import type { IdType } from '@repo-breteuil/common-definitions';
import type { ShowcaseMedia, ShowcaseProperty } from './';

import {
  query,
  mutation,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';
import { ShowcaseMediaFields, ShowcasePropertyFields } from './';
import { ShowcaseStreamPropertiesComposition } from '../../streams-list/api/GetShowcaseStreams';

export type ShowcaseStreamItemContent = (
  ShowcaseMedia & { __typename: 'ShowcaseMedia' }
) | (
  ShowcaseProperty & { __typename: 'MyBreteuilProperty' }
);

export interface ShowcaseStreamItem
{
  id: IdType,
  displayDurationMs: number | null,
  effectiveDisplayDurationMs: number,
  createdAt: number, /*Timestamp*/
  content: ShowcaseStreamItemContent,
  position: number,
}

export interface ShowcaseStream
{
  id: IdType,
  name: string,
  defaultPropertyDisplayDurationMs: number,
  defaultMediaDisplayDurationMs: number,
  displayed: boolean,
  contents: Array<ShowcaseStreamItem>,
  propertiesComposition: ShowcaseStreamPropertiesComposition,
  totalCount: number,
}

export interface UpdateShowcaseStreamArgs
{
  name?: string | undefined,
  defaultPropertyDisplayDurationMs?: number | null | undefined,
  defaultMediaDisplayDurationMs?: number | null | undefined,
}

export async function GetShowcaseStreamWithContents(streamId: IdType): Promise<ShowcaseStream | null>
{
  return query({
    operationName: 'Showcase_StreamMixing_GetShowcaseStreamWithContents',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcase: {
          stream: [{
            args: {
              id: true,
            },
          }, {
            id: true,
            name: true,
            defaultPropertyDisplayDurationMs: true,
            defaultMediaDisplayDurationMs: true,
            displayed: true,
            contents: paginationResultAsList({
              id: true,
              displayDurationMs: true,
              effectiveDisplayDurationMs: true,
              position: true,
              createdAt: true,
              content: [{
                polymorphicFields: {
                  ShowcaseMedia: ShowcaseMediaFields,
                  MyBreteuilProperty: ShowcasePropertyFields,
                },
              }, {}],
            }),
            propertiesComposition: {
              totalCount: true,
              avgPrice: true,
              exclusiveCount: true,
              geoArea_Paris_Count: true,
              geoArea_UK_Count: true,
              geoArea_FRLittoral_Count: true,
              operationType_ResidencyTransaction_Count: true,
              operationType_Rental_Count: true,
              operationType_SeasonalRental_Count: true,
            },
          }],
        },
      }),
    },
  }, {
    id: streamId,
  });
}

export async function UpdateShowcaseStream(id: IdType, args: UpdateShowcaseStreamArgs): Promise<void>
{
  await mutation({
    operationName: 'Showcase_StreamMixing_UpdateShowcaseStream',
    variables: {
      id: 'Int!',
      name: 'String',
      defaultPropertyDisplayDurationMs: 'Int',
      defaultMediaDisplayDurationMs: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseStream: {
          update: errorWrapper([{
            args: {
              id: true,
              name: true,
              defaultPropertyDisplayDurationMs: true,
              defaultMediaDisplayDurationMs: true,
            },
          }, {
            id: true,
          }]),
        },
      }),
    },
  }, {
    ...args,
    id,
  });
}

export async function DeleteShowcaseStream(id: IdType): Promise<void>
{
  await mutation({
    operationName: 'Showcase_StreamMixing_DeleteShowcaseStream',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseStream: {
          delete: errorWrapper([{
            args: {
              id: true,
            },
          }, true]),
        },
      }),
    },
  }, { id });
}
