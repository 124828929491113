import type { TransferUserPropertiesVariables } from './api';

import { action, observable } from 'mobx';
import type { IdType } from '@repo-breteuil/common-definitions';
import { Fetchable, OpeningControl } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import {
  GetVisiblePropertyUsers,
  TransferUserProperties,
} from './api';

export class PropertyTransferStore
{
  @observable private _propertyIds: Array<IdType> = [];
  private _onTransferSuccess: (() => void) | null = null;

  get propertyIds()
  {
    return this._propertyIds;
  }

  @action setPropertyIds(propertyIds: Array<IdType>)
  {
    this._propertyIds = propertyIds;
  }

  public users = new Fetchable(GetVisiblePropertyUsers, { catchUnhandled: handleNonCriticalError });

  private _openingControl = new OpeningControl();

  get opened()
  {
    return this._openingControl.isOpen;
  }

  public async open(
    args: {
      propertyIds: Array<IdType>,
      onTransferSuccess: () => void,
    },
  )
  {
    const { propertyIds, onTransferSuccess } = args;
    await this.users.ensureSuccess();
    this.setPropertyIds(propertyIds);
    this._onTransferSuccess = onTransferSuccess;
    this._openingControl.open();
  }

  public close()
  {
    this._openingControl.close();
  }

  public async transferUserProperties(args: TransferUserPropertiesVariables)
  {
    const res = await TransferUserProperties(args);
    if (this._onTransferSuccess)
      this._onTransferSuccess();
    return res;
  }
}

export default PropertyTransferStore;
