import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilPhotographer
{
  id: IdType,
  name: string,
}

export const MyBreteuilPhotographerFields: ReadonlyNodeFields = {
  id: true,
  name: true,
};

function GetPhotographers()
{
  return query<Array<MyBreteuilPhotographer>>({
    operationName: 'Properties_GetPhotographers',
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilPhotographers:  paginationResultAsList(MyBreteuilPhotographerFields),
      }),
    },
  });
}

export default GetPhotographers;
