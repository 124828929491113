import { StaticRoute, Route } from '@lib/routing';

export const root = new StaticRoute('/');
export const logIn = new StaticRoute('/log-in');
export const resetPassword = new Route('/reset-password/[passwordResetTokenEmail]');
export const folders = new StaticRoute('/folders');
export const personalSpace = new StaticRoute('/personal-space');
export const analytics = new StaticRoute('/analytics');
export const showcaseMediasList = new StaticRoute('/showcase/medias');
export const showcaseStreamsList = new StaticRoute('/showcase/streams');
export const showcaseStreamMixing = new Route('/showcase/streams/[streamId]');
export const showcaseScreensList = new StaticRoute('/showcase/screens');
export const showcaseScreensStatus = new StaticRoute('/showcase/screens-status');
export const agencies = new StaticRoute('/agencies');
export const usersIT = new StaticRoute('/users/it');
export const usersHR = new StaticRoute('/users/hr');
export const usersGoals = new StaticRoute('/users/goals');
export const usersAccounting = new StaticRoute('/users/accounting');
export const configVariables = new StaticRoute('/config-variables');
export const properties = new StaticRoute('/properties');
export const createProperty = new StaticRoute('/property/create');
export const property = new Route('/property/[propertySlug]');
export const propertyWithTab = new Route('/property/[propertySlug]/[tab]');
export const valuations = new StaticRoute('/valuations');
export const contacts = new StaticRoute('/contacts'); // Compat with buyers route
export const buyers = new StaticRoute('/buyers');
export const owners = new StaticRoute('/owners');
export const createContact = new StaticRoute('/contact/create');
export const contact = new Route('/contact/[contactId]');
export const contactWithTab = new Route('/contact/[contactId]/[tab]');
export const logs = new Route('/logs/[logsType]');
export const profile = new StaticRoute('/profile');
export const offers = new StaticRoute('/offers');
export const prospecting = new StaticRoute('/prospecting');
export const valuation = new Route('/valuation/[propertySlug]');
export const valuationPreview = new Route('/valuation/[propertySlug]/preview');
export const holidays = new StaticRoute('/holidays');
export const valuationsPDFs = new StaticRoute('valuations-pdfs');
export const usersAPIRequests = new StaticRoute('/users-api-requests');
export const entityLogs = new StaticRoute('/logs');
export const propertiesConfidentialLinks = new StaticRoute('/properties-confidential-links');

export const marketDataTransactions = new StaticRoute('/market-data/transactions');
export const marketDataHeatMap = new StaticRoute('/market-data/heat-map');
export const marketDataPriceAdjustment = new StaticRoute('/market-data/price-adjustment');

export const dolceVillaPropertiesList = new StaticRoute('/dolce-villa/properties');
export const dolceVillaPropertyPage = new Route('/dolce-villa/properties/[propertyId]');
export const dolceVillaPropertyCreate = new StaticRoute('/dolce-villa/property/create');
export const dolceVillaKeyInfosPage = new StaticRoute('/dolce-villa/key-infos');
export const dolceVillaRoomAmenitiesPage = new StaticRoute('/dolce-villa/amenities');
export const dolceVillaSurroundingsPage = new StaticRoute('/dolce-villa/surroundings');
export const dolceVillaServicesPage = new StaticRoute('/dolce-villa/services');
export const dolceVillaWhatToKnowsPage = new StaticRoute('/dolce-villa/what-to-knows');
export const dolceVillaOwnersPage = new StaticRoute('/dolce-villa/owners');

export const propertyPicturesDownload = new Route('/assets/property/[propertyId]/pictures');
