import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import { PaginationVariables } from '@repo-breteuil/front-store-query';
import { PaginationBaseArgs } from '@repo-lib/graphql-query-pagination';
import type {
  PropertyStatus,
  MyBreteuilContactCategory,
  MyBreteuilPropertyVisibility,
  MyBreteuilSearchLevel,
  AlertsFrequency,
  OperationType,
  Currency,
  Language,
  Filters__Int,
  Filters__Date,
  Filters__Boolean,
  Filters__String,
  Filters__Enum,
  OrderBy,
  UserPinOrigin,
} from '@core/api/types';

export interface Property
{
  id: IdType,
  slug: string,
  addrStreetAddress: string,
  price: number,
  currency: Currency,
  status: PropertyStatus,
  description: string,
  publication: MyBreteuilPropertyVisibility,
  administrativeAreas: {
    city: {
      id: IdType,
      displayName: string,
    } | null,
  },
  operationType: OperationType,
  users: Array<{
    id: IdType,
    fullname: string,
    firstname: string,
    lastname: string,
    agent: boolean,
    isAgencyPotCommun: boolean,
    contactSearchAgency: {
      id: IdType,
      code: string,
      name: string,
    } | null,
  }>,
  lastAgentValuationDate: number | null,
  createdAt: number,
}

const PropertyFields = {
  id: true,
  slug: [{ args: { language: true } }, true],
  addrStreetAddress: true,
  price: true,
  currency: true,
  status: true,
  description: [{ args: { language: true } }, true],
  publication: true,
  administrativeAreas: [{
    preventUnwrap: true,
  }, {
    city: {
      id: true,
      displayName: [{ args: { language: true } }, true],
    },
  }],
  operationType: true,
  users: {
    id: true,
    firstname: true,
    lastname: true,
    fullname: true,
    agent: true,
    isAgencyPotCommun: true,
    contactSearchAgency: {
      id: true,
      code: true,
      name: true,
    },
  },
  lastAgentValuationDate: true,
  createdAt: true,
} satisfies ReadonlyNodeFields;

export interface Search
{
  id: IdType,
  label: string,
  users: Array<{
    id: IdType,
    fullname: string,
    firstname: string,
    lastname: string,
    agent: boolean,
    isAgencyPotCommun: boolean,
    contactSearchAgency: {
      id: IdType,
      code: string,
      name: string,
    } | null,
  }>,
  criteria: Array<{
    id: IdType,
    webName: string,
  }>,
}

const SearchFields = {
  id: true,
  label: [{ args: { language: true } }, true],
  users: {
    id: true,
    firstname: true,
    lastname: true,
    fullname: true,
    agent: true,
    isAgencyPotCommun: true,
    contactSearchAgency: {
      id: true,
      code: true,
      name: true,
    },
  },
  criteria: {
    id: true,
    webName: [{ args: { language: true } }, true],
  },
} satisfies ReadonlyNodeFields;

// Base

export interface ContactBase
{
  id: IdType,
  pinned: boolean,
  firstname: string,
  lastname: string,
  fullname: string,
  fullnameReversed: string,
  budgetMax: number | null,
  vip: boolean,
  recallDate: number | null/*timestamp*/,
  properties: Array<Property>,
  searches: Array<Search>,
  mybHistoriqueLastAction: number | null/*timestamp*/,
  lastContactAttemptDate: number | null/*timestamp*/,
  hasReminders: boolean,
  emails: Array<string>,
  phones: Array<string>,
  activeSearchesCount: number | null,
  createdAt: number/*timestamp*/,
}

export const ContactBaseFields = {
  id: true,
  pinned: [{ args: { origin: 'pinnedOrigin' } }, true],
  firstname: true,
  lastname: true,
  fullname: true,
  fullnameReversed: true,
  budgetMax: true,
  vip: true,
  recallDate: true,
  properties: PropertyFields,
  searches: SearchFields,
  mybHistoriqueLastAction: true,
  lastContactAttemptDate: true,
  hasReminders: true,
  emails: true,
  phones: true,
  activeSearchesCount: true,
  createdAt: true,
} satisfies ReadonlyNodeFields;

// Buyer

export interface BuyerContact extends ContactBase
{
  eval: MyBreteuilSearchLevel | null,
  ownerComment: string | null,
  tenant: boolean | null,
  category: MyBreteuilContactCategory,
  greenAlertCount: number,
  lastInterestDate: number | null/*timestamp*/,
  viewingsCount: number,
  matchesCountAbsoluteNotSent: number,
  matchesCountExtendedNotSent: number,
  searchAreas: Array<{
    id: IdType,
    displayName: string,
  }>,
  bedroomsSearchMin: number | null,
  surfaceSearchMin: number | null,
  effectiveAlertsLevel: AlertsFrequency | null,
}

export const BuyerContactFields = {
  ...ContactBaseFields,
  eval: true,
  ownerComment: true,
  tenant: true,
  category: true,
  greenAlertCount: true,
  lastInterestDate: true,
  viewingsCount: true,
  matchesCountAbsoluteNotSent: true,
  matchesCountExtendedNotSent: true,
  searchAreas: {
    id: true,
    displayName: [{ args: { language: true } }, true],
  },
  bedroomsSearchMin: true,
  surfaceSearchMin: true,
  effectiveAlertsLevel: true,
} satisfies ReadonlyNodeFields;

// Owner

export interface OwnerContact extends ContactBase
{
  lastMarketingReportSendDate: number | null,
}

export const OwnerContactFields = {
  ...ContactBaseFields,
  lastMarketingReportSendDate: true,
} satisfies ReadonlyNodeFields;

// Args

export interface ContactBaseArgs
{
  language: Language,
  pinnedOrigin: UserPinOrigin,
}

export const ContactBaseArgsFields = {
  language: 'Language!',
  pinnedOrigin: 'UserPinOrigin',
};

export interface ContactsFilterFull
{
  responsibleUsers?: Array<IdType> | undefined,
  filter?: ContactsFilter | undefined,
}

export interface ContactsArgs extends ContactBaseArgs, ContactsFilterFull, PaginationBaseArgs
{
  orderBy?: OrderBy | null | undefined,
}

export const ContactsArgsFields = {
  ...ContactBaseArgsFields,
  ...PaginationVariables,
  responsibleUsers: '[Int!]',
  orderBy: '[Order__MyBreteuilContact!]',
  filter: 'Filters__Authenticated_Contacts_MainPagination',
};

export interface ContactsFilter
{
  budgetMax?: Filters__Int | undefined,
  viewingsCount?: Filters__Int | undefined,
  myb_historique_last_action?: Filters__Date | undefined,
  lastContactAttemptDate?: Filters__Date | undefined,
  greenAlertCount?: Filters__Int | undefined,
  lastInterestDate?: Filters__Date | undefined,
  category?: Filters__Enum<MyBreteuilContactCategory> | undefined,
  namesStringReversed?: Filters__String | undefined,
  matchesCountAbsoluteNotSent?: Filters__Int | undefined,
  matchesCountExtendedNotSent?: Filters__Int | undefined,
  searchAreas?: Array<IdType> | undefined,
  searchCriteria?: Array<IdType> | undefined,
  activeSearchesCount?: Filters__Int | undefined,
  propertyStatus?: Array<PropertyStatus> | undefined,
  propertyOwner?: boolean | undefined,
  effectiveAlertsLevel?: Filters__Enum<AlertsFrequency> | undefined,
  eval?: Filters__Enum<MyBreteuilSearchLevel> | undefined,
  vip?: Filters__Boolean | undefined,
  owner?: boolean | null | undefined,
  tenant?: boolean | null | undefined,
  recallDate?: Filters__Date | undefined,
  createdAt?: Filters__Date | undefined,
  bedroomsSearchMin?: Filters__Int | undefined,
  surfaceSearchMin?: Filters__Int | undefined,
  lastMarketingReportSendDate?: Filters__Date | undefined,
  propertyAddress?: string | undefined,
  propertyLastAgentValuationDate?: { min: number | undefined, max: number | undefined } | undefined,
  propertyPrice?: { min: number | undefined, max: number | undefined } | undefined,
}
