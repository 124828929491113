import type { PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationArgs,
} from '@core/api/graphql/gen-v2';
import {
  type OwnerContact,
  OwnerContactFields,
  type ContactsArgs,
  ContactsArgsFields,
} from './shared';

export default function GetOwnerContacts(variables: ContactsArgs)
{
  return query<PaginationExtendedResponse<OwnerContact>>({
    operationName: 'Owners_GetContacts',
    variables: ContactsArgsFields,
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContacts: [{
          args: {
            ...ExtendedPaginationArgs,
            responsibleUsers: true,
            excludePinned: 'pinnedOrigin',
            owners: { literal: true },
          },
        }, paginationResult(OwnerContactFields)],
      }),
    },
  }, variables);
}
