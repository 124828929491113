import type { IdType } from '@repo-breteuil/common-definitions';

import {
  query,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilUser
{
  id: IdType,
  fullname: string,
}

export default function GetAssignableUsers()
{
  return query<Array<MyBreteuilUser>>({
    operationName: 'Common_ViewingDialog_GetAssignableUsers',
    fieldsSelection: {
      authenticated: errorWrapper({
        usersAssignable: [{
          args: {
            excludeAgencyUsers: { literal: 'true' },
          },
        }, {
          id: true,
          fullname: true,
        }],
      }),
    },
  });
}
