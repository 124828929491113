import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';
import { type SessionResult, SessionFields } from './GetSession';

export interface LogInArgs
{
  login: string,
  password: string,
}

export function LogIn(args: LogInArgs)
{
  return mutation<SessionResult | null>({
    operationName: 'LogIn_LogIn',
    variables: {
      login: 'String!',
      password: 'String!',
    },
    fieldsSelection: {
      session: {
        logIn: errorWrapper([{
          args: {
            login: true,
            password: true,
          },
        }, (
          SessionFields
        )]),
      },
    },
  }, args);
}
