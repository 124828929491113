import type { OrderBy } from '@core/api/types';
import type { FilterFields } from '@my-breteuil/store/ui/common/array-processing-utils';
import type { User } from './api';

import { action, computed, observable } from 'mobx';
import { addMonths } from 'date-fns';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { mapRecord, indexArrayItems } from '@repo-lib/utils-core';
import { createDateUTC } from '@repo-lib/utils-date-tz';
import { OrderType } from '@core/api/types';
import { assert, handleCriticalError } from '@repo-breteuil/front-error';
import { getDayOffPeriodBoundaries } from '@my-breteuil/store/utils/holidays';
import {
  GetManagedUsers,
  GetManagedAgencies,
  UpdateUser,
  SetUserSalaryInfo,
  SetUserDayOffBalance, type SetUserDayOffBalanceArgs,
} from './api';
import {
  applyTableFilters,
  applyTableOrderBy,
  applyTablePagination,
} from '@my-breteuil/store/ui/common/array-processing-utils';

export enum ActiveUserStatus
{
  Active,
  Inactive,
  Inactive_6m, // Users that became inactive after the 1st july of the previous year.
}

function getUserActiveStatus(user: User)
{
  const { isAgencyPotCommun, leavedAt, accountDisabled } = user;
  if (isAgencyPotCommun)
    return null;
  const now = new Date();
  if ((leavedAt !== null && leavedAt < now.getTime()) || accountDisabled)
  {
    if (leavedAt !== null)
    {
      const inactive_6mDate = createDateUTC({ year: now.getFullYear() - 1, month: 7, day: 1 });
      if (leavedAt >= inactive_6mDate.getTime())
        return ActiveUserStatus.Inactive_6m;
    }
    return ActiveUserStatus.Inactive;
  }
  return ActiveUserStatus.Active;
}


const SearchStringSeparator = 'ʅ';

function makeEmptySalaryInfo(year: number, month: number)
{
  return {
    year,
    month,
    advanceOwed: null,
    advanceKept: null,
    advanceDropped: null,
    remunerationPercentage: null,
    comment: '',
  };
}

function makeEmptyDayOffPaidLeaveBalance(year: number)
{
  return {
    year,
    paidLeaveDays: null,
  };
}

function makeEmptyDayOffToilBalance(year: number)
{
  return {
    year,
    toilDays: null,
  };
}

export type PreProcessedUser = ReturnType<typeof preProcessUser>;

function preProcessUser(user: User, salaryInfoMonthsOffset: number)
{
  const __active = getUserActiveStatus(user);
  const __globalSearchStr = [
    user.fullnameReversed,
    ...user.agencies.map(agency => agency.name),
  ].filter((val): val is string => val !== null).join(SearchStringSeparator);

  const salaryInfoMap = new Map<string, any/*UserSalaryInfoHistory*/>();
  for (const salaryInfo of user.salaryInfo)
    salaryInfoMap.set(`${salaryInfo.year}-${salaryInfo.month}`, {
      ...salaryInfo,
      comment: salaryInfo.comment || '',
    });
  const now = new Date();
  const salariesInfo = mapRecord({
    salaryInfo1: addMonths(now, salaryInfoMonthsOffset),
    salaryInfo2: addMonths(now, salaryInfoMonthsOffset - 1),
    salaryInfo3: addMonths(now, salaryInfoMonthsOffset - 2),
  }, (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return salaryInfoMap.get(`${year}-${month}`) ?? makeEmptySalaryInfo(year, month);
  });

  const dayOffBalancePerYear = indexArrayItems(user.dayOffBalances, ({ year }) => year);
  const [ currentPaidLeavePeriodStartDate ] = getDayOffPeriodBoundaries({ date: now, startMonth: 6 });
  const currentPaidLeavePeriodStartYear = currentPaidLeavePeriodStartDate.getFullYear();
  const previousPaidLeavePeriodStartYear = currentPaidLeavePeriodStartYear - 1;
  const dayOffPaidLeaveBalancePreviousPeriod = dayOffBalancePerYear.get(previousPaidLeavePeriodStartYear) ?? makeEmptyDayOffPaidLeaveBalance(previousPaidLeavePeriodStartYear);
  const dayOffPaidLeaveBalanceCurrentPeriod = dayOffBalancePerYear.get(currentPaidLeavePeriodStartYear) ?? makeEmptyDayOffPaidLeaveBalance(currentPaidLeavePeriodStartYear);

  const [ currentToilPeriodStartDate ] = getDayOffPeriodBoundaries({ date: now, startMonth: 1 });
  const currentToilPeriodStartYear = currentToilPeriodStartDate.getFullYear();
  const dayOffToilBalanceCurrentPeriod = dayOffBalancePerYear.get(currentToilPeriodStartYear) ?? makeEmptyDayOffToilBalance(currentToilPeriodStartYear);

  return {
    ...user,
    __fullname_HR: user.fullnameReversed, // Trick to allow ordering by fullname from different columns
    __fullname_Goals: user.fullnameReversed, // Trick to allow ordering by fullname from different columns
    __fullname_Accounting: user.fullnameReversed, // Trick to allow ordering by fullname from different columns
    __fullname_Accounting2: user.fullnameReversed, // Trick to allow ordering by fullname from different columns
    __active,
    __globalSearchStr,
    __companyName: user.contactSearchAgency?.companyInfo?.name ?? null,
    ...salariesInfo,
    dayOffPaidLeaveBalancePreviousPeriod,
    dayOffPaidLeaveBalanceCurrentPeriod,
    dayOffToilBalanceCurrentPeriod,
  };
}

export enum GroupView
{
  IT,
  HR,
  Goals,
  Accounting,
}

class UsersStore
{
  @observable private _groupView: GroupView = GroupView.IT;

  public get groupView()
  {
    return this._groupView;
  }

  @action private setGroupView(newGroupView: GroupView)
  {
    this._groupView = newGroupView;
  }

  public users = new Fetchable(GetManagedUsers, { catchUnhandled: handleCriticalError });

  @observable private _salaryInfoMonthsOffset = 0;

  get salaryInfoMonthsOffset()
  {
    return this._salaryInfoMonthsOffset;
  }

  @action increaseSalaryInfoMonthsOffset()
  {
    this._salaryInfoMonthsOffset += 1;
  }

  @action decreaseSalaryInfoMonthsOffset()
  {
    this._salaryInfoMonthsOffset -= 1;
  }

  @computed get preProcessedUsers()
  {
    assert(this.users.lastResult);
    return this.users.lastResult.map(user => preProcessUser(user, this.salaryInfoMonthsOffset));
  }

  @observable private _filters: Record<string, FilterFields> = {};
  @observable private _orderBy: OrderBy = [
    { fieldName: 'lastname', ordering: OrderType.ASC },
  ];
  @observable private _page = 0;
  @observable private _rowsPerPage = 1000;

  @observable private _activeUsersFilter = [ ActiveUserStatus.Active ];
  @observable private _globalSearchStrFilter = '';

  get filters()
  {
    return this._filters;
  }

  @action setFilters(filters: Record<string, FilterFields>)
  {
    this._filters = filters;
    this.setPage(0);
  }

  get orderBy()
  {
    return this._orderBy;
  }

  @action setOrderBy(orderBy: OrderBy)
  {
    this._orderBy = orderBy;
  }

  get page()
  {
    return this._page;
  }

  @action setPage(page: number)
  {
    this._page = page;
  }

  get rowsPerPage()
  {
    return this._rowsPerPage;
  }

  get activeUsersFilter()
  {
    return this._activeUsersFilter;
  }

  @action setActiveUsersFilter(status: ActiveUserStatus, checked: boolean)
  {
    const newStatus = checked ? (
      [ ...this._activeUsersFilter, status ]
    ) : (
      this._activeUsersFilter.filter(s => s !== status)
    );
    this._activeUsersFilter = newStatus;
    this.setPage(0);
  }

  get globalSearchStrFilter()
  {
    return this._globalSearchStrFilter;
  }

  @action setGlobalSearchStrFilter(globalSearchStr: string)
  {
    this._globalSearchStrFilter = globalSearchStr;
    this.setPage(0);
  }

  @computed get filteredUsers()
  {
    const effectiveFilters: Record<string, FilterFields> = {
      ...this.filters,
      __active: { in: this.activeUsersFilter.length > 0 ? this.activeUsersFilter : undefined },
      __globalSearchStr: { contains: this.globalSearchStrFilter },
    };
    return applyTableFilters(this.preProcessedUsers, effectiveFilters);
  }

  @computed get processedUsers()
  {
    const orderedUsers = applyTableOrderBy(this.filteredUsers, this._orderBy);
    return applyTablePagination(orderedUsers, {
      page: this.page,
      rowsPerPage: this.rowsPerPage,
    });
  }

  public agencies = new Fetchable(GetManagedAgencies, { catchUnhandled: handleCriticalError });

  public updateUser = UpdateUser;

  public setUserSalaryInfo = SetUserSalaryInfo;

  public setUserDayOffBalance(args: SetUserDayOffBalanceArgs)
  {
    return SetUserDayOffBalance(args);
  }

  public async refresh(groupView: GroupView)
  {
    this.setGroupView(groupView);
    await Promise.all([
      this.users.ensureSuccessReload(),
      this.agencies.ensureSuccessReload(),
    ]);
  }
}

export default new UsersStore();
