import { Filters__String } from '@core/api/types';

import {
  query,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export type DateMinMax = {
  min?: number | null | undefined,
  max?: number | null | undefined,
}

export interface Filters__ValuationAgencyMeetings
{
  createdAt?: DateMinMax | undefined,
  resolvedAddress?: Filters__String | undefined,
  contact?: boolean | undefined,
  agent?: boolean | undefined,
  confirmedMeetingDate?: DateMinMax | undefined,
}

export default function GetValuationAgencyMeetingsDistinctPhonesCount(variables: Filters__ValuationAgencyMeetings)
{
  return query<number>({
    operationName: 'Valuations_GetValuationAgencyMeetingsDistinctPhonesCount',
    variables: {
      resolvedAddress: "ValuationAgencyMeetingsCountResolvedAddress",
      createdAt: "AdminValuationCountDateRangeFilter",
      contact: "Boolean",
      agent: "Boolean",
      confirmedMeetingDate: "AdminValuationCountDateRangeFilter",
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        admin: errorWrapper({
          valuationAgencyMeetingsDistinctPhonesCount: [{
            args: {
              resolvedAddress: true,
              createdAt: true,
              contact: true,
              agent: true,
              confirmedMeetingDate: true,
            },
          }, true],
        }),
      }),
    },
  }, variables);
}
