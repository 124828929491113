import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export interface ConfigVariableMetadata
{
  name: string,
  type: string,
}

function GetConfigVariablesList()
{
  return query<Array<ConfigVariableMetadata>>({
    operationName: 'ConfigVariables_GetConfigVariablesList',
    fieldsSelection: {
      authenticated: errorWrapper({
        configVariables: {
          getConfigVariablesList: {
            name: true,
            type: true,
          },
        },
      }),
    },
  });
}

export default GetConfigVariablesList;
