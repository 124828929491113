import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import {
  type OwnerContact,
  OwnerContactFields,
  type ContactBaseArgs,
  ContactBaseArgsFields,
} from './shared';

export default function GetUserPinnedContacts(args: ContactBaseArgs)
{
  return query<Array<OwnerContact>>({
    operationName: 'Owners_GetUserPinnedContacts',
    variables: ContactBaseArgsFields,
    fieldsSelection: {
      authenticated: errorWrapper({
        pinnedContacts: [{
          args: {
            origin: 'pinnedOrigin',
          },
        }, OwnerContactFields],
      }),
    },
  }, args);
}
