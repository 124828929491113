import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationConnectionWithCount } from '@repo-lib/graphql-query-pagination';
import type { ShowcaseMedia, MediasFilters, CreateArgs, UpdateArgs } from './api';

import { action, observable } from 'mobx';
import {
  GetShowcaseMedias,
  CreateShowcaseMedia,
  UpdateShowcaseMedia,
  DeleteShowcaseMedia,
} from './api';

class MediasStore
{
  @observable private _medias: PaginationConnectionWithCount<ShowcaseMedia>;
  @observable private _filters: MediasFilters = {};

  public get medias()
  {
    return this._medias;
  }

  public get filters()
  {
    return this._filters;
  }

  @action public setMedias(medias: PaginationConnectionWithCount<ShowcaseMedia>)
  {
    this._medias = medias;
  }

  @action public setFilters(filters: MediasFilters)
  {
    this._filters = filters;
  }

  public async setFiltersAndRefreshMedias(filters: MediasFilters)
  {
    this.setFilters(filters);
    await this.refreshMedias();
  }

  public async createMedia(args: CreateArgs)
  {
    await CreateShowcaseMedia(args);
    await this.refreshMedias();
  }

  public async updateMedia(id: IdType, args: UpdateArgs)
  {
    await UpdateShowcaseMedia(id, args);
    await this.refreshMedias();
  }

  public async deleteMedia(id: IdType)
  {
    await DeleteShowcaseMedia(id);
    await this.refreshMedias();
  }

  public async refreshMedias()
  {
    this.setMedias(await GetShowcaseMedias({ filter: this._filters }));
  }

  public async refresh()
  {
    await this.refreshMedias();
  }
}

export default new MediasStore();
