import { Language } from '@core/api/types';
import locales from '@core/store/Locales';
import FR from './fr';
import EN from './en';

export interface Definition {
  sum: string,
  avg: string,
}

export default locales.createResources<Definition>(new Map([
  [ Language.French, FR ],
  [ Language.English, EN ],
]));
