import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { BreteuilPropertySurfaceUnit, Currency, Language, OperationType } from '@core/api/types';

import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface ValuationGeoAreas {
  id: IdType,
  name: string,
  displayNameFR: string,
  children: Array<{
    id: IdType,
    displayNameFR: string,
  }>,
}

const ValuationGeoAreasFields: ReadonlyNodeFields = {
  id: true,
  name: true,
  displayNameFR: true,
  children: paginationResultAsList({
    id: true,
    displayNameFR: true,
  }),
};

export interface MyBreteuilProperty
{
  id: IdType,
  slug: string,
  title: string,
  description: string,
  surface: number,
  surfaceUnit: BreteuilPropertySurfaceUnit | null,
  price: number,
  pricePerSurface: number,
  currency: Currency,
  operationType: OperationType,
  resolvedAddress: string,
  addrStreetName: string,
  addrStreetNumber: string,
  addrStreetNumberOdd: boolean,
  valuationEstimatedPrice: number | null,
  latitude: number,
  longitude: number,
  geoAreaId: IdType,
  geoAreaResolvedFromAddress: boolean | null,
  geoAreas_valuation: Array<ValuationGeoAreas>,
  propertyAIDescriptionMissingFields: Array<string>,
  city: {
    id: IdType,
    name: string,
  },
  district: {
    id: IdType,
    name: string,
  },
  territoryAgency: {
    id: IdType,
    name: string,
    fullAddress: string,
    phone: string | null,
    valuationManager: {
      id: IdType,
      fullname: string,
      email: string,
      phoneMobile: string | null,
    } | null,
    users: Array<{
      id: IdType,
      email: string,
      fullname: string,
      phoneMobile: string | null,
    }>,
  },
  deltaAddressInfo: {
    fromYear: number | null,
    radius: number | null,
    sameStreet: {
      sameSide: boolean,
    } | null,
    surfaceMin: number | null,
    surfaceMax: number | null,
  } | null,
}

const MyBreteuilPropertyFields: ReadonlyNodeFields = {
  id: true,
  slug: true,
  title: true,
  description: [ { args: { language: true } } , true],
  surface: true,
  surfaceUnit: true,
  price: true,
  pricePerSurface: true,
  currency: true,
  operationType: true,
  resolvedAddress: true,
  addrStreetName: true,
  addrStreetNumber: true,
  addrStreetNumberOdd: true,
  valuationEstimatedPrice: true,
  latitude: true,
  longitude: true,
  geoAreaId: true,
  geoAreaResolvedFromAddress: true,
  geoAreas_valuation: ValuationGeoAreasFields,
  propertyAIDescriptionMissingFields: true,
  city: {
    id: true,
    name: true,
  },
  district: {
    id: true,
    name: true,
  },
  territoryAgency: {
    id: true,
    name: true,
    fullAddress: true,
    phone: true,
    valuationManager: {
      id: true,
      fullname: true,
      email: true,
      phoneMobile: true,
    },
    users: paginationResultAsList({
      id: true,
      email: true,
      fullname: true,
      phoneMobile: true,
    }),
  },
  deltaAddressInfo: {
    filters: {
      fromYear: true,
      radius: true,
      sameStreet: [{
        preventUnwrap: true,
      }, {
        sameSide: true,
      }],
      surfaceMin: true,
      surfaceMax: true,
    },
  },
} as const;

export interface GetPropertyArgs
{
  slug: string,
  language: Language,
}

export default function GetProperty(args: GetPropertyArgs)
{
  return query<MyBreteuilProperty>({
    operationName: 'Valuation_GetProperty',
    variables: {
      slug: 'String!',
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: [{
          args: {
            slug: true,
          },
        }, MyBreteuilPropertyFields],
      }),
    },
  }, args);
}
