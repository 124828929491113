import type { Language } from '@core/api/types';

import { PictureFormat } from '@core/api/types';
import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilAgency
{
  id: number,
  slug: string | null,
  name: string | null,
  code: string | null,
  phone: string | null,
  email: string | null,
  address: string | null,
  fullAddress: string | null,
  postalCode: string | null,
  language: Language | null,
  public: boolean | null,
  physicalAgency: boolean | null,
  position: number | null,
  latitude: number | null,
  longitude: number | null,
  timezone: string | null,
  coverPicture: string | null,
}

function GetMyBreteuilAgencies(): Promise<Array<MyBreteuilAgency>>
{
  return query({
    operationName: 'Agencies_GetMyBreteuilAgencies',
    variables: {
      orderBy: '[Order__MyBreteuilAgency!]',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        admin: errorWrapper({
          myBreteuilAgencies: [{
            args: {
              orderBy: true,
            },
          }, paginationResultAsList({
            id: true,
            slug: true,
            name: true,
            code: true,
            phone: true,
            email: true,
            address: true,
            fullAddress: true,
            postalCode: true,
            language: true,
            public: true,
            physicalAgency: true,
            position: true,
            latitude: true,
            longitude: true,
            timezone: true,
            coverPicture: [{
              args: { format: { literal: PictureFormat._720p }},
            }, true],
          })],
        }),
      }),
    },
  }, {
    orderBy: [{ fieldName: 'position', ordering: 'ASC' }],
  });
}

export default GetMyBreteuilAgencies;
