import type { PaginationExtendedResponse, PaginationBaseArgs } from '@repo-lib/graphql-query-pagination';
import type {
  OrderBy,
  Language,
} from '@core/api/types';
import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationVariables,
  ExtendedPaginationArgs,
} from '@core/api/graphql/gen-v2';
import {
  EntityLogFields,
  type EntityLog,
  type EntityLogHistoryEventFilters,
} from '@my-breteuil/store/ui/fragments/entity-logs';

export interface GetEntityLogsVariables extends PaginationBaseArgs
{
  language: Language,
  orderBy?: OrderBy,
  filter?: EntityLogHistoryEventFilters,
}

export default function GetEntityLogs(variables: GetEntityLogsVariables)
{
  return query<PaginationExtendedResponse<EntityLog>>({
    operationName: 'EntityLogs_GetEntityLogs',
    variables: {
      ...ExtendedPaginationVariables('HistoryEvent'),
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        entityLogs: [{
          args: ExtendedPaginationArgs,
        }, paginationResult(EntityLogFields)],
      }),
    },
  }, variables);
}
