import type { IdType, Language } from '@repo-breteuil/common-definitions';
import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export default function DuplicateProperty(
  args: {
    id: IdType,
    language: Language,
  },
)
{
  return mutation<{ id: IdType, slug: string }>({
    operationName: 'Property_DuplicateProperty',
    variables: {
      id: 'Int!',
      language: 'Language',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: {
          duplicateProperty: errorWrapper([{
            args: {
              id: true,
            },
          }, {
            id: true,
            slug: [ { args: { language: true } }, true ],
          }]),
        },
      }),
    },
  }, args);
}
