import type { IdType } from '@repo-breteuil/common-definitions';

import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleCriticalError } from '@repo-breteuil/front-error';
import {
  GetUserDayOffRequest,
  UpdateUserDayOffRequest, type UpdateUserDayOffRequestArgs,
  DeleteUserDayOffRequest,
} from './api';

export default class UserDayOffRequestDialogStore
{
  public request = new Fetchable(GetUserDayOffRequest, { catchUnhandled: handleCriticalError });

  public async updateDayOffRequest(args: UpdateUserDayOffRequestArgs)
  {
    await UpdateUserDayOffRequest(args);
  }

  public async deleteDayOffRequest(id: IdType)
  {
    await DeleteUserDayOffRequest(id);
  }

  public async open(id: IdType)
  {
    await this.request.ensureSuccessReload({ id });
  }

  public close()
  {
    this.request.reset();
  }
}
