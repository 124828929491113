import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface MyBreteuilAgencyUpdateVariables
{
  id: number,
  slug?: string | undefined,
  public?: boolean | undefined,
  physicalAgency?: boolean | undefined,
  position?: number | undefined,
  latitude?: number | undefined,
  longitude?: number | undefined,
  timezone?: string | undefined,
  coverPicture?: File | null | undefined,
}

async function UpdateMyBreteuilAgency(args: MyBreteuilAgencyUpdateVariables): Promise<void>
{
  await mutation({
    operationName: 'Agencies_UpdateMyBreteuilAgency',
    variables: {
      id: 'Int!',
      slug: 'String',
      public: 'Boolean',
      physicalAgency: 'Boolean',
      position: 'Float',
      latitude: 'Float',
      longitude: 'Float',
      timezone: 'String',
      coverPicture: 'Upload',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        admin: errorWrapper({
          myBreteuilAgency: {
            update: [{ args: {
              id: true,
              slug: true,
              public: true,
              physicalAgency: true,
              position: true,
              latitude: true,
              longitude: true,
              timezone: true,
              coverPicture: true,
            } }, {
              id: true,
            }],
          },
        }),
      }),
    },
  }, args);
}

export default UpdateMyBreteuilAgency;
