import * as React from "react";
import { Typography } from '@mui/material';

export interface TransactionStatusColoredProps {
  color: string,
  status: string,
}

const TransactionStatusColored = (props: TransactionStatusColoredProps) => {
  const { color, status } = props;
  return (
    <Typography component="span" sx={{ color: color }}>
      {status}
    </Typography>
  );
};


export default TransactionStatusColored;
