import { observable, action } from 'mobx';
import { RootPages } from '@my-breteuil/store/routing/pages';

class RoutingStore
{
  @observable public currentPage: RootPages;

  @action public setCurrentPage(page: RootPages)
  {
    this.currentPage = page;
  }

  loginRedirectRoute: string | null = null;
}

export default new RoutingStore();
