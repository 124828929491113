import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationExtendedResponse, PaginationBaseArgs } from '@repo-lib/graphql-query-pagination';
import type {
  CommunicationChannelEnum,
  Currency,
  Language,
  PropertyStatus,
  MyBreteuilPropertyVisibility,
  PropertyType,
  MyBreteuilHeating,
  MyBreteuilWaterHeating,
  OperationType,
} from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResult,
  PaginationArgs,
  PaginationVariables,
} from '@core/api/graphql/gen-v2';
import { PictureFormat, RapprochementType } from '@core/api/types';

export type Property = {
  id: IdType,
  currency: Currency,
  status: PropertyStatus,
  addrStreetAddress: string | null,
  ref: string,
  slug: string,
  price: number,
  surface: number,
  bedrooms: number,
  district: {
    id: IdType,
    name: string, // TODO use District.displayName instead of name
    displayName: string,
  },
  publication: MyBreteuilPropertyVisibility,
  pricePerSurface: number,
  propertyType: PropertyType,
  heating: MyBreteuilHeating,
  waterHeatType: MyBreteuilWaterHeating,
  constructionYear: number,
  bathrooms: number,
  toilets: number,
  rooms: number,
  picturesURL: Array<string>,
  marketConsistent: boolean,
  managerMarketConsistent: boolean,
  operationType: OperationType,
  floor: number,
  floorsCount: number,
}

export type ContactSentPropertyInfo = {
  targets: Array<string>,
  sentDate: number/*timestamp*/,
  sentChannel: CommunicationChannelEnum,
  sentAuto: boolean,
  sentPropertyPrice: number,
  propertyId: IdType,
  clicksCount: number,
  unhandledInterestsCount: number,
  lastInterestDate: number | null/*timestamp*/,
  hasNegativeFeedback: boolean,
  hasViewing: boolean,
  hasWhatsApp: boolean,
  sentAgentsInSignature: Array<{
    id: IdType,
    firstname: string,
    lastname: string,
    fullname: string,
    agent: boolean,
    isAgencyPotCommun: boolean,
    contactSearchAgency: {
      id: IdType,
      code: string,
      name: string,
    } | null,
    __typename: 'MyBreteuilUser',
  } | {
    id: IdType,
    name: string,
    code: string,
    __typename: 'MyBreteuilAgency',
  }>,
  property: Property,
}

export interface SentPropertiesFilterArgs
{
  propertyStatus?: Array<PropertyStatus> | undefined,
  withViewings?: boolean | undefined,
  withUnhandledInterests?: boolean | undefined,
  withClicks?: boolean | undefined,
}

export interface GetContactSentPropertiesArgs extends SentPropertiesFilterArgs, PaginationBaseArgs
{
  id: IdType,
  language: Language,
}

export default function GetContactSentProperties(args: GetContactSentPropertiesArgs)
{
  return query<PaginationExtendedResponse<ContactSentPropertyInfo>>({
    operationName: 'Contact_GetContactSentProperties',
    variables: {
      id: "Int!",
      language: 'Language!',
      ...PaginationVariables,
      propertyStatus: '[MyBreteuilPropertyStatus!]',
      withViewings: 'Boolean',
      withUnhandledInterests: 'Boolean',
      withClicks: 'Boolean',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: [{ args: { id: true } }, {
          sentProperties: [{
            args: {
              ...PaginationArgs,
              propertyStatus: true,
              withViewings: true,
              withUnhandledInterests: true,
              withClicks: true,
            },
          }, paginationResult({
            targets: true,
            sentDate: true,
            sentChannel: true,
            sentAuto: true,
            sentPropertyPrice: true,
            propertyId: true,
            clicksCount: true,
            unhandledInterestsCount: true,
            lastInterestDate: true,
            hasNegativeFeedback: [{
              args: {
                type: { literal: RapprochementType.NegativeFeedback },
                currentUserOnly: { literal: 'true' },
              },
              aliasedField: 'hasRapprochement',
            }, true],
            hasViewing: [{
              args: {
                type: { literal: RapprochementType.Viewing },
                currentUserOnly: { literal: 'true' },
              },
              aliasedField: 'hasRapprochement',
            }, true],
            hasWhatsApp: [{
              args: {
                type: { literal: RapprochementType.WhatsApp },
                currentUserOnly: { literal: 'true' },
              },
              aliasedField: 'hasRapprochement',
            }, true],
            sentAgentsInSignature: [{
              polymorphicFields: {
                MyBreteuilUser: {
                  id: true,
                  firstname: true,
                  lastname: true,
                  fullname: true,
                  agent: true,
                  isAgencyPotCommun: true,
                  contactSearchAgency: {
                    id: true,
                    code: true,
                    name: true,
                  },
                },
                MyBreteuilAgency: {
                  id: true,
                  name: true,
                  code: true,
                },
              },
            }, {}],
            property: {
              id: true,
              currency: true,
              status: true,
              addrStreetAddress: true,
              ref: true,
              slug: [{ args: { language: true }}, true ],
              price: true,
              surface: true,
              bedrooms: true,
              district: {
                id: true,
                name: true,
                displayName: [{ args: { language: true } }, true],
              },
              publication: true,
              pricePerSurface: true,
              propertyType: true,
              heating: true,
              waterHeatType: true,
              constructionYear: true,
              bathrooms: true,
              toilets: true,
              rooms: true,
              picturesURL: [{ args: { format: { literal: PictureFormat._360p }}}, true],
              marketConsistent: true,
              managerMarketConsistent: true,
              operationType: true,
              floor: true,
              floorsCount: true,
            },
          })],
        }],
      }),
    },
  }, args);
}
