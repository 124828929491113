import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import {
  type BuyerContact,
  BuyerContactFields,
  type ContactBaseArgs,
  ContactBaseArgsFields,
} from './shared';

export default function GetPinnedBuyerContacts(args: ContactBaseArgs)
{
  return query<Array<BuyerContact>>({
    operationName: 'Buyers_GetUserPinnedContacts',
    variables: ContactBaseArgsFields,
    fieldsSelection: {
      authenticated: errorWrapper({
        pinnedContacts: [{
          args: {
            origin: 'pinnedOrigin',
          },
        }, BuyerContactFields],
      }),
    },
  }, args);
}
