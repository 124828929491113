import type { TransformValueType } from '@repo-lib/utils-core';

import { action, observable } from 'mobx';
import {
  query,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface TimeRange
{
  from?: number/*Timestamp*/ | undefined,
  to?: number/*Timestamp*/ | undefined,
}

export interface AnalyticsResultType<T extends ReadonlyArray<TimeRange>>
{
  clientArea: {
    clientAreaSignUps: TransformValueType<T, number>,
    clientAreaSignUpsVerifiedEmail: TransformValueType<T, number>,
    clientAreaSignUpsRequiringActivation: TransformValueType<T, number>,
    clientAreaSignUpsWithNewContact: TransformValueType<T, number>,
    searchesCreatedOrUpdated: TransformValueType<T, number>,
    alertEmailsSent: TransformValueType<T, number>,
  },
}

async function _query<T extends ReadonlyArray<TimeRange>>(
  ranges: T,
): Promise<AnalyticsResultType<T>>
{
  return query({
    operationName: 'Analytics_GetAnalytics',
    variables: {
      ranges: '[TimeRange!]!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        admin: errorWrapper({
          analytics: [{ preventUnwrap: true }, {
            clientArea: {
              clientAreaSignUps: [{args: { ranges: true }}, true],
              clientAreaSignUpsVerifiedEmail: [{ args: { ranges: true }}, true],
              clientAreaSignUpsRequiringActivation: [{args: { ranges: true }}, true],
              clientAreaSignUpsWithNewContact: [{args: { ranges: true }}, true],
              searchesCreatedOrUpdated: [{args: { ranges: true }}, true],
              alertEmailsSent: [{args: { ranges: true }}, true],
            },
          }],
        }),
      }),
    },
  }, { ranges });
}

export type EventType
  = 'ClientAreaSignUps'
  | 'ClientAreaSignUpsVerifiedEmail'
  | 'ClientAreaSignUpsRequiringActivation'
  | 'ClientAreaSignUpsWithNewContact'
  | 'SearchesCreatedOrUpdated'
  | 'AlertEmailsSent'
;

export type EventAnalytics = Readonly<[ number, number, number, number, number ]>;
export type EventAnalyticsTimeRanges = TransformValueType<EventAnalytics, TimeRange>;

class Analytics
{
  @observable events: Record<EventType, EventAnalytics>;

  get timeRanges(): EventAnalyticsTimeRanges
  {
    const Day = 24 * 3600 * 1000;
    const Week = 7 * Day;
    const Month = 30 * Day;

    const now = Date.now();

    return [{

      //Day
      to: now,
      from: now - Day,
    }, {

      //Week
      to: now,
      from: now - Week,
    }, {

      //Month
      to: now,
      from: now - Month,
    }, {

      //3 Month
      to: now,
      from: now - 3 * Month,
    }, {

      //Total
    }] as const;
  }

  @action private update(analytics: AnalyticsResultType<EventAnalyticsTimeRanges>)
  {
    const { clientArea } = analytics;
    this.events = {
      ClientAreaSignUps: clientArea.clientAreaSignUps,
      ClientAreaSignUpsVerifiedEmail: clientArea.clientAreaSignUpsVerifiedEmail,
      ClientAreaSignUpsRequiringActivation: clientArea.clientAreaSignUpsRequiringActivation,
      ClientAreaSignUpsWithNewContact: clientArea.clientAreaSignUpsWithNewContact,
      SearchesCreatedOrUpdated: clientArea.searchesCreatedOrUpdated,
      AlertEmailsSent: clientArea.alertEmailsSent,
    };
  }

  async refresh()
  {
    const analytics = await _query(this.timeRanges);
    this.update(analytics);
  }
};

export default new Analytics();
