import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';
import { TransactionFields, type Transaction } from './shared';

export interface UpdateTransactionArgs
{
  id: IdType,
  floor?: number | null | undefined,
  constructionYear?: number | null | undefined,
  comment?: string | null | undefined,
}

export default function UpdateTransaction(args: UpdateTransactionArgs)
{
  return mutation<Transaction>({
    operationName: 'Valuation_UpdateTransaction',
    variables: {
      id: 'Int!',
      floor: 'Int',
      constructionYear: 'Int',
      comment: 'String',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        transaction: {
          update: [{
            args: {
              id: true,
              floor: true,
              constructionYear: true,
              comment: true,
            },
          }, TransactionFields],
        },
      }),
    },
  }, args);
}
