import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { Locale } from '@repo-breteuil/common-texts';

export interface Args
{
  sourceText: string,
  sourceLanguage: Locale,
  targetLanguage: Locale,
}

export default function Valuation_GetTranslatedPropertyDescription(args: Args)
{
  return query<string>(
    {
      operationName: 'Valuation_GetTranslatedPropertyDescription',
      variables: {
        sourceText: 'String!',
        sourceLanguage: 'Language!',
        targetLanguage: 'Language!',
      },
      fieldsSelection: {
        authenticated: errorWrapper({
          ai: {
            translatedText: [
              {
                args: {
                  sourceText: true,
                  sourceLanguage: true,
                  targetLanguage: true,
                },
              },
              true,
            ],
          },
        }),
      },
    },
    args,
  );
}
