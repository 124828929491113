import { MapPointType, type TransactionFilter } from '@core/api/types';
import type { TransactionMapBoundaries } from '@repo-breteuil/react-components';
import type { TransactionsInfo } from './api';

import { observable, action, computed, autorun } from 'mobx';
import { intRange } from '@repo-lib/utils-core';
import { Fetchable, ObservablePromiseStatus } from '@repo-lib/utils-mobx-store';
import { SuspendableReaction } from '@repo-lib/utils-mobx-store';
import { handleCriticalError, handleNonCriticalError } from '@repo-breteuil/front-error';
import googleMapsAPI from '@repo-breteuil/front-store-gmaps';
import { RootPages, store as routingStore } from '@my-breteuil/store/routing';
import {
  GetGeometryAreasTransactionsVolume,
  GetMapPoints,
} from './api';

export interface Box
{
  lngMin: number,
  lngMax: number,
  latMin: number,
  latMax: number,
}

export function makeSquare(box: Box): Array<google.maps.LatLngLiteral>
{
  const { lngMin, lngMax, latMin, latMax } = box;
  return [
    { lat: latMin, lng: lngMin },
    { lat: latMin, lng: lngMax },
    { lat: latMax, lng: lngMax },
    { lat: latMax, lng: lngMin },
    { lat: latMin, lng: lngMin },
  ];
}

interface CellInfo
{
  paths: Array<google.maps.LatLngLiteral>,
  volume: number,
  nbAgencies: number,
}

interface GridInfo
{
  cells: Array<CellInfo>,
  dateFilter: {
    min?: number/*Timestamp*/ | undefined,
    max?: number/*Timestamp*/ | undefined,
  },
  globalInfo: TransactionsInfo,
  filteredInfo: TransactionsInfo,
}

class HeatMapStore
{
  static BreteuilMarketShare = 0.03;
  static SideMinSize = 1;
  static SideMaxSize = 14;

  @observable private _side = 7;
  @observable private _p1: google.maps.LatLngLiteral | null = null;
  @observable private _p2: google.maps.LatLngLiteral | null = null;
  @observable private _mouseLocation: google.maps.LatLngLiteral | null = null;
  @observable private _transactionsFilters: TransactionFilter = {};
  @observable private _displayResultsPerAgency: boolean = false;
  private _northEastBoundaries: google.maps.LatLngLiteral | null = null;
  private _southWestBoundaries: google.maps.LatLngLiteral | null = null;

  public get sideMinSize()
  {
    return HeatMapStore.SideMinSize;
  }

  public get sideMaxSize()
  {
    return HeatMapStore.SideMaxSize;
  }

  public get side()
  {
    return this._side;
  }

  public get p1()
  {
    return this._p1;
  }

  public get p2()
  {
    return this._p2;
  }

  public get transactionsFilters()
  {
    return this._transactionsFilters;
  }

  @action public setTransactionsFilters(transactionsFilters: TransactionFilter)
  {
    this._transactionsFilters = transactionsFilters;
  }

  public get box()
  {
    const { p1, p2 } = this;
    if (!p1 || !p2)
      return null;
    return {
      latMin: Math.min(p1.lat, p2.lat),
      latMax: Math.max(p1.lat, p2.lat),
      lngMin: Math.min(p1.lng, p2.lng),
      lngMax: Math.max(p1.lng, p2.lng),
    };
  }

  @computed public get grid(): Array<Array<google.maps.LatLngLiteral>> | null
  {
    const { box, side } = this;
    if (!box)
      return null;
    const { lngMin, lngMax, latMin, latMax } = box;
    const lngStep = (lngMax - lngMin) / side;
    const latStep = (latMax - latMin) / side;
    return intRange(latMin, side, latStep).map(latMin => (
      intRange(lngMin, side, lngStep).map(lngMin => makeSquare({
        lngMin,
        lngMax: lngMin + lngStep,
        latMin,
        latMax: latMin + latStep,
      }))
    )).reduce((res, line) => res.concat(line), []);
  }

  public get displayResultsPerAgency()
  {
    return this._displayResultsPerAgency;
  }

  @action public setDisplayResultsPerAgency(value: boolean)
  {
    this._displayResultsPerAgency = value;
  }

  @action public setSide(side: number)
  {
    this._side = Math.max(HeatMapStore.SideMinSize, Math.min(side, HeatMapStore.SideMaxSize));
  }

  @action public resetPoints()
  {
    this._p1 = null;
    this._p2 = null;
  }

  @action.bound public handleMapClick(event: google.maps.MapMouseEvent)
  {
    const { latLng } = event;
    if (!latLng)
      return;
    if (this._p1 === null)
      this._p1 = latLng.toJSON();
    else if (this._p2 === null)
      this._p2 = latLng.toJSON();
    else
    {
      this._p1 = latLng.toJSON();
      this._p2 = null;
    }
  }

  @action.bound public handleMapMouseMove(event: google.maps.MapMouseEvent)
  {
    const { latLng } = event;
    if (!latLng)
      return;
    this._mouseLocation = latLng.toJSON();
  }

  @action.bound public saveMapBoudaries(mapBoudaries: TransactionMapBoundaries | null) {
    if (!mapBoudaries)
      return;
    this._northEastBoundaries = mapBoudaries.northEast;
    this._southWestBoundaries = mapBoudaries.southWest;
  }

  @action.bound public setGridBoundaries()
  {
    this._p1 = this._northEastBoundaries;
    this._p2 = this._southWestBoundaries;
  }

  @computed get mouseDistancesFromP1()
  {
    if (!this._p1 || !this._mouseLocation)
      return null;
    const origin = this._p2 || this._mouseLocation;
    return {
      latDistanceMeters: google.maps.geometry.spherical.computeDistanceBetween(
        this._p1,
        { lat: origin.lat, lng: this._p1.lng },
      ),
      lngDistanceMeters: google.maps.geometry.spherical.computeDistanceBetween(
        this._p1,
        { lat: this._p1.lat, lng: origin.lng },
      ),
    };
  }

  static isCoordsInBounds(args: {
    coords: google.maps.LatLngLiteral,
    bounds: {
      bottomLeft: google.maps.LatLngLiteral,
      topRight: google.maps.LatLngLiteral,
    },
  }): boolean
  {
    const { coords, bounds } = args;
    const { bottomLeft, topRight } = bounds;
    return (bottomLeft.lat < coords.lat && coords.lat < topRight.lat
      && bottomLeft.lng < coords.lng && coords.lng < topRight.lng);
  }

  public getNbAgenciesinBounds(args: {
    bottomLeft: google.maps.LatLngLiteral,
    topRight: google.maps.LatLngLiteral,
  }): number
  {
    const { bottomLeft, topRight } = args;
    if (!this._allAgenciesMapPoints.result)
      return 0;
    return this._allAgenciesMapPoints.result.reduce((nbAgencies, agencyPoint) => {
      const { latitude, longitude } = agencyPoint;
      if (HeatMapStore.isCoordsInBounds({
        coords: { lat: latitude, lng: longitude },
        bounds: { bottomLeft, topRight },
      }))
        return nbAgencies + 1;
      return nbAgencies;
    }, 0);
  }

  public async fetchGridInfo(
    grid: Array<Array<google.maps.LatLngLiteral>> | null,
    transactionsFilters: TransactionFilter,
  ): Promise<GridInfo | null>
  {
    if (!grid)
      return null;
    const {
      dateFilter,
      globalInfo,
      filteredInfo,
      volumes,
    } = await GetGeometryAreasTransactionsVolume(grid, transactionsFilters);
    return {
      dateFilter,
      globalInfo,
      filteredInfo,
      cells: grid.map((paths, i) => ({
        paths,
        volume: volumes[i],
        nbAgencies: this.getNbAgenciesinBounds({ bottomLeft: paths[0], topRight: paths[2] }),
      })),
    };
  }

  private _gridInfoFetch = new ObservablePromiseStatus<GridInfo | null>();

  private get _gridInfo(): GridInfo | null
  {
    return this._gridInfoFetch.result || null;
  }

  @action private refreshGridInfo(
    grid: Array<Array<google.maps.LatLngLiteral>> | null,
    transactionsFilters: TransactionFilter,
  )
  {
    this._gridInfoFetch.track(this.fetchGridInfo(grid, transactionsFilters), {
      catchCurrent: handleCriticalError,
      catchDropped: handleNonCriticalError,
    });
  }

  @computed public get gridInfo()
  {
    if (!this._gridInfo)
      return null;
    const {
      dateFilter,
      globalInfo,
      filteredInfo,
      cells: grid,
    } = this._gridInfo;
    const volumes = grid.map(({ volume }) => volume);
    return {
      dateFilter,
      globalInfo,
      filteredInfo,
      grid,
      minVolume: Math.min(...volumes),
      maxVolume: Math.max(...volumes),
    };
  }

  public get periodStart(): number/*Timestamp*/
  {
    if (this._gridInfo)
    {
      const { dateFilter, globalInfo } = this._gridInfo;
      if (dateFilter.min !== undefined)
        return dateFilter.min;
      return globalInfo.startDate ?? 0;
    }
    return 0;
  }

  public get periodEnd(): number/*Timestamp*/
  {
    if (this._gridInfo)
    {
      const { dateFilter, globalInfo } = this._gridInfo;
      if (dateFilter.max !== undefined)
        return dateFilter.max;
      return globalInfo.endDate ?? 0;
    }
    return 0;
  }

  public get periodInYears(): number
  {
    const YearDuration = 365.25 * 24 * 3600 * 1000; //Approximation
    return (this.periodEnd.valueOf() - this.periodStart.valueOf()) / YearDuration;
  }

  public getYearlyPotentialCA(volume: number)
  {
    const { periodInYears } = this;
    if (periodInYears === 0)
      return 0;
    return (volume / periodInYears) * HeatMapStore.BreteuilMarketShare;
  }

  private gridReaction = new SuspendableReaction(
    () => [ this.grid, this.transactionsFilters ] as const,
    ([ grid, transactionsFilters ]) => this.refreshGridInfo(grid, transactionsFilters),
    { initiallySuspended: true, fireImmediately: false },
  );

  private _allAgenciesMapPoints = new Fetchable(
    () => GetMapPoints({
      filter: {
        type: {
          in: [
            MapPointType.BreteuilAgency,
            MapPointType.CompetitorAgency,
          ],
        },
      },
    }),
    { catchUnhandled: handleNonCriticalError },
  );

  @observable private _selectedAgencyLayers: Array<string> = [];

  get selectedAgencyLayers()
  {
    return this._selectedAgencyLayers;
  }

  @action setSelectedAgencyLayers(layers: Array<string>)
  {
    this._selectedAgencyLayers = layers;
  }

  @computed public get agenciesMapPoints()
  {
    const allAgenciesPoints = this._allAgenciesMapPoints.lastResult ?? [];
    return allAgenciesPoints.filter((agencyPoint) => (
      this._selectedAgencyLayers.includes(agencyPoint.layer.name)
    ));
  }

  public get active()
  {
    return routingStore.currentPage === RootPages.MarketDataHeatMap;
  }

  public async refresh()
  {
    await this._allAgenciesMapPoints.ensureSuccessReload();
  }

  constructor()
  {
    autorun(() => {
      if (this.active)
      {
        googleMapsAPI.init(__GOOGLE_MAPS_API_KEY__);
        this.gridReaction.resume();
      }
      else
      {
        this.gridReaction.suspend();
      }
    });
  }
}

export default new HeatMapStore();
