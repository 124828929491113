import type { PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationArgs,
} from '@core/api/graphql/gen-v2';
import {
  type BuyerContact,
  BuyerContactFields,
  type ContactsArgs,
  ContactsArgsFields,
} from './shared';

export default function GetBuyerContacts(variables: ContactsArgs)
{
  return query<PaginationExtendedResponse<BuyerContact>>({
    operationName: 'Buyers_GetContacts',
    variables: ContactsArgsFields,
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContacts: [{
          args: {
            ...ExtendedPaginationArgs,
            responsibleUsers: true,
            excludePinned: 'pinnedOrigin',
            buyers: { literal: true },
          },
        }, paginationResult(BuyerContactFields)],
      }),
    },
  }, variables);
}
