import type { IdType } from '@repo-breteuil/common-definitions';
import type { AdvancedTableColumnOrder } from '@lib/components/data-table/advanced-table';
import type {
  MyBreteuilProperty,
  Filters__MyBreteuilProperty,
} from './api';

import { observable, action, computed, runInAction } from 'mobx';
import { OperationType, OrderField, OrderType, PropertyFavoriteStatus, PropertyStatus, UserVisibilityScope } from '@core/api/types';
import { UncachedPagination, makePaginationResultsAutoObservable } from '@repo-lib/graphql-query-pagination';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleCriticalError } from '@repo-breteuil/front-error';
import { formatGlobalErrorMessage } from '@core/store/GlobalMessage';
import localeStore from '@core/store/Locales';
import sessionStore from '@my-breteuil/store/ui/common/Session';
import MessageDrawerStore from '@my-breteuil/store/ui/common/message-drawer';
import PropertyConfidentialLinkDialogStore from '@my-breteuil/store/ui/common/property-confidential-link-dialog';
import PropertyTransferDialogStore from '@my-breteuil/store/ui/common/property-transfer';
import {
  InitQuery,
  GetProperties,
  GetVisiblePropertyUsers,
  UpdateProperty,
  GetPhotographers,
  RepublishProperty,
  SetPropertyPinned,
  type SetPropertyPinnedArgs,
  GetPinnedProperties,
} from './api';

export type PropertiesListView
  = 'default'
  | 'admin-documents'
;

class PropertiesStore
{
  @observable private _clientMode: boolean = false;
  @observable private _view: PropertiesListView = 'default';

  public get clientMode()
  {
    return this._clientMode;
  }

  public get view()
  {
    return this._view;
  }

  @action.bound public toggleClientMode()
  {
    this._clientMode = !this._clientMode;
  }

  @action.bound public setView(view: PropertiesListView)
  {
    this._view = view;
  }

  private static DefaultPropertiesPageSize = 50;

  private _order: OrderField | null = {
    fieldName: 'lastRelevantPriceOrStatusChange',
    ordering: OrderType.DESC,
  };
  get order()
  {
    return this._order;
  }

  public get defaultFilter(): Filters__MyBreteuilProperty
  {
    const defaultOperationType = sessionStore.mybUser?.defaultOperationType;
    return {
      operationType: defaultOperationType ? { equals: defaultOperationType } : undefined,
      status: {
        in: [
          PropertyStatus.Mandate,
          PropertyStatus.ViewingAuthorization,
        ],
      },
      ref: {
        contains: defaultOperationType !== OperationType.ResidencyTransaction ? 'FR75LO' : '',
      },
    };
  };

  private _filter: Filters__MyBreteuilProperty | null = null;
  get filter(): Filters__MyBreteuilProperty
  {
    return (this._filter === null) ? this.defaultFilter : this._filter;
  }

  @computed get matchesRapprochementsUsersSubsetOptions()
  {
    return [
      UserVisibilityScope.Self,
      UserVisibilityScope.SelfAgency,
      ...(sessionStore.zoneLeader ? [ UserVisibilityScope.SelfAgenciesGroup ] : []),
    ];
  }

  @observable private _matchesRapprochementsUsersSubset = UserVisibilityScope.Self;

  get matchesRapprochementsUsersSubset()
  {
    return this._matchesRapprochementsUsersSubset;
  }

  async setMatchesRapprochementsUsersSubsetAndReload(newSubset: UserVisibilityScope)
  {
    this._matchesRapprochementsUsersSubset = newSubset;
    await this.refreshPropertiesAndPinned();
  }

  public pinnedProperties = new Fetchable(() => GetPinnedProperties({
    matchesRapprochementsUsersSubset: this._matchesRapprochementsUsersSubset,
    language: localeStore.currentLocale,
  }), { catchUnhandled: handleCriticalError });

  public properties = new UncachedPagination({
    fetch: (baseArgs) => GetProperties({
      ...baseArgs,
      orderBy: this._order ? [this._order] : undefined,
      filter: this.filter,
      matchesRapprochementsUsersSubset: this._matchesRapprochementsUsersSubset,
      language: localeStore.currentLocale,
    }).then(makePaginationResultsAutoObservable),
    pageSize: PropertiesStore.DefaultPropertiesPageSize,
  });

  @computed get propertiesAndPinned()
  {
    const pinnedProperties = this.pinnedProperties.lastResult;
    const properties = this.properties.data?.items;
    return [
      ...(pinnedProperties ?? []),
      ...(properties ?? []),
    ];
  }

  @computed get propertiesStats()
  {
    const res = {
      matchesCountSum: 0,
      rapprochedMatchesCountSum: 0,
    };
    const properties = this.properties.data?.items;
    if (!properties || properties.length <= 0)
      return res;
    for (const property of properties)
    {
      const { matchesRapprochements } = property;
      const { matchesCount, rapprochedMatchesCount } = matchesRapprochements;
      res.matchesCountSum += matchesCount;
      res.rapprochedMatchesCountSum += rapprochedMatchesCount;
    }
    return res;
  }

  public setOrderBy(order: AdvancedTableColumnOrder<string> | null)
  {
    this._order = (order !== null ? {
      fieldName: order.column,
      ordering: order.desc ? OrderType.DESC : OrderType.ASC,
    } : null);
  }

  public setFilter(newFilter: Filters__MyBreteuilProperty)
  {
    this._filter = newFilter;
  }

  public resetFilters()
  {
    this._filter = {};
  }

  public selectedProperties = observable.map<IdType, MyBreteuilProperty>();

  @computed get selectedPropertiesList()
  {
    return Array.from(this.selectedProperties.values());
  }

  private _initialData = new Fetchable(InitQuery, { catchUnhandled: handleCriticalError });

  public visibleUsers = new Fetchable(GetVisiblePropertyUsers, { catchUnhandled: handleCriticalError });

  public photographers = new Fetchable(GetPhotographers, { catchUnhandled: handleCriticalError });

  public async refreshPropertiesAndPinned()
  {
    await Promise.all([
      this.properties.reload(),
      this.pinnedProperties.ensureSuccessReload(),
    ]);
  }

  public async refresh()
  {
    const [ initialData ] = await Promise.all([
      this._initialData.ensureSuccessReload(),
      this.refreshPropertiesAndPinned(),
    ]);
    this.visibleUsers.setResult(initialData.visiblePropertyUsers);
    this.photographers.setResult(initialData.photographers);
  }

  public messageDrawerStore = new MessageDrawerStore({
    onMessageSentSuccess: () => {
      this.selectedProperties.clear();
      this.refreshPropertiesAndPinned().catch((error) => formatGlobalErrorMessage(error));
    },
  });

  public openMessageDrawer()
  {
    this.messageDrawerStore.open({
      properties: () => this.selectedPropertiesList,
    });
  }

  public propertyConfidentialLinkDialogStore = new PropertyConfidentialLinkDialogStore();

  public propertyTransferDialogStore = new PropertyTransferDialogStore();

  public updateProperty = UpdateProperty;

  public updateLocalProperty(propertyId: IdType, effect: (property: MyBreteuilProperty) => void)
  {
    const property = this.properties.data?.items.find((property) => (property.id === propertyId)) || this.pinnedProperties.lastResult?.find((p) => p.id === propertyId);
    if (property)
      runInAction(() => effect(property));
  }

  public async setPropertiesBoolean(
    args: {
      id: IdType,
      favoriteStatus?: PropertyFavoriteStatus | null | undefined,
      marketConsistent?: boolean | undefined,
      managerMarketConsistent?: boolean | undefined,
      priceDecreaseRequest?: number | null | undefined,
      verifiedWarrant?: boolean | undefined,
      exclusive?: boolean | undefined,
    },
  )
  {
    await UpdateProperty(args);
    this.updateLocalProperty(args.id, (property) => {
      if (args.favoriteStatus !== undefined)
        property.favoriteStatus = args.favoriteStatus;
      if (args.marketConsistent !== undefined)
        property.marketConsistent = args.marketConsistent;
      if (args.managerMarketConsistent !== undefined)
        property.managerMarketConsistent = args.managerMarketConsistent;
      if (args.priceDecreaseRequest !== undefined)
        property.priceDecreaseRequest = args.priceDecreaseRequest === null ? null : { price: args.priceDecreaseRequest, fulfilled: false };
      if (args.verifiedWarrant !== undefined)
        property.verifiedWarrant = args.verifiedWarrant;
      if (args.exclusive !== undefined)
        property.exclusive = args.exclusive;
    });
  };

  public async republishProperty(propertyId: IdType)
  {
    await RepublishProperty({ propertyId });
    await this.refreshPropertiesAndPinned();
  }

  public async setPropertyPinned(args: SetPropertyPinnedArgs)
  {
    await SetPropertyPinned(args);
    await this.refreshPropertiesAndPinned();
  }
}

export default new PropertiesStore();
