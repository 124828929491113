import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationBaseArgs, PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import type {
  OrderBy,
  MyBreteuilFeedbackType,
  Filters__Date,
  Filters__String,
  Filters__Enum,
  Filters__Int,
} from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationArgs,
  ExtendedPaginationVariables,
} from '@core/api/graphql/gen-v2';

interface MyBreteuilUser
{
  id: IdType,
  firstname: string,
  lastname: string,
  fullname: string,
  agent: boolean,
  isAgencyPotCommun: boolean,
  contactSearchAgency: {
    id: IdType,
    code: string,
    name: string,
  } | null,
}

interface MyBreteuilProperty
{
  id: IdType,
  slug: string,
  ref: string,
  refId: number,
}

interface MyBreteuilContact
{
  id: IdType,
  fullname: string,
}

export interface MyBreteuilFeedback
{
  id: IdType,
  date: number/*timestamp*/,
  feedback: MyBreteuilFeedbackType,
  user: MyBreteuilUser | null,
  property: MyBreteuilProperty | null,
  contact: MyBreteuilContact | null,
}

export interface Filters__MyBreteuilFeedback
{
  date?: Filters__Date | undefined,
  property?: { ref?: Filters__String | undefined } | undefined,
  contact?: { fullname: Filters__String | undefined } | undefined,
  feedback?: Filters__Enum<MyBreteuilFeedbackType> | undefined,
  userId?: Filters__Int | undefined,
}

export interface GetFeedbacksVariables extends PaginationBaseArgs
{
  filter?: Filters__MyBreteuilFeedback | undefined,
  orderBy?: OrderBy | undefined,
}

export default function GetFeedbacks(variables: GetFeedbacksVariables)
{
  return query<PaginationExtendedResponse<MyBreteuilFeedback>>({
    operationName: 'Logs_GetFeedbacks',
    variables: ExtendedPaginationVariables('MyBreteuilFeedback'),
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          logs: {
            feedbacks: [{
              args: ExtendedPaginationArgs,
            }, paginationResult({
              id: true,
              date: true,
              feedback: true,
              user: {
                id: true,
                firstname: true,
                lastname: true,
                fullname: true,
                agent: true,
                isAgencyPotCommun: true,
                contactSearchAgency: {
                  id: true,
                  code: true,
                  name: true,
                },
              },
              property: {
                id: true,
                slug: true,
                ref: true,
                refId: true,
              },
              contact: {
                id: true,
                fullname: true,
              },
            })],
          },
        },
      }),
    },
  }, variables);
}
