import type { IdType } from '@repo-breteuil/common-definitions';

import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import { OrderType } from '@core/api/types';
import localesStore from '@core/store/Locales';
import {
  GetValuationsPDFs, type Filters__MyBreteuilPropertyValuationPDF,
  UpdateValuationPDF, type UpdateValuationPDFArgs,
  DenyValidation,
} from './api';

class ValuationsPDFsStore
{
  private static ValuationsPDFsDefaultPageSize = 200;
  public readonly ValuationsPDFsPageSizeOptions = [ 50, 100, 200, 500 ];

  public filters: Filters__MyBreteuilPropertyValuationPDF = {
    pending: { equals: true },
  };

  public async setFiltersAndRefresh(newFilters: Filters__MyBreteuilPropertyValuationPDF)
  {
    this.filters = newFilters;
    await this.valuationsPDFs.first();
  }

  public valuationsPDFs = new UncachedPagination({
    fetch: (baseArgs) => GetValuationsPDFs({
      ...baseArgs,
      filter: this.filters,
      order: [{ fieldName: 'createdAt', ordering: OrderType.DESC }],
      language: localesStore.currentLocale,
    }),
    pageSize: ValuationsPDFsStore.ValuationsPDFsDefaultPageSize,
  });

  public async updateValuation(args: UpdateValuationPDFArgs)
  {
    await UpdateValuationPDF(args);
    await this.valuationsPDFs.first();
  }

  public async denyValidation(args: { id: IdType, comment: string })
  {
    await DenyValidation(args);
    await this.valuationsPDFs.first();
  }

  public async refresh()
  {
    await this.valuationsPDFs.first();
  }
}

export default new ValuationsPDFsStore();
