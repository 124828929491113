import { runInAction } from 'mobx';
import type { IdType } from '@repo-breteuil/common-definitions';
import { UncachedPagination, makePaginationResultsAutoObservable } from '@repo-lib/graphql-query-pagination';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import {
  ContactOwnershipFilter,
  OrderType,
  type OrderField,
} from '@core/api/types';
import { handleCriticalError } from '@repo-breteuil/front-error';
import { formatGlobalErrorMessage } from '@core/store/GlobalMessage';
import localesStore from '@core/store/Locales';
import PaginationSelection from '@my-breteuil/store/utils/PaginationSelection';
import ReminderDialogStore from '@my-breteuil/store/ui/common/reminder-dialog';
import {
  GetPropertyMatches,
  SetMatchBanned,
  type Filters__MyBreteuilMatch,
  type SetMatchBannedVariables,
  AddNegativePropertyFeedback, type AddNegativePropertyFeedbackArgs,
  RemoveNegativePropertyFeedback, type RemoveNegativePropertyFeedbackArgs,
  GetMatchesCriteria,
  GetMatchesUsers,
} from './api';
import propertyStore from './index';

export class PropertyMatchesStore {
  get propertyMainInfo()
  {
    return propertyStore.propertyMainInfo;
  }

  private static DefaultPageSize = 50;
  public static RowPerPageOptions = [25, PropertyMatchesStore.DefaultPageSize, 100, 200, 500];

  public criteria = new Fetchable(GetMatchesCriteria, { catchUnhandled: handleCriticalError });

  public users = new Fetchable(GetMatchesUsers, { catchUnhandled: handleCriticalError });

  private _matchesOrder: OrderField | null = {
    fieldName: 'search_contactName',
    ordering: OrderType.ASC,
  };
  get matchesOrder()
  {
    return this._matchesOrder;
  }
  set matchesOrder(newOrder)
  {
    this._matchesOrder = newOrder;
  }

  /*
    TODO This would need to be in the _matchesFilters object but it isn't part of Filters__MyBreteuilMatch.
    _matchesFilters could be a GetPropertyMatchesFilters type that includes custom filters
  */
  private _matchesContactOwnershipFilter: ContactOwnershipFilter | null = ContactOwnershipFilter.Self;
  get matchesContactOwnershipFilter()
  {
    return this._matchesContactOwnershipFilter;
  }

  public setMatchesContactOwnershipFilter(contactOwnershipFilter: ContactOwnershipFilter | null)
  {
    this._matchesContactOwnershipFilter = contactOwnershipFilter;
  }

  private _matchesFilters: Filters__MyBreteuilMatch = {};

  get matchesFilters()
  {
    return this._matchesFilters;
  }

  public setMatchesFilters(newFilters: Filters__MyBreteuilMatch)
  {
    this._matchesFilters = newFilters;
  }

  public matches = new UncachedPagination({
    fetch: (baseArgs) => GetPropertyMatches({
      ...baseArgs,
      slug: propertyStore.propertyMainInfo.lastResult!.slug,
      language: localesStore.currentLocale,
      orderBy: this._matchesOrder ? [this._matchesOrder] : [],
      filter: this._matchesFilters,
      contactsFilter: this._matchesContactOwnershipFilter ?? undefined,
    }).then(makePaginationResultsAutoObservable),
    pageSize: PropertyMatchesStore.DefaultPageSize,
  });

  private _matchesSelection = new PaginationSelection(this.matches);

  private async _openReminderDialogWithCurrentMatch()
  {
    const selectedMatch = this._matchesSelection.item;
    await this.reminderDialogStore.open(selectedMatch.contactId, selectedMatch.id);
  }

  public async openReminderDialog(matchId: IdType)
  {
    this._matchesSelection.selectItem((match) => match.id === matchId);
    await this._openReminderDialogWithCurrentMatch();
  }

  private async _prevMatch()
  {
    await this._matchesSelection.prevItem();
    await this._openReminderDialogWithCurrentMatch();
  }

  private async _nextMatch()
  {
    await this._matchesSelection.nextItem();
    await this._openReminderDialogWithCurrentMatch();
  }

  public reminderDialogStore = new ReminderDialogStore({
    navigationControls: {
      hasPrevContact: () => this._matchesSelection.hasPrevItem,
      hasNextContact: () => this._matchesSelection.hasNextItem,
      onClickPrev: () => this._prevMatch().catch((error) => formatGlobalErrorMessage(error)),
      onClickNext: () => this._nextMatch().catch((error) => formatGlobalErrorMessage(error)),
    },
    onReminderCreateSuccess: () => this.matches.reload().catch((error) => formatGlobalErrorMessage(error)),
    onSentPropertiesTableRefresh: () => this.matches.reload().catch((error) => formatGlobalErrorMessage(error)),
    onRapprochementsRefresh: () => this.matches.reload().catch((error) => formatGlobalErrorMessage(error)),
  });

  public async setMatchBanned(args: SetMatchBannedVariables)
  {
    await SetMatchBanned(args);
    const match = this.matches.data?.items.find((match) => (match.id === args.id));
    if (match)
      runInAction(() => {
        match.banned = args.banned;
      });
  }

  public async addNegativePropertyFeedback(args: AddNegativePropertyFeedbackArgs)
  {
    await AddNegativePropertyFeedback(args);
  }

  public async removeNegativePropertyFeedback(args: RemoveNegativePropertyFeedbackArgs)
  {
    await RemoveNegativePropertyFeedback(args);
  }

  public async refresh()
  {
    await this.matches.first();
    await this.criteria.ensureSuccess({ language: localesStore.currentLocale });
    await this.users.ensureSuccess();
  }
}

export default new PropertyMatchesStore();
