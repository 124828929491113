import type {
  Filters__String,
  Filters__Boolean,
  Filters__Float,
  Filters__Date,
  Filters__Int,
  OrderBy,
} from '@core/api/types';

import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { TransactionFields, type Transaction } from './shared';

export interface Filters__Transaction
{
  surface?: Filters__Float | undefined,
  streetName?: Filters__String | undefined,
  streetNumberOdd?: Filters__Boolean | undefined,
  date?: Filters__Date | undefined,
  constructionYear?: Filters__Int | undefined,
  floor?: Filters__Int | undefined,
  pricePerSurface?: Filters__Float | undefined,
  geoAreaId?: Filters__Int | undefined,
}

export interface GeoCircle
{
  center: { latitude: number, longitude: number },
  radius: number,
}

export interface GetTransactionsArgs
{
  first?: number | undefined,
  filter: Filters__Transaction,
  radiusFilter?: GeoCircle | undefined,
  orderBy?: OrderBy | undefined,
}

export default function GetTransactions(args: GetTransactionsArgs)
{
  return query<{ count: number, data: Array<Transaction> }>({
    operationName: 'Valuation_GetTransactions',
    variables: {
      first: 'Int',
      filter: 'Filters__Transaction',
      radiusFilter: 'GeoCircle',
      orderBy: '[Order__Transaction!]',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        transactions: [{
          args: {
            first: true,
            filter: true,
            radiusFilter: true,
            orderBy: true,
          },
        }, {
          count: true,
          data: {
            edges: {
              node: TransactionFields,
            },
          },
        }],
      }),
    },
  }, args);
}
