import { action, computed, observable } from 'mobx';
import type { IdType } from '@repo-breteuil/common-definitions';
import { Fetchable, OpeningControl } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import {
  GetVisibleContactUser,
  GetManagedUsers,
  TransferUserContacts,
  type TransferUserContactsArgs,
} from './api';

export class ReferentTransferStore {
  public users = new Fetchable(GetVisibleContactUser, { catchUnhandled: handleNonCriticalError });

  public targetUsers = new Fetchable(GetManagedUsers, { catchUnhandled: handleNonCriticalError });

  // TODO bad, should be computed backend side
  @computed public get agentPotCommun() {
    const agentPotCommun = this.users.result?.filter((agent) => agent.isAgencyPotCommun)[0];
    return agentPotCommun || null;
  }

  @observable private _contactIds: Array<IdType> = [];

  get contactIds()
  {
    return this._contactIds;
  }

  @action setContactIds(contactIds: Array<IdType>)
  {
    this._contactIds = contactIds;
  }

  private _openingControl = new OpeningControl();

  get opened()
  {
    return this._openingControl.isOpen;
  }

  async open(contactIds: Array<IdType>)
  {
    await Promise.all([
      this.users.ensureSuccess(),
      this.targetUsers.ensureSuccess(),
    ]);
    this.setContactIds(contactIds);
    this._openingControl.open();
  }

  close()
  {
    this._openingControl.close();
  }

  public async transferUserContactsAndReload(args: TransferUserContactsArgs)
  {
    return TransferUserContacts(args);
  }
}

export default ReferentTransferStore;
