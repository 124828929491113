import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';
import {
  type SessionResult,
  SessionFields,
} from '@my-breteuil/store/ui/common/Session/api';

export type PasswordResetExecuteArgs = {
  emailToken: string,
  password: string,
}

export default function PasswordResetExecute(args: PasswordResetExecuteArgs)
{
  return mutation<SessionResult>({
    operationName: 'ResetPassword_PasswordResetExecute',
    variables: {
      emailToken: "String!",
      password: "String!",
    },
    fieldsSelection: {
      myBreteuilUser: {
        passwordResetExecute: errorWrapper([{
          args: {
            emailToken: true,
            password: true,
          },
        }, SessionFields ]),
      },
    },
  }, args);
}
