import type { IdType } from '@repo-breteuil/common-definitions';

import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';
import { PictureFormat } from '@core/api/types';

export interface MyBreteuilPhotographer
{
  id: IdType,
  name: string,
}

export interface MyBreteuilPropertyPhoto
{
  id: IdType,
  fileURL: string,
  pictureFormat: string,
}

export interface MyBreteuilProperty
{
  id: IdType,
  videoURL: string | null,
  photograph: MyBreteuilPhotographer | null,
  photos: Array<MyBreteuilPropertyPhoto>,
}

export interface GetPropertyMediasVariables
{
  slug: string,
}

export default function GetPropertyMedias(variables: GetPropertyMediasVariables)
{
  return query<MyBreteuilProperty>({
    operationName: 'Property_Medias_GetPropertyMedias',
    variables: {
      slug: 'String!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: [{
          args: {
            slug: true,
          },
        }, {
          id: true,
          videoURL: true,
          photograph: {
            id: true,
            name: true,
          },
          photos: paginationResultAsList({
            id: true,
            fileURL: [{ args: { format: { literal: PictureFormat._240p } } }, true],
            pictureFormat: true,
          }),
        }],
      }),
    },
  }, variables);
}
