import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface DeleteViewingVariables
{
  id: IdType,
}

export default function DeleteViewing(variables: DeleteViewingVariables)
{
  return mutation<boolean>({
    operationName: 'Common_ViewingDialog_DeleteViewing',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilViewing: {
          delete: [{ args: { id: true }}, true] },
      }),
    },
  }, variables);
}
