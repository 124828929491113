import type { IdType } from '@repo-breteuil/common-definitions';
import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface Agency
{
  id: IdType,
  name: string,
}

export function GetAgencies()
{
  return query<Array<Agency>>({
    operationName: 'Folders_AdminMyBreteuilAgencies',
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilAgencies: paginationResultAsList({
          id: true,
          name: true,
        }),
      }),
    },
  });
}
