import type { IdType } from '@repo-breteuil/common-definitions';

import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface AssignableStream
{
  id: IdType,
  name: string,
}

function GetShowcaseScreensAssignableStreams(): Promise<Array<AssignableStream>>
{
  return query({
    operationName: 'Showcase_ScreensList_GetShowcaseScreensAssignableStreams',
    fieldsSelection: {
      authenticated: errorWrapper({
        showcase: {
          streams: paginationResultAsList({
            id: true,
            name: true,
          }),
        },
      }),
    },
  });
}

export default GetShowcaseScreensAssignableStreams;
