import type { IdType } from '@repo-breteuil/common-definitions';
import { errorWrapper, query } from '@core/api/graphql/gen-v2';
import type { ContactsFilter } from './shared';

export interface QuickFilterCountsResult
{
  buyerMatchAndManualCount_1M: number, // 0-1M
  buyerMatchAndManualCount_4M: number, // 1-4M
  buyerMatchAndManualCount_4MPlus: number, //4M+
  commonPotActiveCount_1M: number,
  commonPotActiveCount_4M: number,
  commonPotActiveCount_4MPlus: number,
}

function makeMyBreteuilContactsCountField(args: { name: string, useCommonPotUsers?: boolean })
{
  const { name, useCommonPotUsers } = args;
  return [{
    args: {
      filter: `filter_${name}`,
      responsibleUsers: useCommonPotUsers ? 'commonPotUsersIds' : 'userId',
      buyers: { literal: true },
    },
    aliasedField: 'myBreteuilContacts',
  }, { count: true }] as const;
}

export interface QuickFilterCountsArgs
{
  userId: IdType,
  commonPotUsersIds: Array<IdType>,
  filter_buyerMatchAndManualCount_1M?: ContactsFilter | undefined,
  filter_buyerMatchAndManualCount_4M?: ContactsFilter | undefined,
  filter_buyerMatchAndManualCount_4MPlus?: ContactsFilter | undefined,
  filter_commonPotActiveCount_1M?: ContactsFilter | undefined,
  filter_commonPotActiveCount_4M?: ContactsFilter | undefined,
  filter_commonPotActiveCount_4MPlus?: ContactsFilter | undefined,
}

export default function QuickFilterCounts(args: QuickFilterCountsArgs)
{
  const { userId, ...restArgs } = args;
  return query<QuickFilterCountsResult>({
    operationName: 'Contacts_QuickFilterCounts',
    variables: {
      userId: '[Int!]', // Array for compatibility with responsibleUsers filter
      commonPotUsersIds: '[Int!]',
      filter_buyerMatchAndManualCount_1M: 'Filters__Authenticated_Contacts_MainPagination',
      filter_buyerMatchAndManualCount_4M: 'Filters__Authenticated_Contacts_MainPagination',
      filter_buyerMatchAndManualCount_4MPlus: 'Filters__Authenticated_Contacts_MainPagination',
      filter_commonPotActiveCount_1M: 'Filters__Authenticated_Contacts_MainPagination',
      filter_commonPotActiveCount_4M: 'Filters__Authenticated_Contacts_MainPagination',
      filter_commonPotActiveCount_4MPlus: 'Filters__Authenticated_Contacts_MainPagination',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        buyerMatchAndManualCount_1M: makeMyBreteuilContactsCountField({ name: 'buyerMatchAndManualCount_1M' }),
        buyerMatchAndManualCount_4M: makeMyBreteuilContactsCountField({ name: 'buyerMatchAndManualCount_4M' }),
        buyerMatchAndManualCount_4MPlus: makeMyBreteuilContactsCountField({ name: 'buyerMatchAndManualCount_4MPlus' }),
        commonPotActiveCount_1M: makeMyBreteuilContactsCountField({ name: 'commonPotActiveCount_1M', useCommonPotUsers: true }),
        commonPotActiveCount_4M: makeMyBreteuilContactsCountField({ name: 'commonPotActiveCount_4M', useCommonPotUsers: true }),
        commonPotActiveCount_4MPlus: makeMyBreteuilContactsCountField({ name: 'commonPotActiveCount_4MPlus', useCommonPotUsers: true }),
      }),
    },
  }, {
    userId: [ userId ],
    ...restArgs,
  });
}
