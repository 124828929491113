import type { PartialUndefined } from '@repo-lib/utils-core';
import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationBaseArgs, PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import type {
  PropertyStatus,
  OperationType,
  PropertyType,
  MyBreteuilPropertyVisibility,
  PropertyFavoriteStatus,
} from '@core/api/types/enums';
import type {
  OrderBy,
  Filters__String,
  Filters__Int,
  Filters_HasMany,
  Filters__Enum,
  Filters__Date,
  Filters__Float,
  Filters__Boolean,
} from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationVariables,
  ExtendedPaginationArgs,
} from '@core/api/graphql/gen-v2';
import {
  type MyBreteuilPropertyVars,
  MyBreteuilPropertyVarsFields,
  type MyBreteuilProperty,
  MyBreteuilPropertyFields,
} from './shared';

export type Filters__MyBreteuilProperty = PartialUndefined<{
  users?: Filters_HasMany<{ userId: Filters__Int }>,
  addrStreetAddress?: Filters__String,
  publication?: Filters__Enum<MyBreteuilPropertyVisibility>,
  operationType?: Filters__Enum<OperationType>,
  lastRelevantPriceOrStatusChange?: Filters__Date,
  ref?: Filters__String,
  warrantNumber?: Filters__String,
  verifiedWarrant?: Filters__Boolean,
  status?: Filters__Enum<PropertyStatus>,
  price?: Filters__Int,
  pricePerSurface?: Filters__Float,
  addressPricePerSurface?: Filters__Float,
  feesPercentage?: Filters__Float,
  geoAreaId?: Array<IdType>,
  surface?: Filters__Float,
  bedrooms?: Filters__Int,
  floor?: Filters__Int,
  propertyType?: Filters__Enum<PropertyType>,
  viewingsCount?: Filters__Int,
  favoriteStatus?: Filters__Enum<PropertyFavoriteStatus>,
  marketConsistent?: Filters__Boolean,
  managerMarketConsistent?: boolean,
  priceDecreaseRequested?: boolean,
  exclusive?: Filters__Boolean,
  hasVideo?: Filters__Boolean,
  photos?: Filters_HasMany,
  warrantDate?: Filters__Date,
  matchesCountAbsolute?: Filters__Int,
  createdAt?: Filters__Date,
  photograph?: { id: Filters__Int},
  platformsUserPublished?: boolean,
  lastMarketingReportSendDate?: Filters__Date,
  lastAgentValuationDate?: Filters__Date,
  distanceFromUserAgency?: number,
}>

export interface GetPropertiesVariables extends MyBreteuilPropertyVars, PaginationBaseArgs
{
  orderBy?: OrderBy | null | undefined,
  filter?: Filters__MyBreteuilProperty | null | undefined,
}

function GetProperties(variables: GetPropertiesVariables)
{
  return query<PaginationExtendedResponse<MyBreteuilProperty>>({
    operationName: 'Properties_GetProperties',
    variables: {
      ...ExtendedPaginationVariables('MyBreteuilProperty'),
      ...MyBreteuilPropertyVarsFields,
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperties: [{
          args: {
            ...ExtendedPaginationArgs,
            excludePinned: { literal: 'true' },
          },
        }, paginationResult(MyBreteuilPropertyFields)],
      }),
    },
  }, variables);
}

export default GetProperties;
