import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';


import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { UserGoalMetric, UserGoalPeriod } from '@core/api/types';

export interface UserGoals {
  reminders: UserGoal,
  successfulReminders: UserGoal,
  successfulPhoneReminders: UserGoal,
  successfulWhatsAppReminders: UserGoal,
  acceptedOffers: UserGoal,
  madeOffer: UserGoal,
  mandates: UserGoal,
  viewings: UserGoal,
  valuations: UserGoal,
  prospectedStreets: UserGoal,
}

export interface UserGoal
{
  value: number,
  goal: number | null,
}

export const UserGoalFields: ReadonlyNodeFields = {
  value: true,
  goal: true,
};

export const UserGoalArgs: ReadonlyNodeFields = {
  userId: true,
  period: true,
  previousPeriod: true,
};

export interface GetUserGoalsArgs {
  userId: IdType,
  period: UserGoalPeriod,
  previousPeriod: number,
}

export default function GetUserGoals(args: GetUserGoalsArgs)
{
  return query<UserGoals>({
    operationName: 'Profile_GetUserGoals',
    variables: {
      userId: "Int!",
      period: "UserGoalPeriod!",
      previousPeriod: "Int",
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        successfulReminders: [{
          aliasedField: "userGoal",
          args: { ...UserGoalArgs, metric: { literal: UserGoalMetric.SuccessfulReminders } },
        }, UserGoalFields],
        successfulPhoneReminders: [{
          aliasedField: "userGoal",
          args: { ...UserGoalArgs, metric: { literal: UserGoalMetric.SuccessfulPhoneReminders } },
        }, UserGoalFields],
        successfulWhatsAppReminders: [{
          aliasedField: "userGoal",
          args: { ...UserGoalArgs, metric: { literal: UserGoalMetric.SuccessfulWhatsAppReminders } },
        }, UserGoalFields],
        acceptedOffers: [{
          aliasedField: "userGoal",
          args: { ...UserGoalArgs, metric: { literal: UserGoalMetric.AcceptedOffersHT } },
        }, UserGoalFields],
        madeOffer: [{
          aliasedField: "userGoal",
          args: { ...UserGoalArgs, metric: { literal: UserGoalMetric.MadeOffers } },
        }, UserGoalFields],
        mandates: [{
          aliasedField: "userGoal",
          args: { ...UserGoalArgs, metric: { literal: UserGoalMetric.Mandates } },
        }, UserGoalFields],
        viewings: [{
          aliasedField: "userGoal",
          args: { ...UserGoalArgs, metric: { literal: UserGoalMetric.Viewings } },
        }, UserGoalFields],
        valuations: [{
          aliasedField: "userGoal",
          args: { ...UserGoalArgs, metric: { literal: UserGoalMetric.Valuations } },
        }, UserGoalFields],
        prospectedStreets: [{
          aliasedField: "userGoal",
          args: { ...UserGoalArgs, metric: { literal: UserGoalMetric.ProspectedStreets } },
        }, UserGoalFields],
      }),
    },
  }, args);
}
