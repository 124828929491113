import type { IdType } from '@repo-breteuil/common-definitions';
import type { Language, CommunicationChannelEnum } from '@core/api/types';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';
import { ManualPropertiesInfoErrorFields, type ManualPropertiesInfoError } from './common';

export interface SendManualPropertiesInfoVariables
{
  propertyIds?: Array<IdType> | undefined,
  contactIds?: Array<IdType> | undefined,
  channels: Array<CommunicationChannelEnum>,
  targetsEmail?: Array<string> | undefined,
  targetsSMS?: Array<string> | undefined,
  subject?: string | undefined,
  message?: string | undefined,
  language: Language,
}

export default function SendManualPropertiesInfo(variables: SendManualPropertiesInfoVariables)
{
  return mutation<{ errors: Array<ManualPropertiesInfoError> }>({
    operationName: 'Common_MessageDrawer_SendManualPropertiesInfo',
    variables: {
      propertyIds: '[Int!]',
      contactIds: '[Int!]',
      channels: '[CommunicationChannelEnum]',
      targetsEmail: '[String]',
      targetsSMS: '[String]',
      subject: 'String',
      message: 'String',
      language: 'Language',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          agents: {
            sendManualPropertiesInfo: [{
              args: {
                propertyIds: true,
                contactIds: true,
                channels: true,
                targetsEmail: true,
                targetsSMS: true,
                subject: true,
                message: true,
                language: true,
              },
              preventUnwrap: true,
            }, {
              errors: ManualPropertiesInfoErrorFields,
            }],
          },
        },
      }),
    },
  }, variables);
}
