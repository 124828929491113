import type {
  Filters__MyBreteuilValuation,
  Filters__MyBreteuilLogSimple,
  Filters__MyBreteuilTransaction,
  Filters__MyBreteuilViewing,
  Filters__MyBreteuilReminder,
  Filters__MyBreteuilFeedback,
} from './index';

import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export interface LogsAnalytics
{
  valuations: number,
  warrants: number,
  transactions: number,
  viewings: number,
  reminders: number,
  feedbacks: number,
}

export interface GetLogsAnalyticsVariables
{
  valuationsFilter?: Filters__MyBreteuilValuation | undefined,
  warrantsFilter?: Filters__MyBreteuilLogSimple | undefined,
  transactionsFilter?: Filters__MyBreteuilTransaction | undefined,
  viewingsFilter?: Filters__MyBreteuilViewing | undefined,
  remindersFilter?: Filters__MyBreteuilReminder | undefined,
  feedbacksFilter?: Filters__MyBreteuilFeedback | undefined,
}

export default function GetLogsAnalytics(variables: GetLogsAnalyticsVariables)
{
  return query<LogsAnalytics>({
    operationName: 'Logs_GetLogsAnalytics',
    variables: {
      valuationsFilter: 'Filters__MyBreteuilValuation',
      warrantsFilter: 'Filters__MyBreteuilLogSimple',
      transactionsFilter: 'Filters__MyBreteuilTransaction',
      viewingsFilter: 'Filters__MyBreteuilViewing',
      remindersFilter: 'Filters__MyBreteuilReminder',
      feedbacksFilter: 'Filters__MyBreteuilFeedback',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          logs: {
            valuations: [{
              args: {
                filter: 'valuationsFilter',
              },
            }, {
              count: true,
            }],
            warrants: [{
              args: {
                filter: 'warrantsFilter',
              },
            }, {
              count: true,
            }],
            transactions: [{
              args: {
                filter: 'transactionsFilter',
              },
            }, {
              count: true,
            }],
            viewings: [{
              args: {
                filter: 'viewingsFilter',
              },
            }, {
              count: true,
            }],
            reminders: [{
              args: {
                filter: 'remindersFilter',
              },
            }, {
              count: true,
            }],
            feedbacks: [{
              args: {
                filter: 'feedbacksFilter',
              },
            }, {
              count: true,
            }],
          },
        },
      }),
    },
  }, variables);
}
