import type { IdType } from '@repo-breteuil/common-definitions';

import type { PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import {
  query,
  errorWrapper,
  paginationResult,
} from '@core/api/graphql/gen-v2';
import type { ShowcaseScreenResolution } from '@core/api/types';

export interface ShowcaseDisplaySession
{
  id: IdType,
  clientVersion: string,
  startDate: number | null,
  lastActiveDate: number | null,
  viewportWidth: number | null,
  viewportHeight: number | null,
  slowConnection: boolean | null,
  errorState: boolean | null,
}

export interface ShowcaseScreenPropertiesComposition
{
  totalCount: number,
  avgPrice: number,
  exclusiveCount: number,
  geoArea_Paris_Count: number,
  geoArea_UK_Count: number,
  geoArea_FRLittoral_Count: number,
  operationType_ResidencyTransaction_Count: number,
  operationType_Rental_Count: number,
  operationType_SeasonalRental_Count: number,
}

export interface ShowcaseScreenStatus
{
  id: IdType,
  name: string,
  lastActiveSession: ShowcaseDisplaySession | null,
  startDate: number | null,
  expectedResolution: ShowcaseScreenResolution | null,
  activeSessionsCount: number,
  stream: ShowcaseScreenPropertiesComposition,
}

export default async function GetShowcaseScreensStatus(): Promise<PaginationExtendedResponse<ShowcaseScreenStatus>>
{
  return query({
    operationName: 'Showcase_ScreensStatus_GetShowcaseScreensStatus',
    fieldsSelection: {
      authenticated: errorWrapper({
        showcase: {
          screens: paginationResult({
            id: true,
            name: true,
            startDate: true,
            expectedResolution: true,
            activeSessionsCount: true,
            lastActiveSession: {
              id: true,
              clientVersion: true,
              startDate: true,
              lastActiveDate: true,
              viewportWidth: true,
              viewportHeight: true,
              slowConnection: true,
              errorState: true,
            },
            stream: {
              propertiesComposition: {
                totalCount: true,
                avgPrice: true,
                exclusiveCount: true,
                geoArea_Paris_Count: true,
                geoArea_UK_Count: true,
                geoArea_FRLittoral_Count: true,
                operationType_ResidencyTransaction_Count: true,
                operationType_Rental_Count: true,
                operationType_SeasonalRental_Count: true,
              },
            },
          }),
        },
      }),
    },
  });
}
