import type { IdType } from '@repo-breteuil/common-definitions';
import type { NotificationChannel } from '@core/api/types';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface CreateReminderVariables
{
  contactId: IdType,
  response: boolean,
  communicationChannel: NotificationChannel,
  recallDate?: number | null/*timestamp*/ | undefined,
  comments?: string | null | undefined,
  createCalendarEvent?: boolean | null | undefined,
  deniedPropertyValuationViewing?: boolean | null | undefined,
}

export default function CreateReminder(variables: CreateReminderVariables)
{
  return mutation<{ id: IdType }>({
    operationName: 'Common_ReminderDialog_CreateReminder',
    variables: {
      contactId: 'Int!',
      response: 'Boolean!',
      communicationChannel: 'NotificationChannel!',
      recallDate: 'Date',
      comments: 'String',
      createCalendarEvent: 'Boolean',
      deniedPropertyValuationViewing: 'Boolean',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilReminder: {
          create: [{
            args: {
              contactId: true,
              response: true,
              communicationChannel: true,
              recallDate: true,
              comments: true,
              createCalendarEvent: true,
              deniedPropertyValuationViewing: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, variables);
}
