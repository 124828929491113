import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useTexts, error as errorTexts } from '@repo-breteuil/common-texts';
import texts from '@my-breteuil/lang/common';

export const Loading = observer(() => {
  const T = texts.current;
  return (
    <p>{T.loading}</p>
  );
});

export const Failure = observer(( props: { error: Error }) => {
  const currentTextsError = useTexts(errorTexts.texts);
  return (
    <p>{texts.current.error}: {currentTextsError.formatError(props.error)}</p>
  );
});
