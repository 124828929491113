import type { OptionalPromise } from '@repo-lib/utils-core';

import { action } from 'mobx';
import { Router } from '@repo-lib/ptr-router';
import { ObservablePromiseStatus } from '@repo-lib/utils-mobx-store';
import { isPromise } from '@repo-lib/utils-core';
import {
  RouteInterface,
  ParsedLocation,
  ResolvedRouteLocation,
  parseLocationString,
} from '@repo-lib/routing-routes';

/*
 * A route handler is composed of two functions:
 * - The `loader`: it loads data, checks errors, makes redirects
 * - The `effect`: it updates the app's data to reflect the page change
 *
 * The `loader` is of the `RouteHandler` type. It optionally returns an `effect` function.
 */
export type RouteHandlerEffectFn<ResultType> = () => (ResultType | null);
export type RouteHandlerBase<ResultType> = (
  location: ResolvedRouteLocation,
  info: RoutingInfo,
) => ResultType;
export type RouteHandlerReturnType<ResultType> = OptionalPromise<RouteHandlerEffectFn<ResultType> | null>;
export type RouteHandler<ResultType> = RouteHandlerBase<RouteHandlerReturnType<ResultType>>;
export type RouteHandler_Sync<ResultType> = RouteHandlerBase<RouteHandlerEffectFn<ResultType> | null>;
export type RouteHandler_Async<ResultType> = RouteHandlerBase<Promise<RouteHandlerEffectFn<ResultType> | null>>;

export type RouteHandlersMap = Map<RouteInterface, RouteHandler<string | null>>;

export interface MatchedRouteHandler
{
  handler?: RouteHandler<string | null> | undefined,
  resolvedLocation: ResolvedRouteLocation,
}

export function matchRouteHandlersMap(
  handlers: RouteHandlersMap,
  location: ParsedLocation | null,
): MatchedRouteHandler | null
{
  if (!location)
    return null;
  for (const [ route, handler ] of handlers.entries())
  {
    const routeParams = route.match(location.pathname);
    if (routeParams)
      return {
        handler,
        resolvedLocation: {
          ...location,
          route,
          routeParams,
        },
      };
  }
  return null;
}

export function ensureLocationHandler(handlers: RouteHandlersMap, locationString: string): MatchedRouteHandler
{
  const parsedLocation = parseLocationString(locationString);
  const match = matchRouteHandlersMap(handlers, parsedLocation);
  if (!match)
    throw new Error(`ensureLocationHandler: no handler matched by location: ${locationString}`);
  return match;
}

export interface RoutingInfoOpts
{
  /*
   * This enables the browser's default behaviour of trying to restore the scroll level when going back/forward in history.
   * It isn't compatible with our async routing system: the browser will change the scroll level before the loading finished.
   * Thus, it's not recommended to enable it.
   *
   * PageRouter's PageRedirect.scrollTop should be used to restore scroll properly as needed.
   */
  enableBrowserHistoryScrollRestoration?: boolean | undefined,

  beforeRouteChange?: ((location: ParsedLocation | null, info: RoutingInfo) => void) | undefined,
  onRouteNotFound?: ((location: ParsedLocation | null, info: RoutingInfo) => MatchedRouteHandler | string | null) | undefined,
  onRouteChange?: ((location: ResolvedRouteLocation, info: RoutingInfo) => void) | undefined,
  onRouteChangeError?: ((
    error: Error,
    location: ResolvedRouteLocation | ParsedLocation | null,
    info: RoutingInfo,
  ) => string | null) | undefined,
  onRouteChangeDroppedError?: ((
    error: Error,
    location: ResolvedRouteLocation | ParsedLocation | null,
    info: RoutingInfo,
  ) => void) | undefined,
}

export default class RoutingInfo
{
  private _routeChangeStatus = new ObservablePromiseStatus<(() => (string | null)) | null>();

  //All the route handlers (both the loader and the effect functions) may throw

  //May not throw
  public beforeRouteChange: ((location: ParsedLocation | null, info: RoutingInfo) => void) | null = null;

  //May throw
  public onRouteNotFound: ((location: ParsedLocation | null, info: RoutingInfo) => MatchedRouteHandler | string | null) | null = null;

  //May not throw
  //Called when a routeHandler effect was succesfully executed
  public onRouteChange: ((location: ResolvedRouteLocation, info: RoutingInfo) => void) | null = null;

  //May not throw
  //Called when an error occured in a route handler (loader or effect) or in onRouteNotFound
  //Won't be called if the error is outdated (from an old async route change) and didn't impact routing
  public onRouteChangeError: ((
    error: Error,
    location: ResolvedRouteLocation | ParsedLocation | null,
    info: RoutingInfo,
  ) => string | null) | null = null;

  //May not throw
  //Called when an error occuring in a route handler or in onRouteNotFound wasn't caught by onRouteChangeError
  //This happens either if onRouteChangeError is null, or if the error is outdated
  public onRouteChangeDroppedError: ((
    error: Error,
    location: ResolvedRouteLocation | ParsedLocation | null,
    info: RoutingInfo,
  ) => void) | null = null;

  public get loading(): boolean
  {
    return this._routeChangeStatus.pending;
  }

  public get loadError(): Error | null
  {
    const { value } = this._routeChangeStatus;
    if (value.status === 'failure')
      return value.error;
    return null;
  }

  /*
   * A unique identifier that changes on every route change
   */
  public get currentIdentifier(): string
  {
    return this._routeChangeStatus.currentIdentifier;
  }

  private handleRouteChangeError(
    error: Error,
    location: ((ResolvedRouteLocation | ParsedLocation) & { originalLocationString?: string | undefined }) | null,
    lastRoute: string | null,
  ): string | null
  {
    let redirect: string | null = null;
    if (this.onRouteChangeError)
      redirect = this.onRouteChangeError(error, location, this);
    else if (this.onRouteChangeDroppedError)
      this.onRouteChangeDroppedError(error, location, this);
    if (redirect === null)
      redirect = lastRoute;
    return redirect ?? lastRoute ?? location?.originalLocationString ?? location?.locationString ?? null;
  }

  private asyncRouteChange(
    location: ResolvedRouteLocation & { originalLocationString?: string | undefined },
    result: Promise<RouteHandlerEffectFn<string | null> | null>,
  ): string | null
  {
    const lastRoute = this._router.lastRoute;
    this._routeChangeStatus.track(result, {
      thenCurrent: (handler) => {
        const redirect = this.executeRouteHandlerEffect(handler, location, lastRoute);
        this.changeRouteBypassHandler(redirect ?? location.locationString, { replace: true });
      },
      catchCurrent: (err) => {
        const redirect = this.handleRouteChangeError(err, location, lastRoute);
        this.changeRouteBypassHandler(redirect ?? location.locationString, { replace: true });
      },
      catchDropped: (err) => {
        if (this.onRouteChangeDroppedError)
          this.onRouteChangeDroppedError(err, location, this);
      },
    });
    return location.originalLocationString ?? null;
  }

  private handleRouteChange(
    routeHandler: RouteHandler<string | null> | undefined,
    location: ResolvedRouteLocation & { originalLocationString?: string | undefined },
  ): string | null
  {
    const lastRoute = this._router.lastRoute;
    let result: RouteHandlerReturnType<string | null>;
    try
    {
      result = routeHandler ? routeHandler(location, this) : null;
    }
    catch (error)
    {
      return this.handleRouteChangeError(error, location, lastRoute);
    }
    if (isPromise(result))
      return this.asyncRouteChange(location, result);
    return this.executeRouteHandlerEffect(result, location, lastRoute);
  }

  private executeRouteHandlerEffect(
    effect: RouteHandlerEffectFn<string | null> | null,
    location: ResolvedRouteLocation,
    lastRoute: string | null,
  ): string | null
  {
    let redirect: string | null = null;
    try
    {
      redirect = effect && effect();
    }
    catch (error)
    {
      return this.handleRouteChangeError(error, location, lastRoute);
    }
    if (this.onRouteChange)
      this.onRouteChange(location, this);
    return redirect ?? location.locationString;
  }

  private handleRouteNotFound(
    parsedLocation: ParsedLocation | null,
  ): string | null
  {
    if (!this.onRouteNotFound)
      return null;
    let res: MatchedRouteHandler | string | null;
    try
    {
      res = this.onRouteNotFound(parsedLocation, this);
    }
    catch (error)
    {
      const lastRoute = this._router.lastRoute;
      return this.handleRouteChangeError(error, parsedLocation, lastRoute);
    }
    return this._changeRoute(res);
  }

  @action.bound private handleLocationChange(locationString: string): string | null
  {
    const parsedLocation = parseLocationString(locationString, { sanitize: true });
    if (this.beforeRouteChange)
      this.beforeRouteChange(parsedLocation, this);
    this._routeChangeStatus.reset();
    const matchedRoute = matchRouteHandlersMap(this._routeHandlers, parsedLocation);
    if (matchedRoute)
      return this.handleRouteChange(matchedRoute.handler, {
        ...matchedRoute.resolvedLocation,
        originalLocationString: parsedLocation?.originalLocationString,
      });
    return this.handleRouteNotFound(parsedLocation);
  }

  constructor(
    private _router: Router,
    private _routeHandlers: RouteHandlersMap = new Map(),
    opts: RoutingInfoOpts = {},
  )
  {
    if (!opts.enableBrowserHistoryScrollRestoration && window.history.scrollRestoration)
      window.history.scrollRestoration = 'manual';

    _router.routeChangeHandler = this.handleLocationChange;
    this.beforeRouteChange = opts.beforeRouteChange || null;
    this.onRouteNotFound = opts.onRouteNotFound || null;
    this.onRouteChange = opts.onRouteChange || null;
    this.onRouteChangeError = opts.onRouteChangeError || null;
    this.onRouteChangeDroppedError = opts.onRouteChangeDroppedError || null;
  }

  public setRouteHandlers(handlers: RouteHandlersMap)
  {
    this._routeHandlers = handlers;
  }

  public get routeHandlers(): RouteHandlersMap
  {
    return this._routeHandlers;
  }

  public changeRouteBypassHandler(
    route: string | null,
    opts: {
      replace?: boolean | undefined,
      force?: boolean | undefined,
    } = {},
  ): void
  {
    if (route === null)
    {
      if (opts.force)
        return this.changeRouteBypassHandler(this._router.currentRoute, opts);
      return;
    }

    return this._router.changeRouteBypassHandler(route, opts.replace, opts.force);
  }

  public updateFromCurrentLocation()
  {
    this._router.updateFromCurrentLocation();
  }

  private _changeRoute(route: MatchedRouteHandler | string | null): string | null
  {
    if (route === null || typeof route === 'string')
      return route;
    const { handler, resolvedLocation } = route;
    const redirectLocation = this.handleRouteChange(handler, resolvedLocation);
    return (redirectLocation === null) ? resolvedLocation.locationString : redirectLocation;
  }

  public changeRoute(
    route: MatchedRouteHandler | string | null,
    opts: {
      replace?: boolean | undefined,
      force?: boolean | undefined,
    } = {},
  ): void
  {
    if (route === null)
    {
      if (opts.force)
        return this.changeRoute(this._router.currentRoute, opts);
      return;
    }

    if (typeof route === 'string')
    {
      this._router.changeRoute(route, opts.replace, opts.force);
      return;
    }

    const redirection = this._changeRoute(route);
    return this.changeRoute(redirection, opts);
  }

  public get router()
  {
    return this._router;
  }
};
