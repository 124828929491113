import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type {
  OperationType,
  AlertsFrequency,
  MyBreteuilSearchLevel,
  PropertyStatus,
  MyBreteuilContactCategory,
  BuildingType,
  Currency,
  BreteuilPropertyPriceChangeStatus,
  MyBreteuilPropertyVisibility,
  NotificationChannel,
  MyBreteuilContactOriginType,
  MyBreteuilContactType,
} from '@core/api/types/enums';

import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';
import { Language } from '@core/api/types';

export type Person = {
  id: IdType,
  firstname: string,
  lastname: string,
  fullname: string | null,
  position: number,
  emails: Array<string>,
  phones: Array<string>,
};

export type ContactSentPropertyInfo = {
  sentAuto: boolean,
  sentPropertyPrice: number,
  propertyId: IdType,
  clicksCount: number,
  unhandledInterestsCount: number,
  lastInterestDate: number/*timestamp*/,
  negativeInterest: boolean,
  property: MyBreteuilProperty,
}

export type MyBreteuilContactComment = {
  id: IdType,
  message: string,
  user: MyBreteuilUser,
  createdAt: number/*timestamp*/,
  search: {
    id: IdType,
    label: string | null,
  } | null,
  reminder: {
    id: IdType,
    response: boolean | null,
    com: NotificationChannel,
  } | null,
};

export type MyBreteuilViewing = {
  id: IdType,
  date: number/*timestamp*/,
  user: {
    id: IdType,
    login: string,
  } | null,
  property: {
    id: IdType,
    slug: string,
    publication: MyBreteuilPropertyVisibility,
    address: string,
  } | null,
};

const MyBreteuilViewingFields: ReadonlyNodeFields = {
  id: true,
  date: true,
  user: {
    id: true,
    login: true,
  },
  property: {
    id: true,
    slug: true,
    publication: true,
    address: true,
  },
};

export type Contact = {
  id: IdType,
  editable: boolean,
  createdAt: number/**timestamp*/,
  lastUpdate: number | null,
  category: MyBreteuilContactCategory,
  origins: Array<MyBreteuilContactOriginType>,
  archived: boolean,
  gender: string,
  firstname: string,
  lastname: string,
  emails: Array<string>,
  address: string,
  address1: string,
  postalCode: string,
  city: string,
  countryCode: string,
  alertsFrequency: AlertsFrequency | null,
  clientAreaCreated: boolean,
  clientAreaEmail: string,
  clientAreaMobilePhone: string,
  offMarketAccess: boolean,
  verified: boolean,
  optDate: number/**timestamp*/,
  fullname: string,
  vip: boolean,
  optIn: boolean,
  newsletterOptIn: boolean,
  persons: Array<Person>,
  searches: Array<MyBreteuilSearch>,
  searchesArchived: Array<MyBreteuilSearch>,
  properties: Array<MyBreteuilProperty>,
  greenAlertCount: number,
  orangeAlert: boolean,
  redAlert: boolean,
  recallDate: number | null/*timestamp*/,
  budget: number | null,
  eval: MyBreteuilSearchLevel | null,
  ownerComment: string | null,
  tenant: boolean | null,
  mybHistoriqueCallAbouti: number | null/*timestamp*/,
  mybHistoriqueCall: number | null/*timestamp*/,
  viewingsCount: number,
  viewings: Array<MyBreteuilViewing>,
  phones: Array<string>,
  statuses: Array<MyBreteuilContactType>,
  responsibleUsers: Array<MyBreteuilUser>,
  daysWithoutInteraction: number,
  numberOfClicks: number,
  budgetMax: number | null,
  mybHistoriqueLastAction: number | null/*timestamp*/,
  comments: Array<MyBreteuilContactComment>,
  mergedIntoContactId: IdType | null,
  hidePersonalInfo: boolean,
  language: Language | null,
}

export type MyBreteuilAgency = {
  id: IdType,
  name: string,
}

type MyBreteuilUser = {
  id: IdType,
  firstname: string,
  lastname: string,
  fullname: string,
  agent: boolean,
  isAgencyPotCommun: boolean,
  left: boolean,
  contactSearchAgency: {
    id: IdType,
    code: string,
    name: string,
  } | null,
}

export type MyBreteuilProperty = {
  id: IdType,
  slug: string,
  ref: string,
  currency: Currency,
  status: PropertyStatus,
  description: string,
  users: Array<MyBreteuilUser>,
  address: string,
  agency: MyBreteuilAgency,
  postalCode: string,
  refId: number,
  city: {
    id: IdType,
    name: string,
    displayName: string,
  },
  country: string,
  district: {
    id: IdType,
    name: string,
  },
  price: number,
  surface: number,
  bedrooms: number,
  floor: number,
  constructionYear: number,
  buildingType: BuildingType,
  user: Array<MyBreteuilUser>,
  lastPriceUpdateStatus: BreteuilPropertyPriceChangeStatus,
  publication: MyBreteuilPropertyVisibility,
  marketConsistent: boolean,
  managerMarketConsistent: boolean,
  operationType: OperationType,
};

export type MyBreteuilCriteria = {
  id: IdType,
  webName: string,
};

export interface SearchUser
{
  id: IdType,
  firstname: string,
  lastname: string,
  fullname: string,
  agent: boolean,
  isAgencyPotCommun: boolean,
  active: boolean,
  contactSearchAgency: MyBreteuilAgency | null,
}

const SearchUserFields: ReadonlyNodeFields = {
  id: true,
  firstname: true,
  lastname: true,
  fullname: true,
  agent: true,
  isAgencyPotCommun: true,
  left: true,
  active: true,
  contactSearchAgency: {
    id: true,
    name: true,
  },
};

export type MyBreteuilSearch = {
  id: IdType,
  label: string,
  operationType: OperationType,
  mybOffre: boolean,
  archived: boolean,
  areas: Array<IdType>,
  budgetMin: number,
  budgetMax: number,
  bedroomsMin: number,
  bedroomsMax: number,
  surfaceMin: number,
  surfaceMax: number,
  criteria: Array<MyBreteuilCriteria>,
  users: Array<SearchUser>,
  previousUsers: Array<{
    id: IdType,
    fullname: string,
  }>,
  createdAt: number/*timestamp*/,
  lastUpdate: number | null/*timestamp*/,
}

const MyBreteuilUserFields: ReadonlyNodeFields = {
  id: true,
  firstname: true,
  lastname: true,
  fullname: true,
  agent: true,
  isAgencyPotCommun: true,
  left: true,
  contactSearchAgency: {
    id: true,
    code: true,
    name: true,
  },
};

export const SearchFields: ReadonlyNodeFields = {
  id: true,
  label: [{ args: { language: true }}, true ],
  operationType: true,
  mybOffre: true,
  archived: true,
  areas: paginationResultAsList({ id: true }),
  budgetMin: true,
  budgetMax: true,
  bedroomsMin: true,
  bedroomsMax: true,
  surfaceMin: true,
  surfaceMax: true,
  criteria: {
    id: true,
    webName: [{ args: { language: true } }, true],
  },
  users: SearchUserFields,
  previousUsers: paginationResultAsList({
    id: true,
    fullname: true,
  }),
  createdAt: true,
  lastUpdate: true,
};

export const PropertyFields: ReadonlyNodeFields = {
  id: true,
  ref: true,
  slug: [{ args: { language: true } }, true],
  currency: true,
  status: true,
  address: true,
  postalCode: true,
  refId: true,
  description: [{ args: { language: true } }, true],
  city: {
    id: true,
    name: true,
    displayName: [{ args: { language: true } }, true],
  },
  country: true,
  district: {
    id: true,
    name: true,
  },
  price: true,
  surface: true,
  bedrooms: true,
  floor: true,
  constructionYear: true,
  buildingType: true,
  users: {
    id: true,
    firstname: true,
    lastname: true,
    fullname: true,
    agent: true,
    isAgencyPotCommun: true,
    left: true,
    contactSearchAgency: {
      id: true,
      code: true,
      name: true,
    },
  },
  lastPriceUpdateStatus: true,
  publication: true,
  marketConsistent: true,
  managerMarketConsistent: true,
  operationType: true,
};

export const ContactFields: ReadonlyNodeFields = {
  id: true,
  editable: true,
  createdAt: true,
  lastUpdate: true,
  category: true,
  origins: true,
  archived: true,
  gender: true,
  firstname: true,
  lastname: true,
  emails: true,
  address: true,
  address1: true,
  postalCode: true,
  city: true,
  countryCode: true,
  alertsFrequency: true,
  clientAreaCreated: true,
  clientAreaEmail: true,
  clientAreaMobilePhone: true,
  offMarketAccess: true,
  verified: true,
  optDate: true,
  fullname: true,
  vip: true,
  optIn: true,
  newsletterOptIn: true,
  persons: {
    id: true,
    firstname: true,
    lastname: true,
    fullname: true,
    position: true,
    emails: true,
    phones: true,
  },
  searches: [{ args: { archived: { literal: 'false' }}}, SearchFields],
  searchesArchived: [
    { args: { archived: { literal: 'true' }}, aliasedField: 'searches' },
    SearchFields,
  ],
  properties: PropertyFields,
  greenAlertCount: true,
  orangeAlert: true,
  redAlert: true,
  recallDate: true,
  budget: true,
  eval: true,
  ownerComment: true,
  tenant: true,
  mybHistoriqueCallAbouti: true,
  mybHistoriqueCall: true,
  viewingsCount: true,
  viewings: paginationResultAsList(MyBreteuilViewingFields),
  phones: true,
  statuses: true,
  responsibleUsers: paginationResultAsList({
    id: true,
    firstname: true,
    lastname: true,
    fullname: true,
  }),
  daysWithoutInteraction: true,
  numberOfClicks: true,
  budgetMax: true,
  mybHistoriqueLastAction: true,
  comments: [{
    args: { filter: { literal: `{ message: { not: "" } }` }},
  }, paginationResultAsList({
    id: true,
    message: true,
    user: MyBreteuilUserFields,
    createdAt: true,
    search: {
      id: true,
      label: [{ args: { language: true }}, true ],
    },
    reminder: {
      id: true,
      response: true,
      com: true,
    },
  })],
  mergedIntoContactId: true,
  hidePersonalInfo: true,
  language: true,
};

export interface GetContactArgs
{
  id: IdType,
  language: Language,
}

export default function GetContact(args: GetContactArgs)
{
  return query<Contact>({
    operationName: 'Contact_GetContact',
    variables: {
      id: "Int!",
      language: "Language!",
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: [{ args: { id: true } }, ContactFields ],
      }),
    },
  }, args);
}
