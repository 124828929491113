import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationConnectionWithCount } from '@repo-lib/graphql-query-pagination';
import type { NodeFields } from '@repo-lib/graphql-query-builder';
import {
  Currency,
  OperationType,
  PictureFormat,
  type Filters__Int,
} from '@core/api/types';

import {
  query,
  mutation,
  errorWrapper,
  paginationResult,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';
import { PropertyStatus } from '@core/api/types';

export function isSold(status: PropertyStatus): boolean
{
  return status === PropertyStatus.Sold
    || status === PropertyStatus.Competition
    || status === PropertyStatus.Removed;
}

export interface ShowcasePropertyStream
{
  id: IdType,
  name: string,
}

export const ShowcasePropertyStreamFields: NodeFields = {
  id: true,
  name: true,
};

export interface ShowcaseProperty
{
  id: IdType,
  title: string,
  mainPictureURL: string,
  status: PropertyStatus,
  showcaseFavorite: boolean,
  showcaseBlacklisted: boolean,
  showcasePublished: boolean,
  createdAt: number,/*Timestamp*/
  price: number,
  rooms: number,
  bedrooms: number,
  surface: number,
  ref: string,
  operationType: OperationType,
  currency: Currency,
  exclusive: boolean,
  displayedExclusive: boolean,
  district: {
    id: IdType,
    name: string,
  } | null,
  city: {
    id: IdType,
    name: string,
  } | null,
  mainPictureFormat: string,
}

export const ShowcasePropertyFields: NodeFields = {
  id: true,
  title: true,
  mainPictureURL: [{
    args: {
      format: { literal: PictureFormat._240p },
    },
  }, true],
  status: true,
  showcaseFavorite: true,
  showcaseBlacklisted: true,
  showcasePublished: true,
  createdAt: true,
  price: true,
  rooms: true,
  bedrooms: true,
  surface: true,
  ref: true,
  operationType: true,
  currency: true,
  exclusive: true,
  displayedExclusive: true,
  district: {
    id: true,
    name: true,
  },
  city: {
    id: true,
    name: true,
  },
  mainPictureFormat: true,
};

export interface PropertiesFilters
{
  filter: {
    status?: { in: Array<PropertyStatus> } | undefined,
    operationType?: { in: Array<OperationType> } | undefined,
    ref?: { containsOneOf: Array<string> } | undefined,
    price?: Filters__Int | undefined,
  },

  geoAreaId?: Array<IdType> | undefined,
  showcaseStreamId?: Array<IdType> | undefined,
  showcaseFavorite?: boolean | undefined,
  showcaseBlacklisted?: boolean | undefined,
  showcasePublished?: boolean | undefined,
}

export async function GetShowcaseProperties(
  currentStreamId: IdType | null,
  filters?: Partial<PropertiesFilters> | undefined,
): Promise<PaginationConnectionWithCount<ShowcaseProperty & { showcasePublishedInCurrentStream: boolean }>>
{
  return query({
    operationName: 'Showcase_StreamMixing_GetShowcaseProperties',
    variables: {
      currentStreamId: 'Int',
      filter: 'Filters__MyBreteuilProperty',
      geoAreaId: '[Int!]',
      showcaseStreamId: '[Int!]',
      showcaseFavorite: 'Boolean',
      showcaseBlacklisted: 'Boolean',
      showcasePublished: 'Boolean',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcase: {
          properties: [{
            args: {
              filter: true,
              geoAreaId: true,
              showcaseStreamId: true,
              showcaseFavorite: true,
              showcaseBlacklisted: true,
              showcasePublished: true,
            },
          }, paginationResult({
            ...ShowcasePropertyFields,
            showcasePublishedInCurrentStream: [{
              aliasedField: 'showcasePublished',
              args: {
                streamId: 'currentStreamId',
              },
            }, true],
          })],
        },
      }),
    },
  }, {
    currentStreamId,
    ...filters,
  });
}


export async function GetShowcasePropertiesGeoAreasIds()
{
  return query<Array<IdType>>({
    operationName: 'Showcase_StreamMixing_GetShowcasePropertiesGeoAreasIds',
    fieldsSelection: {
      authenticated: errorWrapper({
        showcase: {
          propertiesGeoAreasIds: true,
        },
      }),
    },
  });
}

export async function GetShowcasePropertiesStreams(
): Promise<Array<ShowcasePropertyStream>>
{
  return query({
    operationName: 'Showcase_StreamMixing_GetShowcasePropertiesStreams',
    fieldsSelection: {
      authenticated: errorWrapper({
        showcase: {
          streams: paginationResultAsList(ShowcasePropertyStreamFields),
        },
      }),
    },
  });
}

export async function SetShowcasePropertyFavorite(
  id: IdType,
  favorite: boolean,
): Promise<void>
{
  await mutation({
    operationName: 'Showcase_StreamMixing_SetShowcasePropertyFavorite',
    variables: {
      id: 'Int!',
      favorite: 'Boolean!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: {
          setShowcaseFavorite: errorWrapper([{
            args: {
              id: true,
              favorite: true,
            },
          }, {
            id: true,
          }]),
        },
      }),
    },
  }, {
    id,
    favorite,
  });
}

export async function SetShowcasePropertyBlacklisted(
  id: IdType,
  blacklisted: boolean,
): Promise<void>
{
  await mutation({
    operationName: 'Showcase_StreamMixing_SetShowcasePropertyBlacklisted',
    variables: {
      id: 'Int!',
      blacklisted: 'Boolean!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: {
          setShowcaseBlacklisted: errorWrapper([{
            args: {
              id: true,
              blacklisted: true,
            },
          }, {
            id: true,
          }]),
        },
      }),
    },
  }, {
    id,
    blacklisted,
  });
}
