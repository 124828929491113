import type { IdType } from '@repo-breteuil/common-definitions';
import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface SetPropertyPinnedArgs
{
  id: IdType,
  pinned: boolean,
}

export default function SetPropertyPinned(args: SetPropertyPinnedArgs)
{
  return mutation<IdType>({
    operationName: 'Properties_SetPropertyPinned',
    variables: {
      id: 'Int!',
      pinned: 'Boolean!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: {
          setPinned: [{
            args: {
              id: true,
              pinned: true,
            },
          }, { result: { id: true } }],
        },
      }),
    },
  }, args);
}
