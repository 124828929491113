import type { IdType } from '@repo-breteuil/common-definitions';
import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';
import { UserPinOrigin } from '@core/api/types';

export interface SetContactPinnedArgs
{
  id: IdType,
  pinned: boolean,
  origin: UserPinOrigin,
}

export default function SetContactPinned(args: SetContactPinnedArgs)
{
  return mutation<IdType>({
    operationName: 'Contacts_SetContactPinned',
    variables: {
      id: 'Int!',
      pinned: 'Boolean!',
      origin: 'UserPinOrigin',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: {
          setPinned: [{
            args: {
              id: true,
              pinned: true,
              origin: true,
            },
          }, { result: { id: true } }],
        },
      }),
    },
  }, args);
}
