import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationBaseArgs, PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import type { OrderBy, Filters__Date, Filters__String } from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationArgs,
  ExtendedPaginationVariables,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilContact
{
  id: IdType,
  fullname: string,
}

export interface MyBreteuilAgency
{
  id: IdType,
  name: string,
}

export interface ValuationAgencyMeeting
{
  id: IdType,
  resolvedAddress: string,
  name: string,
  email: string,
  phone: string,
  phoneVerified: boolean,
  confirmedMeetingDate: number | null/*timestamp*/,
  agency: MyBreteuilAgency | null,
  contact: MyBreteuilContact | null,
  createdAt: number/*timestamp*/,
}

export interface Filters__ValuationAgencyMeeting
{
  resolvedAddress?: Filters__String | undefined,
  confirmedMeetingDate?: Filters__Date | undefined,
  contact?: boolean | undefined,
  agent?: boolean | undefined,
  createdAt?: Filters__Date | undefined,
}

export interface GetAgencyMeetingsVariables extends PaginationBaseArgs
{
  orderBy?: OrderBy | undefined,
  filter?: Filters__ValuationAgencyMeeting | undefined,
}

export default function GetAgencyMeetings(variables: GetAgencyMeetingsVariables)
{
  return query<PaginationExtendedResponse<ValuationAgencyMeeting>>({
    operationName: 'Valuations_GetAgencyMeetings',
    variables: ExtendedPaginationVariables('ValuationAgencyMeeting'),
    fieldsSelection: {
      authenticated: errorWrapper({
        admin: errorWrapper({
          valuationAgencyMeetings: [{
            args: ExtendedPaginationArgs,
          }, paginationResult({
            id: true,
            resolvedAddress: true,
            name: true,
            email: true,
            phone: true,
            phoneVerified: true,
            confirmedMeetingDate: true,
            agency: {
              id: true,
              name: true,
            },
            contact: {
              id: true,
              fullname: true,
            },
            createdAt: true,
          })],
        }),
      }),
    },
  }, variables);
}
