import {
  query,
  errorWrapper,
  ExtendedPaginationVariables,
  ExtendedPaginationArgs,
} from '@core/api/graphql/gen-v2';
import {
  OrderBy,
  Filters__Boolean,
} from '@core/api/types';
import { type Folder, getFoldersFields } from '@my-breteuil/store/ui/pages/folders/api';

export function GetSessionUserFolders(
  args: {
    filter?: {
      personal: Filters__Boolean,
      visibleByAgents: Filters__Boolean,
    },
    orderBy?: OrderBy,
  },
)
{
  return query<Array<Folder>>({
    operationName: 'Folders_SessionUserFolders',
    variables: {
      ...ExtendedPaginationVariables('Folder'),
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        account: {
          mybUser: {
            folders: [{
              args: ExtendedPaginationArgs,
            }, getFoldersFields({ files: 'self' }) ],
          },
        },
      }),
    },
  }, args);
}
