import type { IdType } from '@repo-breteuil/common-definitions';
import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import localesStore from '@core/store/Locales';
import {
  GetUserConfidentialProperties,
  UpdateProperty,
  UpdatePropertyArgs,
  UpdateConfidentialLinkStatus,
  UpdateConfidentialLinkStatusArgs,
  DeleteLink,
} from './api';

class PropertiesConfidentialLinks
{
  private static PropertiesPageSize = 50;
  private static PropertyPageSizeOptions = [50, 100, 200, 500];

  get propertiesPageSize()
  {
    return PropertiesConfidentialLinks.PropertiesPageSize;
  }

  get propertiesPageSizeOptions()
  {
    return PropertiesConfidentialLinks.PropertyPageSizeOptions;
  }

  public properties = new UncachedPagination({
    fetch: (baseArgs) => GetUserConfidentialProperties({
      ...baseArgs,
      language: localesStore.currentLocale,
    }),
    pageSize: this.propertiesPageSize,
  });

  public async refresh()
  {
    await this.properties.first();
  }

  public async updateProperty(args: UpdatePropertyArgs)
  {
    await UpdateProperty(args);
    await this.properties.reload();
  }

  public async updateConfidentialLinkStatus(args: UpdateConfidentialLinkStatusArgs)
  {
    await UpdateConfidentialLinkStatus(args);
    await this.properties.reload();
  }

  public async deleteLink(id: IdType)
  {
    await DeleteLink(id);
    await this.properties.reload();
  }
}

export default new PropertiesConfidentialLinks();
