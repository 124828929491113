import type { IdType } from '@repo-breteuil/common-definitions';
import type { Language, OperationType } from '@core/api/types';

import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export interface MyBreteuilCriteria
{
  id: IdType,
  webName: string,
}

export interface GetSearchCriteriaVariables
{
  language: Language,
  operationType?: OperationType | null | undefined,
  geoAreaId?: IdType | null | undefined,
  property?: boolean | undefined,
}

export default function GetSearchCriteria(variables: GetSearchCriteriaVariables)
{
  return query<Array<Array<MyBreteuilCriteria>>>({
    operationName: 'Contact_GetSearchCriteria',
    variables: {
      operationType: 'MyBreteuilOperationType',
      geoAreaId: 'Int',
      property: 'Boolean',
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        searchCriteria: [{
          args: {
            operationType: true,
            geoAreaId: true,
            property: true,
          },
        }, {
          id: true,
          webName: [{ args: { language: true } }, true],
        }],
      }),
    },
  }, variables);
}
