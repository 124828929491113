import { strCompare } from '@lib/utils/string';
import { OrderType, type OrderBy } from '@core/api/types';

function computeOrderValue(
  args: {
    lhsVal: any,
    rhsVal: any,
    desc?: boolean | undefined,
    valueComparator?: ((lhs: any, rhs: any) => number) | undefined,
  },
)
{
  const {
    lhsVal,
    rhsVal,
    desc,
    valueComparator = (lhs, rhs) => (Number(lhs) - Number(rhs)),
  } = args;
  if (lhsVal === null && rhsVal === null)
    return 0;
  if (lhsVal === null)
    return 1;
  if (rhsVal === null)
    return -1;
  const compared = valueComparator(lhsVal, rhsVal);
  if (compared === 0)
    return 0;
  const comparedSafe = compared > 0 ? 1 : -1;
  if (desc)
    return comparedSafe * -1;
  return comparedSafe;
}

export function sortMultiple<ItemType>(
  items: Array<ItemType>,
  orderBy: Array<{
    valueAccessor: (item: ItemType) => any,
    desc?: boolean,
    /** @default lhs - rhs */
    valueComparator?: (lhs: any, rhs: any) => number,
  }>,
)
{
  if (orderBy.length <= 0)
    return items;
  return items.slice().sort((lhs, rhs) => {
    return orderBy.reduce((acc, orderField, idx) => {
      const { valueAccessor, desc, valueComparator } = orderField;
      const lhsVal = valueAccessor(lhs);
      const rhsVal = valueAccessor(rhs);
      const orderWeight = orderBy.length - idx;
      const value = computeOrderValue({ lhsVal, rhsVal, desc, valueComparator });
      return acc + (orderWeight * value);
    }, 0);
  });
}

export function applyTableOrderBy<ItemType>(items: Array<ItemType>, orderBy: OrderBy)
{
  return sortMultiple(
    items,
    orderBy.map((orderField) => ({
      valueAccessor: (item) => item[orderField.fieldName],
      desc: orderField.ordering === OrderType.DESC,
      valueComparator: (lhs, rhs) => {
        if (typeof lhs === 'string' && typeof rhs === 'string')
          return strCompare(lhs, rhs);
        return Number(lhs) - Number(rhs);
      },
    })),
  );
}
