import type { IdType } from '@repo-breteuil/common-definitions';

import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilVisibleUser
{
  id: IdType,
  fullname: string,
  isAgencyPotCommun: boolean,
  contactSearchAgency: {
    id: IdType,
    shortName: string,
    agencyTreeGeoAreaId: IdType | null,
  } | null,
}

export default function GetVisibleContactUser()
{
  return query<Array<MyBreteuilVisibleUser>>({
    operationName: 'Common_ReferentTransfer_GetContactUsers',
    fieldsSelection: {
      authenticated: errorWrapper({
        users: [{
          args: {
            managedOrSameAgencyOnly: { literal: 'true' },
            includePotCommun: { literal: 'true' },
            includeInactiveUsers: { literal: 'true' },
            includeNonAgents: { literal: 'true' },
            filterAssignedToContactsOnly: { literal: 'true' },
            applyFilterOnlyOnInactiveUsers: { literal: 'true' },
          },
        }, paginationResultAsList({
          id: true,
          fullname: true,
          isAgencyPotCommun: true,
          contactSearchAgency: {
            id: true,
            shortName: true,
            agencyTreeGeoAreaId: true,
          },
        })],
      }),
    },
  });
}
