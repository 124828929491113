import type { DateMinMax } from './GetValuationAgencyMeetingsDistinctPhonesCount';

import {
  query,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface Filters__SimplePropertyValuation
{
  createdAt?: DateMinMax | undefined,
  geoAreasIds?: Array<number> | undefined,
  contact?: boolean | undefined,
  agent?: boolean | undefined,
}

export default function GetSimplePropertyValuationsDistinctPhonesCount(variables: Filters__SimplePropertyValuation)
{
  return query<number>({
    operationName: 'Valuations_GetSimplePropertyValuationsDistinctPhonesCount',
    variables: {
      geoAreasIds: "[Int]",
      createdAt: "AdminValuationCountDateRangeFilter",
      contact: "Boolean",
      agent: "Boolean",
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        admin: errorWrapper({
          simplePropertyValuationsDistinctPhonesCount: [{
            args: {
              geoAreasIds: true,
              createdAt: true,
              contact: true,
              agent: true,
            },
          }, true],
        }),
      }),
    },
  }, variables);
}
