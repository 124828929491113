import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationBaseArgs } from '@repo-lib/graphql-query-pagination';

import {
  query,
  errorWrapper,
  paginationResultAsList,
  PaginationVariables,
  PaginationArgs,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilContact
{
  id: IdType,
  firstname: string,
  lastname: string,
  emails: Array<string>,
  phones: Array<string>,
  createdAt: number/*timestamp*/,
}

export interface GetContactsVariables extends PaginationBaseArgs
{
  term: string,
}

export default function GetContacts(variables: GetContactsVariables)
{
  return query<Array<MyBreteuilContact>>({
    operationName: 'Contact_GetContacts',
    variables: {
      ...PaginationVariables,
      term: 'String!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        searchContacts: [{
          args: {
            ...PaginationArgs,
            term: true,
          },
        }, paginationResultAsList({
          id: true,
          firstname: true,
          lastname: true,
          emails: true,
          phones: true,
          createdAt: true,
        })],
      }),
    },
  }, variables);
}
