import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface AdjustValuationPricePerSurfaceVariables
{
  geoAreaId: IdType,
  avg: number,
}

export default function AdjustValuationPricePerSurface(variables: AdjustValuationPricePerSurfaceVariables)
{
  return mutation<IdType>({
    operationName: 'MarketData_PriceAdjustment_AdjustValuationPricePerSurface',
    variables: {
      geoAreaId: 'Int!',
      avg: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        valuation: {
          adjustValuationPricePerSurface: [{
            args: {
              geoAreaId: true,
              avg: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, variables);
}
