import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface DeleteTransactionVariables
{
  id: IdType,
}

export default function DeleteTransaction(variables: DeleteTransactionVariables)
{
  return mutation<boolean>({
    operationName: 'Property_ValuationsAndTransactions_DeleteTransaction',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          myBreteuilTransaction: {
            delete: errorWrapper([{
              args: {
                id: true,
              },
            }, true]),
          },
        },
      }),
    },
  }, variables);
}
