import {
  createLinkComponent,
  makeLinkProps as makeLinkProps_,
  Router,
} from '@repo-lib/ptr-router';

const router = new Router();

export const Link = createLinkComponent(router);
export const makeLinkProps = (
  args: Omit<Parameters<typeof makeLinkProps_>[0], 'router'>,
) => (
  makeLinkProps_({ ...args, router })
);

export default router;
