import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationBaseArgs, PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import type {
  ExteriorSurfaceType,
  Orientation,
  BreteuilWebsiteViewType,
  LuminosityType,
  BuildingType,
  Filters__Date,
  Filters__Int,
  OrderBy,
  BreteuilWebsiteRenovationType,
} from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationVariables,
  ExtendedPaginationArgs,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface ValuationExteriorSurface
{
  id: IdType,
  surfaceType: ExteriorSurfaceType,
  surface: number,
}

export interface MyBreteuilContact
{
  id: IdType,
  fullname: string,
}

export interface SimplePropertyValuation
{
  id: IdType,
  resolvedAddress: string | null,
  surface: number | null,
  estimatedPrice: number | null,
  phone: string | null,
  phoneVerified: boolean | null,
  contactMe: boolean | null,
  contact: MyBreteuilContact | null,
  createdAt: number/*timestamp*/,
  // Details
  floor: number | null,
  lastFloor: number | null,
  elevator: boolean | null,
  orientation: Orientation | null,
  view: BreteuilWebsiteViewType | null,
  luminosity: LuminosityType | null,
  noisy: boolean | null,
  popularAdress: boolean | null,
  rooms: number | null,
  buildingType: BuildingType | null,
  exteriorSurfaces: Array<ValuationExteriorSurface>,
  renovation: BreteuilWebsiteRenovationType | null,
}

export interface Filters__SimplePropertyValuation
{
  createdAt?: Filters__Date | undefined,
  geoAreaId?: Filters__Int | undefined,
  contact?: boolean | undefined,
  agent?: boolean | undefined,
}

export interface GetValuationsVariables extends PaginationBaseArgs {
  orderBy?: OrderBy | undefined,
  filter?: Filters__SimplePropertyValuation | undefined,
}

export default function GetValuations(variables: GetValuationsVariables)
{
  return query<PaginationExtendedResponse<SimplePropertyValuation>>({
    operationName: 'Valuations_GetValuations',
    variables: ExtendedPaginationVariables('SimplePropertyValuation'),
    fieldsSelection: {
      authenticated: errorWrapper({
        admin: errorWrapper({
          simplePropertyValuations: [{
            args: ExtendedPaginationArgs,
          }, paginationResult({
            id: true,
            resolvedAddress: true,
            surface: true,
            estimatedPrice: true,
            phone: true,
            phoneVerified: true,
            contactMe: true,
            contact: {
              id: true,
              fullname: true,
            },
            createdAt: true,
            // Details
            floor: true,
            lastFloor: true,
            elevator: true,
            orientation: true,
            view: true,
            luminosity: true,
            noisy: true,
            popularAdress: true,
            rooms: true,
            buildingType: true,
            exteriorSurfaces: paginationResultAsList({
              id: true,
              surfaceType: true,
              surface: true,
            }),
            renovation: true,
          })],
        }),
      }),
    },
  }, variables);
}
