import type { IdType } from '@repo-breteuil/common-definitions';
import { errorWrapper, query } from '@core/api/graphql/gen-v2';
import { type PropertyConfidentialLink, PropertyConfidentialLinkFields } from './shared';

export interface GetConfidentialLinkArgs
{
  propertyId: IdType,
  contactId: IdType,
}

export default function GetConfidentialLink(args: GetConfidentialLinkArgs)
{
  return query<PropertyConfidentialLink | null>({
    operationName: 'Common_PropertyConfidentialLinkDialog_GetConfidentialLink',
    variables: {
      propertyId: 'Int!',
      contactId: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: [{
          args: {
            id: 'propertyId',
          },
        }, {
          confidentialLink: [{
            args: { contactId: true },
          }, PropertyConfidentialLinkFields],
        }],
      }),
    },
  }, args);
}
