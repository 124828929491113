import {
  type DisplayErrorOpts,
  GlobalMessage,
} from '@repo-breteuil/front-store-global-message';
import Locales from '@core/store/Locales';

const globalMessage = new GlobalMessage({
  locale: {
    get locale() { return Locales.currentLocale },
  },
});

export function formatGlobalErrorMessage(
  error: Error,
  opts: DisplayErrorOpts = {},
)
{
  globalMessage.displayError(error, opts);
}

export default globalMessage;
