import type { IdType } from '@repo-breteuil/common-definitions';

import { computed } from 'mobx';
import { OpeningControl } from '@repo-lib/utils-mobx-store';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import { assert, handleCriticalError } from '@repo-breteuil/front-error';
import localesStore from '@core/store/Locales';
import MessageDrawerStore from '@my-breteuil/store/ui/common/message-drawer';
import { ViewingCreateDialogStore } from '@my-breteuil/store/ui/common/viewing-dialogs';
import {
  GetContact,
  GetContactSentProperties,
  CreateReminder, type CreateReminderVariables,
  UpdateContact, type UpdateContactArgs,
  GetMatch,
  SetMatchBanned, type SetMatchBannedVariables,
  AddNegativePropertyFeedback, type AddNegativePropertyFeedbackArgs,
  RemoveNegativePropertyFeedback, type RemoveNegativePropertyFeedbackArgs,
} from './api';

class ReminderDialogStore
{
  constructor(
    private _args: {
      onReminderCreateSuccess: () => void,
      onSentPropertiesTableRefresh: () => void,
      onRapprochementsRefresh?: (() => void) | undefined,
      navigationControls?: {
        onClickPrev: () => void,
        onClickNext: () => void,
        /** Must be observable */
        hasPrevContact: () => boolean,
        /** Must be observable */
        hasNextContact: () => boolean,
      } | undefined,
    },
  )
  { }

  get args()
  {
    return this._args;
  }

  public openingControl = new OpeningControl();

  private _contact = new Fetchable(GetContact, { catchUnhandled: handleCriticalError });

  @computed get contact()
  {
    const contact = this._contact.lastResult;
    assert(contact);
    return contact;
  }

  public match = new Fetchable(GetMatch, { catchUnhandled: handleCriticalError });

  public sentProperties = new UncachedPagination({
    fetch: (baseArgs) => GetContactSentProperties({
      ...baseArgs,
      id: this.contact.id,
      language: localesStore.currentLocale,
      withUnhandledInterests: true,
    }),
    pageSize: 50,
  });

  public onSentPropertiesTableRefresh()
  {
    this._args.onSentPropertiesTableRefresh();
  }

  public messageDrawerStore = new MessageDrawerStore({
    onMessageSentSuccess: () => this.onSentPropertiesTableRefresh(), // TODO rename callback
  });

  public openMessageDrawer(property: any/*Property*/)
  {
    this.messageDrawerStore.open({
      defaultContacts: () => [ this.contact ],
      properties: () => [ property ],
    });
  }

  public viewingCreateDialogStore = new ViewingCreateDialogStore();

  get navigationControlsEnabled()
  {
    return this._args.navigationControls !== undefined;
  }

  public onClickPrevContact()
  {
    assert(this._args.navigationControls);
    this._args.navigationControls.onClickPrev();
  }

  public onClickNextContact()
  {
    assert(this._args.navigationControls);
    this._args.navigationControls.onClickNext();
  }

  @computed get hasPrevContact()
  {
    assert(this._args.navigationControls);
    return this._args.navigationControls.hasPrevContact();
  }

  @computed get hasNextContact()
  {
    assert(this._args.navigationControls);
    return this._args.navigationControls.hasNextContact();
  }

  public async open(contactId: IdType, matchId?: IdType)
  {
    await this._contact.ensureSuccessReload({
      id: contactId,
      language: localesStore.currentLocale,
    });
    if (matchId)
      await this.match.ensureSuccessReload(matchId);
    await this.sentProperties.first();
    this.openingControl.open();
  }

  public async refresh()
  {
    await this._contact.ensureSuccessReload({
      id: this.contact.id,
      language: localesStore.currentLocale,
    });
  }

  public async createReminder(args: CreateReminderVariables)
  {
    await CreateReminder(args);
    this._args.onReminderCreateSuccess();
  }

  public async updateContact(args: UpdateContactArgs)
  {
    await UpdateContact(args);
  }

  public async setMatchBanned(args: SetMatchBannedVariables)
  {
    await SetMatchBanned(args);
    const match = this.match.result;
    if (match)
      await this.match.ensureSuccessReload(match.id);
  }

  public async addNegativePropertyFeedback(args: AddNegativePropertyFeedbackArgs)
  {
    await AddNegativePropertyFeedback(args);
  }

  public async removeNegativePropertyFeedback(args: RemoveNegativePropertyFeedbackArgs)
  {
    await RemoveNegativePropertyFeedback(args);
  }
}

export default ReminderDialogStore;
