import type { Definition } from '.';
import type { MyBreteuilTransaction } from './types';

import * as React from 'react';
import { useTheme } from '@mui/material';
import { TransactionStatusColored } from '@my-breteuil/components/common/elements';

export default {
  appBar: {
    title: "MyBreteuil Dashboard",
    buttons: {
      managerMenu: "Manager",
      adminMenu: "Admin",
      profile: "Profile",
      logOut: "Log out",
      logIn: "Log in",
      help: "Help",
      folders: "Folders management",
      personalSpace: "Documents",
      analytics: "Analytics",
      showcaseMenu: "Showcase",
      showcaseMediasList: "Medias",
      showcaseStreamsList: "Streams",
      showcaseScreensList: "Screens",
      showcaseScreensStatus: "Screens status",
      marketDataTransactions: "Transactions",
      marketDataHeatMap: "Heat Map",
      marketDataPriceAdjustment: "Adjust price/m²",
      valuationsPDFs: "Valuations PDFs",
      agencies: "Agencies",
      users: "Blueprint agent",
      configVariables: "Configuration variables",
      properties: "Properties",
      valuations: "Valuations",
      buyers: "Buyers",
      owners: "Owners",
      logs: "Logs (old version)",
      entityLogs: "Logs",
      offers: "Offres et Transactions",
      substituteUser: "Substitute user",
      property: "Property",
      prospecting: "Terrain",
      propertiesConfidentialLinks: "Confidential links",
      holidays: "Holidays",
      usersAPIRequests: "API logs",
      theme: (mode) => `${mode === 'light' ? 'Dark' : 'Light'} mode`,
    },
  },

  drawer: {
    buttons: {
      logIn: "Log in",
      folders: "Folders",
      personalSpace: "Personal Space",
    },
  },

  tablePagination: {
    formatCurrentRange(currentRange: [number, number], count: number)
    {
      const [ from, to ] = currentRange;
      if (count === 0)
        return "No items";
      return `${from + 1} - ${to + 1} of ${count}`;
    },
  },

  reloadPageAfterError: "Try reloading the page",
  loading: "Loading...",
  error: "Error",
  noDataToDisplay: "No data to display",

  version: "Version",

  formatUserLogin(
    user: {
      firstname: string | null,
      lastname: string | null,
    },
  )
  {
    const { firstname, lastname } = user;
    if (!firstname || !lastname)
      return '';
    return firstname.charAt(0).toLowerCase() + lastname.toLowerCase();
  },

  formatTransactionStatus: (transaction: MyBreteuilTransaction) =>
    {
      const theme = useTheme();
      if (transaction.cancelledOffer) {
        return <TransactionStatusColored status="Cancelled" color={theme.colors.transactionStatus.cancelled}/>;
      }
      else if (transaction.invoiceSettled) {
        return <TransactionStatusColored status="Settled" color={theme.colors.transactionStatus.paimentValidated}/>;
      }
      else if (transaction.validateInvoice) {
        return <TransactionStatusColored status="Validated" color={theme.colors.transactionStatus.validated}/>;
      }
      else if (transaction.authenticDeedDate) {
        return <TransactionStatusColored status="Authentic Deed" color={theme.colors.transactionStatus.bill}/>;
      }
      else if (transaction.compromiseDate) {
        return <TransactionStatusColored status="Compromise" color={theme.colors.transactionStatus.agreement}/>;
      }
      else if (transaction.acceptedOfferDate) {
        return <TransactionStatusColored status="Accepted" color={theme.colors.transactionStatus.accepted}/>;
      }
      else {
        return <TransactionStatusColored status="Offer" color={theme.colors.transactionStatus.offre}/>;
      }
    },
} satisfies Definition;
