import type { IdType } from '@repo-breteuil/common-definitions';
import type { NodeFields, ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import { OperationType, Currency, MyBreteuilInvoiceType } from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilUser
{
  id: IdType,
  firstname: string,
  lastname: string,
  fullname: string,
  agent: boolean,
  isAgencyPotCommun: boolean,
  contactSearchAgency: {
    id: IdType,
    code: string,
    name: string,
  } | null,
}

const MyBreteuilUserFields: NodeFields = {
  id: true,
  firstname: true,
  lastname: true,
  fullname: true,
  agent: true,
  isAgencyPotCommun: true,
  contactSearchAgency: {
    id: true,
    code: true,
    name: true,
  },
};

export interface MyBreteuilValuation
{
  id: IdType,
  date: number/*timestamp*/,
  user: MyBreteuilUser,
  lowValuation: number | null,
  highValuation: number | null,
  propertyDocument: {
    id: IdType,
    fileURL: string,
  } | null,
}

export interface MyBreteuilTransaction
{
  id: IdType,
  createdAt: number/*timestamp*/,
  user: MyBreteuilUser,
  buyerGivenName: string | null,
  buyerFamilyName: string | null,
  sellerFamilyName: string | null,
  amount: number,
  acceptedOfferDate: number/*timestamp*/,
  compromiseDate: number/*timestamp*/,
  authenticDeedDate: number/*timestamp*/,
  madeOfferDate: number/*timestamp*/,
  validateInvoice: boolean,
  invoiceSettled: boolean,
  cancelledOffer: boolean,
  property: {
    id: IdType,
    currency: Currency,
    address: string,
  },
  invoices: Array<MyBreteuilInvoice>,
  currentUserReferent: boolean | null,
}

export interface MyBreteuilProperty
{
  id: IdType,
  operationType: OperationType,
  valuations: Array<MyBreteuilValuation>,
  transactions: Array<MyBreteuilTransaction>,
}

export interface MyBreteuilInvoice
{
  id: IdType,
  transactionId: IdType,
  type: MyBreteuilInvoiceType,
  invoiceNumber: number | null,
  statement: string | null,
  invoiceSettled: boolean,

  transactionMandate: string | null,
  transactionProperty: string | null,
  transactionIdentifier: string | null,
  transactionAuthenticDeedDate: number /** timestamp */ | null,

  recipientTitle: string | null,
  recipientCity: string | null,
  recipientFirstname: string | null,
  recipientLastname: string | null,
  recipientFullName: string | null,
  recipientAddress: string | null,
  recipientPostalCode: string | null,
  recipientCountryCode: string | null,

  company: string,
  companyBIC: string,
  companyCPI: string,
  companyVAT: string,
  companyName: string,
  companySIREN: string,
  companyWarranty: string,
  companyRCS: string,
  companyIBAN: string,
  companyAccountName: string,
  companySAS: string,

  agencyName: string,
  agencyPhone: string,
  agencyEmail: string,
  agencyAddress: string,
  agencyCode: string,

  amountTTC: number | null,
  amountHT: number | null,
  amountVAT: number | null,

  agency: {
    id: IdType,
    identifier: string,
  },
}

const TransactionInvoicesFields: ReadonlyNodeFields = {
  id: true,
  transactionId: true,
  type: true,
  invoiceNumber: true,
  statement: true,
  invoiceSettled: true,

  transactionMandate: true,
  transactionProperty: true,
  transactionIdentifier: true,
  transactionAuthenticDeedDate: true,

  recipientTitle: true,
  recipientCity: true,
  recipientFirstname: true,
  recipientLastname: true,
  recipientFullName: true,
  recipientAddress: true,
  recipientPostalCode: true,
  recipientCountryCode: true,

  company: true,
  companyBIC: true,
  companyCPI: true,
  companyVAT: true,
  companyName: true,
  companySIREN: true,
  companyWarranty: true,
  companyRCS: true,
  companyIBAN: true,
  companyAccountName: true,
  companySAS: true,

  agencyName: true,
  agencyPhone: true,
  agencyEmail: true,
  agencyAddress: true,
  agencyCode: true,

  amountTTC: true,
  amountHT: true,
  amountVAT: true,

  agency: {
    id: true,
    identifier: true,
  },
} as const;

export interface GetPropertyValuationsAndTransactionsVariables
{
  slug: string,
}

export default function GetPropertyValuationsAndTransactions(variables: GetPropertyValuationsAndTransactionsVariables)
{
  return query<MyBreteuilProperty>({
    operationName: 'Property_ValuationsAndTransactions_GetPropertyValuationsAndTransactions',
    variables: {
      slug: 'String!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: [{
          args: {
            slug: true,
          },
        }, {
          id: true,
          operationType: true,
          valuations: paginationResultAsList({
            id: true,
            date: true,
            user: MyBreteuilUserFields,
            lowValuation: true,
            highValuation: true,
            propertyDocument: {
              id: true,
              fileURL: true,
            },
          }),
          transactions: paginationResultAsList({
            id: true,
            createdAt: true,
            user: MyBreteuilUserFields,
            buyerGivenName: true,
            buyerFamilyName: true,
            sellerFamilyName: true,
            amount: true,
            acceptedOfferDate: true,
            compromiseDate: true,
            authenticDeedDate: true,
            madeOfferDate: true,
            validateInvoice: true,
            invoiceSettled: true,
            cancelledOffer: true,
            property: {
              id: true,
              currency: true,
              address: true,
            },
            invoices: paginationResultAsList(TransactionInvoicesFields),
            currentUserReferent: true,
          }),
        }],
      }),
    },
  }, variables);
}
