import type { Event } from 'react-big-calendar';
import type { IdType } from '@repo-breteuil/common-definitions';
import type {
  MyBreteuilProperty,
  SetDescriptionTranslationLockedArgs,
  UpdatePropertyVariables,
  UpdateViewingVariables,
} from './api';

import { action, observable, computed } from 'mobx';
import { subYears } from 'date-fns';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { reverseMap } from '@repo-lib/utils-core';
import { OpeningControl } from '@repo-lib/utils-mobx-store';
import {
  ensureFetchableResource,
  handleCriticalError,
  handleNonCriticalError,
} from '@repo-breteuil/front-error';
import { MyBreteuilMatchType } from '@core/api/types';
import { formatGlobalErrorMessage } from '@core/store/GlobalMessage';
import localesStore from '@core/store/Locales';
import googleMapsAPI from '@repo-breteuil/front-store-gmaps';
import MessageDrawerStore from '@my-breteuil/store/ui/common/message-drawer';

import {
  GetPropertyMainInfo,
  GetProperty,
  GetPropertyValuationsAndTransactions,
  GetPropertyViewings,
  GetSearchCriteria,
  CreateProperty,
  UpdateProperty,
  RepublishProperty,
  GetPhotographers,
  GetPropertyMedias,
  SetPropertyPhotos,
  GetPropertyDocuments,
  SetPropertyDocuments,
  GetAgencies,
  GetUsersAssignable,
  GetContact,
  UpdateViewing,
  DeleteTransaction,
  DeleteValuation,
  GetKeyInfos,
  GetSurroundings,
  DuplicateProperty,
  SetDescriptionTranslationLocked,
} from './api';
import propertyLogsStore from './propertyLogs';
import propertyMatchesStore from './propertyMatches';
import {
  ViewingCreateDialogStore,
  ViewingUpdateDialogStore,
} from '@my-breteuil/store/ui/common/viewing-dialogs';
import PropertyConfidentialLinkDialogStore from '@my-breteuil/store/ui/common/property-confidential-link-dialog';

export enum PropertyTab
{
  Info = 'Info',
  Medias = 'Medias',
  Matches = 'Matches',
  Logs = 'Logs',
  ValuationsAndTransactions = 'ValuationsAndTransactions',
  Documents = 'Documents',
  Schedule = 'Schedule',
}
export const RouteParamToPropertyTab = new Map<string, PropertyTab>([
  ['info', PropertyTab.Info],
  ['medias', PropertyTab.Medias],
  ['matches', PropertyTab.Matches],
  ['logs', PropertyTab.Logs],
  ['valuations-and-transactions', PropertyTab.ValuationsAndTransactions],
  ['documents', PropertyTab.Documents],
  ['schedule', PropertyTab.Schedule],
]);
export const PropertyTabToRouteParam = reverseMap(RouteParamToPropertyTab);

export class PropertyStore {
  private static DefaultTab = PropertyTab.Info;
  private static DefaultPageSize = 50;
  public static RowPerPageOptions = [PropertyStore.DefaultPageSize, 100, 200, 500];

  @computed get mode()
  {
    return this.property.lastResult ? 'update' : 'create';
  }

  @observable private _currentTab = PropertyStore.DefaultTab;

  get currentTab()
  {
    return this._currentTab;
  }

  public agencies = new Fetchable(GetAgencies, { catchUnhandled: handleNonCriticalError });
  public agents = new Fetchable(GetUsersAssignable, { catchUnhandled: handleNonCriticalError });

  public propertyMainInfo = new Fetchable(GetPropertyMainInfo, { catchUnhandled: handleCriticalError });

  public searchCriteria = new Fetchable(GetSearchCriteria, { catchUnhandled: handleCriticalError });

  public keyInfos = new Fetchable(GetKeyInfos, { catchUnhandled: handleNonCriticalError });
  public surroundings = new Fetchable(GetSurroundings, { catchUnhandled: handleNonCriticalError });

  public defaultContact = new Fetchable(GetContact, { catchUnhandled: handleNonCriticalError });

  public property = new Fetchable(GetProperty, { catchUnhandled: handleCriticalError });

  public _photographers = new Fetchable(GetPhotographers, { catchUnhandled: handleCriticalError });

  public get photographers()
  {
    const photographers = this._photographers.lastResult;
    if (!photographers)
      throw new Error(`PropertyStore.photographers was accessed before PropertyStore.refresh was called`);
    return photographers;
  }

  public _propertyMedias = new Fetchable(GetPropertyMedias, { catchUnhandled: handleCriticalError });

  public get propertyMedias()
  {
    const propertyMedias = this._propertyMedias.lastResult;
    if (!propertyMedias)
      throw new Error(`PropertyStore.propertyMedias was accessed before PropertyStore.refresh was called`);
    return propertyMedias;
  }

  public valuationsAndTransactions = new Fetchable(GetPropertyValuationsAndTransactions, {
    catchUnhandled: handleCriticalError,
  });

  public _propertyDocuments = new Fetchable(GetPropertyDocuments, { catchUnhandled: handleCriticalError });

  public get propertyDocuments()
  {
    const propertyDocuments = this._propertyDocuments.lastResult;
    if (!propertyDocuments)
      throw new Error(`PropertyStore.propertyDocuments was accessed before PropertyStore.refresh was called`);
    return propertyDocuments;
  }

  public viewings = new Fetchable(() => GetPropertyViewings({
    slug: this.propertyMainInfo.lastResult!.slug,
    filter: { date: { gte: subYears(Date.now(), 1).getTime() } },
  }), { catchUnhandled: handleCriticalError });

  @computed get viewingsEvents(): Array<Event> | undefined
  {
    return this.viewings.lastResult?.map(viewing => {
      const start = new Date(viewing.date);
      const end = new Date(viewing.endDate);
      return { start, end, resource: viewing };
    });
  }

  public refreshResources(property?: MyBreteuilProperty)
  {
    return Promise.all([
      googleMapsAPI.init(__GOOGLE_MAPS_API_KEY__),
      this.agencies.ensureSuccess(),
      this.agents.ensureSuccess(),
      this.keyInfos.ensureSuccess({ language: localesStore.currentLocale }),
      this.surroundings.ensureSuccess({ language: localesStore.currentLocale }),
    ]);
  }

  @action public async refreshCurrentTab()
  {
    return this.refresh({
      propertySlug: this.propertyMainInfo.lastResult!.slug,
      tab: this.currentTab,
    });
  }

  @action public async refresh(
    args: {
      propertySlug: string,
      tab?: PropertyTab | undefined,
      searchParams?: URLSearchParams | undefined,
    },
  )
  {
    const { propertySlug: slug, tab, searchParams } = args;
    const newTab = tab ?? PropertyStore.DefaultTab;

    if (this.defaultContact.result !== undefined)
      this.defaultContact.reset();

    const propertyMainInfo = await this.propertyMainInfo.ensureSuccessReload({
      slug,
      language: localesStore.currentLocale,
    });

    const promises: Array<Promise<unknown>> = [];
    switch (newTab)
    {
      case PropertyTab.Info:
        const property = await this.property.ensureSuccessReload({
          slug,
          language: localesStore.currentLocale,
        });
        promises.push(this.refreshResources(property));
        break;
      case PropertyTab.Medias:
        promises.push(Promise.all([
          this._photographers.ensureSuccessReload(),
          this._propertyMedias.ensureSuccessReload({ slug }),
        ]));
        break;
      case PropertyTab.Matches:
        const matchFilterParam = searchParams?.get('match');
        if (matchFilterParam)
        {
          const matchType = matchFilterParam === 'absolute'
            ? MyBreteuilMatchType.Absolute
            : MyBreteuilMatchType.Extended;
          propertyMatchesStore.setMatchesFilters({
            ...propertyMatchesStore.matchesFilters,
            match: { in: [ matchType ] },
          });
        }
        promises.push(propertyMatchesStore.refresh());
        break;
      case PropertyTab.Logs:
        promises.push(propertyLogsStore.refresh());
        break;
      case PropertyTab.ValuationsAndTransactions:
        promises.push(this.valuationsAndTransactions.ensureSuccessReload({ slug }));
        break;
      case PropertyTab.Documents:
        promises.push(this._propertyDocuments.ensureSuccessReload({ slug }));
        break;
      case PropertyTab.Schedule:
        promises.push(this.viewings.ensureSuccessReload());
        break;
    }
    await Promise.all(promises).then(() => { this._currentTab = newTab; });
    return propertyMainInfo;
  }

  @action public reset()
  {
    this._currentTab = PropertyStore.DefaultTab;
    this.propertyMainInfo.reset();
    this.property.reset();
    this.valuationsAndTransactions.reset();
    this.viewings.reset();
    this.searchCriteria.reset();
    this.defaultContact.reset();
  }

  public createProperty = CreateProperty;

  public updateProperty(args: Omit<UpdatePropertyVariables, 'id'>)
  {
    const propertyMainInfo = ensureFetchableResource(this.propertyMainInfo);
    return UpdateProperty({
      id: propertyMainInfo.id,
      ...args,
    });
  }

  public republishProperty = RepublishProperty;

  public setPropertyPhotos = SetPropertyPhotos;

  public setPropertyDocuments = SetPropertyDocuments;

  public async deleteTransaction(id: IdType)
  {
    await DeleteTransaction({ id });
    await this.refreshCurrentTab();
  }

  public async deleteValuation(id: IdType)
  {
    await DeleteValuation({ id });
    await this.refreshCurrentTab();
  }

  public async updateViewingAndRefresh(args: UpdateViewingVariables)
  {
    await UpdateViewing(args);
    await this.viewings.ensureSuccessReload();
  }

  public async duplicateProperty()
  {
    const property = ensureFetchableResource(this.propertyMainInfo);
    return DuplicateProperty({ id: property.id, language: localesStore.currentLocale });
  }

  public async setDescriptionTranslationLocked(args: SetDescriptionTranslationLockedArgs)
  {
    await SetDescriptionTranslationLocked(args);
    await this.refreshCurrentTab();
  }

  public viewingCreateDialogStore = new ViewingCreateDialogStore();
  public viewingUpdateDialogStore = new ViewingUpdateDialogStore();

  public propertyConfidentialLinkDialogStore = new PropertyConfidentialLinkDialogStore();

  public messageDrawerStore = new MessageDrawerStore({
    onMessageSentSuccess: () => this.refreshCurrentTab().catch((error) => formatGlobalErrorMessage(error)),
  });

  public marketingReportDialogControl = new OpeningControl();
}

export default new PropertyStore();
