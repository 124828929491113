import type {
  NodeFields,
} from '@repo-lib/graphql-query-builder';
import type { TransactionFilter } from '@core/api/types';

import {
  query,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface TransactionsInfo
{
  startDate: number | null/*Timestamp*/,
  endDate: number | null/*Timestamp*/,
}

export interface ResultType
{
  dateFilter: {
    min?: number/*Timestamp*/ | undefined,
    max?: number/*Timestamp*/ | undefined,
  },
  globalInfo: TransactionsInfo,
  filteredInfo: TransactionsInfo,
  volumes: Array<number>,
}

export async function GetGeometryAreasTransactionsVolume(
  areas: Array<Array<google.maps.LatLngLiteral>>,
  filter?: TransactionFilter | undefined,
): Promise<ResultType>
{
  const res = await query({
    operationName: 'MarketData_HeatMap_GetGeometryAreasTransactionsVolume',
    variables: {
      filter: 'Filters__Transaction',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        ...(areas.reduce<NodeFields>((res, points, i) => {
          res[`area${i}`] = [{
            aliasedField: 'transactionsStatsGov',
            args: {
              filter: true,
              geometryFilter: {
                literal: JSON.stringify(points.map((
                  ({ lat, lng }) => ({ latitude: lat, longitude: lng })
                ))).replace(/"/g, ''),
              },
            },
          }, {
            volume: true,
          }];
          return res;
        }, {
          globalInfo: [{
            aliasedField: 'transactionsStatsGov',
          }, {
            startDate: true,
            endDate: true,
          }],
          filteredInfo: [{
            aliasedField: 'transactionsStatsGov',
            args: {
              filter: true,
            },
          }, {
            startDate: true,
            endDate: true,
          }],
        })),
      }),
    },
  }, {
    filter,
  });
  const { globalInfo, filteredInfo } = (res as any);
  return {
    dateFilter: {
      min: filter?.date?.gte ?? undefined,
      max: filter?.date?.lte ?? undefined,
    },
    globalInfo,
    filteredInfo,
    volumes: areas.map((_, i) => (res as any)[`area${i}`]),
  };
}
