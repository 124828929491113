import type { IdType } from '@repo-breteuil/common-definitions';
import type { HierarchyNodeBase } from '@repo-lib/utils-hierarchy-tree';

export interface GeoArea
{
  id: IdType,
  parentId: IdType | null,
  displayName: string,
  agencyAreaDisplayOrder: number | null,
  agencyAreaDefaultExpanded: boolean,
}

export interface UserWithAgency
{
  user: {
    id: IdType,
    fullname: string,
  },
  agency: {
    id: IdType,
    shortName: string,
    agencyTreeGeoAreaId: IdType | null,
  } | null, // We sometimes do not have any agency to place the user within. User will be placed in a separated node.
}

export interface UserWithAgencyHistory extends UserWithAgency
{
  activeInAgency: boolean,
}

export enum NodeType
{
  Area = 'Area',
  Agency = 'Agency',
  User = 'User',
}

export function isNodeType(type: string): type is NodeType
{
  return type in NodeType;
}

export interface UserHierarchyNode extends HierarchyNodeBase<string>
{
  name: string,
  defaultExpanded: boolean,
  ignore: boolean, // Used to filter certain areas with agencyAreaDisplayOrder === null
}

export interface UserHistoryHierarchyNode extends UserHierarchyNode
{
  activeInAgency?: boolean | undefined, // Only meaningfull when node type === User
}
