import * as React from 'react';
import { observer } from 'mobx-react-lite';
import main from '@my-breteuil/store/ui/Main';
import { error as errorTexts } from '@repo-breteuil/common-texts';
import texts from '@my-breteuil/lang/common';

import { Loading } from '@my-breteuil/components/common';

const Main = observer(() => {
  if (main.error)
    return (
      <React.Fragment>
        <p>{errorTexts.EN.formatError(main.error)}</p>
        <p>{texts.current.reloadPageAfterError}</p>
      </React.Fragment>
    );
  if (main.loading)
    return (
      <Loading/>
    );
  const { AppComponent: App } = main;
  return (
    <App/>
  );
});

export default Main;
