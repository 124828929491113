import { mutation } from '@core/api/graphql/gen-v2';
import { type SessionResult, SessionFields } from './GetSession';

export function LogOut()
{
  return mutation<SessionResult | null>({
    operationName: 'LogOut_LogOut',
    fieldsSelection: {
      session: {
        logOut: SessionFields,
      },
    },
  });
}
