import type { IdType } from '@repo-breteuil/common-definitions';

import { errorWrapper, query } from '@core/api/graphql/gen-v2';
import { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

export interface User
{
  id: IdType,
  email: string,
  fullname: string,
  phoneMobile: string | null,
  contactSearchAgency: {
    id: IdType,
    phone: string,
  } | null,
}

const UserFields: ReadonlyNodeFields = {
  id: true,
  email: true,
  fullname: true,
  phoneMobile: true,
  contactSearchAgency: {
    id: true,
    phone: true,
  },
};

export default function Valuation_GetUser(id: IdType)
{
  return query<User>({
    operationName: 'Valuation_GetUser',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilUser: [{
          args: {
            id: true,
          },
        }, UserFields],
      }),
    },
  }, { id });
}
