import type { IdType } from '@repo-breteuil/common-definitions';
import { Fetchable, OpeningControl } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import {
  GetProperty,
  GetContact,
  GetConfidentialLink,
  CreatePropertyConfidentialLink,
  CreatePropertyConfidentialLinkArgs,
} from './api';

class PropertyConfidentialLinkDialogStore
{
  private _control = new OpeningControl();

  get opened()
  {
    return this._control.isOpen;
  }

  public propertyFetchable = new Fetchable(GetProperty, { catchUnhandled: handleNonCriticalError });

  get property()
  {
    if (!this.propertyFetchable.lastResult)
      throw new Error(`Property must be loaded when dialog is open.`);
    return this.propertyFetchable.lastResult;
  }

  public contactFetchable = new Fetchable(GetContact, { catchUnhandled: handleNonCriticalError });

  get contact()
  {
    return this.contactFetchable.lastResult ?? null;
  }

  public linkFetchable = new Fetchable(GetConfidentialLink, { catchUnhandled: handleNonCriticalError });

  get link()
  {
    return this.linkFetchable.lastResult ?? null;
  }

  private _onConfidentialLinkCreateSuccess: (() => void) | null = null;

  async open(
    args: {
      propertyId: IdType,
      contactId?: IdType,
      onConfidentialLinkCreateSuccess?: () => void,
    },
  )
  {
    const { propertyId, contactId, onConfidentialLinkCreateSuccess } = args;
    this.reset();
    await Promise.all([
      this.propertyFetchable.ensureSuccessReload(propertyId),
      ...(contactId ? [
        this.contactFetchable.ensureSuccessReload(contactId),
        this.linkFetchable.ensureSuccessReload({ propertyId, contactId }),
      ] : []),
    ]);
    this._onConfidentialLinkCreateSuccess = onConfidentialLinkCreateSuccess ?? null;
    this._control.open();
  }

  close()
  {
    this._control.close();
  }

  reset()
  {
    this.contactFetchable.reset();
    this.linkFetchable.reset();
  }

  public async createPropertyConfidentialLink(args: CreatePropertyConfidentialLinkArgs) {
    const res = await CreatePropertyConfidentialLink(args);
    this.linkFetchable.setResult(res);
    await this.propertyFetchable.ensureSuccessReload(this.property.id);
    if (this._onConfidentialLinkCreateSuccess)
      this._onConfidentialLinkCreateSuccess();
  }
}

export default PropertyConfidentialLinkDialogStore;
