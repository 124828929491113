import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { IdType } from '@repo-breteuil/common-definitions';

export interface MyBreteuilUserHistorical
{
  user: {
    id: IdType,
    fullname: string,
  },
  agency: {
    id: IdType,
    shortName: string,
    agencyTreeGeoAreaId: IdType | null,
  },
  activeInAgency: boolean,
}

const MyBreteuilUserHistoricalFields: ReadonlyNodeFields = {
  user: {
    id: true,
    fullname: true,
  },
  agency: {
    id: true,
    shortName: true,
    agencyTreeGeoAreaId: true,
  },
  activeInAgency: true,
};

export default function GetManagedUsersHistorical()
{
  return query<Array<MyBreteuilUserHistorical>>({
    operationName: 'Offers_GetUsers',
    fieldsSelection: {
      authenticated: errorWrapper({
        usersPerAgency: [{
          args: {
            managedOnly: { literal: 'true' },
            managerPrioritizeShareholderManagedAgencies: { literal: 'true' },
            includePotCommun: { literal: 'false' },
            includeInactiveUsers: { literal: 'true' },
            includePreviousAgencyAffectations: { literal: 'true' },
            includeChildAgencies: { literal: 'true' },
            includeNonAgents: { literal: 'true' },
            includeCommissionsAgencies: { literal: 'true' },
          },
        }, MyBreteuilUserHistoricalFields],
      }),
    },
  });
}
