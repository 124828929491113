import type { IdType } from '@repo-breteuil/common-definitions';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilUser
{
  id: IdType,
}

export interface UpdatePasswordArgs {
  id: IdType,
  password: string,
  currentPassword: string,
}

export default function UpdatePassword(args: UpdatePasswordArgs)
{
  return mutation<MyBreteuilUser>({
    operationName: 'PersonalSpace_UpdatePassword',
    variables: {
      id: "Int!",
      password: "String",
      currentPassword: "String",
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilUser: {
          update: [{
              args: {
                id: true,
                password: true,
                currentPassword: true,
              },
            }, {
            id: true,
          }],
        },
      }),
    },
  }, args);
}
