import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationExtendedResponse, PaginationBaseArgs } from '@repo-lib/graphql-query-pagination';
import type {
  Language,
  BreteuilPropertyPriceChangeStatus,
  MyBreteuilPropertyVisibility,
  MyBreteuilMatchType,
  PropertyType,
  MyBreteuilHeating,
  MyBreteuilWaterHeating,
  AlertsFrequency,
  OperationType,
  PropertyStatus,
  OrderBy,
  Filters__Boolean,
  Filters__Date,
  Filters__String,
  Filters__Enum,
  Filters__Int,
  RapprochementType,
  PropertyConfidentialLinkStatus,
  Currency,
} from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResult,
  paginationResultAsList,
  ExtendedPaginationArgs,
  ExtendedPaginationVariables,
} from '@core/api/graphql/gen-v2';
import { PictureFormat } from '@core/api/types';

export interface MyBreteuilCriteria
{
  id: IdType,
  webName: string,
}

export type Property = {
  id: IdType,
  refId: IdType,
  slug: string,
  lastPriceUpdateStatus: BreteuilPropertyPriceChangeStatus,
  publication: MyBreteuilPropertyVisibility,
  confidential: boolean,
  price: number,
  surface: number,
  pricePerSurface: number,
  propertyType: PropertyType,
  heating: MyBreteuilHeating,
  waterHeatType: MyBreteuilWaterHeating,
  constructionYear: number,
  bathrooms: number,
  bedrooms: number,
  toilets: number,
  rooms: number,
  district: {
    id: IdType,
    displayName: string,
  },
  address: string,
  addrStreetAddress: string,
  picturesURL: Array<string>,
  marketConsistent: boolean,
  managerMarketConsistent: boolean,
  floor: number,
  floorsCount: number,
  status: PropertyStatus,
  criteria: Array<MyBreteuilCriteria>,
  currency: Currency,
}

export type MyBreteuilMatch = {
  id: IdType,
  match: MyBreteuilMatchType,
  banned: boolean,
  extendedMissingFields: Array<string>,
  extendedMissingCriteria: Array<{
    name: string,
  }>,
  property: Property,
  propertyId: IdType,
  propertyLastPriceUpdate: number | null/*timestamp*/,
  propertyAddrStreetAddress: string | null,
  propertyPrice: number | null,
  propertySurface: number | null,
  propertyBedrooms: number | null,
  sendable: boolean | null,
  sendableConditionNotAlreadySent: boolean | null,
  sendableConditionSearchSendable: boolean | null,
  rapprochedTypes: Array<RapprochementType>,
  searchContactAlertsFrequency: AlertsFrequency | null,
  propertyOperationType: OperationType,
  confidentialLink: {
    id: IdType,
    status: PropertyConfidentialLinkStatus,
  } | null,
};

export interface Filters_MyBreteuilProperty {
  marketConsistent?: Filters__Boolean | undefined,
}


export interface Filters__MyBreteuilMatch
{
  sendable?: Filters__Boolean | undefined,
  sendableCondition_NotAlreadySent?: Filters__Boolean | undefined,
  search_archived?: Filters__Boolean | undefined,
  match?: Filters__Enum<MyBreteuilMatchType> | undefined,
  property_lastPriceUpdate?: Filters__Date | undefined,
  property_addrStreetAddress?: Filters__String | undefined,
  property_price?: Filters__Int | undefined,
  property_surface?: Filters__Int | undefined,
  property_bedrooms?: Filters__Int | undefined,
  property?: Filters_MyBreteuilProperty | undefined,
}

export interface GetContactMatchesArgs extends PaginationBaseArgs
{
  id: IdType,
  language: Language,
  filter?: Filters__MyBreteuilMatch | undefined,
  orderBy?: OrderBy | undefined,
}

export default function GetContactMatches(args: GetContactMatchesArgs)
{
  return query<PaginationExtendedResponse<MyBreteuilMatch>>({
    operationName: 'Contact_GetContactMatchesNonSent',
    variables: {
      id: 'Int!',
      language: 'Language!',
      ...ExtendedPaginationVariables('MyBreteuilMatch'),
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: [{ args: { id: true } }, {
          matchesNonSent: [{
            args: ExtendedPaginationArgs,
          }, paginationResult({
            id: true,
            match: true,
            banned: true,
            extendedMissingFields: true,
            extendedMissingCriteria: [{ preventUnwrap: true }, { name: true }],
            property: {
              id: true,
              refId: true,
              slug: [{ args: { language: true } }, true],
              lastPriceUpdateStatus: true,
              publication: true,
              confidential: true,
              price: true,
              surface: true,
              pricePerSurface: true,
              propertyType: true,
              heating: true,
              waterHeatType: true,
              constructionYear: true,
              bathrooms: true,
              toilets: true,
              bedrooms: true,
              rooms: true,
              district: {
                id: true,
                displayName: [{ args: { language: true } }, true],
              },
              address: true,
              addrStreetAddress: true,
              picturesURL: [{ args: { format: { literal: PictureFormat._360p }}}, true],
              marketConsistent: true,
              managerMarketConsistent: true,
              floor: true,
              floorsCount: true,
              status: true,
              criteria: paginationResultAsList({
                id: true,
                webName: [{ args: { language: true } }, true],
              }),
              currency: true,
            },
            propertyId: true,
            propertyLastPriceUpdate: true,
            propertyAddrStreetAddress: true,
            propertyStatus: true,
            propertyPrice: true,
            propertySurface: true,
            propertyBedrooms: true,
            sendable: true,
            sendableConditionNotAlreadySent: true,
            sendableConditionSearchSendable: true,
            rapprochedTypes: true,
            searchContactAlertsFrequency: true,
            propertyOperationType: true,
            confidentialLink: {
              id: true,
              status: true,
            },
          })],
        }],
      }),
    },
  }, args);
}
