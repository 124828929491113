
import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

export interface ConfigVariable__Int
{
  value: number | null,
  type: 'Int',
}

export interface ConfigVariable__Float
{
  value: number | null,
  type: 'Float',
}

export interface ConfigVariable__Boolean
{
  value: boolean | null,
  type: 'Bool',
}

export interface ConfigVariables {
  propertiesInfo__resendDelayDays: ConfigVariable__Int,
}

const ConfigVariableArgs: ReadonlyNodeFields = {
  propertiesInfo__resendDelayDays: {
    type: true,
    value: true,
  },
} as const;

function GetConfigVariables()
{
  return query<ConfigVariables>({
    operationName: 'Global_GetConfigVariables',
    fieldsSelection: {
      authenticated: errorWrapper({
        configVariables: {
          getConfigVariables: [{
            preventUnwrap: true,
          }, ConfigVariableArgs],
        },
      }),
    },
  });
}

export default GetConfigVariables;
