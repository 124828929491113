import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';
import { ReadonlyFieldArgs } from '@repo-lib/graphql-query-builder';

export interface TransferAreaStreetsArgs
{
  streetsIds: Array<IdType>,
  targetUserId: IdType,
}

const TransferAreaStreetsArgsVariables = {
  streetsIds: '[Int!]!',
  targetUserId: 'Int!',
} as const;

const TransferAreaStreetsArgsFields: ReadonlyFieldArgs = {
  streetsIds: true,
  targetUserId: true,
};

export default function TransferAreaStreets(args: TransferAreaStreetsArgs)
{
  return mutation<number>({
    operationName: 'Prospecting_TransferAreaStreets',
    variables: TransferAreaStreetsArgsVariables,
    fieldsSelection: {
      authenticated: errorWrapper({
        prospectingAreaStreet: {
          transferStreets: [{ args: TransferAreaStreetsArgsFields }, true],
        },
      }),
    },
  }, args);
}
