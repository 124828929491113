import latinize from 'latinize';

/**
 * Makes the string lowercase and remove diacritics (accents) from it.
 */
export function normalizeString(str: string): string
{
  return latinize(str.toLowerCase());
}

/**
 * Checks if str contains subStr. Ignoring case and diacritics.
 */
export function strContains(str: string, subStr: string)
{
  return normalizeString(str).includes(normalizeString(subStr));
}

export function strCompare(lhs: string, rhs: string)
{
  const lhsNormalized = normalizeString(lhs);
  const rhsNormalized = normalizeString(rhs);
  if (lhsNormalized < rhsNormalized)
    return -1;
  if (lhsNormalized > rhsNormalized)
    return 1;
  return 0;
}
