import type { ReadonlyNode, NodeFields } from '@repo-lib/graphql-query-builder';
import { errorWrapper as genericErrorWrapper } from '@repo-breteuil/front-store-query';

export {
  type ClientError,
  isClientError,
} from '@repo-breteuil/front-store-query';

export function errorWrapper(
  result: ReadonlyNode,
  opts: {
    errorPolymorphicFields?: Record<string/*Typename*/, NodeFields>,
  } = {},
): ReadonlyNode
{
  const polymorphicFields: Record<string/*Typename*/, NodeFields> = {
    InvalidAddress: {
      address: true,
    },
    InvalidUploadType: {
      allowedTypes: true,
    },
    PhoneAlreadyTaken: {
      phone: true,
    },
    EmailAlreadyTaken: {
      email: true,
    },
    InvalidToken: {
      token: true,
    },
    InvalidProperty: {
      context: true,
      requirements: {
        requiredFields: true,
        requiredPhotos: true,
        requiredStatus: true,
        requiredVisibility: true,
      },
    },
  };
  if (opts.errorPolymorphicFields)
  {
    for (const key in opts.errorPolymorphicFields)
      polymorphicFields[key] = { ...polymorphicFields[key], ...opts.errorPolymorphicFields[key] };
  }

  return genericErrorWrapper(result, { errorPolymorphicFields: polymorphicFields });
}
