import type { IdType } from '@repo-breteuil/common-definitions';

import { errorWrapper, mutation } from '@core/api/graphql/gen-v2';
import {
  MyBreteuilPropertyValuationPDFFields, type MyBreteuilPropertyValuationPDF,
  type PropertyValuationPDFData,
} from './shared';

export type SetPropertyValuationPDFArgs = PropertyValuationPDFData;

export default function SetPropertyValuationPDF(
  args: {
    propertyId: IdType,
    userId: IdType,
    data: SetPropertyValuationPDFArgs,
  })
{
  return mutation<MyBreteuilPropertyValuationPDF>({
    operationName: 'Valuation_SetPropertyValuationPDF',
    variables: {
      propertyId: 'Int!',
      userId: 'Int',
      data: 'PropertyValuationPDFDataInput!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilPropertyValuationPDF: {
          setPropertyValuationPDF: [{
            args: {
              propertyId: true,
              userId: true,
              data: true,
            },
          }, MyBreteuilPropertyValuationPDFFields],
        },
      }),
    },
  }, args);
}
