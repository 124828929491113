import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface ManagedUser
{
  id: IdType,
  fullname: string,
  isAgencyPotCommun: boolean,
  contactSearchAgency: {
    id: IdType,
    shortName: string,
    agencyTreeGeoAreaId: IdType | null,
  } | null,
}

export const ManagedUserFields: ReadonlyNodeFields = {
  id: true,
  fullname: true,
  isAgencyPotCommun: true,
  contactSearchAgency: {
    id: true,
    shortName: true,
    agencyTreeGeoAreaId: true,
  },
};

export default function GetManagedUsers()
{
  return query<Array<ManagedUser>>({
    operationName: 'Common_ReferentTransfer_GetUsers_Managed',
    fieldsSelection: {
      authenticated: errorWrapper({
        users: [{
          args: {
            managedOnly: { literal: 'true' },
            includePotCommun: { literal: 'true' },
            includeInactiveUsers: { literal: 'false' },
          },
        }, paginationResultAsList(ManagedUserFields) ],
      }),
    },
  });
}
