import type { IdType } from '@repo-breteuil/common-definitions';
import type { Filters__Date, ViewingType } from '@core/api/types';

import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface MyBreteuilUser
{
  id: IdType,
  fullname: string,
}

export interface MyBreteuilContact
{
  id: IdType,
  fullname: string,
}

export interface MyBreteuilViewing
{
  id: IdType,
  type: ViewingType,
  date: number/*timestamp*/,
  endDate: number/*timestamp*/,
  user: MyBreteuilUser,
  contact: MyBreteuilContact | null,
  mybNom: string | null,
}

export interface Filters__MyBreteuilViewing
{
  date: Filters__Date,
}

export interface GetPropertyViewingsVariables
{
  slug: string,
  filter?: Filters__MyBreteuilViewing | undefined,
}

export default function GetPropertyViewings(variables: GetPropertyViewingsVariables)
{
  return query<Array<MyBreteuilViewing>>({
    operationName: 'Property_Viewings_GetPropertyViewings',
    variables: {
      slug: 'String!',
      filter: 'Filters__MyBreteuilViewing',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: [{
          args: {
            slug: true,
          },
        }, {
          viewings: [{
            args: {
              filter: true,
            },
          }, paginationResultAsList({
            id: true,
            type: true,
            date: true,
            endDate: true,
            user: {
              id: true,
              fullname: true,
            },
            contact: {
              id: true,
              fullname: true,
            },
            mybNom: true,
          })],
        }],
      }),
    },
  }, variables);
}
