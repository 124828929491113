import type { IdType } from '@repo-breteuil/common-definitions';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface CreateArgs
{
  name: string,
  identifier: string,
  showcaseStreamId?: IdType | null | undefined,
  streamItemOffset?: IdType | null | undefined,
  nightTimeFilterStartMinute?: number | null | undefined,
  nightTimeFilterEndMinute?: number | null | undefined,
}

async function CreateShowcaseScreen(args: CreateArgs): Promise<void>
{
  await mutation({
    operationName: 'Showcase_ScreensList_CreateShowcaseScreen',
    variables: {
      name: 'String!',
      identifier: 'String!',
      showcaseStreamId: 'Int',
      streamItemOffset: 'Int',
      nightTimeFilterStartMinute: 'Int',
      nightTimeFilterEndMinute: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseScreen: {
          create: [{
            args: {
              name: true,
              identifier: true,
              showcaseStreamId: true,
              streamItemOffset: true,
              nightTimeFilterStartMinute: true,
              nightTimeFilterEndMinute: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, args);
}

export default CreateShowcaseScreen;
