import type { FiltersArgs } from './filters';

import { pickKeysFromObject } from '@repo-lib/utils-core';
import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { FiltersVariables, FiltersFieldArgs } from './filters';
import { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

export interface OffersAndTransactionMiddleStats
{
  totalAgencyFees_SubtractingContributorsFees_TTC: number,
  totalAgencyFees_SubtractingContributorsFees_HT: number,
  totalFeesPercentage_TTC: number,
  userRemuneration_weightedMean_normalized: number,
  totalAgencyFeesExcludingManagers_SubtractingContributorsFees_HT: number,
  totalAgencyFeesOnlyCoAgents_SubtractingContributorsFees_TTC: number,
  pricePerSurface: number,
  totalAmount: number,
  transactionsCount: number,
  contributorsCount: number,
  totalContributors_TTC: number,
  totalCashedAgencyFees_TTC: number,
  totalCashableAgencyFees_TTC: number,
}

export const OffersAndTransactionMiddleStatsFields: ReadonlyNodeFields = {
  totalAgencyFees_SubtractingContributorsFees_TTC: true,
  totalAgencyFees_SubtractingContributorsFees_HT: true,
  totalFeesPercentage_TTC: true,
  userRemuneration_weightedMean_normalized: true,
  totalAgencyFeesExcludingManagers_SubtractingContributorsFees_HT: true,
  totalAgencyFeesOnlyCoAgents_SubtractingContributorsFees_TTC: true,
  pricePerSurface: true,
  totalAmount: true,
  transactionsCount: true,
  contributorsCount: true,
  totalContributors_TTC: true,
  totalCashedAgencyFees_TTC: true,
  totalCashableAgencyFees_TTC: true,
};

export interface GetCommissionsStatsVariables extends FiltersArgs {}

const FiltersArgsPickedKeys: Array<keyof FiltersArgs> = [
  'agencyUsers', 'cancelledOnly', 'dateRange', 'operationTypes', 'propertyStatuses', 'contractTypes', 'type',
];

export default function GetCommissionsStats(variables: GetCommissionsStatsVariables)
{
  return query<OffersAndTransactionMiddleStats>({
    operationName: 'Offers_GetCommissionsStats',
    variables: pickKeysFromObject(FiltersVariables, FiltersArgsPickedKeys),
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          reporting: {
            // TODO This endpoint should be renamed on the API side
            middleStats: [{
              args: pickKeysFromObject(FiltersFieldArgs, FiltersArgsPickedKeys),
            }, OffersAndTransactionMiddleStatsFields],
          },
        },
      }),
    },
  }, variables);
}
