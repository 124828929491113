import type { IdType } from '@repo-breteuil/common-definitions';

import { observable, computed, action } from 'mobx';
import { OrderType } from '@repo-lib/graphql-query-pagination';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import { GetSessionUserFolders } from './api';

class PersonalSpacePublicFolders
{
  private _folders = new Fetchable(() => {
    return GetSessionUserFolders({
      filter: {
        personal: { equals: false },
        visibleByAgents: { equals: true },
      },
      orderBy: [{ fieldName: 'name', ordering: OrderType.ASC }],
    });
  }, { catchUnhandled: handleNonCriticalError });

  @observable selectedFolderId: IdType | null = null;

  @action setSelectedFolderId(id: IdType | null)
  {
    this.selectedFolderId = id;
  }

  @computed get folders()
  {
    return this._folders.result || [];
  }

  @computed get selectedFolder()
  {
    if (this.selectedFolderId === null)
      return null;
    return this.folders.find(folder => (folder.id === this.selectedFolderId)) || null;
  }

  refreshFolders()
  {
    return this._folders.ensureSuccessReload();
  }
}

export default PersonalSpacePublicFolders;
