import type { User } from './shared';

import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';
import { UserFields } from './shared';

export default function GetManagedUsers()
{
  return query<Array<User>>({
    operationName: 'Users_GetUsers',
    fieldsSelection: {
      authenticated: errorWrapper({
        users: [{
          args: {
            managedOnly: { literal: 'true' },
            includePotCommun: { literal: 'true' },
            includeInactiveUsers: { literal: 'true' },
            includeNonAgents: { literal: 'true' },
          },
        }, paginationResultAsList(UserFields)],
      }),
    },
  });
}
