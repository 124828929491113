import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationConnectionWithCount } from '@repo-lib/graphql-query-pagination';
import type { NodeFields } from '@repo-lib/graphql-query-builder';
import { formatFieldsToPaginationFilter } from '@repo-lib/graphql-query-pagination';
import { omitUndefinedValues } from '@repo-lib/utils-core';

import {
  query,
  errorWrapper,
  paginationResult,
} from '@core/api/graphql/gen-v2';

export interface ShowcaseMedia
{
  id: IdType,
  name: string,
  mediaType: 'Image' | 'Video',
  defaultDisplayDurationMs: number | null,
  fileURL: string,
  pictureFormat: string,
}

export const ShowcaseMediaFields: NodeFields = {
  id: true,
  name: true,
  mediaType: true,
  defaultDisplayDurationMs: true,
  fileURL: true,
  pictureFormat: true,
};

export interface MediasFilters
{
  name?: string | undefined,
  mediaType?: Array<'Image' | 'Video'> | undefined,
}

export async function GetShowcaseMedias(
  variables: { filter?: MediasFilters } = {},
): Promise<PaginationConnectionWithCount<ShowcaseMedia>>
{
  return query({
    operationName: 'Showcase_StreamMixing_GetShowcaseMedias',
    variables: {
      filter: 'Filters__ShowcaseMedia',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcase: {
          medias: [{
            args: {
              filter: true,
            },
          }, paginationResult({
            id: true,
            name: true,
            mediaType: true,
            defaultDisplayDurationMs: true,
            fileURL: true,
            pictureFormat: true,
          })],
        },
      }),
    },
  }, {
    filter: variables.filter && formatFieldsToPaginationFilter(omitUndefinedValues(variables.filter)),
  });
}
