import type { IdType } from '@repo-breteuil/common-definitions';
import { assert } from '@repo-breteuil/front-error';
import { isNodeType, NodeType } from './types';

const IdentifierSeparator = ':';

function makeIdentifierFromParts(type: NodeType, id: IdType | ReadonlyArray<IdType>)
{
  const ids = Array.isArray(id) ? id : [ id ];
  return [ type, ...ids ].join(IdentifierSeparator);
}

export function makeAreaIdentifier(areaId: IdType)
{
  return makeIdentifierFromParts(NodeType.Area, areaId);
}

export function makeAgencyIdentifier(agencyId: IdType)
{
  return makeIdentifierFromParts(NodeType.Agency, agencyId);
}

export function makeUserIdentifier(userId: IdType)
{
  return makeIdentifierFromParts(NodeType.User, userId);
}

export function makeUserHistoryIdentifier({ userId, agencyId }: { userId: IdType, agencyId: IdType })
{
  return makeIdentifierFromParts(NodeType.User, [ agencyId, userId ]);
}

export function parseNodeTypeIdentifier(identifier: string)
{
  const parts = identifier.split(IdentifierSeparator);
  assert(parts.length > 0, `Invalid identifier format: ${identifier}`);
  const type = parts[0];
  assert(isNodeType(type), `Invalid NodeType: ${type} from identifier: ${identifier}`);
  return type;
}

export function parseNodeIdentifier(identifier: string)
{
  const parts = identifier.split(IdentifierSeparator);
  assert(parts.length === 2, `Invalid identifier format: ${identifier}`);
  const [ type, id_ ] = parts;
  assert(isNodeType(type), `Invalid NodeType: ${type} from identifier: ${identifier}`);
  const id = parseInt(id_);
  assert(!isNaN(id), `Invalid node id: ${id_} from identifier: ${identifier}`);
  return { type, id };
}

export function parseUserHistoryNodeIdentifier(identifier: string)
{
  const parts = identifier.split(IdentifierSeparator);
  assert(parts.length === 3, `Invalid user identifier format: ${identifier}`);
  const [ type, agencyId_, userId_ ] = parts;
  const agencyId = parseInt(agencyId_);
  const userId = parseInt(userId_);
  assert(type === NodeType.User && !isNaN(agencyId) && !isNaN(userId), `Invalid user identifier: ${identifier}`);
  return {
    agencyId,
    userId,
  };
}

export function parseUserHistoryNodesIdentifiers(identifiers: Iterable<string>)
{
  return Array.from(identifiers).map(parseUserHistoryNodeIdentifier);
}

export function parseHistoryNodeIdentifier(identifier: string): {
  type: NodeType.User,
  agencyId: IdType,
  userId: IdType,
} | {
  type: NodeType.Area | NodeType.Agency,
  id: IdType,
}
{
  const parts = identifier.split(IdentifierSeparator);
  assert(parts.length > 0, `Invalid identifier format: ${identifier}`);
  const type = parts[0];
  assert(isNodeType(type), `Invalid NodeType: ${type} from identifier: ${identifier}`);
  if (type === NodeType.User)
  {
    assert(parts.length === 3, `Invalid user identifier: ${identifier}`);
    const agencyId = parseInt(parts[1]);
    const userId = parseInt(parts[2]);
    assert(!isNaN(agencyId) && !isNaN(userId), `Invalid user identifier: ${identifier}`);
    return {
      type,
      agencyId,
      userId,
    };
  }
  assert(parts.length === 2, `Invalid identifier: ${identifier}`);
  const id = parseInt(parts[1]);
  return {
    type,
    id,
  };
}
