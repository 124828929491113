import { observable, action } from 'mobx';
import session from '@my-breteuil/store/ui/common/Session';

import AppBundle from '@my-breteuil/bundles/app';

export class Main
{
  @observable private _error: Error | null = null;

  constructor()
  {
    AppBundle.load().catch(action((err: Error) => {
      this._error = err;
    }));
    session.initializeSessionFromStorage();
  }

  get sessionPromise()
  {
    return session.fetchable.currentPromise;
  }

  get loading()
  {
    return AppBundle.loading || session.fetchable.pending;
  }

  get error()
  {
    return this._error || session.fetchable.error || null;
  }

  get AppComponent()
  {
    return AppBundle.get().default;
  }
};

export default new Main();
