import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationBaseArgs, PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import type { OrderBy, Filters__Date, Filters__Int } from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationArgs,
  ExtendedPaginationVariables,
} from '@core/api/graphql/gen-v2';

interface MyBreteuilUser
{
  id: IdType,
  firstname: string,
  lastname: string,
  fullname: string,
  agent: boolean,
  isAgencyPotCommun: boolean,
  contactSearchAgency: {
    id: IdType,
    code: string,
    name: string,
  } | null,
}

interface MyBreteuilProperty
{
  id: IdType,
  slug: string,
  ref: string,
  refId: number,
  address: string,
}

export interface MyBreteuilValuation
{
  id: IdType,
  user: MyBreteuilUser | null,
  property: MyBreteuilProperty | null,
  date: number/*timestamp*/,
}

export interface Filters__MyBreteuilValuation {
  date?: Filters__Date | undefined,
  userId?: Filters__Int | undefined,
}

export interface GetValuationsVariables extends PaginationBaseArgs
{
  filter?: Filters__MyBreteuilValuation | undefined,
  orderBy?: OrderBy | undefined,
}

export default function GetValuations(variables: GetValuationsVariables)
{
  return query<PaginationExtendedResponse<MyBreteuilValuation>>({
    operationName: 'Logs_GetValuations',
    variables: ExtendedPaginationVariables('MyBreteuilValuation'),
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          logs: {
            valuations: [{
              args: ExtendedPaginationArgs,
            }, paginationResult({
              id: true,
              user: {
                id: true,
                firstname: true,
                lastname: true,
                fullname: true,
                agent: true,
                isAgencyPotCommun: true,
                contactSearchAgency: {
                  id: true,
                  code: true,
                  name: true,
                },
              },
              property: {
                id: true,
                slug: true,
                ref: true,
                refId: true,
                address: true,
              },
              date: true,
            })],
          },
        },
      }),
    },
  }, variables);
}
