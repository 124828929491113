import type { IdType } from '@repo-breteuil/common-definitions';

import type { ViewingOrigin, ViewingType } from '@core/api/types';
import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface CreateViewingVariables
{
  type: ViewingType,
  origin: ViewingOrigin,
  date: number/*timestamp*/,
  endDate: number/*timestamp*/,
  propertyId: IdType,
  contactId?: IdType | null | undefined,
  userId: IdType,
  comments?: string | null | undefined,
  myb_confirmation?: boolean | null | undefined,
}

export default function CreateViewing(variables: CreateViewingVariables)
{
  return mutation<{ id: IdType }>({
    operationName: 'Common_ViewingDialog_CreateViewing',
    variables: {
      type: 'ViewingType!',
      origin: 'ViewingOrigin!',
      date: 'Date!',
      endDate: 'Date!',
      propertyId: 'Int!',
      contactId: 'Int',
      userId: 'Int!',
      comments: 'String',
      myb_confirmation: 'Boolean',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilViewing: {
          create: [{
            args: {
              type: true,
              origin: true,
              date: true,
              endDate: true,
              propertyId: true,
              contactId: true,
              userId: true,
              comments: true,
              myb_confirmation: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, variables);
}
