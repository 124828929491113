import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyFieldArgs } from '@repo-lib/graphql-query-builder';
import { UserDayOffType, UserGoalMetric, type UserGoalPeriod } from '@core/api/types';
import { errorWrapper, paginationResultAsList, query } from '@core/api/graphql/gen-v2';
import { type ManagedUser, ManagedUserFields, ManagedUsersFieldArgs } from './GetManagedUsers';
import { type MyBreteuilUserHistorical, MyBreteuilUserHistoricalFields, ManagedUsersHistoricalFieldArgs } from './GetManagedUsersHistorical';
import { type BeeTeamReportingAgenciesStats, BeeTeamReportingAgenciesStatsFields } from './GetAgenciesStats';
import { type UserTasksRecap, UserTasksRecapFields } from './GetUserTasksRecap';
import { type DailyReport, DailyReportFields } from './SubmitObjectivesDailyReport';
import { type UserObjectivesDailyReportStats, UserObjectivesDailyReportStatsFields } from './GetUserDailyReportStats';
import { type UserGoal, UserGoalFields } from './GetUserGoals';
import { type UserDayOffRequest, UserDayOffRequestFields } from './GetUserDayOffRequests';
import { DayOffBalancesFields } from './GetUserDayOffBalances';
import { type UsersGoals, UsersGoalsFields } from './GetUsersGoals';
import { type UserAnalytics, UserAnalyticsFields } from './GetUsersAnalytics';
import { type UserRemunerationAnalytics, UserRemunerationAnalyticsFields } from './GetUsersRemunerationAnalytics';

export interface InitQueryResponse
{
  managedUsers: Array<ManagedUser>,
  managedUsersHistorical: Array<MyBreteuilUserHistorical>,
  agenciesStats: Array<BeeTeamReportingAgenciesStats>,
  userTasksRecap: UserTasksRecap,
  userTasksRecapMonthly: UserTasksRecap,
  userDailyReport: DailyReport,
  userDailyReportStats: UserObjectivesDailyReportStats,
  userDailyReportStatsDay: UserObjectivesDailyReportStats,
  userDailyReportStats3m: UserObjectivesDailyReportStats,

  userGoalsMonthly_reminders: UserGoal,
  userGoalsMonthly_successfulReminders: UserGoal,
  userGoalsMonthly_successfulPhoneReminders: UserGoal,
  userGoalsMonthly_successfulWhatsAppReminders: UserGoal,
  userGoalsMonthly_acceptedOffers: UserGoal,
  userGoalsMonthly_madeOffer: UserGoal,
  userGoalsMonthly_mandates: UserGoal,
  userGoalsMonthly_viewings: UserGoal,
  userGoalsMonthly_valuations: UserGoal,
  userGoalsMonthly_prospectedStreets: UserGoal,

  dayOffRequests: Array<UserDayOffRequest>,
  userDayOffBalances: Record<UserDayOffType, number | null>,

  usersGoals: UsersGoals,
  usersAnalytics: Array<UserAnalytics>,

  usersRemunerationAnalytics: Array<UserRemunerationAnalytics>,
}

export const UserGoalsMonthlyFieldArgs: ReadonlyFieldArgs = {
  userId: true,
  period: 'period_userGoalsMonthly',
};

export interface InitQueryArgs
{
  userId: IdType,
  period_userTasksRecap: UserGoalPeriod,
  period_userTasksRecapMonthly: UserGoalPeriod,
  period_userGoalsMonthly: UserGoalPeriod,
  period_userDailyReportStats: UserGoalPeriod,
  period_userDailyReportStatsDay: UserGoalPeriod,
  period_userDailyReportStats3m: UserGoalPeriod,
  startDate_usersGoals: number,
  endDate_usersGoals: number,
}

export default function InitQuery(args: InitQueryArgs)
{
  return query<InitQueryResponse>({
    operationName: 'Profile_InitQuery',
    variables: {
      userId: 'Int!',
      period_userTasksRecap: 'UserGoalPeriod!',
      period_userTasksRecapMonthly: 'UserGoalPeriod!',
      period_userGoalsMonthly: 'UserGoalPeriod!',
      period_userDailyReportStats: 'UserGoalPeriod!',
      period_userDailyReportStatsDay: 'UserGoalPeriod!',
      period_userDailyReportStats3m: 'UserGoalPeriod!',
      startDate_usersGoals: 'Date!',
      endDate_usersGoals: 'Date!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        managedUsers: [{
          aliasedField: 'users',
          args: ManagedUsersFieldArgs,
        }, paginationResultAsList(ManagedUserFields) ],

        managedUsersHistorical: [{
          aliasedField: 'usersPerAgency',
          args: ManagedUsersHistoricalFieldArgs,
        }, MyBreteuilUserHistoricalFields],

        agenciesStats: [{
          aliasedField: 'beeTeam',
        }, {
          reporting: {
            agenciesStats: BeeTeamReportingAgenciesStatsFields,
          },
        }],

        userTasksRecap: [{
          args: {
            userId: true,
            period: 'period_userTasksRecap',
          },
        }, UserTasksRecapFields],

        userTasksRecapMonthly: [{
          args: {
            userId: true,
            period: 'period_userTasksRecapMonthly',
          },
          aliasedField: 'userTasksRecap',
        }, UserTasksRecapFields],

        userDailyReport: [{
          args: {
            userId: true,
          },
        }, DailyReportFields],
        userDailyReportStats: [{
          args: {
            userId: true,
            period: 'period_userDailyReportStats',
          },
        }, UserObjectivesDailyReportStatsFields],
        userDailyReportStatsDay: [{
          args: {
            userId: true,
            period: 'period_userDailyReportStatsDay',
          },
          aliasedField: 'userDailyReportStats',
        }, UserObjectivesDailyReportStatsFields],
        userDailyReportStats3m: [{
          args: {
            userId: true,
            period: 'period_userDailyReportStats3m',
          },
          aliasedField: 'userDailyReportStats',
        }, UserObjectivesDailyReportStatsFields],

        userGoalsMonthly_successfulReminders: [{
          aliasedField: "userGoal",
          args: { ...UserGoalsMonthlyFieldArgs, metric: { literal: UserGoalMetric.SuccessfulReminders } },
        }, UserGoalFields],
        userGoalsMonthly_successfulPhoneReminders: [{
          aliasedField: "userGoal",
          args: { ...UserGoalsMonthlyFieldArgs, metric: { literal: UserGoalMetric.SuccessfulPhoneReminders } },
        }, UserGoalFields],
        userGoalsMonthly_successfulWhatsAppReminders: [{
          aliasedField: "userGoal",
          args: { ...UserGoalsMonthlyFieldArgs, metric: { literal: UserGoalMetric.SuccessfulWhatsAppReminders } },
        }, UserGoalFields],
        userGoalsMonthly_acceptedOffers: [{
          aliasedField: "userGoal",
          args: { ...UserGoalsMonthlyFieldArgs, metric: { literal: UserGoalMetric.AcceptedOffersHT } },
        }, UserGoalFields],
        userGoalsMonthly_madeOffer: [{
          aliasedField: "userGoal",
          args: { ...UserGoalsMonthlyFieldArgs, metric: { literal: UserGoalMetric.MadeOffers } },
        }, UserGoalFields],
        userGoalsMonthly_mandates: [{
          aliasedField: "userGoal",
          args: { ...UserGoalsMonthlyFieldArgs, metric: { literal: UserGoalMetric.Mandates } },
        }, UserGoalFields],
        userGoalsMonthly_viewings: [{
          aliasedField: "userGoal",
          args: { ...UserGoalsMonthlyFieldArgs, metric: { literal: UserGoalMetric.Viewings } },
        }, UserGoalFields],
        userGoalsMonthly_valuations: [{
          aliasedField: "userGoal",
          args: { ...UserGoalsMonthlyFieldArgs, metric: { literal: UserGoalMetric.Valuations } },
        }, UserGoalFields],
        userGoalsMonthly_prospectedStreets: [{
          aliasedField: "userGoal",
          args: { ...UserGoalsMonthlyFieldArgs, metric: { literal: UserGoalMetric.ProspectedStreets } },
        }, UserGoalFields],

        dayOffRequests: [{
          args: {
            id: 'userId',
          },
          aliasedField: 'myBreteuilUser',
        }, {
          dayOffRequests: [{
            args: {
              filter: { literal: '{ accepted: { not: false } }' }, // TODO handle this on the backend side?
            },
          }, paginationResultAsList(UserDayOffRequestFields)],
        }],

        userDayOffBalances: [{
          args: {
            id: 'userId',
          },
          aliasedField: 'myBreteuilUser',
        }, DayOffBalancesFields],

        usersGoals: [{
          args: {
            startDate: 'startDate_usersGoals',
            endDate: 'endDate_usersGoals',
          },
        }, UsersGoalsFields],

        usersAnalytics: UserAnalyticsFields,

        usersRemunerationAnalytics: UserRemunerationAnalyticsFields,
      }),
    },
  }, args);
}
