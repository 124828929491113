import { action, computed } from 'mobx';
import { TokenStorage, type TokenInfo } from '@repo-lib/browser-utils-dom';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { GlobalErrorCode } from '@repo-breteuil/common-definitions';
import { handleCriticalError } from '@repo-breteuil/front-error';
import queryStore from '@core/api/graphql/client';
import { setLocaleSafe } from '@core/store/Locales';
import router from '@my-breteuil/store/routing';
import {
  type SessionResult,
  type LogInArgs,
  GetSession,
  LogIn,
  LogOut,
} from './api';

class AuthenticationRequired extends Error
{
  constructor()
  {
    super('Authentication required');
  }

  get code()
  {
    return GlobalErrorCode.AuthenticationRequired;
  }

  get __typename()
  {
    return GlobalErrorCode.AuthenticationRequired;
  }
}

//TODO: handle data.expire

export class Session
{
  private static storage = new TokenStorage('authenticationToken');

  private _storedToken: TokenInfo | null = null;
  private _session = new Fetchable(async () => {
    const session = await GetSession();
    return this._update(session);
  }, { catchUnhandled: handleCriticalError });

  constructor()
  {
    this._storedToken = Session.storage.get();
    queryStore.stores.session = this;
    queryStore.errorsHandler = (errors) => {
      if (errors.find(error => error['code'] === GlobalErrorCode.AuthenticationRequired))
      {
        router.changeToDefaultLoggedOutRoute({ replace: true, force: true, invalidateCurrentLocation: true });
        this.update(null);
      }
    };
  }

  public get fetchable()
  {
    return this._session;
  }

  public initializeSessionFromStorage()
  {
    if (this.storedToken)
      this._session.load();
    else
      this._session.setResult(null);
    return this._session.currentPromise;
  }

  @action private _update(session: SessionResult | null)
  {
    if (session)
    {
      const { token, expire, language } = session;
      Session.storage.store(token, expire);
      if (language !== null)
        setLocaleSafe(language);
    }
    return session;
  }

  @action update(session: SessionResult | null)
  {
    this._update(session);
    this._session.setResult(session);
    return session;
  }

  @action simulateLogOut()
  {
    this.clearSession();
  }

  @action clearSession()
  {
    this._session.setResult(null);
    Session.storage.clear();
  }

  @action authError()
  {
    this._session.setResult(null);
  }

  async logIn(args: LogInArgs)
  {
    const session = await LogIn(args);
    return this.update(session);
  }

  async logOut()
  {
    const promise = LogOut();
    this.clearSession();
    return this.update(await promise);
  }

  get storedToken(): boolean
  {
    return !!this._storedToken;
  }

  @computed get data()
  {
    return this._session.result || null;
  }

  @computed get loggedIn(): boolean
  {
    return Boolean(this.data?.mybUser?.id);
  }


  @computed get mybUser()
  {
    if (!this.data)
      return null;
    return this.data.mybUser;
  }

  @computed get originMybUser()
  {
    if (!this.data)
      return null;
    return this.data.originMybUser;
  }

  ensureUser()
  {
    const user = this.mybUser;
    if (!user)
      throw new AuthenticationRequired();
    return user;
  }

  @computed get admin(): boolean
  {
    return Boolean(this.mybUser?.admin);
  }

  @computed get manager(): boolean
  {
    return this.mybUser?.manager || this.admin;
  }

  @computed get superManager(): boolean
  {
    return this.mybUser?.superManager ?? this.admin;
  }

  @computed get zoneLeader(): boolean
  {
    return this.mybUser?.zoneLeader ?? this.admin;
  }

  @computed get agent(): boolean
  {
    return this.mybUser?.agent || this.admin;
  }

  @computed get accountant(): boolean
  {
    return this.mybUser?.accountant || this.admin;
  }

  @computed get propertyManager(): boolean
  {
    return this.mybUser?.propertyManager || this.admin;
  }

  @computed get token(): string | null
  {
    if (this.data)
      return this.data.token;
    if (this._storedToken)
      return this._storedToken.token;
    return null;
  }

  @computed get authorizationHeader(): string | null
  {
    if (this.token)
      return `Bearer ${this.token}`;
    return null;
  }
};

export default new Session();
