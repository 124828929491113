import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { UserDayOffType } from '@core/api/types';

import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface UserDayOffRequest
{
  id: IdType,
  accepted: boolean | null,
  pending: boolean,
  startDate: number,
  endDate: number | null,
  type: UserDayOffType,
}

export const UserDayOffRequestFields: ReadonlyNodeFields = {
  id: true,
  accepted: true,
  pending: true,
  startDate: true,
  endDate: true,
  type: true,
};

export default function GetUserDayOffRequests(args: { id: IdType })
{
  return query<Array<UserDayOffRequest>>({
    operationName: 'Profile_GetUserDayOffRequests',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilUser: [{
          args: {
            id: true,
          },
        }, {
          dayOffRequests: [{
            args: {
              filter: { literal: '{ accepted: { not: false } }' }, // TODO handle this on the backend side?
            },
          }, paginationResultAsList(UserDayOffRequestFields)],
        }],
      }),
    },
  }, args);
}
