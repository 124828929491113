import type { IdType } from '@repo-breteuil/common-definitions';
import { query, errorWrapper } from '@core/api/graphql/gen-v2';
import { Locale } from '@repo-breteuil/common-texts';
import { CriteriaAIDecriptionField } from './Valuation_GetAnalyzedPropertyDescription';

export interface Args
{
  propertyId: IdType,
  description: string,
  language: Locale,
  criteria?: Array<CriteriaAIDecriptionField>,
  fields?: Array<CriteriaAIDecriptionField>,
}

export default function Valuation_GetImprovedPropertyDescriptions(args: Args)
{
  return query<Array<string> | null>(
    {
      operationName: 'Valuation_GetImprovedPropertyDescriptions',
      variables: {
        propertyId: 'Int!',
        description: 'String!',
        language: 'Language!',
        criteria: '[AdditionalAttributesDescriptionKeyValueInput!]',
        fields: '[AdditionalAttributesDescriptionKeyValueInput!]',
      },
      fieldsSelection: {
        authenticated: errorWrapper({
          ai: {
            improvedPropertyDescriptions: [
              {
                args: {
                  propertyId: true,
                  description: true,
                  language: true,
                  criteria: true,
                  fields: true,
                },
              },
              true,
            ],
          },
        }),
      },
    },
    args,
  );
}
