import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';

import { query, errorWrapper, paginationResultAsList } from '@core/api/graphql/gen-v2';

export interface User
{
  id: IdType,
  fullname: string,
  contactSearchAgency: {
    id: IdType,
    shortName: string,
    agencyTreeGeoAreaId: IdType | null,
  } | null,
}

const UserFields: ReadonlyNodeFields = {
  id: true,
  fullname: true,
  contactSearchAgency: {
    id: true,
    shortName: true,
    agencyTreeGeoAreaId: true,
  },
};

export default function GetManagedUsers()
{
  return query<Array<User>>({
    operationName: 'UsersAPIRequests_GetUsers',
    fieldsSelection: {
      authenticated: errorWrapper({
        users: [{
          args: {
            managedOnly: { literal: 'true' },
            includePotCommun: { literal: 'true' },
            includeInactiveUsers: { literal: 'true' },
            includeNonAgents: { literal: 'true' },
          },
        }, paginationResultAsList(UserFields)],
      }),
    },
  });
}
