import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationConnectionWithCount } from '@repo-lib/graphql-query-pagination';
import type {
  ShowcaseMedia,
  ShowcaseProperty,
  ShowcaseStream,
  ShowcaseStreamItem,
  UpdateShowcaseStreamArgs,
  CreateShowcaseStreamItemArgs,
  UpdateShowcaseStreamItemArgs,
  MediasFilters,
  PropertiesFilters,
  ShowcasePropertyStream,
} from './api';

import { action, observable } from 'mobx';
import {
  createInArrayOptimistic,
  moveInArrayOptimistic,
} from '@repo-breteuil/utils-position';
import {
  GetShowcaseMedias,
  GetShowcaseProperties,
  GetShowcasePropertiesGeoAreasIds,
  GetShowcasePropertiesStreams,
  SetShowcasePropertyFavorite,
  SetShowcasePropertyBlacklisted,
  GetShowcaseStreamWithContents,
  UpdateShowcaseStream,
  DeleteShowcaseStream,
  CreateShowcaseStreamItem,
  UpdateShowcaseStreamItem,
  DeleteShowcaseStreamItem,
  FixShowcaseStreamItemsPositions,
} from './api';

export interface ShowcaseStreamItem_optimistic
{
  id: IdType,
  position: number,
  content: { __typename: 'ShowcaseMedia' | 'MyBreteuilProperty' },
  _optimistic: true,
}

export type ShowcaseStreamItem_possiblyOptimistic = ShowcaseStreamItem | ShowcaseStreamItem_optimistic;

export type ShowcaseStream_possiblyOptimistic
  = ShowcaseStream
  | Omit<ShowcaseStream, 'contents'> & {
    contents: Array<ShowcaseStreamItem_possiblyOptimistic>,
  }
;

export function isShowcaseStreamItemOptimistic(
  item: ShowcaseStreamItem_possiblyOptimistic,
): item is ShowcaseStreamItem_optimistic
{
  return (item as ShowcaseStreamItem_optimistic)._optimistic === true;
}

let uniqueVirtualId = 0;
function getUniqueVirtualId()
{
  return --uniqueVirtualId;
}

class StreamMixingStore
{
  @observable private _stream: ShowcaseStream_possiblyOptimistic | null;
  @observable private _medias: PaginationConnectionWithCount<ShowcaseMedia>;
  @observable private _mediasFilters: MediasFilters = {};
  @observable private _properties: PaginationConnectionWithCount<ShowcaseProperty>;
  @observable private _propertiesGeoAreasIds: Array<IdType>;
  @observable private _propertiesStreams: Array<ShowcasePropertyStream>;
  @observable private _propertiesFilters: PropertiesFilters = { filter: {} };

  public get stream()
  {
    return this._stream;
  }

  @action public setStream(stream: ShowcaseStream_possiblyOptimistic | null)
  {
    this._stream = stream;
  }

  public get medias()
  {
    return this._medias;
  }

  @action public setMedias(medias: PaginationConnectionWithCount<ShowcaseMedia>)
  {
    this._medias = medias;
  }

  public get mediasFilters()
  {
    return this._mediasFilters;
  }

  @action public setMediasFilters(filters: MediasFilters)
  {
    this._mediasFilters = filters;
  }

  public async setMediasFiltersAndRefreshMedias(filters: MediasFilters)
  {
    this.setMediasFilters(filters);
    await this.refreshMedias();
  }

  public get properties()
  {
    return this._properties;
  }

  @action public setProperties(properties: PaginationConnectionWithCount<ShowcaseProperty>)
  {
    this._properties = properties;
  }

  public get propertiesGeoAreasIds()
  {
    return this._propertiesGeoAreasIds;
  }

  @action public setPropertiesGeoAreasIds(propertiesGeoAreasIds: Array<IdType>)
  {
    this._propertiesGeoAreasIds = propertiesGeoAreasIds;
  }

  public get propertiesStreams()
  {
    return this._propertiesStreams;
  }

  @action public setPropertiesStreams(propertiesStreams: Array<ShowcasePropertyStream>)
  {
    this._propertiesStreams = propertiesStreams;
  }

  public get propertiesFilters()
  {
    return this._propertiesFilters;
  }

  @action public setPropertiesFilters(filters: PropertiesFilters)
  {
    this._propertiesFilters = filters;
  }

  public async setPropertiesFiltersAndRefreshProperties(
    filters: PropertiesFilters,
    overrideStreamId: IdType | null = null,
  )
  {
    const streamId = overrideStreamId || this.stream?.id || null;

    this.setPropertiesFilters(filters);
    await this.refreshProperties(streamId);
  }

  public async updateStream(args: UpdateShowcaseStreamArgs)
  {
    if (!this._stream)
      return;
    const { id } = this._stream;
    await UpdateShowcaseStream(id, args);
    await this.refreshStream(id);
  }

  public async deleteStream()
  {
    if (!this._stream)
      return;
    const { id } = this._stream;
    await DeleteShowcaseStream(id);
    this.setStream(null);
  }

  public async createStreamItem(args: CreateShowcaseStreamItemArgs)
  {
    if (!this._stream)
      return;
    const { id: streamId } = this._stream;
    await CreateShowcaseStreamItem(args);
    await this.refreshStream(streamId);
  }

  public async updateStreamItem(id: IdType, args: UpdateShowcaseStreamItemArgs)
  {
    if (!this._stream)
      return;
    const { id: streamId } = this._stream;
    await UpdateShowcaseStreamItem(id, args);
    await this.refreshStream(streamId);
  }

  public async deleteStreamItem(id: IdType)
  {
    if (!this._stream)
      return;
    const { id: streamId } = this._stream;
    await DeleteShowcaseStreamItem(id);
    await Promise.all([
      this.refreshStream(streamId),
      this.refreshProperties(streamId), //Refreshing properties to update Property.showcasePublished
    ]);
  }

  private async setStreamContentsOptimistically(
    contents: Array<ShowcaseStreamItem | ShowcaseStreamItem_optimistic>,
    operation: Promise<unknown>,
  )
  {
    const lastValidStreamState = this._stream!;
    this.setStream({
      ...this._stream!,
      contents,
    });
    try
    {
      await operation;
    }
    catch (err)
    {
      this.setStream(lastValidStreamState);
      throw err;
    }
  }

  public async createStreamItem_optimistic(
    args: Omit<CreateShowcaseStreamItemArgs, 'showcaseStreamId' | 'position'>, targetIndex: number,
  )
  {
    if (!this._stream)
      return;
    const { id: streamId, contents } = this._stream;
    const { optimisticResult, result } = createInArrayOptimistic(contents, {
      targetIndex,
      create: (position) => CreateShowcaseStreamItem({ ...args, showcaseStreamId: streamId, position }),
      fix: FixShowcaseStreamItemsPositions,
      createOptimisticItem: (position) => ({
        id: getUniqueVirtualId(),
        position,
        content: { __typename: args.contentType },
        _optimistic: true as const,
      }),
    });
    await this.setStreamContentsOptimistically(optimisticResult, result.then(async () => {
      await Promise.all([
        this.refreshStream(streamId),
        this.refreshProperties(streamId), //Refreshing properties to update Property.showcasePublished
      ]);
    }));
  }

  public async moveStreamItem_optimistic(args: { sourceIndex: number, targetIndex: number })
  {
    if (!this._stream)
      return;
    const { id: streamId, contents } = this._stream;
    const { optimisticResult, result } = moveInArrayOptimistic(contents, {
      ...args,
      setPosition: (id, position) => UpdateShowcaseStreamItem(id, { position }),
      fix: FixShowcaseStreamItemsPositions,
    });
    await this.setStreamContentsOptimistically(optimisticResult, result.then(async () => {
      await this.refreshStream(streamId);
    }));
  }

  public async refreshStream(streamId: IdType)
  {
    const stream = await GetShowcaseStreamWithContents(streamId);
    this.setStream(stream);
  }

  public async refreshMedias()
  {
    this.setMedias(await GetShowcaseMedias({ filter: this._mediasFilters }));
  }

  public async refreshProperties(currentStreamId: IdType | null)
  {
    this.setProperties(await GetShowcaseProperties(currentStreamId, this._propertiesFilters));
  }

  public async refreshPropertiesGeoAreasIds()
  {
    this.setPropertiesGeoAreasIds(await GetShowcasePropertiesGeoAreasIds());
  }

  public async refreshPropertiesStreams()
  {
    this.setPropertiesStreams(await GetShowcasePropertiesStreams());
  }

  public async refresh(streamId: IdType)
  {
    await Promise.all([
      this.refreshStream(streamId),
      this.setMediasFiltersAndRefreshMedias({}),
      this.setPropertiesFiltersAndRefreshProperties({ filter: {}}, streamId),
      this.refreshPropertiesGeoAreasIds(),
      this.refreshPropertiesStreams(),
    ]);
  }

  public async setPropertyFavorite(id: IdType, favorite: boolean)
  {
    await SetShowcasePropertyFavorite(id, favorite);
    await this.refreshProperties(id);
  }

  public async setPropertyBlacklisted(id: IdType, blacklisted: boolean)
  {
    await SetShowcasePropertyBlacklisted(id, blacklisted);
    await this.refreshProperties(id);
  }
}

export default new StreamMixingStore();
