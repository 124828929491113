import { getDateParts, createDate, addYears } from '@repo-lib/utils-date-tz';

// Imported from backend
// Get the dayOff [start/end] dates boundaries for the given date.
export function getDayOffPeriodBoundaries(
  args: {
    date: Date | number,
    startMonth: number, // 1-12
    periodOffset?: number,
  },
)
{
  const { date, startMonth, periodOffset } = args;
  const { year, month } = getDateParts(date);
  const startYear = (month < startMonth ? (year - 1) : year) + (periodOffset ?? 0);
  const startDate = createDate({
    year: startYear,
    month: startMonth,
    day: 1,
  });
  const endDate = addYears(startDate, 1);
  return [ startDate, endDate ] as const;
}
