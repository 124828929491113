export { Currency } from '@repo-lib/utils-texts';
export { Language } from '@repo-breteuil/common-texts';
export {
  AlertsFrequency,
  CommunicationChannelEnum,
  FileUploadType,
  PictureFormat,
  PictureRatio,
  OperationType,
  BreteuilPropertySurfaceUnit,
  PropertyStatus,
  PropertyType,
  RentalPeriod,
  LuminosityType,
  BuildingType,
  ExteriorSurfaceType,
  Orientation,
  BreteuilWebsiteViewType,
  MyBreteuilPropertySurroundingDistance,
  BreteuilWebsiteRenovationType,
  DPERating,
} from '@repo-breteuil/common-definitions';

export enum MyBreteuilPropertyVisibility
{
  Public = 'Public',
  Hidden = 'Hidden',
  Invisible = 'Invisible',
  Confidential = 'Confidential',
}

export enum MyBreteuilContactCategory
{
  Prospect = 'Prospect',
  MyBChasseur = 'MyBChasseur',
  MyBNotaire = 'MyBNotaire',
  MyBGardien = 'MyBGardien',
  MyBConcurrent = 'MyBConcurrent',
  PropertyDeveloper = 'PropertyDeveloper',
}

export enum MyBreteuilContactOriginType
{
  Other = 'Other',
  PlaformSeLoger = 'PlaformSeLoger',
  PlatformFigaro = 'PlatformFigaro',
  PlatformSMC = 'PlatformSMC',
}

export enum MyBreteuilLogSimpleType
{
  Email = 'Email',
  SMS = 'SMS',
  WhatsApp = 'WhatsApp',
  Viewing = 'Viewing',
  Call = 'Call',
  Retouch = 'Retouch',
  Translation = 'Translation',
  PriceUpdate = 'PriceUpdate',
  NewWarrant = 'NewWarrant',
  Myb_retour_a_la_vente = 'Myb_retour_a_la_vente',
  AgentUpdate = 'AgentUpdate',
  AgentComment = 'AgentComment',
  Myb_maj_autre_champ = 'Myb_maj_autre_champ',
  PublicationUpdate = 'PublicationUpdate',
  StatutUpdate = 'StatutUpdate',
  Myb_courrier = 'Myb_courrier',
  Myb_cheque = 'Myb_cheque',
  Myb_passage = 'Myb_passage',
  Myb_publipostage = 'Myb_publipostage',
  Reminder = 'Reminder',
  ValuationEmail = 'ValuationEmail',
  Other = 'Other',
}

export enum MyBreteuilFeedbackType
{
  NotInterested = 'NotInterested',
  RDVPris = 'RDVPris',
  OffreFaite = 'OffreFaite',
}

export enum MyBreteuilContactType
{
  Owner = 'Owner',
  Buyer = 'Buyer',
  Introducer = 'Introducer',
}

export enum MyBreteuilCompanyIdentifier
{
  ARS = 'ARS',
  BI = 'BI',
  ALB = 'ALB',
  PI = 'PI',
  Other = 'Other',
  BN = 'BN',
  BBS = 'BBS',
  BreteuilUK = 'BreteuilUK',
  BreteuilIDF = 'BreteuilIDF',
  BreteuilSudOuest = 'BreteuilSudOuest',
}

export enum MyBreteuilCommissionType
{
  In = 'In',
  Out = 'Out',
  Pro = 'Pro',
  Loc = 'Loc',
}

export enum MyBreteuilNotaryType
{
  Buyer = 'Buyer',
  Seller = 'Seller',
}

export enum MyBreteuilCountryCode
{
  FR = 'FR',
  US = 'US',
  GB = 'GB',
}

export enum MyBreteuilSearchLevel
{
  AA = 'AA',
  A = 'A',
  B = 'B',
  C = 'C',
}

export enum MyBreteuilPropertyRentalExpensesTypeEnum
{
  Myb_Forfaitaire = 'Myb_Forfaitaire',
  Myb_Provision = 'Myb_Provision',
}

export enum BreteuilPropertyPriceChangeStatus
{
  NewWarrant = 'NewWarrant',
  PriceChange = 'PriceChange',
  ReturnOfferToWarrant = 'ReturnOfferToWarrant',
}

export enum BreteuilPropertyLawFulRental
{
  CivilCode = 'CivilCode',
  LawHeightyNine = 'LawHeightyNine',
}

export enum MyBreteuilWaterHeating
{
  Electric = 'Electric',
  Gas = 'Gas',
  Collective = 'Collective',
}

export enum MyBreteuilHeating
{
  Electric = 'Electric',
  Gas = 'Gas',
  Collective = 'Collective',
  CollectiveGas = 'CollectiveGas',
  CollectiveFuel = 'CollectiveFuel',
  CollectiveCPCU = 'CollectiveCPCU',
}

export enum BreteuilPropertyUsage
{
  Private = 'Private',
  Commercial = 'Commercial',
}

export enum MyBreteuilPropertyDisponibilityEnum
{
  Free = 'Free',
  Rented = 'Rented',
}

export enum MyBreteuilPropertyDocumentType
{
  Valuation = 'Valuation',
  PV_AG = 'PV_AG',
  Charges = 'Charges',
  Taxes = 'Taxes',
  RCP = 'RCP',
  Diagnostic = 'Diagnostic',
  Mandate = 'Mandate',
  TransactionDocs = 'TransactionDocs',
  CNI = 'CNI',
  RentalDocs = 'RentalDocs',
  Plan = 'Plan',
}

export enum MyBreteuilMatchType
{
  Extended = 'Extended',
  Absolute = 'Absolute',
}

export enum PublicationPlatform
{
  BienIci = 'BienIci',
  SeLoger = 'SeLoger',
  BellesDemeures = 'BellesDemeures',
  MaisonsEtAppartements = 'MaisonsEtAppartements',
  ResidencesImmobilier = 'ResidencesImmobilier',
  PLF = 'PLF',
  FigaroImmo = 'FigaroImmo',
}

export enum MapPointType
{
  BreteuilAgency = 'BreteuilAgency',
  CompetitorAgency = 'CompetitorAgency',
}

export enum HistoryEventCommunicationType
{
  PropertiesInfo = 'PropertiesInfo',
  MessageNotification = 'MessageNotification',
  ViewingReminder = 'ViewingReminder',
  NotaryDocuments = 'NotaryDocuments',
}

export enum NotificationChannel
{
  Manual = 'Manual',
  Phone = 'Phone',
  SMS = 'SMS',
  WhatsApp = 'WhatsApp',
  Email = 'Email',
}

export enum ContactOwnershipFilter
{
  All = 'All',
  Self = 'Self',
  SelfAgency = 'SelfAgency',
  SelfAgencyPotCommun = 'SelfAgencyPotCommun',
  SelfAndAgencyPotCommun = 'SelfAndAgencyPotCommun',
}

export enum ContactOwnership
{
  All = 'All',
  Self = 'Self',
  SelfAgency = 'SelfAgency',
  SelfAgencyPotCommun = 'SelfAgencyPotCommun',
}

export enum MyBreteuilUserAddressBookSyncingType
{
  Self = 'Self',
  SelfAndAgencyPotCommun = 'SelfAndAgencyPotCommun',
}

export enum MyBreteuilTransactionYearlySummaryType
{
  Offers = 'Offers',
  CAP = 'CAP',
  CAE = 'CAE',
  CAEV = 'CAEV',
}

export enum TranslationStatus
{
  ManuallyTranslated = 'ManuallyTranslated',
  AutoTranslated = 'AutoTranslated',
  ManuallyTranslatedLocked = 'ManuallyTranslatedLocked',
}

// Property errors

export enum PropertiesInfoContactIssue
{
  OptOut = 'OptOut',
  MissingEmail = 'MissingEmail',
  MissingPhone = 'MissingPhone',
  Archived = 'Archived',
}

export enum InvalidPropertyContext
{
  Visibility = 'Visibility',
  Status = 'Status',
  PublicationPlatforms = 'PublicationPlatforms',
  Showcase = 'Showcase',
  PropertiesInfo = 'PropertiesInfo',
}

export enum PropertyRequiredField
{
  Title = 'Title',
  Title_MaxLength30 = 'Title_MaxLength30',
  Price = 'Price',
  Description = 'Description',
  Status = 'Status',
  OperationType = 'OperationType',
  Agency = 'Agency',
  Origin = 'Origin',
  Localization = 'Localization',
  District = 'District',
  Surface = 'Surface',
  SurfaceUnit = 'SurfaceUnit',
  Rooms = 'Rooms',
  Bedrooms = 'Bedrooms',
  Floor = 'Floor',
  FloorsCount = 'FloorsCount',
  PropertyType = 'PropertyType',
  ConstructionYear = 'ConstructionYear',
  Bathrooms = 'Bathrooms',
  Toilets = 'Toilets',
  Heating = 'Heating',
  WaterHeatType = 'WaterHeatType',
  Usage = 'Usage',
  Disponibility = 'Disponibility',
  Orientation = 'Orientation',
  Transports = 'Transports',
  BuildingType = 'BuildingType',
  PracticalInformations = 'PracticalInformations',
  Warrant = 'Warrant',
  FeesPercentage = 'FeesPercentage',
  RentalExpenses = 'RentalExpenses',
  RentalExpensesType = 'RentalExpensesType',
  PriceMax = 'PriceMax',
  GuestsCount = 'GuestsCount',
}

export enum MyBreteuilUserContractTypeEnum
{
  Manager = 'Manager',
  Commercial = 'Commercial',
  Alter = 'Alter',
  Vrp1 = 'Vrp1',
  Vrp2 = 'Vrp2',
  Vrp3 = 'Vrp3',
  Vrp4 = 'Vrp4',
  Vrp5 = 'Vrp5',
}

export enum MyBreteuilInvoiceType {
  Other = "Other",
  TransactionFees = "TransactionFees",
  RentalOwnerFees = "RentalOwnerFees",
  RentalTenantFees = "RentalTenantFees",
}

export enum ProspectingActionType
{
  Mailing = 'Mailing', // Courrier (non-nominatif)
  NominativeMailing = 'NominativeMailing', // Courrier nominatif
  ComplimentSlip = 'ComplimentSlip',
  Leaflet = 'Leaflet',
  Other = 'Other', // Deprecated
}

export enum UserGoalPeriod {
  Day = "Day",
  _7Day = "_7Day",
  _63Day = "_63Day",
  Week = "Week",
  Month = "Month",
  Quarter = "Quarter",
  Year = "Year",
}

export enum UserGoalMetric {
  SuccessfulReminders = "SuccessfulReminders",
  SuccessfulPhoneReminders = "SuccessfulPhoneReminders",
  SuccessfulWhatsAppReminders = "SuccessfulWhatsAppReminders",
  AcceptedOffersHT = "AcceptedOffersHT",
  MadeOffers = "MadeOffers",
  Viewings = "Viewings",
  Valuations = "Valuations",
  ProspectedStreets = "ProspectedStreets",
  Mandates = "Mandates",
}

export enum PropertyMarketingReportData
{
  Viewings = "Viewings",
  ClicksOnProperty = "ClicksOnProperty",
  PropertiesInfoSent = "PropertiesInfoSent",
  MandatePriceChangeHistory = "MandatePriceChangeHistory",
}

export enum SearchAreaLevel
{
  MyBCity = 'MyBCity', //Capital cities, or major regions
  MyBDistrict = 'MyBDistrict', //For Paris: départements or nearby cities; For other capital cities: major districts; For major regions: cities
  MyBSubDistrict = 'MyBSubDistrict', //For Paris: administrative districts; For other capital cities: subdistricts; For major regions: unused
}

export enum PropertyFavoriteStatus
{
  Refused = 'Refused',
  Requested = 'Requested',
  AcceptedShowcase = 'AcceptedShowcase',
  Accepted = 'Accepted',
}

export enum UserDayOffType
{
  /**
   * Congé payé
   * 30 jours par an du 1er juin au 31 mai
   * Accumulation de 2,5 jours par mois pour la période suivante
   */
  PaidLeave = 'PaidLeave',

  /**
    * Congé payé pris sur le solde de la période N (plutot que n-1)
  */
  PaidLeaveCurrentPeriod = 'PaidLeaveCurrentPeriod',

  /**
   * Congé sans solde
   * Illimité
   */
  UnpaidLeave = 'UnpaidLeave',

  /**
   * Indisponible (A-CO)
   * Illimité
   * Seuls les agents commerciaux peuvent poser ce type de congé
   */
  Unavailable = 'Unavailable',

  /**
   * RTT (TOIL => Time Off In Lieu)
   *  0.84 jour par mois
   */
  Toil = 'Toil',

  /**
   * Mariage
   * 4 jours
   */
  Wedding = 'Wedding',

  /**
   * PACS
   * 4 jours
   */
  CivilUnion = 'CivilUnion',

  /**
   * Naissance ou adoption d'un enfant
   * 3 jours
   */
  ChildBirth = 'ChildBirth',

  /**
   * Mariage d'un enfant
   * 1 jour
   */
  ChildWedding = 'ChildWedding',

  /**
   * Annonce de la survenue d'un handicap chez un enfant
   * 2 jours
   */
  ChildHandicap = 'ChildHandicap',

  /**
   * Décès d'un enfant
   * 5 jours
   */
  ChildDeath = 'ChildDeath',

  /**
   * Décès d'un conjoint, partenaire de Pacs ou concubin, père ou de la mère, d'un frère ou d'une soeur
   * 3 jours
   */
  DeathFamilyDegree1 = 'DeathFamilyDegree1',

  /**
   * Décès des grands-parents, d'un beau-parent (parents du conjoint)
   * 1 jour
   */
  DeathFamilyDegree2 = 'DeathFamilyDegree2',

  /**
   * Congé maternité/paternité
   * Illimité
   */
  MaternityLeave = 'MaternityLeave',

  /**
   * Arrêt maladie
   * Illimité
   */
  SickLeave = 'SickLeave',

  /**
   * Enfant malade
   * Illimité
   */
  SickChild = 'SickChild',
}

export enum RapprochementType
{
  Viewing = 'Viewing',
  WhatsApp = 'WhatsApp',
  NegativeFeedback = 'NegativeFeedback',
}

export enum UserVisibilityScope
{
  All = 'All',
  Self = 'Self',
  SelfAgency = 'SelfAgency',
  SelfAgenciesGroup = 'SelfAgenciesGroup',
}

export enum EntityLogsEventType
{
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  PropertyCreate = 'PropertyCreate',
  PropertyUpdate = 'PropertyUpdate',
  PropertyDelete = 'PropertyDelete',
  ContactCreate = 'ContactCreate',
  ContactUpdate = 'ContactUpdate',
  ContactDelete = 'ContactDelete',
  SearchCreate = 'SearchCreate',
  SearchUpdate = 'SearchUpdate',
  SearchDelete = 'SearchDelete',
  Property_Price_Change = 'Property_Price_Change',
  Property_Price_RelevantChange = 'Property_Price_RelevantChange',
  Property_Status_Change = 'Property_Status_Change',
  Property_Status_ReturnToWarrant = 'Property_Status_ReturnToWarrant',
  Property_PublicationChange_Website = 'Property_PublicationChange_Website',
  Property_PublicationChange_Showcase = 'Property_PublicationChange_Showcase',
  Viewing = 'Viewing',
  TransactionEvent = 'TransactionEvent',
  PropertiesInfoSent = 'PropertiesInfoSent',
  PropertiesInfoSent_Manual = 'PropertiesInfoSent_Manual',
  PropertiesInfoSent_Auto = 'PropertiesInfoSent_Auto',
  Contact_ClientAreaSignup = 'Contact_ClientAreaSignup',
  Contact_OptIn = 'Contact_OptIn',
  Contact_OptOut = 'Contact_OptOut',
  Search_Users_Change = 'Search_Users_Change',
  SearchCreate_4M = 'SearchCreate_4M',
  SearchUpdate_4M = 'SearchUpdate_4M',
}

export enum TransactionStep
{
  OfferMade = 'OfferMade',
  OfferAccepted = 'OfferAccepted',
  Compromis__Agreement = 'Compromis__Agreement',
  AuthenticatedDeed = 'AuthenticatedDeed',
}

export enum ViewingType
{
  PotentialBuyer = 'PotentialBuyer',
  PropertyValuation = 'PropertyValuation',
}

export enum ViewingOrigin
{
  PropertyCalendar = 'PropertyCalendar',
  Reminder = 'Reminder',
}

export enum ShowcaseScreenResolution
{
  _1080p = '_1080p',
  _2160p = '_2160p',
}

export enum MyBUserRoleEnum
{
  Admin = 'Admin',
  Agency = 'Agency',
  AgencyManager = 'AgencyManager',
  ZoneLeader = 'ZoneLeader',
  SuperManager = 'SuperManager',
  Accounting = 'Accounting',
  Exterior = 'Exterior',
  Agent = 'Agent',
  AgentFirstLevel = 'AgentFirstLevel',
  AgentSecondLevel = 'AgentSecondLevel',
  Supervisor = 'Supervisor',
  Translator = 'Translator',
  PropertyManager = 'PropertyManager',
}

export enum PropertyConfidentialLinkStatus
{
  PendingValidation = 'PendingValidation',
  Active = 'Active',
  ValidationRefused = 'ValidationRefused',
  Disabled = 'Disabled',
}

export enum UserPinOrigin
{
  Any = 'Any',
  MybOwners = 'MybOwners',
  MybBuyers = 'MybBuyers',
}

export enum AdministrativeAreaLevel
{
  Country = "Country",
  State_Province_Region = "State_Province_Region",
  County_Department = "County_Department",
  City_Municipality = "City_Municipality",
  District = "District", //For Paris: Arrondissement; For other cities: major districts
  SubDistrict = "SubDistrict", //For Paris: administrative districts; For other cities: subdistricts (if any)
}
