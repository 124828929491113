import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { Currency, RapprochementType } from '@core/api/types';
import { errorWrapper, query } from '@core/api/graphql/gen-v2';

export interface Match
{
  id: IdType,
  banned: boolean,
  propertyId: IdType,
  rapprochedTypes: Array<RapprochementType>,
  propertyPrice: number | null,
  propertyAddress: string | null,
  property: {
    id: IdType,
    slug: string,
    currency: Currency,
  },
}

const MatchFields: ReadonlyNodeFields = {
  id: true,
  banned: true,
  propertyId: true,
  rapprochedTypes: true,
  propertyPrice: true,
  propertyAddress: true,
  property: {
    id: true,
    slug: true,
    currency: true,
  },
};

export default function GetMatch(matchId: IdType)
{
  return query<Match>({
    operationName: 'Common_ReminderDialog_GetMatch',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilMatch: [{
          args: { id: true },
        }, MatchFields],
      }),
    },
  }, { id: matchId });
}
