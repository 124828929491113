import type { IdType } from '@repo-breteuil/common-definitions';

import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export interface MyBreteuilAgency
{
  id: IdType,
  name: string,
}

export interface MyBreteuilUser
{
  id: IdType,
  firstname: string,
  lastname: string,
  fullname: string,
  agent: boolean,
  isAgencyPotCommun: boolean,
  active: boolean,
  contactSearchAgency: MyBreteuilAgency,
}

export default function GetUsersAssignableToSearches()
{
  return query<Array<MyBreteuilUser>>({
    operationName: 'Contact_GetUsers_AssignableToSearches',
    fieldsSelection: {
      authenticated: errorWrapper({
        usersAssignableToSearches: {
          id: true,
          firstname: true,
          lastname: true,
          fullname: true,
          agent: true,
          isAgencyPotCommun: true,
          active: true,
          contactSearchAgency: {
            id: true,
            name: true,
          },
        },
      }),
    },
  });
}
