import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { PaginationExtendedResponse, PaginationBaseArgs } from '@repo-lib/graphql-query-pagination';
import type {
  MyBreteuilMatchType,
  Language,
  OrderBy,
  ContactOwnership,
  ContactOwnershipFilter,
  MyBreteuilSearchLevel,
  AlertsFrequency,
  CommunicationChannelEnum,
  PropertyConfidentialLinkStatus,
  Filters__Enum,
  Filters__String,
  Filters__Int,
  Filters__Date,
  Filters__Boolean,
  Filters_HasMany,
} from '@core/api/types';

import { RapprochementType } from '@core/api/types';
import {
  query,
  errorWrapper,
  paginationResult,
  ExtendedPaginationArgs,
  ExtendedPaginationVariables,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilCriteria
{
  id: IdType,
  webName: string,
}

export interface MyBreteuilAgency {
  id: IdType,
  code: string,
  name: string,
}

export interface MyBreteuilUser
{
  id: IdType,
  fullname: string,
  firstname: string,
  lastname: string,
  isAgencyPotCommun: boolean,
  left: boolean,
  contactSearchAgency: MyBreteuilAgency | null,
}

const MyBreteuilUserFields: ReadonlyNodeFields = {
  id: true,
  firstname: true,
  lastname: true,
  fullname: true,
  isAgencyPotCommun: true,
  left: true,
  agent: true,
  contactSearchAgency: {
    id: true,
    code: true,
    name: true,
  },
};

export interface MyBreteuilSearch
{
  id: IdType,
  criteria: Array<MyBreteuilCriteria>,
  users: Array<{
    id: IdType,
    firstname: string,
    lastname: string,
    fullname: string,
    agent: boolean,
    isAgencyPotCommun: boolean,
    contactSearchAgency: MyBreteuilAgency | null,
  }>,
}

const MyBreteuilSearchFields: ReadonlyNodeFields = {
  id: true,
  criteria: {
    id: true,
    webName: [{ args: { language: true } }, true],
  },
  users: MyBreteuilUserFields,
};

export interface MyBreteuilContact
{
  id: IdType,
  firstname: string | null,
  lastname: string | null,
  fullname: string,
  phones: Array<string>,
  emails: Array<string>,
  hasReminders: boolean,
  mybHistoriqueLastAction: number | null/*timestamp*/,
  lastContactAttemptDate: number | null/*timestamp*/,
  recallDate: number | null/*timestamp*/,
  eval: MyBreteuilSearchLevel | null,
  effectiveAlertsLevel: AlertsFrequency | null,
}

const MyBreteuilContactFields: ReadonlyNodeFields = {
  id: true,
  firstname: true,
  lastname: true,
  fullname: true,
  phones: true,
  emails: true,
  hasReminders: true,
  mybHistoriqueLastAction: true,
  lastContactAttemptDate: true,
  recallDate: true,
  eval: true,
  effectiveAlertsLevel: true,
};

export interface MatchLastSentDetails {
  communicationChannel: CommunicationChannelEnum,
  sentAuto: boolean,
  sentPropertyPrice: number,
  sentAgentsInSignature: Array<{
    id: IdType,
    fullname: string,
    __typename: 'MyBreteuilUser',
  } | {
    id: IdType,
    name: string,
    __typename: 'MyBreteuilAgency',
  }>,
}

const SentAgentsInSignaturePolymorphicFields: Record<string/*Typename*/, ReadonlyNodeFields> = {
  MyBreteuilUser: {
    id: true,
    fullname: true,
  },
  MyBreteuilAgency: {
    id: true,
    name: true,
  },
};

const MatchLastSentDetailsFields: ReadonlyNodeFields = {
  communicationChannel: true,
  sentAuto: true,
  sentPropertyPrice: true,
  sentAgentsInSignature: [{ polymorphicFields: SentAgentsInSignaturePolymorphicFields}, {}],
};

export interface MyBreteuilMatch
{
  id: IdType,
  contactId: IdType,
  contact: MyBreteuilContact | null,
  owner: ContactOwnership,
  match: MyBreteuilMatchType,
  banned: boolean,
  sent: boolean,
  search: MyBreteuilSearch,
  notificationStatus: {
    firstDate: number | null/*timestamp*/,
    lastDate: number | null/*timestamp*/,
    count: number | null,
  } | null,
  contactPropertyInterest: {
    firstInterestDate: number | null/*timestamp*/,
    lastInterestDate: number | null/*timestamp*/,
    totalInterestsCount: number | null,
    unhandledInterestsCount: number | null,
  } | null,
  lastViewingDate: number | null/*timestamp*/,
  viewingsCount: number | null,
  sendableConditionNotAlreadySent: boolean,
  hasViewing: boolean,
  hasWhatsApp: boolean,
  hasNegativeFeedback: boolean,
  rapprochedTypes: Array<RapprochementType>,
  extendedMissingFields: Array<string>,
  extendedMissingCriteria: Array<{
    name: string,
  }>,
  confidentialLink: {
    status: PropertyConfidentialLinkStatus,
    propertyUrl: string,
  } | null,
  // computed fields
  propertyPrice: number | null,
  searchContactName: number | null,
  searchBudgetMax: number | null,
  searchSurfaceMin: number | null,
  searchBedroomsMin: number | null,
  lastSentDetails: MatchLastSentDetails | null,
}

const MyBreteuilMatchFields: ReadonlyNodeFields = {
  id: true,
  contactId: true,
  contact: MyBreteuilContactFields,
  owner: true,
  match: true,
  banned: true,
  sent: true,
  search: MyBreteuilSearchFields,
  notificationStatus: {
    firstDate: true,
    lastDate: true,
    count: true,
  },
  contactPropertyInterest: {
    firstInterestDate: true,
    lastInterestDate: true,
    totalInterestsCount: true,
    unhandledInterestsCount: true,
  },
  lastViewingDate: true,
  viewingsCount: true,
  sendableConditionNotAlreadySent: true,
  hasViewing: [{
    args: {
      type: { literal: RapprochementType.Viewing },
      currentUserOnly: { literal: 'true' },
    },
    aliasedField: 'hasRapprochement',
  }, true],
  hasWhatsApp: [{
    args: {
      type: { literal: RapprochementType.WhatsApp },
      currentUserOnly: { literal: 'true' },
    },
    aliasedField: 'hasRapprochement',
  }, true],
  hasNegativeFeedback: [{
    args: {
      type: { literal: RapprochementType.NegativeFeedback },
      currentUserOnly: { literal: 'true' },
    },
    aliasedField: 'hasRapprochement',
  }, true],
  rapprochedTypes: true,
  extendedMissingFields: true,
  extendedMissingCriteria: [{ preventUnwrap: true }, { name: true }],
  confidentialLink:  {
    status: true,
    propertyUrl: true,
  },
  // computed fields
  propertyPrice: true,
  searchContactName: true,
  searchBudgetMax: true,
  searchSurfaceMin: true,
  searchBedroomsMin: true,
  lastSentDetails: MatchLastSentDetailsFields,
};

export interface Filters__MyBreteuilMatch
{
  match?: Filters__Enum<MyBreteuilMatchType> | undefined,
  clicked?: Filters__Boolean | undefined,
  search_contactName?: Filters__String | undefined,
  search_budgetMax?: Filters__Int | undefined,
  search_bedroomsMin?: Filters__Int | undefined,
  search_surfaceMin?: Filters__Int | undefined,
  contact?: {
    lastContactAttemptDate?: Filters__Date | undefined,
    myb_historique_last_action?: Filters__Date | undefined,
    recallDate?: Filters__Date | undefined,
  } | undefined,
  search?: {
    criteria?: Filters_HasMany<{
      criteriaId?: Filters__Int | undefined,
    }> | undefined,
    users?: Filters_HasMany<{
      userId?: Filters__Int | undefined,
    }> | undefined,
  } | undefined,
  lastInterestDate?: {
    gte?: number | undefined,
    lt?: number | undefined,
  } | undefined,
}

export interface GetPropertyMatchesVariables extends PaginationBaseArgs
{
  slug: string,
  language: Language,
  orderBy?: OrderBy | undefined,
  filter?: Filters__MyBreteuilMatch | undefined,
  contactsFilter?: ContactOwnershipFilter | undefined,
}

export default function GetPropertyMatches(variables: GetPropertyMatchesVariables)
{
  return query<PaginationExtendedResponse<MyBreteuilMatch>>({
    operationName: 'Property_Matches_GetMatches',
    variables: {
      ...ExtendedPaginationVariables('MyBreteuilMatch'),
      contactsFilter: 'ContactOwnershipFilter',
      slug: 'String!',
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: [{
          args: {
            slug: true,
          },
        }, {
          matches: [{
            args: {
              ...ExtendedPaginationArgs,
              contactsFilter: true,
            },
          }, paginationResult(MyBreteuilMatchFields)],
        }],
      }),
    },
  }, variables);
}
