import type { IdType } from '@repo-breteuil/common-definitions';
import type { MyBreteuilSearchLevel } from '@core/api/types';

import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface UpdateContactArgs
{
  id: IdType,
  eval?: MyBreteuilSearchLevel | null | undefined,
  ownerComment?: string | null | undefined,
  tenant?: boolean | null | undefined,
}

export default function UpdateContact(args: UpdateContactArgs)
{
  return mutation<{ id: IdType }>({
    operationName: 'Common_ReminderDialog_UpdateContact',
    variables: {
      id: 'Int!',
      eval: 'MyBreteuilSearchLevel',
      ownerComment: 'String',
      tenant: 'Boolean',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: {
          update: errorWrapper([{
            args: {
              id: true,
              eval: true,
              ownerComment: true,
              tenant: true,
            },
          }, {
            id: true,
          }]),
        },
      }),
    },
  }, args);
}
