import type { IdType } from '@repo-breteuil/common-definitions';
import { Fetchable, OpeningControl } from '@repo-lib/utils-mobx-store';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import {
  GetViewing,
  GetAssignableUsers,
  DeleteViewing,
  UpdateViewing,
  type UpdateViewingArgs,
  SearchContacts,
  type SearchContactsVariables,
} from './api';

class ViewingUpdateDialogStore
{
  private _onUpdateSuccess: (() => void) | null = null;

  get onUpdateSuccess()
  {
    return this._onUpdateSuccess;
  }

  public viewing = new Fetchable(GetViewing, { catchUnhandled: handleNonCriticalError });

  public visibleUsers = new Fetchable(GetAssignableUsers, { catchUnhandled: handleNonCriticalError });

  private _openingControl = new OpeningControl();

  public get opened()
  {
    return this._openingControl.isOpen;
  }

  public async open(
    args: {
      viewingId: IdType,
      onUpdateSuccess: () => void,
    },
  )
  {
    await Promise.all([
      this.viewing.ensureSuccessReload({ id: args.viewingId }),
      this.visibleUsers.ensureSuccessReload(),
    ]);
    this._onUpdateSuccess = args.onUpdateSuccess;
    this._openingControl.open();
  }

  public close()
  {
    this._openingControl.close();
  }

  public async searchContacts(args: SearchContactsVariables)
  {
    return SearchContacts(args);
  }

  public async updateViewing(args: UpdateViewingArgs)
  {
    return UpdateViewing(args);
  }

  public async deleteViewing(viewingId: IdType)
  {
    return DeleteViewing({ id: viewingId });
  }
}

export default ViewingUpdateDialogStore;
