import type { IdType } from '@repo-breteuil/common-definitions';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface SettleInvoiceArgs {
  id: IdType,
}

export default function SettleInvoice(args: SettleInvoiceArgs)
{
  return mutation<IdType>({
    operationName: 'Offers_SettleInvoice',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        beeTeam: {
          myBreteuilTransaction: {
            settleInvoice: [{ args: { id: true }}, { id: true }],
          },
        },
      }),
    },
  }, args);
}
