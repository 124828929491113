import type { IdType } from '@repo-breteuil/common-definitions';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface CreateShowcaseStreamItemArgs
{
  showcaseStreamId: IdType,
  contentType: 'ShowcaseMedia' | 'MyBreteuilProperty',
  contentId: IdType,
  displayDurationMs?: number | null | undefined,
  position?: number | undefined,
}

export interface UpdateShowcaseStreamItemArgs
{
  displayDurationMs?: number | null | undefined,
  position?: number | undefined,
}

export async function CreateShowcaseStreamItem(args: CreateShowcaseStreamItemArgs): Promise<void>
{
  await mutation({
    operationName: 'Showcase_StreamMixing_CreateShowcaseStreamItem',
    variables: {
      showcaseStreamId: 'Int!',
      contentType: 'String!',
      contentId: 'Int!',
      displayDurationMs: 'Int',
      position: 'Float',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseStreamItem: {
          create: [{
            args: {
              showcaseStreamId: true,
              contentType: true,
              contentId: true,
              displayDurationMs: true,
              position: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, args);
}

export async function UpdateShowcaseStreamItem(id: IdType, args: UpdateShowcaseStreamItemArgs): Promise<void>
{
  await mutation({
    operationName: 'Showcase_StreamMixing_UpdateShowcaseStreamItem',
    variables: {
      id: 'Int!',
      displayDurationMs: 'Int',
      position: 'Float',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseStreamItem: {
          update: errorWrapper([{
            args: {
              id: true,
              displayDurationMs: true,
              position: true,
            },
          }, {
            id: true,
          }]),
        },
      }),
    },
  }, {
    ...args,
    id,
  });
}

export async function DeleteShowcaseStreamItem(id: IdType): Promise<void>
{
  await mutation({
    operationName: 'Showcase_StreamMixing_DeleteShowcaseStreamItem',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseStreamItem: {
          delete: errorWrapper([{
            args: {
              id: true,
            },
          }, true]),
        },
      }),
    },
  }, { id });
}

export async function FixShowcaseStreamItemsPositions(
  args: {
    before?: Array<IdType> | undefined,
    after?: Array<IdType> | undefined,
  },
): Promise<number>
{
  return mutation({
    operationName: 'Showcase_StreamMixing_FixShowcaseStreamItemsPositions',
    variables: {
      before: '[Int!]',
      after: '[Int!]',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseStreamItem: {
          regeneratePositionsAround: [{
            args: {
              before: true,
              after: true,
            },
          }, {
            position: true,
          }],
        },
      }),
    },
  }, args);
}
