import type { IdType } from '@repo-breteuil/common-definitions';
import type {
  MyBreteuilPropertyDocumentType,
  FileUploadType,
} from '@core/api/types';

import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MyBreteuilPropertyDocument
{
  id: IdType,
  documentType: MyBreteuilPropertyDocumentType,
  originalFilename: string,
  fileType: FileUploadType,
  fileURL: string,
}

export interface MyBreteuilProperty
{
  id: IdType,
  documents: Array<MyBreteuilPropertyDocument>,
}

export interface GetPropertyDocumentsVariables
{
  slug: string,
}

export default function GetPropertyDocuments(variables: GetPropertyDocumentsVariables)
{
  return query<MyBreteuilProperty>({
    operationName: 'Property_Documents_GetPropertyDocuments',
    variables: {
      slug: 'String!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: [{
          args: {
            slug: true,
          },
        }, {
          id: true,
          documents: paginationResultAsList({
            id: true,
            documentType: true,
            originalFilename: true,
            fileType: true,
            fileURL: true,
          }),
        }],
      }),
    },
  }, variables);
}
