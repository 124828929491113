import type { IdType } from '@repo-breteuil/common-definitions';
import {
  type UserHistoryHierarchyNode,
  type UserWithAgencyHistory,
  type GeoArea,
} from './types';
import { makeAreaIdentifier, makeAgencyIdentifier, makeUserHistoryIdentifier } from './identifiers';
import { genAreasNodes, DummyAgency } from './gen';

export function genUsersHistoryNodes(usersHistorical: ReadonlyArray<UserWithAgencyHistory>)
{
  const nodes: Array<UserHistoryHierarchyNode> = [];

  const orderedUsersHistorical = usersHistorical.slice().sort((lhs, rhs) => (
    Number(rhs.activeInAgency) - Number(lhs.activeInAgency)
  )); // Move inactive users to the end
  const addedAgenciesIds = new Set<IdType>();
  for (const userHistorical of orderedUsersHistorical)
  {
    const { user, agency, activeInAgency } = userHistorical;
    if (agency && !agency.agencyTreeGeoAreaId)
      continue;
    const effectiveAgency = agency ?? DummyAgency;
    const agencyIdentifier = makeAgencyIdentifier(effectiveAgency.id);
    nodes.push({
      identifier: makeUserHistoryIdentifier({ userId: user.id, agencyId: effectiveAgency.id }),
      parentIdentifier: agencyIdentifier,
      name: user.fullname,
      defaultExpanded: false,
      ignore: false,
      activeInAgency,
    });
    if (addedAgenciesIds.has(effectiveAgency.id))
      continue;
    addedAgenciesIds.add(effectiveAgency.id);
    nodes.push({
      identifier: agencyIdentifier,
      parentIdentifier: effectiveAgency.agencyTreeGeoAreaId ? makeAreaIdentifier(effectiveAgency.agencyTreeGeoAreaId) : null,
      name: effectiveAgency.shortName,
      defaultExpanded: false,
      ignore: false,
      activeInAgency: undefined,
    });
  }

  return nodes;
}

export function genHistoryNodes(
  areas: ReadonlyArray<GeoArea>,
  users: ReadonlyArray<UserWithAgencyHistory>,
): Array<UserHistoryHierarchyNode>
{
  return [
    ...genAreasNodes(areas),
    ...genUsersHistoryNodes(users),
  ];
}
