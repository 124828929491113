import type { IdType } from '@repo-breteuil/common-definitions';
import type { CreateArgs } from './CreateShowcaseMedia';

import { mutation, errorWrapper } from "@core/api/graphql/gen-v2";

export type UpdateArgs = Partial<CreateArgs>;

async function UpdateShowcaseMedia(id: IdType, args: UpdateArgs): Promise<void>
{
  await mutation({
    operationName: 'Showcase_MediasList_UpdateShowcaseMedia',
    variables: {
      id: 'Int!',
      name: 'String',
      file: 'Upload',
      defaultDisplayDurationMs: 'Int',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseMedia: {
          update: errorWrapper([{
            args: {
              id: true,
              name: true,
              file: true,
              defaultDisplayDurationMs: true,
            },
          }, {
            id: true,
          }]),
        },
      }),
    },
  }, { ...args, id });
}

export default UpdateShowcaseMedia;
