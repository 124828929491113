import type { OrderField } from '@core/api/types';
import type { UserRemunerationAnalytics } from './api';

import { action, computed, observable } from 'mobx';
import { differenceInMonths } from 'date-fns';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { assert, handleCriticalError } from '@repo-breteuil/front-error';
import { MyBreteuilUserContractTypeEnum, OrderType } from '@core/api/types';
import { applyTableOrderBy } from '@my-breteuil/store/ui/common/array-processing-utils';
import { GetUsersRemunerationAnalytics } from './api';
import profileStore from './index';
import { aggregateMultiple } from './common';

function flattenUserRemunerationAnalytics(userAnalytics: UserRemunerationAnalytics)
{
  const { user, remunerationPercentage, ...rest } = userAnalytics;
  // Adjusted remunerationPercentage to consider everyone as a VRP agent (used to compute accurate average on this field)
  const adjustedRemunerationPercentage = (
    remunerationPercentage !== null && user.contractType === MyBreteuilUserContractTypeEnum.Commercial
      ? (remunerationPercentage / 1.4)
      : remunerationPercentage
  );
  const monthsOfExperience = differenceInMonths(Date.now(), user.arrivedAt);
  return {
    userId: user.id,
    fullname: user.fullname,
    active: user.active,
    agencyId: user.contactSearchAgency?.id ?? null,
    monthsOfExperience,
    remunerationPercentage,
    adjustedRemunerationPercentage,
    dailyLoginTimeAvg6m: rest.lastDailyRequestAvg6m && rest.firstDailyRequestAvg6m ? (
      rest.lastDailyRequestAvg6m - rest.firstDailyRequestAvg6m
    ) : null,
    ...rest,
  };
}

export type UsersRemunerationAnalyticsTotals = ReturnType<typeof aggregateUsersRemunerationAnalyticsTotals>;

function aggregateUsersRemunerationAnalyticsTotals(usersRemunerationAnalytics: Array<ReturnType<typeof flattenUserRemunerationAnalytics>>)
{
  return aggregateMultiple(usersRemunerationAnalytics, {
    monthsOfExperience: { method: 'avg' },
    CAP12M_HT: { method: 'sum' },
    feesPercentageCAP12m_TTC: { method: 'weightedAvg', weightField: 'CAP12M_HT' },
    adjustedRemunerationPercentage: { method: 'weightedAvg', weightField: 'CAP12M_HT' },
    advanceOwedJanuary1st: { method: 'sum' },
    advanceOwed: { method: 'sum' },
    advanceEstimateN5M: { method: 'sum' },
    vipsCount: { method: 'sum' },
    dailyLoginTimeAvg6m: { method: 'avg' },
    firstDailyRequestAvg6m: { method: 'avg' },
    lastDailyRequestAvg6m: { method: 'avg' },
  });
}

class UsersRemunerationAnalyticsStore
{
  public usersRemunerationAnalytics = new Fetchable(GetUsersRemunerationAnalytics, { catchUnhandled: handleCriticalError });

  @computed private get _flattenedUsersRemunerationAnalytics()
  {
    assert(this.usersRemunerationAnalytics.lastResult);
    return this.usersRemunerationAnalytics.lastResult.map(flattenUserRemunerationAnalytics);
  }

  @observable private _order: OrderField | null = {
    fieldName: 'CAP12M_HT',
    ordering: OrderType.DESC,
  };

  get order()
  {
    return this._order;
  }

  @action setOrder(order: OrderField | null)
  {
    this._order = order;
  }

  @computed private get _filteredUsers()
  {
    if (profileStore.selectedUsersIds.size <= 0)
      return this._flattenedUsersRemunerationAnalytics;
    return this._flattenedUsersRemunerationAnalytics.filter(({ userId, agencyId }) => (
      profileStore.selectedAgencyUsersIds.some((selected) => selected.userId === userId && selected.agencyId === agencyId)
    ));
  }

  @computed public get processedUsersRemunerationAnalytics()
  {
    const orderBy = this.order ? [ this.order ] : [];
    return applyTableOrderBy(this._filteredUsers, orderBy);
  }

  @computed public get processedUsersRemunerationAnalyticsTotals()
  {
    return aggregateUsersRemunerationAnalyticsTotals(this.processedUsersRemunerationAnalytics);
  }

  @computed public get pinnedNodesTotals()
  {
    return profileStore.pinnedNodesInfo.map(({ node, usersWithAgencies }) => {
      const pinnedUsersIds = new Set(usersWithAgencies.map(({ userId }) => userId));
      const filteredUsersGoals = this._flattenedUsersRemunerationAnalytics.filter(({ userId }) => pinnedUsersIds.has(userId));
      const totals = aggregateUsersRemunerationAnalyticsTotals(filteredUsersGoals);
      return { node: node.node, totals } as const;
    });
  }

  public async refresh()
  {
    await this.usersRemunerationAnalytics.ensureSuccessReload();
  }
}

export default new UsersRemunerationAnalyticsStore();
