import type { IdType, Language } from '@repo-breteuil/common-definitions';
import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export interface SetDescriptionTranslationLockedArgs
{
  id: IdType,
  language: Language,
  locked: boolean,
}

export default function SetDescriptionTranslationLocked(args: SetDescriptionTranslationLockedArgs)
{
  return mutation<IdType>({
    operationName: 'Property_SetDescriptionTranslationLocked',
    variables: {
      id: 'Int!',
      language: 'Language!',
      locked: 'Boolean!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: {
          setDescriptionTranslationLocked: [{
            args: {
              id: true,
              language: true,
              locked: true,
            },
          }, {
            id: true,
          }],
        },
      }),
    },
  }, args);
}
