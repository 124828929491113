import type { IdType } from '@repo-breteuil/common-definitions';
import { errorWrapper, mutation } from '@core/api/graphql/gen-v2';
import type { MyBreteuilPropertyVisibility } from '@core/api/types';

export interface UpdatePropertyArgs
{
  id: IdType,
  publication: MyBreteuilPropertyVisibility,
}

export default function UpdateProperty(args: UpdatePropertyArgs)
{
  return mutation<IdType>({
    operationName: 'PropertiesConfidentialLinks_UpdateProperty',
    variables: {
      id: 'Int!',
      publication: 'MyBreteuilPropertyVisibility',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilProperty: {
          update: [{
            args: {
              id: true,
              publication: true,
            },
          }, {
            result: {
              id: true,
            },
          }],
        },
      }),
    },
  }, args);
}
