import type { IdType } from '@repo-breteuil/common-definitions';
import type { OrderField } from '@core/api/types';
import type {
  Filters__ProspectingAreaStreet,
  ProspectingAreaStreet,
} from './api';

import { observable } from 'mobx';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import { UncachedPagination } from '@repo-lib/graphql-query-pagination';
import { handleNonCriticalError } from '@repo-breteuil/front-error';
import { OrderType } from '@core/api/types';
import sessionstore from '@my-breteuil/store/ui/common/Session';
import {
  GetProspectingStreets,
  GetVisibleUsers,
  GetManagedUsers,
  GetManagedAgencies,
  CreateAreaStreet,
  DeleteAreaStreet,
  TransferAreaStreets,
} from './api';

class ProspectingStore
{
  private static DefaultPageSize = 100;
  private static PageSizeOptions = [ 50, ProspectingStore.DefaultPageSize, 200, 500 ];

  get pageSizeOptions()
  {
    return ProspectingStore.PageSizeOptions;
  }

  public users = new Fetchable(() => {
    if (sessionstore.manager)
      return GetManagedUsers();
    return GetVisibleUsers();
  }, { catchUnhandled: handleNonCriticalError });
  public managedAgencies = new Fetchable(GetManagedAgencies, { catchUnhandled: handleNonCriticalError });

  private _streetsFilters: Filters__ProspectingAreaStreet = {};

  get streetsFilters()
  {
    return this._streetsFilters;
  }

  public async setStreetsFiltersAndReload(filters: Filters__ProspectingAreaStreet)
  {
    this._streetsFilters = filters;
    await this.streets.first();
  }

  private _streetsOrderField: OrderField | null = {
    fieldName: 'user_fullname',
    ordering: OrderType.ASC,
  };

  get streetsOrderField()
  {
    return this._streetsOrderField;
  }

  public async setStreetsOrderFieldAndReload(orderField: OrderField | null)
  {
    this._streetsOrderField = orderField;
    await this.streets.first();
  }

  public streets = new UncachedPagination({
    fetch: (baseArgs) => GetProspectingStreets({
      ...baseArgs,
      filter: this._streetsFilters,
      orderBy: this._streetsOrderField ? [this._streetsOrderField] : undefined,
    }),
    pageSize: ProspectingStore.DefaultPageSize,
  });

  public selectedStreets = observable.map<IdType, ProspectingAreaStreet>();

  public async createAreaStreets(userId: IdType, streetsNames: Array<string>)
  {
    await Promise.all(streetsNames.map(name => CreateAreaStreet({ userId, name })));
    await this.streets.reload();
  }

  public async deleteAreaStreet(streetId: IdType)
  {
    await DeleteAreaStreet({ id: streetId });
    await this.streets.reload();
  }

  public async transferAreaStreets(targetUserId: IdType)
  {
    const streetsIds = Array.from(this.selectedStreets.keys());
    await TransferAreaStreets({ targetUserId, streetsIds });
    this.selectedStreets.clear();
    await this.streets.reload();
  }

  public async refresh()
  {
    return Promise.all([
      this.users.ensureSuccess(),
      this.managedAgencies.ensureSuccessReload(),
      this.streets.reload(),
    ]);
  }
}

export default new ProspectingStore();
