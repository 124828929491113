import { subDays } from 'date-fns';
import type { IdType, PropertyStatus } from '@repo-breteuil/common-definitions';
import { AlertsFrequency, MyBreteuilSearchLevel, Filters__Enum, MyBreteuilContactCategory } from '@core/api/types';
import type { ContactsFilterFull } from './shared';

export enum AlertsFrequencyLevel
{
  Manual = 'Manual',
  Never = 'Never',
  Auto = 'Auto',
}

const FrequencyLevelToFrequency = new Map<AlertsFrequencyLevel, Array<AlertsFrequency>>([
  [ AlertsFrequencyLevel.Manual, [ AlertsFrequency.Manual ] ],
  [ AlertsFrequencyLevel.Never, [ AlertsFrequency.Never ] ],
  [ AlertsFrequencyLevel.Auto, [ AlertsFrequency.RealTime, AlertsFrequency.Daily, AlertsFrequency.Batched ] ],
]);

export function getAlertsFrequencyLevel(frequency: AlertsFrequency): AlertsFrequencyLevel
{
  for (const [key, value] of FrequencyLevelToFrequency.entries())
  {
    if (value.includes(frequency))
      return key;
  }
  throw new Error(`Unsupported frequency: "${frequency}"`);
}

export function getAlertsFrequency(frequencyLevel: AlertsFrequencyLevel): Array<AlertsFrequency>
{
  const frequencies = FrequencyLevelToFrequency.get(frequencyLevel);
  if (frequencies === undefined)
    throw new Error(`Unsupported frequencyLevel: "${frequencyLevel}"`);
  return frequencies;
}

export function getAlertsFrequencies(frequencyLevels: Array<AlertsFrequencyLevel>): Array<AlertsFrequency>
{
  const set = new Set<AlertsFrequency>();
  for (const frequencyLevel of frequencyLevels)
    getAlertsFrequency(frequencyLevel).forEach(set.add, set);
  return Array.from(set);
}

export enum SearchLevelFilter
{
  AA = 'AA',
  A = 'A',
  B = 'B',
  C = 'C',
  Without = 'Without',
}

export function searchLevelToFilter(levels: Array<SearchLevelFilter>): Filters__Enum<MyBreteuilSearchLevel> | undefined
{
  if (levels.length <= 0)
    return undefined;
  const searchLevels = levels.filter(lvl => lvl !== SearchLevelFilter.Without).map(lvl => MyBreteuilSearchLevel[lvl]);
  return {
    in: searchLevels.length > 0 ? searchLevels : undefined,
    equals: levels.includes(SearchLevelFilter.Without) ? null : undefined,
  };
}

export enum OwnerFilter
{
  Owner = 'Owner',
  Tenant = 'Tenant',
  None = 'None'
}

export interface Owner_Filters
{
  owner?: boolean | null | undefined,
  tenant?: boolean | null | undefined,
}

export function ownerFilterToFilters(owner: OwnerFilter | null | undefined): Owner_Filters
{
  if (!owner)
    return {};
  switch (owner)
  {
    case OwnerFilter.Owner: return { owner: true };
    case OwnerFilter.Tenant: return { tenant: true };
    case OwnerFilter.None: return { owner: false, tenant: false };
  }
}


export interface SimpleRangeFilter<T>
{
  min?: T | null | undefined,
  max?: T | null | undefined,
}

export type SimpleNumberRangeFilter = SimpleRangeFilter<number>;
export type SimpleDateRangeFilter = SimpleRangeFilter<Date>;

export interface ContactsSimpleFilter
{
  responsibleUsers?: Array<IdType>,
  budgetMax?: SimpleNumberRangeFilter,
  viewingsCount?: SimpleNumberRangeFilter,
  myb_historique_last_action?: SimpleNumberRangeFilter, // Offset converted to date
  lastContactAttemptDate?: SimpleNumberRangeFilter, // Offset converted to date
  greenAlertCount?: SimpleNumberRangeFilter,
  lastInterestDate?: SimpleNumberRangeFilter, // Offset converted to date
  category?: Array<MyBreteuilContactCategory>,
  namesStringReversed?: string,
  matchesCountAbsoluteNotSent?: SimpleNumberRangeFilter,
  matchesCountExtendedNotSent?: SimpleNumberRangeFilter,
  searchAreas?: Array<IdType>,
  searchCriteria?: Array<IdType>,
  activeSearchesCount?: SimpleNumberRangeFilter,
  propertyStatus?: Array<PropertyStatus>,
  effectiveAlertsLevel?: Array<AlertsFrequencyLevel>,
  eval?: Array<SearchLevelFilter>,
  vip?: boolean,
  owner?: OwnerFilter | null,
  recallDate?: boolean,
  createdAt?: SimpleNumberRangeFilter, // Offset converted to date
  bedroomsSearchMin?: SimpleNumberRangeFilter,
  surfaceSearchMin?: SimpleNumberRangeFilter,
  lastMarketingReportSendDate?: SimpleNumberRangeFilter,
  propertyAddress?: string,
  propertyLastAgentValuationDate?: SimpleDateRangeFilter,
  propertyPrice?: SimpleNumberRangeFilter,
}

export function convertContactsSimpleFilterToAPIFilter(filter: ContactsSimpleFilter): ContactsFilterFull
{
  return {
    responsibleUsers: filter.responsibleUsers?.length ? filter.responsibleUsers : undefined,
    filter: {
      budgetMax: {
        gte: filter.budgetMax?.min ?? undefined,
        lte: filter.budgetMax?.max ?? undefined,
      },
      vip: filter.vip ? { equals: filter.vip } : undefined,
      eval: filter.eval?.length ? searchLevelToFilter(filter.eval) : undefined,
      ...ownerFilterToFilters(filter.owner),
      viewingsCount: {
        gte: filter.viewingsCount?.min ?? undefined,
        lte: filter.viewingsCount?.max ?? undefined,
      },
      matchesCountAbsoluteNotSent: {
        gte: filter.matchesCountAbsoluteNotSent?.min ?? undefined,
        lte: filter.matchesCountAbsoluteNotSent?.max ?? undefined,
      },
      matchesCountExtendedNotSent: {
        gte: filter.matchesCountExtendedNotSent?.min ?? undefined,
        lte: filter.matchesCountExtendedNotSent?.max ?? undefined,
      },
      myb_historique_last_action: {
        lte: filter.myb_historique_last_action?.min ? subDays(Date.now(), filter.myb_historique_last_action.min).getTime() : undefined,
        gte: filter.myb_historique_last_action?.max ? subDays(Date.now(), filter.myb_historique_last_action.max).getTime() : undefined,
      },
      lastContactAttemptDate: {
        lte: filter.lastContactAttemptDate?.min ? subDays(Date.now(), filter.lastContactAttemptDate.min).getTime() : undefined,
        gte: filter.lastContactAttemptDate?.max ? subDays(Date.now(), filter.lastContactAttemptDate.max).getTime() : undefined,
      },
      greenAlertCount: {
        gte: filter.greenAlertCount?.min ?? undefined,
        lte: filter.greenAlertCount?.max ?? undefined,
      },
      lastInterestDate: {
        lte: filter.lastInterestDate?.min ? subDays(Date.now(), filter.lastInterestDate.min).getTime() : undefined,
        gte: filter.lastInterestDate?.max ? subDays(Date.now(), filter.lastInterestDate.max).getTime() : undefined,
      },
      category: filter.category?.length ? { in: filter.category } : undefined,
      namesStringReversed: filter.namesStringReversed ? { contains: filter.namesStringReversed } : undefined,
      searchAreas: filter.searchAreas?.length ? filter.searchAreas : undefined,
      searchCriteria: filter.searchCriteria?.length ? filter.searchCriteria : undefined,
      propertyStatus: filter.propertyStatus?.length ? filter.propertyStatus : undefined,
      effectiveAlertsLevel: filter.effectiveAlertsLevel?.length ? { in: getAlertsFrequencies(filter.effectiveAlertsLevel) } : undefined,
      recallDate: filter.recallDate ? { lte: Date.now() } : undefined,
      createdAt: {
        lte: filter.createdAt?.min ? subDays(Date.now(), filter.createdAt.min).getTime() : undefined,
        gte: filter.createdAt?.max ? subDays(Date.now(), filter.createdAt.max).getTime() : undefined,
      },
      bedroomsSearchMin: {
        gte: filter.bedroomsSearchMin?.min ?? undefined,
        lte: filter.bedroomsSearchMin?.max ?? undefined,
      },
      surfaceSearchMin: {
        gte: filter.surfaceSearchMin?.min ?? undefined,
        lte: filter.surfaceSearchMin?.max ?? undefined,
      },
      lastMarketingReportSendDate: {
        lte: filter.lastMarketingReportSendDate?.min ? subDays(Date.now(), filter.lastMarketingReportSendDate.min).getTime() : undefined,
        gte: filter.lastMarketingReportSendDate?.max ? subDays(Date.now(), filter.lastMarketingReportSendDate.max).getTime() : undefined,
      },
      activeSearchesCount: {
        gte: filter.activeSearchesCount?.min ?? undefined,
        lte: filter.activeSearchesCount?.max ?? undefined,
      },
      propertyAddress: filter.propertyAddress || undefined,
      propertyLastAgentValuationDate: {
        min: filter.propertyLastAgentValuationDate?.min?.getTime(),
        max: filter.propertyLastAgentValuationDate?.max?.getTime(),
      },
      propertyPrice: {
        min: filter.propertyPrice?.min ?? undefined,
        max: filter.propertyPrice?.max ?? undefined,
      },
    },
  };
}
