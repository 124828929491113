import type { IdType } from '@repo-breteuil/common-definitions';
import type { MapPointType } from '@core/api/types/enums';

import {
  query,
  errorWrapper,
  paginationResultAsList,
} from '@core/api/graphql/gen-v2';

export interface MapLayer
{
  id: IdType,
  name: string,
}

export interface MapPoint
{
  id: IdType,
  type: MapPointType,
  label: string,
  latitude: number,
  longitude: number,
  layer: MapLayer,
}

export interface MapPointFilter
{
  type?: {
    equals?: MapPointType | undefined,
    in?: Array<MapPointType> | undefined,
  } | undefined,
  layer?: {
    id?: {
      equals?: IdType | undefined,
      in?: Array<IdType> | undefined,
    } | undefined,
    name?: {
      equals?: string | undefined,
      in?: Array<string> | undefined,
    } | undefined,
  } | undefined,
}

export default async function GetMapPoints(
  args: {
    filter: MapPointFilter,
  },
): Promise<Array<MapPoint>>
{
  return query({
    operationName: 'MarketData_HeatMap_GetMapPoints',
    variables: {
      filter: 'Filters__MapPoint',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        mapPoints: [{ args: { filter: true } }, paginationResultAsList({
          id: true,
          type: true,
          label: true,
          latitude: true,
          longitude: true,
          layer: {
            id: true,
            name: true,
          },
        })],
      }),
    },
  }, args);
}
