import type { IdType } from '@repo-breteuil/common-definitions';

import {
  mutation,
  errorWrapper,
} from '@core/api/graphql/gen-v2';

export interface UpdateContactArgs
{
  id: IdType,
  vip: boolean | null,
}

export default function UpdateContact(args: UpdateContactArgs)
{
  return mutation<IdType>({
    operationName: 'Contacts_UpdateContact',
    variables: {
      id: "Int!",
      vip: 'Boolean',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        myBreteuilContact: {
          update: errorWrapper([{
            args: {
              id: true,
              vip: true,
            },
          }, { id: true }]),
        },
      }),
    },
  }, args);
}
