import type { IdType } from '@repo-breteuil/common-definitions';

import { mutation, errorWrapper } from "@core/api/graphql/gen-v2";

async function DeleteShowcaseMedia(id: IdType): Promise<void>
{
  await mutation({
    operationName: 'Showcase_MediasList_DeleteShowcaseMedia',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        showcaseMedia: {
          delete: errorWrapper([{
            args: {
              id: true,
            },
          }, true]),
        },
      }),
    },
  }, { id });
}

export default DeleteShowcaseMedia;
