import { IdType } from '@repo-breteuil/common-definitions';
import { mutation, errorWrapper } from '@core/api/graphql/gen-v2';

export default function DeleteLink(id: IdType)
{
  return mutation({
    operationName: 'PropertiesConfidentialLinks_DeleteLink',
    variables: {
      id: 'Int!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        propertyConfidentialLink: {
          delete: [{
            args: {
              id: true,
            },
          }, true],
        },
      }),
    },
  }, { id });
}
