import type { IdType } from '@repo-breteuil/common-definitions';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import type { Language } from '@core/api/types';
import type { AdministrativeAreaLevel } from '@core/api/types/enums';

import { query, errorWrapper } from '@core/api/graphql/gen-v2';

export interface TransactionsStatsPerQuarterPerArea
{
  area: {
    id: IdType,
    displayName: string,
    administrativeAreaLevel: AdministrativeAreaLevel,
  },
  year: number,
  quarter: number,
  pricePerSurface: number,
  projected: boolean,
}

const TransactionsStatsPerQuarterPerAreaFields: ReadonlyNodeFields = {
  area: {
    id: true,
    displayName: [ { args: { language: true } }, true ],
    administrativeAreaLevel: true,
  },
  year: true,
  quarter: true,
  pricePerSurface: true,
  projected: true,
};

export interface GetMarketDataPricePerSurfacePerQuarterPerAreaArgs
{
  geoAreaIds: Array<IdType>,
  surfaceMin?: number | undefined,
  surfaceMax?: number | undefined,
  language: Language,
}

export default function GetMarketDataPricePerSurfacePerQuarterPerArea(args: GetMarketDataPricePerSurfacePerQuarterPerAreaArgs)
{
  return query<Array<TransactionsStatsPerQuarterPerArea>>({
    operationName: 'Valuation_GetMarketDataPricePerSurfacePerQuarterPerArea',
    variables: {
      geoAreaIds: "[Int!]!",
      surfaceMin: "Int",
      surfaceMax: "Int",
      language: 'Language!',
    },
    fieldsSelection: {
      authenticated: errorWrapper({
        marketDataPricePerSurfacePerQuarterPerArea: [{
          args: {
            geoAreaIds: true,
            surfaceMin: true,
            surfaceMax: true,
          },
        },
        TransactionsStatsPerQuarterPerAreaFields],
      }),
    },
  }, args);
}
