import type { IdType } from '@repo-breteuil/common-definitions';
import type { PaginationBaseArgs, PaginationExtendedResponse } from '@repo-lib/graphql-query-pagination';
import type { ReadonlyNodeFields } from '@repo-lib/graphql-query-builder';
import {
  query,
  paginationResult,
  paginationResultAsList,
  ExtendedPaginationVariables,
  ExtendedPaginationArgs,
} from '@core/api/graphql/gen-v2';
import type {
  Currency,
  Language,
  MyBreteuilPropertyVisibility,
  PropertyConfidentialLinkStatus,
} from '@core/api/types';

export interface PropertyConfidentialLink
{
  id: IdType,
  status: PropertyConfidentialLinkStatus,
  statusLastUpdate: number | null,
  comment: string | null,
  propertyUrl: string,
  contact: {
    id: IdType,
    fullname: string,
    propertiesCount: number,
    transactionsCount: number,
    madeOffersCount: number,
    viewingsCount: number,
    budgetMax: number | null,
    surfaceSearchMin: number | null,
    bedroomsSearchMin: number | null,
    createdAt: number,
  },
  user: {
    id: IdType,
    fullname: string,
  },
  openedCount: number,
  associatedViewing: {
    id: IdType,
    date: number,
  } | null,
}

const PropertyConfidentialLinkFields: ReadonlyNodeFields = {
  id: true,
  status: true,
  statusLastUpdate: true,
  comment: true,
  propertyUrl: true,
  contact: {
    id: true,
    fullname: true,
    propertiesCount: true,
    transactionsCount: true,
    madeOffersCount: true,
    viewingsCount: true,
    budgetMax: true,
    surfaceSearchMin: true,
    bedroomsSearchMin: true,
    createdAt: true,
  },
  user: {
    id: true,
    fullname: true,
  },
  openedCount: true,
  associatedViewing: {
    id: true,
    date: true,
  },
};

export interface Property
{
  id: IdType,
  slug: string,
  confidential: boolean,
  publication: MyBreteuilPropertyVisibility,
  price: number | null,
  currency: Currency,
  addrStreetAddress: string | null,
  administrativeAreas: {
    district: {
      id: IdType,
      displayName: string,
    },
  },
  users: Array<{
    id: IdType,
    firstname: string,
    lastname: string,
    fullname: string,
    agent: boolean,
    isAgencyPotCommun: boolean,
    contactSearchAgency: {
      id: IdType,
      code: string,
      name: string,
    } | null,
  }>,
  confidentialPreviewLink: string | null,
  confidentialLinksOpens: number,
  confidentialLinks: Array<PropertyConfidentialLink>,
}

const PropertyFields: ReadonlyNodeFields = {
  id: true,
  slug: [{ args: { language: true } }, true],
  confidential: true,
  price: true,
  currency: true,
  publication: true,
  addrStreetAddress: true,
  administrativeAreas: [{
    preventUnwrap: true,
  }, {
    district: {
      id: true,
      displayName: [{ args: { language: true } }, true],
    },
  }],
  users: {
    id: true,
    firstname: true,
    lastname: true,
    fullname: true,
    agent: true,
    isAgencyPotCommun: true,
    contactSearchAgency: {
      id: true,
      code: true,
      name: true,
    },
  },
  confidentialPreviewLink: true,
  confidentialLinksOpens: true,
  confidentialLinks: paginationResultAsList(PropertyConfidentialLinkFields),
};

export interface GetUserConfidentialPropertiesArgs extends PaginationBaseArgs {
  language: Language,
}

export default function GetUserConfidentialProperties(args: GetUserConfidentialPropertiesArgs)
{
  return query<PaginationExtendedResponse<Property>>({
    operationName: 'PropertiesConfidentialLinks_GetUserConfidentialProperties',
    variables: {
      ...ExtendedPaginationVariables('MyBreteuilProperty'),
      language: 'Language!',
    },
    fieldsSelection: {
      session: {
        mybUser: {
          managedConfidentialProperties: [{
            args: ExtendedPaginationArgs,
          }, paginationResult(PropertyFields)],
        },
      },
    },
  }, args);
}
