import type { IdType } from '@repo-breteuil/common-definitions';
import type { AdvancedTableColumnOrder } from '@lib/components/data-table/advanced-table';
import type { OrderField } from '@core/api/types';

import { observable, computed, runInAction } from 'mobx';
import { UncachedPagination, makePaginationResultsAutoObservable } from '@repo-lib/graphql-query-pagination';
import { Fetchable } from '@repo-lib/utils-mobx-store';
import {
  PropertyPriceBracket_1M,
  PropertyPriceBracket_4M,
} from '@repo-breteuil/common-definitions';
import { assert, handleNonCriticalError } from '@repo-breteuil/front-error';
import { OrderType, UserPinOrigin } from '@core/api/types';
import { formatGlobalErrorMessage } from '@core/store/GlobalMessage';
import localesStore from '@core/store/Locales';
import PaginationSelection from '@my-breteuil/store/utils/PaginationSelection';
import sessionStore from '@my-breteuil/store/ui/common/Session';
import ReminderDialogStore from '@my-breteuil/store/ui/common/reminder-dialog';
import MessageDrawerStore from '@my-breteuil/store/ui/common/message-drawer';
import ReferentTransferStore from '@my-breteuil/store/ui/common/referent-transfer';
import {
  GetBuyerContacts,
  type BuyerContact,
  type ContactsSimpleFilter,
  convertContactsSimpleFilterToAPIFilter,
  GetPinnedBuyerContacts,
  GetUsers,
  QuickFilterCounts,
  SetContactPinned,
  type SetContactPinnedArgs,
  UpdateContact,
  GetCriteria,
  type GetCriteriaArgs,
  AlertsFrequencyLevel,
} from './api';

function makeBuyerMatchAndManualCountFilter(
  args: {
    currentUserId: IdType,
    budgetMax: { min?: number, max?: number },
    removeManualAlertLevel?: boolean,
  },
): ContactsSimpleFilter
{
  const { currentUserId, budgetMax, removeManualAlertLevel } = args;
  return {
    responsibleUsers: [ currentUserId ],
    effectiveAlertsLevel: removeManualAlertLevel ? [] : [ AlertsFrequencyLevel.Manual ],
    matchesCountAbsoluteNotSent: { min: 1 },
    budgetMax,
  };
}

export function makeBuyerMatchAndManualCountFilter_1M(currentUserId: IdType)
{
  return makeBuyerMatchAndManualCountFilter({
    currentUserId,
    budgetMax: { max: PropertyPriceBracket_1M - 1 },
  });
}

export function makeBuyerMatchAndManualCountFilter_4M(currentUserId: IdType)
{
  return makeBuyerMatchAndManualCountFilter({
    currentUserId,
    budgetMax: { min: PropertyPriceBracket_1M, max: PropertyPriceBracket_4M - 1 },
  });
}

export function makeBuyerMatchAndManualCountFilter_4MPlus(currentUserId: IdType)
{
  return makeBuyerMatchAndManualCountFilter({
    currentUserId,
    budgetMax: { min: PropertyPriceBracket_4M },
    removeManualAlertLevel: true,
  });
}

function makeCommonPotActiveCountFilter(
  args: {
    commonPotUsersIds: Array<IdType>,
    budgetMax: { min?: number, max?: number },
  },
): ContactsSimpleFilter
{
  const { commonPotUsersIds, budgetMax } = args;
  return {
    responsibleUsers: commonPotUsersIds,
    lastInterestDate: { max: 45 },
    budgetMax,
  };
}

export function makeCommonPotActiveCountFilter_1M(commonPotUsersIds: Array<IdType>)
{
  return makeCommonPotActiveCountFilter({
    commonPotUsersIds,
    budgetMax: { max: PropertyPriceBracket_1M - 1 },
  });
}

export function makeCommonPotActiveCountFilter_4M(commonPotUsersIds: Array<IdType>)
{
  return makeCommonPotActiveCountFilter({
    commonPotUsersIds,
    budgetMax: { min: PropertyPriceBracket_1M, max: PropertyPriceBracket_4M - 1 },
  });
}

export function makeCommonPotActiveCountFilter_4MPlus(commonPotUsersIds: Array<IdType>)
{
  return makeCommonPotActiveCountFilter({
    commonPotUsersIds,
    budgetMax: { min: PropertyPriceBracket_4M },
  });
}

class BuyersStore {
  private static DefaultContactsPageSize = 50;
  public readonly PageSizeOptions = [50, 100, 200, 500];

  private _order: OrderField | null = {
    fieldName: 'lastInterestDate',
    ordering: OrderType.DESC,
  };
  get order()
  {
    return this._order;
  }

  private _filter: ContactsSimpleFilter = {};
  get filter()
  {
    return this._filter;
  }

  private _getDefaultFilter(): ContactsSimpleFilter
  {
    return {
      responsibleUsers: sessionStore.mybUser ? [ sessionStore.mybUser.id ] : [],
    };
  }

  public pinnedContacts = new Fetchable(() => GetPinnedBuyerContacts({
    language: localesStore.currentLocale,
    pinnedOrigin: UserPinOrigin.MybBuyers,
  }), { catchUnhandled: handleNonCriticalError });

  public contacts = new UncachedPagination({
    fetch: (baseArgs) => GetBuyerContacts({
      ...baseArgs,
      pinnedOrigin: UserPinOrigin.MybBuyers,
      orderBy: this._order ? [this._order] : undefined,
      ...convertContactsSimpleFilterToAPIFilter(this._filter),
      language: localesStore.currentLocale,
    }).then(makePaginationResultsAutoObservable),
    pageSize: BuyersStore.DefaultContactsPageSize,
  });

  @computed get contactsAndPinned()
  {
    const pinnedContacts = this.pinnedContacts.lastResult;
    const contacts = this.contacts.data?.items;
    return [
      ...(pinnedContacts ?? []),
      ...(contacts ?? []),
    ];
  }

  public setOrderAndReload(order: AdvancedTableColumnOrder<string> | null)
  {
    this._order = (order !== null ? {
      fieldName: order.column,
      ordering: order.desc ? OrderType.DESC : OrderType.ASC,
    } : null);
    return this.contacts.first();
  }

  public setFilter(newFilter: ContactsSimpleFilter)
  {
    this._filter = newFilter;
  }

  public setFilterAndReload(newFilter: ContactsSimpleFilter)
  {
    this.setFilter(newFilter);
    return this.contacts.first();
  }

  public resetFilter()
  {
    this._filter = {};
  }

  public users = new Fetchable(GetUsers, { catchUnhandled: handleNonCriticalError });

  @computed get userCommonPot()
  {
    return this.users.result?.filter((user) => user.isAgencyPotCommun).map(user => user.id);
  }

  public criteria = new Fetchable(async (args: GetCriteriaArgs) => {
    const res = await GetCriteria(args);
    return res.flat();
  }, { catchUnhandled: handleNonCriticalError });

  public selectedContacts = observable.map<IdType, BuyerContact>();

  @computed get selectedContactsList()
  {
    return Array.from(this.selectedContacts.values());
  }

  public messageDrawerStore = new MessageDrawerStore({
    onMessageSentSuccess: () => {
      this.selectedContacts.clear();
      this.refreshContactsAndPinned().catch((error) => formatGlobalErrorMessage(error));
    },
  });

  public openMessageDrawer()
  {
    this.messageDrawerStore.open({
      defaultContacts: () => this.selectedContactsList,
    });
  }

  public reminderDialogStore = new ReminderDialogStore({
    onReminderCreateSuccess: () => this.refresh().catch((error) => formatGlobalErrorMessage(error)),
    onSentPropertiesTableRefresh: () => this.refresh().catch((error) => formatGlobalErrorMessage(error)),
    navigationControls: {
      hasPrevContact: () => this._contactsSelection.hasPrevItem,
      hasNextContact: () => this._contactsSelection.hasNextItem,
      onClickPrev: () => this._prevContact().catch((error) => formatGlobalErrorMessage(error)),
      onClickNext: () => this._nextContact().catch((error) => formatGlobalErrorMessage(error)),
    },
  });

  private _contactsSelection = new PaginationSelection(this.contacts, this.pinnedContacts);

  private async _openReminderDialogWithCurrentContact()
  {
    await this.reminderDialogStore.open(this._contactsSelection.item.id);
  }

  public async openReminderDialog(contactId: number)
  {
    this._contactsSelection.selectItem((contact) => contact.id === contactId);
    await this._openReminderDialogWithCurrentContact();
  }

  private async _prevContact()
  {
    await this._contactsSelection.prevItem();
    await this._openReminderDialogWithCurrentContact();
  }

  private async _nextContact()
  {
    await this._contactsSelection.nextItem();
    await this._openReminderDialogWithCurrentContact();
  }

  public referentTransferStore = new ReferentTransferStore();

  public quickFilterCounts = new Fetchable(async () => {
    const user = sessionStore.mybUser;
    assert(user);
    const commonPotUsersIds = this.userCommonPot ?? [];

    const filter_buyerMatchAndManualCount_1M = convertContactsSimpleFilterToAPIFilter(makeBuyerMatchAndManualCountFilter_1M(user.id));
    const filter_buyerMatchAndManualCount_4M = convertContactsSimpleFilterToAPIFilter(makeBuyerMatchAndManualCountFilter_4M(user.id));
    const filter_buyerMatchAndManualCount_4MPlus = convertContactsSimpleFilterToAPIFilter(makeBuyerMatchAndManualCountFilter_4MPlus(user.id));
    const filter_commonPotActiveCount_1M = convertContactsSimpleFilterToAPIFilter(makeCommonPotActiveCountFilter_1M(commonPotUsersIds));
    const filter_commonPotActiveCount_4M = convertContactsSimpleFilterToAPIFilter(makeCommonPotActiveCountFilter_4M(commonPotUsersIds));
    const filter_commonPotActiveCount_4MPlus = convertContactsSimpleFilterToAPIFilter(makeCommonPotActiveCountFilter_4MPlus(commonPotUsersIds));
    return QuickFilterCounts({
      userId: user.id,
      commonPotUsersIds: commonPotUsersIds,
      filter_buyerMatchAndManualCount_1M: filter_buyerMatchAndManualCount_1M.filter,
      filter_buyerMatchAndManualCount_4M: filter_buyerMatchAndManualCount_4M.filter,
      filter_buyerMatchAndManualCount_4MPlus: filter_buyerMatchAndManualCount_4MPlus.filter,
      filter_commonPotActiveCount_1M: filter_commonPotActiveCount_1M.filter,
      filter_commonPotActiveCount_4M: filter_commonPotActiveCount_4M.filter,
      filter_commonPotActiveCount_4MPlus: filter_commonPotActiveCount_4MPlus.filter,
    });
  }, { catchUnhandled: handleNonCriticalError });

  public updateLocalContact(contactId: IdType, effect: (contact: BuyerContact) => void)
  {
    const contact = this.contacts.data?.items.find((contact) => (contact.id === contactId)) || this.pinnedContacts.lastResult?.find((c) => c.id === contactId);
    if (contact)
      runInAction(() => effect(contact));
  }

  public async setContactVIP(
    args: {
      id: IdType,
      vip: boolean,
    },
  )
  {
    await UpdateContact(args);
    this.updateLocalContact(args.id, (contact) => {
      contact.vip = args.vip;
    });
  };

  private _firstRefresh = true;

  async refresh()
  {
    if (this._firstRefresh)
    {
      if (sessionStore.mybUser)
        this.setFilter(this._getDefaultFilter());
      this._firstRefresh = false;
    }
    await Promise.all([
      this.users.ensureSuccess(),
      this.criteria.ensureSuccess({
        language: localesStore.currentLocale,
        operationType: sessionStore.mybUser?.defaultOperationType,
        geoAreaId: sessionStore.mybUser?.contactSearchAgency?.agencyTreeGeoAreaId,
      }),
      this.refreshContactsAndPinned(),
    ]);
    this.quickFilterCounts.ensureSuccessReload().catch(formatGlobalErrorMessage); // Must be loaded after users. Not awaited to improve loading time.
  }

  public async refreshContactsAndPinned()
  {
    await Promise.all([
      this.contacts.reload(),
      this.pinnedContacts.ensureSuccessReload(),
    ]);
  }

  public async setContactPinned(args: SetContactPinnedArgs)
  {
    await SetContactPinned(args);
    await this.refreshContactsAndPinned();
  }
}

export default new BuyersStore();
